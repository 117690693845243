import { Initiative } from "../../types/services/post";
import Status from "./Status";

interface Props {
  initiative: Initiative;
}
const InitiativeType = ({ initiative }: Props) => {
  const fetchInitiativeType = () => {
    switch (initiative.initiativeType) {
      case "buergerbegehren":
        return "Bürgerbegehren";
        break;

      case "einwohnerinnen-antrag":
        return "Einwohner:innen-Antrag";
        break;

      case "volksbegehren":
        return "Volksbegehren";
        break;

      case "volksinitiative":
        return "Volksinitiative";
        break;

      default:
        return null;
        break;
    }
  };

  const initiativeType = fetchInitiativeType();

  return (
    initiativeType && (
      <Status
        label={initiativeType}
        theme={initiative.type}
      />
    )
  );
};

export default InitiativeType;
