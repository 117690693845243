import { CSSObject } from "@emotion/react";
import { rgba } from "emotion-rgba";

import colors from "@Variables/colors";
import themes, { Tone } from "@Variables/themes";

const background: { [key: string]: CSSObject } = {};
const backgrounds: { [key: string]: typeof background } = {};

for (const color in colors) {
  backgrounds[color] = {
    opacity100: { backgroundColor: colors[color] },
    opacity15: { backgroundColor: rgba(colors[color], 0.15) },
    opacity25: { backgroundColor: rgba(colors[color], 0.25) },
    opacity50: { backgroundColor: rgba(colors[color], 0.5) },
    opacity75: { backgroundColor: rgba(colors[color], 0.75) },
  };
}

export const bgPrimary = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: themes[theme].colors.primary,
});

export const bgPrimaryOpacity15 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.primary, 0.15),
});
export const bgPrimaryOpacity25 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.primary, 0.25),
});
export const bgPrimaryOpacity50 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.primary, 0.5),
});
export const bgPrimaryOpacity75 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.primary, 0.75),
});

export const bgSecondary = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: themes[theme].colors.secondary,
});
export const bgSecondaryOpacity15 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.secondary, 0.15),
});
export const bgSecondaryOpacity25 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.secondary, 0.25),
});
export const bgSecondaryOpacity50 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.secondary, 0.5),
});
export const bgSecondaryOpacity75 = (theme: Tone = "petition"): CSSObject => ({
  backgroundColor: rgba(themes[theme].colors.secondary, 0.75),
});

export const bgTransparent: CSSObject = {
  backgroundColor: "transparent",
};

export const bgOrange = backgrounds.orange.opacity100;
export const bgMauve = backgrounds.mauve.opacity100;
export const bgPurple = backgrounds.purple.opacity100;
export const bgBlue = backgrounds.blue.opacity100;
export const bgCyan = backgrounds.cyan.opacity100;
export const bgTeal = backgrounds.teal.opacity100;
export const bgBlack = backgrounds.black.opacity100;
export const bgWhite = backgrounds.white.opacity100;
export const bgGray1 = backgrounds.gray1.opacity100;
export const bgGray2 = backgrounds.gray2.opacity100;
export const bgGray3 = backgrounds.gray3.opacity100;
export const bgGray4 = backgrounds.gray4.opacity100;
export const bgGray5 = backgrounds.gray5.opacity100;
export const bgDanger = backgrounds.danger.opacity100;
export const bgSuccess = backgrounds.success.opacity100;

export const bgOrangeOpacity15 = backgrounds.orange.opacity15;
export const bgMauveOpacity15 = backgrounds.mauve.opacity15;
export const bgPurpleOpacity15 = backgrounds.purple.opacity15;
export const bgBlueOpacity15 = backgrounds.blue.opacity15;
export const bgCyanOpacity15 = backgrounds.cyan.opacity15;
export const bgTealOpacity15 = backgrounds.teal.opacity15;
export const bgBlackOpacity15 = backgrounds.black.opacity15;
export const bgWhiteOpacity15 = backgrounds.white.opacity15;
export const bgGray1Opacity15 = backgrounds.gray1.opacity15;
export const bgGray2Opacity15 = backgrounds.gray2.opacity15;
export const bgGray3Opacity15 = backgrounds.gray3.opacity15;
export const bgGray4Opacity15 = backgrounds.gray4.opacity15;
export const bgGray5Opacity15 = backgrounds.gray5.opacity15;
export const bgDangerOpacity15 = backgrounds.danger.opacity15;
export const bgSuccessOpacity15 = backgrounds.success.opacity15;

export const bgOrangeOpacity25 = backgrounds.orange.opacity25;
export const bgMauveOpacity25 = backgrounds.mauve.opacity25;
export const bgPurpleOpacity25 = backgrounds.purple.opacity25;
export const bgBlueOpacity25 = backgrounds.blue.opacity25;
export const bgCyanOpacity25 = backgrounds.cyan.opacity25;
export const bgTealOpacity25 = backgrounds.teal.opacity25;
export const bgBlackOpacity25 = backgrounds.black.opacity25;
export const bgWhiteOpacity25 = backgrounds.white.opacity25;
export const bgGray1Opacity25 = backgrounds.gray1.opacity25;
export const bgGray2Opacity25 = backgrounds.gray2.opacity25;
export const bgGray3Opacity25 = backgrounds.gray3.opacity25;
export const bgGray4Opacity25 = backgrounds.gray4.opacity25;
export const bgGray5Opacity25 = backgrounds.gray5.opacity25;
export const bgDangerOpacity25 = backgrounds.danger.opacity25;
export const bgSuccessOpacity25 = backgrounds.success.opacity25;

export const bgOrangeOpacity50 = backgrounds.orange.opacity50;
export const bgMauveOpacity50 = backgrounds.mauve.opacity50;
export const bgPurpleOpacity50 = backgrounds.purple.opacity50;
export const bgBlueOpacity50 = backgrounds.blue.opacity50;
export const bgCyanOpacity50 = backgrounds.cyan.opacity50;
export const bgTealOpacity50 = backgrounds.teal.opacity50;
export const bgBlackOpacity50 = backgrounds.black.opacity50;
export const bgWhiteOpacity50 = backgrounds.white.opacity50;
export const bgGray1Opacity50 = backgrounds.gray1.opacity50;
export const bgGray2Opacity50 = backgrounds.gray2.opacity50;
export const bgGray3Opacity50 = backgrounds.gray3.opacity50;
export const bgGray4Opacity50 = backgrounds.gray4.opacity50;
export const bgGray5Opacity50 = backgrounds.gray5.opacity50;
export const bgDangerOpacity50 = backgrounds.danger.opacity50;
export const bgSuccessOpacity50 = backgrounds.success.opacity50;

export const bgOrangeOpacity75 = backgrounds.orange.opacity75;
export const bgMauveOpacity75 = backgrounds.mauve.opacity75;
export const bgPurpleOpacity75 = backgrounds.purple.opacity75;
export const bgBlueOpacity75 = backgrounds.blue.opacity75;
export const bgCyanOpacity75 = backgrounds.cyan.opacity75;
export const bgTealOpacity75 = backgrounds.teal.opacity75;
export const bgBlackOpacity75 = backgrounds.black.opacity75;
export const bgWhiteOpacity75 = backgrounds.white.opacity75;
export const bgGray1Opacity75 = backgrounds.gray1.opacity75;
export const bgGray2Opacity75 = backgrounds.gray2.opacity75;
export const bgGray3Opacity75 = backgrounds.gray3.opacity75;
export const bgGray4Opacity75 = backgrounds.gray4.opacity75;
export const bgGray5Opacity75 = backgrounds.gray5.opacity75;
export const bgDangerOpacity75 = backgrounds.danger.opacity75;
export const bgSuccessOpacity75 = backgrounds.success.opacity75;

const fetchLinearGradient = (
  primaryColor: string,
  secondaryColor: string,
): string => {
  return `linear-gradient(86.68deg, ${primaryColor} 30%, ${secondaryColor})`;
};

export const bgGradient = (
  theme: Tone = "petition",
  variant: "light" | "dark" = "dark",
) => {
  const opacity = "light" === variant ? 0.1 : 1;
  return {
    background:
      fetchLinearGradient(
        rgba(themes[theme].colors.primary, opacity),
        rgba(themes[theme].colors.secondary, opacity),
      ) + `, linear-gradient(to right, ${colors.white}, ${colors.white})`,
  };
};

/**
 * @param backgroundStyle Extra styles for the background like color, gradient, etc.
 * @param top Display rounded corners on top
 * @param bottom Display rounded corners on bottom
 * @returns Returns a CSSObject with top/+bottom rounded corners
 */
export const bgRounded = (
  backgroundStyle: CSSObject,
  top = true,
  bottom = true,
) => {
  const roundedBorder = "calc(infinity * 1px)";
  const bgRoundedStyle: CSSObject = {
    position: "relative",
    "&:before": {
      borderRadius: `${top ? `${roundedBorder} ${roundedBorder}` : "0 0"} ${
        bottom ? `${roundedBorder} ${roundedBorder}` : "0 0"
      }`,
      content: "''",
      display: "block",
      paddingBottom: `${bottom ? "50%" : 0}`,
      paddingTop: `${top ? "50%" : 0}`,
      position: "absolute",
      zIndex: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      ...backgroundStyle,
    },
    "& > *": {
      position: "relative",
      zIndex: 1,
    },
  };

  return bgRoundedStyle;
};
