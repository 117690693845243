import { Signable } from "../../../types/services/post";
import Fixed from "./Fixed";
import Sidebar from "./Sidebar";

const Aside = ({ signable }: { signable: Signable }) => {
  return (
    <>
      <Fixed signable={signable} />
      <Sidebar signable={signable} />
    </>
  );
};

export default Aside;
