import CardColumn from "../../../emotion/components/CardColumn";
import { Container } from "../../../emotion/components/Container";
import Row from "../../../emotion/components/Row";
import { Signable } from "../../../types/services/post";
import SignatureCard from "@Components/SignatureCard";
import LazyLoader from "@Components/LazyLoader";
import { useSignatureContext } from "@Contexts/SignatureContext";
import { useEffect, useState } from "react";
import { fetchSignatures } from "./fetchSignatures";

export type adjustedSignableType = Signable & { signatureId?: string };

const MySignatures = ({
  initialData,
}: {
  initialData: adjustedSignableType[];
}) => {
  const { signature } = useSignatureContext(); // Access the context
  const [contextSignature, setContextSignature] = useState(signature); // Track signature changes
  const [signatures, setSignatures] = useState(initialData);

  // Update contextSignature state whenever the signature context value changes
  useEffect(() => {
    if (signature?.isDone) {
      setContextSignature({ isDone: true, signatureId: "" });
    }
  }, [signature?.isDone]);

  const handleDelete = (deletedSignatureId: string) => {
    setSignatures((prevSignatures) => {
      console.info({ deletedSignatureId });
      return prevSignatures.filter(
        (sig) => sig.signatureId !== deletedSignatureId,
      );
    });
  };

  return (
    <section>
      <Container size="lg">
        <Row>
          {
            <LazyLoader
              key={`${signatures.map((sig) => sig.signatureId).join("-")}`}
              fetchData={fetchSignatures}
              initialItems={signatures}
              renderItem={(petition: adjustedSignableType) => (
                <CardColumn
                  key={`my-signatures-successes-${petition.signatureId}`}
                  span={{ default: 12, sm: 6, md: 4 }}
                >
                  <SignatureCard
                    mode="edit"
                    signable={petition}
                    modalId="DeleteSignatureModal"
                    onDelete={() => handleDelete(petition.signatureId!)}
                  />
                </CardColumn>
              )}
              limit={6} // Number of items to load per page
            />
          }
        </Row>
      </Container>
    </section>
  );
};

export default MySignatures;
