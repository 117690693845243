import type { CSSObject } from "@emotion/react";
import {
  bodyStyle,
  colorGray5,
  colorTransparent,
  fontSizeLg,
  gapXs,
  marginBottomZero,
  paddingZero,
} from "@Styles";
import type { ColorPalette } from "@Types/configs";
import { type Tone, colors } from "@Variables";
import themes from "@Variables/themes";

const activeCSS = (secondaryColor: string): CSSObject => ({
  "&:active, &:focus, &:hover, &:focus-within": {
    borderColor: secondaryColor,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(118, 118, 118, 0.15)", // eslint-disable-line max-len
    outline: 0,
  },
});

const disabledCSS: CSSObject = {
  "&:disabled, &.disabled, fieldset:disabled &, &[readonly]": {
    borderColor: colors.gray5,
    cursor: "default",
    pointerEvents: "none",
  },
};

const baseBorder: CSSObject = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "0.375rem",
  boxSizing: "border-box",
  width: "100%",
  height: "3rem",
};

const placeholderStyle: CSSObject = {
  "&::placeholder": {
    ...bodyStyle,
    ...colorGray5,
  },
  "&:focus::placeholder": {
    ...colorTransparent,
  },
};

const inputCSS = (swatchColors: ColorPalette): CSSObject => {
  const secondaryColor = swatchColors.secondary.main;

  return {
    ...activeCSS(secondaryColor),
    ...disabledCSS,
    ...baseBorder,
    ...placeholderStyle,
  };
};

const inputErrorStyle = (tone: Tone, hasError = false): CSSObject => {
  const toneBorder =
    tone === "initiative" ? themes[tone].colors.primary : colors.black;

  return {
    borderColor: hasError ? colors.danger : toneBorder,
  };
};

const inputStyle: CSSObject = {
  ...bodyStyle,
  gap: "0.75rem",
  marginBottom: 0,
  padding: "0.75rem 1rem",
};

//#region Radio
const optionStyle: CSSObject = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  boxShadow:
    "0px 0px 2px rgba(130, 136, 148, 0.16), 0px 4px 6px rgba(130, 136, 148, 0.16);", // eslint-disable-line max-len
  borderRadius: "0.375rem",
};

const radioLabelStyle: CSSObject = {
  color: colors.black,
  cursor: "pointer",
  fontWeight: 400,
  justifyItems: "center",
  alignItems: "center",
  display: "flex",
  position: "relative",
  ...fontSizeLg,
  ...gapXs,
  ...marginBottomZero,
};

const radioStyle = (swatchColors: ColorPalette): CSSObject => {
  const primaryColor = swatchColors.primary.main;

  return {
    WebkitAppearance: "none",
    appearance: "none",
    backgroundColor: colors.white,
    border: `1px solid ${primaryColor}`,
    borderRadius: "50%",
    color: colors.white,
    height: "1.25rem",
    textAlign: "center",
    justifyContent: "center",
    transform: "translateY(-0.075em)",
    placeContent: "center",
    width: "1.25rem",
    margin: 0,
    ...paddingZero,

    ":checked": {
      backgroundColor: primaryColor,
    },

    ":disabled": {
      backgroundColor: colors.gray3,
      border: colors.gray4,
    },

    ":focus, :hover": {
      border: `1px solid ${primaryColor}`,
    },

    "::before": {
      backgroundColor: colors.white,
      borderRadius: "50%",
      content: "''",
      height: "0.375rem",
      transform: "scale(0)",
      transition: "120ms transform ease-in-out",
      width: "0.375rem",
    },

    ":checked::before": {
      transform: "scale(1)",
    },
  };
};
//#endregion
export {
  inputCSS,
  inputErrorStyle,
  inputStyle,
  optionStyle,
  radioLabelStyle,
  radioStyle,
};
