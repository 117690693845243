import { Signable } from "../types/services/post";

export const getSignableStatus = (signable: Signable) => {
  if (signable.success) {
    return "success";
  }
  return (
    ("initiative" == signable.type &&
      signable.currentStage &&
      signable.currentStage.status) ||
    ("petition" == signable.type && signable.initiativeStatus) ||
    "canceled"
  );
};
