import { faEnvelopeCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";

const RegistrationMessageModal = () => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      content={t("pages.userAccount.modals.registrationMessage.content")}
      icon={faEnvelopeCircleCheck}
      id="registrationMessage"
      title={t("pages.userAccount.modals.registration.title")}
    ></ModalDialog>
  );
};

export default RegistrationMessageModal;
