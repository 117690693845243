import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import {
  bannerContainerStyle,
  containerStyle,
  headingStyle,
} from "@Appearances/pages/styleHomeReasons";
import { BannerText, Container, Heading, Placeholder } from "@Components";
import { ColumnProps } from "@Components/Column";
import { routes } from "@Configs";
import {
  displayFlex,
  gap2xl,
  paddingBottomZero,
  paddingXMdXl,
  paddingXZero,
} from "@Styles";
import { SectionPageProps } from "@Types/components/pageProps";
import { type Fragment } from "@Types/services/post";
import { useMemo } from "react";

const Reasons = ({ loaded, page, ...props }: SectionPageProps) => {
  const { t } = useTranslation();

  const getPageFragments = () => {
    let fragments: Fragment[] = [];
    let title = "";

    if (page?.fragments?.length) {
      title =
        page.fragments.filter(
          (fragment) => fragment.section == "reasons-title",
        )[0].title ?? "";

      fragments = page.fragments.filter(
        (fragment) => fragment.section == "reasons",
      );
    }
    return { title, fragments };
  };

  const reasons = useMemo(() => {
    if (loaded) {
      return getPageFragments();
    }

    return { title: <Placeholder />, fragments: [] };
  }, [loaded]);

  const columns: Omit<ColumnProps, "children"> = {
    content: {
      span: { default: 12, md: 6, lg: 6 },

      offset: { default: 0, lg: 0 },
      // offset={{
      //   default: 0,
      //   md: imagePosition === "left" ? 1 : 0,
      //   lg: imagePosition === "left" ? 1 : 0,
      // }}
      // css: [
      //   {
      //     justifyContent: "center",
      //     flexDirection: "column",
      //   },
      //   displayFlex,
      // ],
      css: [paddingXMdXl, paddingXZero, { alignContent: "center" }],
    },
    image: {
      span: { default: 12, md: 6, lg: 6 },
      offset: { default: 0, lg: 0, xs: 1 },
      css: [paddingXMdXl, paddingXZero, { alignContent: "center" }],
    },
  };

  return (
    <section
      css={containerStyle}
      {...props}
    >
      <Container
        size="lg"
        css={[paddingBottomZero]}
      >
        <Heading
          css={headingStyle}
          scale={3}
          role="heading"
          aria-level={2}
          color="label"
        >
          {reasons.title}
        </Heading>

        <div css={[displayFlex, gap2xl, { flexDirection: "column" }]}>
          {reasons.fragments.map((fragment) => {
            const imagePosition = fragment.order % 2 ? "right" : "left";

            return (
              <div
                css={bannerContainerStyle}
                key={`hpme-${fragment.section}-${fragment.order}`}
              >
                <BannerText
                  title={fragment.title}
                  content={parse(fragment.content)}
                  attachment={fragment.featuredImage}
                  alt={fragment.title}
                  buttonLabel={t("common.banners.startPetition.buttonLabel")}
                  buttonLink={routes.petition.start}
                  imagePosition={imagePosition}
                  columns={columns}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Reasons;
