import { CSSObject, useTheme } from "@emotion/react";

import type { DivisionComponentProps } from "@Types/components/pageProps";
import { imgWidths } from "@Variables/imageSizes";

const illustrationNames = [
  "alert",
  "data",
  "donation",
  "newsletter",
  "share",
  "signature",
  "society",
  "start",
  "team",
  "thank-you",
] as const;

export type IllustrationNames = (typeof illustrationNames)[number];

export interface IllustrationProps extends DivisionComponentProps {
  /** Name of the illustration. */
  name: IllustrationNames;
  /** Size that the illustration is going to be displayed. */
  size: "sm" | "md" | "lg";
}

/** Component to display illustrations. */
const Illustration = ({ name, size = "sm", ...props }: IllustrationProps) => {
  const { tone } = useTheme();

  const imgStyle: CSSObject = {
    display: "block",
    width: "100%",
    maxWidth: imgWidths[size],
    height: "auto",
  };

  const fileName = `${name}-${tone}-${size}`;
  const webpSource = `${process.env.PUBLIC_URL}/assets/illustrations/webp/${fileName}.webp`;
  const webpSource2x = `${process.env.PUBLIC_URL}/assets/illustrations/webp/${fileName}@2x.webp`;
  const pngSource = `${process.env.PUBLIC_URL}//assets/illustrations/png/${fileName}.png`;
  const pngSource2x = `${process.env.PUBLIC_URL}//assets/illustrations/png/${fileName}@2x.png`;

  return (
    <div {...props}>
      <picture>
        <source
          css={imgStyle}
          srcSet={`${webpSource} 1x, ${webpSource2x} 2x`}
          type="image/webp"
        />
        <img
          alt=""
          css={imgStyle}
          src={pngSource}
          srcSet={`${pngSource} 1x, ${pngSource2x} 2x`}
        />
      </picture>
    </div>
  );
};

export default Illustration;
