import { CSSObject } from "@emotion/react";

export const fonts: CSSObject[] = [
  {
    "@font-face": {
      fontFamily: "Soehne",
      fontStyle: "normal",
      fontWeight: 400,
      src: "url('/fonts/soehne-buch.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne",
      fontStyle: "italic",
      fontWeight: 400,
      src: "url('/fonts/soehne-buch-kursiv.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne",
      fontStyle: "normal",
      fontWeight: 500,
      src: "url('/fonts/soehne-kraftig.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne",
      fontStyle: "normal",
      fontWeight: 700,
      src: "url('/fonts/soehne-dreiviertelfett.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne",
      fontStyle: "italic",
      fontWeight: 700,
      src: "url('/fonts/soehne-dreiviertelfett-kursiv.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne Schmal",
      fontStyle: "normal",
      fontWeight: 700,
      src: "url('/fonts/soehne-schmal-dreiviertelfett.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne Kraftig",
      src: "url('/fonts/soehne-kraftig.woff2') format('woff2')",
    },
  },
  {
    "@font-face": {
      fontFamily: "Soehne Dreiviertelfett",
      src: "url('/fonts/soehne-schmal-dreiviertelfett.woff2') format('woff2')",
    },
  },
];

export const fontFamily = "Soehne, Arial, Helvetica, sans-serif";

export const displayFontFamily =
  "Soehne Schmal, Soehne, Arial, Helvetica, sans-serif";

export const headingFontFamily =
  "Soehne Kraftig, Soehne, Arial, Helvetica, sans-serif";
