import BannerIllustration from "@Components/BannerIllustration";
import { marginTop2xl, marginTopLg } from "@Styles/spacers";
import { Signable } from "@Types/services/post";
import { useTranslation } from "react-i18next";
import MyPetitions from "./my-petitions";

interface StartPetitionBannerProps {
  buttonLabel: string;
  content: string;
  title: string;
}

export const MyPetitionsPage = ({
  initialData,
}: {
  initialData: Signable[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      {initialData?.length ? (
        <section css={marginTopLg}>
          <MyPetitions initialData={initialData} />
        </section>
      ) : (
        <section css={marginTop2xl}>
          <StartPetitionBanner
            buttonLabel={t(
              "pages.userAccount.petitions.startPetition.banner.buttonLabel",
            )}
            title={t("pages.userAccount.petitions.startPetition.banner.title")}
            content={t(
              "pages.userAccount.petitions.startPetition.banner.content",
            )}
          />
        </section>
      )}
    </>
  );
};

const StartPetitionBanner = (props: StartPetitionBannerProps) => {
  return (
    <BannerIllustration
      buttonLink="/start"
      illustration="start"
      {...props}
    />
  );
};
