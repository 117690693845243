import React, { createContext, useContext, useEffect, useReducer } from "react";

import { AxiosError } from "axios";

import { User } from "@Types/services/user";
import api from "@Utils/api";

export enum UserContextAction {
  ME = "me",
  LOGIN_ERROR = "loginError",
  LOADING = "loading",
  LOGOUT = "logout",
  UPDATE_USER = "updateUser", // Add this new action
}

interface UserProviderProps {
  children: React.ReactNode;
}

export interface UserReducerPayload {
  isAuth: boolean;
  isLoading: boolean;
  user?: User;
  error?: AxiosError;
}

export interface UserReducerAction {
  type: UserContextAction;
  payload?: UserReducerPayload;
}

export type UnauthUser = {
  email: string;
  hasPassword?: false;
  password: string;
};

const isLoading = (): UserReducerAction => ({
  type: UserContextAction.LOADING,
});

export const login = (
  params: { email: string; password?: string; token?: string },
  dispatch: React.Dispatch<UserReducerAction>,
) => {
  dispatch(isLoading());

  api
    .login(params)
    .then(async () => {
      await me(dispatch);
    })
    .catch((error) => {
      dispatch({
        type: UserContextAction.LOGIN_ERROR,
        payload: { isAuth: false, isLoading: false, error },
      });
    });
};

export const logout = (dispatch: React.Dispatch<UserReducerAction>) => {
  dispatch(isLoading());

  api
    .logout()
    .then(() => {
      me(dispatch);
    })
    .catch(() => null);
};

export const me = (dispatch: React.Dispatch<UserReducerAction>) => {
  dispatch(isLoading());

  api
    .me()
    .then((response) => {
      dispatch({
        type: UserContextAction.ME,
        payload: { isAuth: true, isLoading: false, user: response.data.me },
      });
    })
    .catch(() => {
      dispatch({
        type: UserContextAction.LOGIN_ERROR,
        payload: { isAuth: false, isLoading: false },
      });
    });
};

export const UserContext = createContext<UserReducerPayload>({
  isAuth: false,
} as UserReducerPayload);

export const useUserContext = (): UserReducerPayload => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within User Provider");
  }

  return context;
};

export const UserDispatchContext = createContext<
  React.Dispatch<UserReducerAction>
>({} as React.Dispatch<UserReducerAction>);

export const useUserDispatchContext = (): React.Dispatch<UserReducerAction> => {
  const context = useContext(UserDispatchContext);

  if (!context) {
    throw new Error(
      "useUserDispatchContext must be used within UserDispatchContext Provider",
    );
  }

  return context;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [payload, dispatch] = useReducer(userReducer, {
    isAuth: false,
    isLoading: false,
    user: {} as User,
    error: {} as AxiosError,
  });

  useEffect(() => {
    (async () => {
      await me(dispatch);
    })();
  }, []);

  return (
    <UserContext.Provider value={payload}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export const userReducer = (
  payload: UserReducerPayload,
  action: UserReducerAction,
): UserReducerPayload => {
  switch (action.type) {
    case UserContextAction.LOADING:
      return { ...payload, isLoading: true };
    case UserContextAction.ME:
      return {
        ...payload,
        isAuth: true,
        isLoading: false,
        user: action.payload?.user,
      };
    case UserContextAction.LOGIN_ERROR:
      return {
        ...payload,
        isAuth: false,
        isLoading: false,
        error: action.payload?.error,
      };
    case UserContextAction.LOGOUT:
      return { ...payload, isAuth: false, isLoading: false, user: undefined };

    case UserContextAction.UPDATE_USER: // Add the new case
      return {
        ...payload,
        user: {
          ...payload,
          ...(action.payload?.user as User),
        },
      };

    default:
      return payload;
  }
};
