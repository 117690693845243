import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { CookiesProvider } from "react-cookie";
import { Outlet, ScrollRestoration } from "react-router-dom";

import {
  CookieBanner,
  CookieModal,
  Footer,
  Main,
  NavBar,
  NewsletterModal,
} from "@Components";
import { AuthProvider } from "@Contexts/AuthContext";
import { ModalsProvider } from "@Contexts/ModalsContext";
import { PageTitleProvider } from "@Contexts/PageTitleContext";
import { ThemeProvider } from "@Contexts/ThemeContext";
import { UserProvider } from "@Contexts/UserContext";
import { GlobalStyles } from "@Stylize/global/GlobalStyles";
import { QueryClientProvider } from "@tanstack/react-query";
import matomoOptions from "@Utils/matomoOptions";
import { useCookieOptions } from "@Utils/useCookies";
import queryClient from "./configs/react.query";
import AuthenticationModals from "./pages/AuthenticationModals";
import { SignatureProvider } from "@Contexts/SignatureContext";
import UserProfileModals from "./pages/UserProfileModals";

const App = () => {
  const { cookieConsent } = useCookieOptions();

  return (
    <QueryClientProvider client={queryClient}>
      <MatomoProvider value={createInstance(matomoOptions)}>
        <CookiesProvider>
          <UserProvider>
            <AuthProvider>
              <ThemeProvider>
                <SignatureProvider>
                  <PageTitleProvider>
                    <ModalsProvider>
                      <GlobalStyles />
                      <NavBar />
                      <Main>
                        <Outlet />
                        <ScrollRestoration />
                      </Main>
                      <Footer />
                      <CookieBanner display={!cookieConsent} />
                      <CookieModal />
                      <NewsletterModal />
                      <AuthenticationModals />
                      <UserProfileModals />
                    </ModalsProvider>
                  </PageTitleProvider>
                </SignatureProvider>
              </ThemeProvider>
            </AuthProvider>
          </UserProvider>
        </CookiesProvider>
      </MatomoProvider>
    </QueryClientProvider>
  );
};

export default App;
