import { CSSObject, useTheme } from "@emotion/react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { bgPrimary, bgSecondary } from "../styles/backgrounds";
import { borderPrimary, borderSecondary } from "../styles/borders";
import { fontWeightMedium } from "../styles/fontWeights";
import { colorPrimary } from "../styles/textColors";
import { colors } from "../variables";
import { BodyLg } from "./typography";

interface Props {
  /** Active step. */
  active: number;
  /** Number of steps. */
  max: number;
  /** An object where the key is the number of the step and the value is a callback action to be triggered when the step is clicked. */
  onClick?: (step: number) => void;
  /** in rem unit */
  buttonSize: number;
}

const Steps = ({ active, max, onClick, buttonSize }: Props) => {
  const { colors: swatchColors, tone } = useTheme();

  const { t } = useTranslation();
  const steps: number[] = Array.from(
    { length: max },
    (_value, index) => index + 1,
  );

  const primaryColor = swatchColors.primary.main;
  const secondaryColor = swatchColors.secondary.main;

  const size = `${buttonSize}rem`;
  const baseListStyles: CSSObject = {
    listStyle: "none",
    margin: 0,
    padding: 0,
    color: "initial",
  };
  const baseStyles: { [key: string]: CSSObject } = {
    list: {
      ul: baseListStyles,
      li: baseListStyles,
      display: "flex",
      justifyContent: "space-between",
    },

    link: {
      ...borderPrimary(tone),
      ...colorPrimary(tone),
      ...fontWeightMedium,
      alignItems: "center",
      backgroundColor: colors.white,
      borderRadius: "50%",
      display: "flex",
      height: size,
      justifyContent: "center",
      margin: 0,
      textDecoration: "none",
      width: size,
    },

    listItem: {
      listStyle: "none",
      position: "relative",

      ":not(:last-child)": {
        flexGrow: 1,
      },

      ":not(:last-child):after": {
        borderTop: `1px solid ${primaryColor}`,
        content: "''",
        height: "1px",
        left: size,
        position: "absolute",
        right: "0%",
        top: "50%",
      },
    },
  };

  const activeStyles: { [key: string]: CSSObject } = {
    link: {
      ...bgPrimary(tone),
      color: colors.white,
    },
  };

  const checkedStyles: { [key: string]: CSSObject } = {
    link: {
      ...bgSecondary(tone),
      ...borderSecondary(tone),
      color: colors.white,
    },

    listItem: {
      ":not(:last-child):after": {
        borderTopColor: secondaryColor,
      },
    },
  };

  const clickableStyles: { [key: string]: CSSObject } = {
    link: {
      cursor: "pointer",
    },
  };

  const handleClick = (step: number) => {
    if (step == active) {
      return;
    }

    onClick?.(step);
  };

  return (
    <nav aria-label={t("components.steps.steps") as string}>
      <ul css={baseStyles.list}>
        {steps.map((step) => {
          const checked = active > step;
          const clickable = onClick;
          const linkStyle = [baseStyles.link];
          const listItemStyle = [baseStyles.listItem];

          if (step === active) {
            linkStyle.push(activeStyles.link);
          }

          if (checked) {
            listItemStyle.push(checkedStyles.listItem);
            linkStyle.push(checkedStyles.link);
          }

          if (clickable) {
            linkStyle.push(clickableStyles.link);
          }

          return (
            <li
              key={"step" + step.toString()}
              css={listItemStyle}
            >
              <BodyLg
                aria-label={t("components.steps.step", { step }) as string}
                as={clickable ? "a" : "span"}
                css={linkStyle}
                onClick={() => handleClick(step)}
              >
                {checked ? (
                  <FontAwesomeIcon
                    aria-label={t("components.steps.complete") as string}
                    fixedWidth
                    icon={faCheck}
                  />
                ) : (
                  step
                )}
              </BodyLg>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Steps;
