import { useContext, useEffect } from "react";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../contexts/AuthContext";
import { ModalsContext } from "../../contexts/ModalsContext";
import ModalDialog from "../../emotion/components/ModalDialog";

const EmailConfirmedMessageModal = () => {
  const { auth, setAuth } = useContext(AuthContext);

  const { openModal, toggleModal } = useContext(ModalsContext);

  const { t } = useTranslation();

  useEffect(() => {
    if ("verify" === auth.action && "emailConfirmedMessage" !== openModal) {
      toggleModal("emailConfirmedMessage");
      setAuth({});
    }
  }, [auth, openModal, setAuth, toggleModal]);

  return (
    <ModalDialog
      content={t("pages.userAccount.modals.emailConfirmedMessage.content")}
      icon={faCheck}
      id="emailConfirmedMessage"
      title={t("pages.userAccount.modals.emailConfirmedMessage.title")}
    ></ModalDialog>
  );
};

export default EmailConfirmedMessageModal;
