import linearGradient from "./colorGradient";
import alterOpacity from "./colorOpacity";
import opacityVariants from "./colorOpacityVariants";
import generateColorVariants from "./colorVariants";
import capitalize from "./stringCapitalize";

const color = {
  linearGradient,
  alterOpacity,
  opacityVariants,
  generateColorVariants,
};

const word = {
  capitalize,
};

export { color, word };
