import { marginTopLg, paddingLeftXl, paddingTopLg } from "@Styles/spacers";
import MySignatures, { adjustedSignableType } from "./my-signatures";
import { Heading } from "@Components/Heading";
import { Container } from "@Components/Container";
import Row from "@Components/Row";
import { useTranslation } from "react-i18next";

export const MySignaturesPage = ({
  initialData,
}: {
  initialData: adjustedSignableType[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      {initialData?.length ? (
        <section css={marginTopLg}>
          <MySignatures initialData={initialData} />
        </section>
      ) : (
        <section css={[paddingTopLg, paddingLeftXl]}>
          <Container size="lg">
            <Row css={{ width: "50%" }}>
              <Heading
                variant="display"
                scale={3}
                color="label"
              >
                {t("pages.userAccount.signatures.NoSignaturesHeadline")}
              </Heading>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};
