import {
  bannerContainerStyle,
  buttonContainerCSS,
  orderColumn,
  titleStyles,
} from "@Appearances/components/stylesBannerText";
import type { BannerTextProps } from "@Types/components/typeBannerText";
import AttachmentImage from "./AttachmentImage";
import Button from "./Button";
import Column from "./Column";
import { Container } from "./Container";
import { Heading } from "./Heading";
import Row from "./Row";
import { Body } from "./typography";

/**
 * Display a text banner with call-to-action and an image.
 */

const BannerText = ({
  alt,
  attachment,
  buttonLabel,
  buttonLink,
  content,
  imagePosition = "right",
  title,
  columns,
  ...props
}: BannerTextProps) => {
  return (
    <Container
      {...props}
      size="lg"
      css={[bannerContainerStyle]}
    >
      <Row>
        <Column
          span={{ default: 12, sm: 12, md: 6, lg: 6 }}
          order={orderColumn(1, imagePosition)}
          // offset={{
          //   default: 0,
          //   md: imagePosition === "left" ? 1 : 0,
          //   lg: imagePosition === "left" ? 1 : 0,
          // }}
          {...columns?.content}
        >
          <Heading
            scale={4}
            color="primary"
            css={titleStyles}
          >
            {title}
          </Heading>

          <Body>{content}</Body>

          <div css={buttonContainerCSS}>
            <Button
              as="a"
              fullWidth={{ default: true, md: false }}
              label={buttonLabel}
              href={buttonLink}
              size="md"
              variant="primary"
            />
          </div>
        </Column>

        <Column
          span={{ default: 12, md: 6, lg: 6 }}
          order={orderColumn(2, imagePosition)}
          css={[{ alignContent: "center" }]}
          {...columns?.image}
        >
          <AttachmentImage
            attachment={attachment}
            alt={alt}
            size="lg"
          />
        </Column>
      </Row>
    </Container>
  );
};

export default BannerText;
