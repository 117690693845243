import styled, { CSSObject } from "@emotion/styled";

import { css, useTheme } from "@emotion/react";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  bodyLgStyle,
  bodySmStyle,
  bodyStyle,
  fontSizeMd,
  labelStyle,
  lineClampStyle,
  linkStyle,
  marginBottomSm,
  marginLeftZero,
  marginRightMd,
  marginRightXs,
} from "@Styles";
import type { TypographyBody } from "@Types/typesTypography";
import { type Tone } from "@Variables/themes";
import React from "react";

//#region body
const bodyCSS = css([bodyStyle]);

/**
 * A component to display body text.
 * Size: 16px/1rem
 * @param children - The content to display.
 * @param lineClamp - The number of lines to display before truncating.
 * @param color - The color of the text.
 * @param align - The alignment of the text.
 */
export const Body = styled.p<TypographyBody>`
  ${bodyCSS}
  ${({ lineClamp }) => lineClampStyle({ lineClamp })}
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors.label.main};
`;

const bodyLgCSS = css(bodyLgStyle);

/**
 * A component to display large body text.
 * Size:20px/1.25rem
 * @param children - The content to display.
 * @param lineClamp - The number of lines to display before truncating.
 * @param color - The color of the text.
 * @param align - The alignment of the text.
 * @param style - Additional styles to apply.
 */
export const BodyLg = styled.p<TypographyBody>`
  ${bodyLgCSS}
  ${({ lineClamp }) => lineClampStyle({ lineClamp })}
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors.label.main};
`;

/**
 * A component to display small body text.
 * Size: 12px/0.75rem
 * @param children - The content to display.
 * @param lineClamp - The number of lines to display before truncating.
 * @param color - The color of the text.
 * @param align - The alignment of the text.
 */
const bodySmCSS = css(bodySmStyle);
export const BodySm = styled.p<TypographyBody>`
  ${bodySmCSS}
  ${({ lineClamp }) => lineClampStyle({ lineClamp, lineHeight: "bodySm" })}
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors.label.main};
`;

//#endregion body

type LabelProps = {
  children: React.ReactNode;
  icon?: IconProp;
  htmlFor?: string;
  variant?: "primary" | "secondary";
};

export const Label = ({
  children,
  icon,
  variant = "primary",
  ...props
}: LabelProps) => {
  const { colors } = useTheme();

  const iconContainerStyle: CSSObject[] = [
    { color: colors[variant].main },
    marginRightXs,
    fontSizeMd,
  ];

  const labelCSS: CSSObject[] = [];
  labelCSS.push({
    color: colors.label.main || labelStyle.color,
  });

  return (
    <label
      {...props}
      css={labelCSS}
    >
      {icon && (
        <span css={iconContainerStyle}>
          <FontAwesomeIcon
            fixedWidth
            icon={icon}
          />
        </span>
      )}
      {children}
    </label>
  );
};

// Ensure the Tone is compatible with Theme
export interface LinkProps extends React.ComponentPropsWithRef<"a"> {
  variant: "neutral";
  to?: string;
  as?: React.ElementType;
}

export const StyledLink = styled.a<LinkProps>(({ variant }) =>
  linkStyle(variant),
);

export const Link: React.FC<Partial<LinkProps>> = ({
  variant: tone,
  ...props
}) => {
  const theme = useTheme();
  const appliedTone = (tone || theme.tone) as Tone;

  return (
    <StyledLink
      {...props}
      variant={appliedTone as any}
    />
  );
};

interface IconLiProps extends React.ComponentPropsWithRef<"li"> {
  children: React.ReactNode;
  icon: IconProp;
  label?: string;
  variant: "primary" | "secondary";
}

export const IconLi = ({ children, icon, label, variant }: IconLiProps) => {
  const { colors } = useTheme();
  const liCSS: CSSObject[] = [
    marginLeftZero,
    marginBottomSm,
    {
      alignItems: "center",
      display: "flex",
      listStyle: "none",
    },
  ];

  const iconCSS: CSSObject[] = [marginRightMd, { color: colors[variant].main }];

  return (
    <li css={liCSS}>
      <span>
        <FontAwesomeIcon
          aria-hidden={label ? false : true}
          aria-label={label ? label : undefined}
          css={iconCSS}
          fixedWidth
          icon={icon}
        />
      </span>
      <span>{children}</span>
    </li>
  );
};
