import Placeholder from "./Placeholder";

interface ContentLoaderProps {
  /** Number of lines to be displayed. */
  readonly lines?: number;
  /** Whether the content is loading or not. */
  readonly loading: boolean;
  /** The content to display when loading is false. */
  children?: React.ReactNode;
}

/**
 * A component to display a loading animation while the content is loading.
 * @param lines - Number of lines to be displayed.
 * @param loading - Whether the content is loading or not.
 * @param children - The content to display when loading is false.
 */
const ContentLoader = ({
  lines = 1,
  loading,
  children,
}: ContentLoaderProps) => {
  let totalLines = lines;
  if (totalLines < 1) {
    totalLines = 1;
  }

  if (!loading) {
    return <>{children}</>;
  }

  return <Placeholder lines={lines} />;
};

export default ContentLoader;
