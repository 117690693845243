import api from "@Utils/api";

export const fetchPetitions = async (offset: number, limit = 6) => {
  return api
    .getSignables({
      type: "petition",
      initiator: "me",
      offset,
      limit,
    })
    .then((response) => response.data);
};
