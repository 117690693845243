import React, { useContext, useRef } from "react";

import { CSSObject, keyframes, useTheme } from "@emotion/react";

import { ModalsContext } from "@Contexts/ModalsContext";
import {
  bgGray5Opacity15,
  bgSecondaryOpacity25,
  bgWhite,
  elevation3,
  marginBottomSm,
  paddingBottomLg,
  paddingBottomMdZero,
  paddingLeftMd,
  paddingLeftMdXl,
  paddingRightMd,
  paddingRightMdMd,
  paddingRightMdXl,
  paddingSm,
  paddingTopMdMd,
  paddingXMd,
  paddingXMdXl,
} from "@Styles";
import { breakpointUp, zIndexes } from "@Variables";
import CloseButton from "./CloseButton";

export interface ModalProps {
  children: React.ReactNode;
  /** Extra CSS for the modal. */
  modalCss?: CSSObject | CSSObject[];
  /** Footer content. */
  footer?: React.ReactNode;
  /** Modal ID. */
  id: string;
}

/**
 * Creates a modal that can be triggered from an outside element.
 */
const Modal = ({ children, footer, id, modalCss = {} }: ModalProps) => {
  const { tone } = useTheme();
  const { openModal, toggleModal } = useContext(ModalsContext);

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  const backdropStyle: CSSObject = {
    ...bgGray5Opacity15,
    height: "100vh",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: zIndexes.modalBackdrop,
  };

  const modalContainerStyle: CSSObject = {
    animation: `${fadeIn} 1s ease`,
    bottom: 0,
    height: "100%",
    left: 0,
    outline: 0,
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: zIndexes.modal,
  };

  const modalStyle: CSSObject[] = [
    bgWhite,
    elevation3,
    paddingBottomLg,
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",

      left: "50%",
      position: "relative",
      top: "50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "calc(100vh - 83.78px)",
      maxWidth: "calc(100vw - 32px)",
      width: "22.5rem",
      [breakpointUp["md"]]: {
        width: "34.5rem",
        maxHeight: "calc(100vh - 219.32px)",
      },
    },
  ];

  const modalContentStyle: CSSObject[] = [
    marginBottomSm,
    paddingLeftMd,
    paddingLeftMdXl,
    paddingRightMd,
    paddingRightMdXl,
    {
      flex: "1 1 auto",
      overflowY: "auto",

      "::-webkit-scrollbar": {
        width: "0.75rem",
      },

      "::-webkit-scrollbar-track": {
        ...bgWhite,
        borderRadius: "0.5rem",
      },
      "::-webkit-scrollbar-thumb": {
        ...bgSecondaryOpacity25(tone),
        borderRadius: "1.25rem",
        border: "0.188rem solid white",
      },
    },
  ];

  const modalFooterStyle: CSSObject[] = [paddingXMd, paddingXMdXl];

  const closeButtonContainerStyle: CSSObject[] = [
    paddingSm,
    paddingTopMdMd,
    paddingBottomMdZero,
    paddingRightMdMd,
  ];

  const modalRef = useRef<HTMLDivElement>(null);

  const outsideClose = (event: React.MouseEvent<HTMLDivElement>) => {
    const { target } = event;
    event.stopPropagation();
    if (modalRef.current && !modalRef.current.contains(target as HTMLElement)) {
      toggleModal(id);
    }
  };

  return (
    <>
      {id == openModal && (
        <>
          <div
            css={modalContainerStyle}
            onClick={outsideClose}
          >
            <div
              ref={modalRef}
              css={[modalStyle, modalCss]}
              role="dialog"
            >
              <div css={closeButtonContainerStyle}>
                <CloseButton
                  bg="light"
                  onClick={() => toggleModal(id)}
                />
              </div>
              <div css={modalContentStyle}>{children}</div>
              {footer && <div css={modalFooterStyle}>{footer}</div>}
            </div>
          </div>
          <div css={backdropStyle}></div>
        </>
      )}
    </>
  );
};

export default Modal;
