import React from "react";

import styled, { CSSObject } from "@emotion/styled";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Panel from "../../../../emotion/components/Panel";
import { FieldHelpSetter } from "../../utils/types";

interface ContentHelpProps {
  setFieldHelp: FieldHelpSetter;
}

const ContentHelp = ({ setFieldHelp }: ContentHelpProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    header: t("components.startPetitionForm.help.fieldTips.content.header"),
    listTitle: t(
      "components.startPetitionForm.help.fieldTips.content.list.title",
    ),
    listItems: [
      {
        bold: t(
          "components.startPetitionForm.help.fieldTips.content.list.item1.bold",
        ),
        normal: t(
          "components.startPetitionForm.help.fieldTips.content.list.item1.normal",
        ),
      },
      {
        bold: t(
          "components.startPetitionForm.help.fieldTips.content.list.item2.bold",
        ),
        normal: t(
          "components.startPetitionForm.help.fieldTips.content.list.item2.normal",
        ),
      },
      {
        bold: t(
          "components.startPetitionForm.help.fieldTips.content.list.item3.bold",
        ),
        normal: t(
          "components.startPetitionForm.help.fieldTips.content.list.item3.normal",
        ),
      },
      {
        bold: t(
          "components.startPetitionForm.help.fieldTips.content.list.item4.bold",
        ),
        normal: t(
          "components.startPetitionForm.help.fieldTips.content.list.item4.normal",
        ),
      },
      {
        bold: t(
          "components.startPetitionForm.help.fieldTips.content.list.item5.bold",
        ),
        normal: t(
          "components.startPetitionForm.help.fieldTips.content.list.item5.normal",
        ),
      },
    ],
  };

  const boldTextStyle: CSSObject = {
    fontWeight: "700",
    fontSize: "16px",
  };

  const ListTitle = styled.p(boldTextStyle);

  const BoldSpan = styled.span(boldTextStyle);

  const StyledList = styled.ul({
    listStyleType: "none",
    paddingLeft: "1em",
  });

  const StyledListItem = styled.li({
    marginLeft: "0",
    paddingLeft: "1rem",
    marginBottom: "1.5rem",
    "&::marker": {
      content: "'•'",
    },
  });

  return (
    <Panel
      header={CONTENT.header}
      icon={faInfoCircle}
      onClose={() => {
        setFieldHelp(null);
      }}
    >
      <ListTitle>{CONTENT.listTitle}</ListTitle>
      <StyledList>
        {CONTENT.listItems.map((item, index) => (
          <StyledListItem key={index}>
            <BoldSpan>{item.bold}</BoldSpan>
            {item.normal}
          </StyledListItem>
        ))}
      </StyledList>
    </Panel>
  );
};

export default ContentHelp;
