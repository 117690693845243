import React, { useContext, useEffect } from "react";

import { CSSObject } from "@emotion/react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { PageTitleContext } from "../../contexts/PageTitleContext";
import { navBarHeight } from "../variables/navBar";

type MainProps = {
  children: React.ReactNode;
};

const mainStyle: CSSObject = {
  marginTop: navBarHeight,
};

const Main = ({ children }: MainProps) => {
  const { pageTitle } = useContext(PageTitleContext);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    if (pageTitle) {
      document.title = `${pageTitle} | innn.it`;
    } else {
      document.title = "innn.it";
    }
  }, [pageTitle]);

  return <main css={mainStyle}>{children}</main>;
};

export default Main;
