import React, { ReactNode, useId, useState } from "react";

import { CSSObject } from "@emotion/react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  marginTopLg,
  marginTopXl,
  paddingMd,
  paddingXMd,
  paddingXXl,
} from "@Styles";
import { type Tone, breakpointUp, colors } from "@Variables";
import themes from "../variables/themes";
import { Heading } from "./Heading";

export interface QandAItemProps {
  children: ReactNode;
  question: string;
  tone?: Tone;
}

const QandAItem: React.FunctionComponent<QandAItemProps> = ({
  children,
  question,
  tone = "petition",
}) => {
  const [open, setOpen] = useState(false);
  const id = useId();

  const primaryColor = themes[tone].colors.primary;
  const secondaryColor = themes[tone].colors.secondary;
  const containerStyle: CSSObject = {
    borderBottom: `2px solid ${secondaryColor}`,
  };

  const childrenStyle: CSSObject = {
    maxHeight: 0,
    overflow: "hidden",
    ...paddingXMd,
    transition: "max-height 300ms",

    [breakpointUp["md"]]: {
      ...paddingXXl,
    },
    "> *:first-of-type": {
      [breakpointUp["md"]]: {
        ...marginTopXl,
      },
      ...marginTopLg,
    },
    ...(open && {
      maxHeight: "300rem",
    }),
  };

  const iconStyle: CSSObject = {
    transition: "all 300ms ease-in-out",
  };

  const questionStyle: CSSObject = {
    alignItems: "center",
    height: "7rem",
    backgroundColor: open ? secondaryColor : "",
    color: open ? colors.white : colors.black,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    transition: "background-color 300ms ease-in-out",
    ...paddingMd,
    [breakpointUp["md"]]: {
      ...paddingXXl,
    },
  };

  return (
    <div
      css={containerStyle}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div css={questionStyle}>
        <Heading
          scale={5}
          id={`question-${id}`}
          aria-controls={`answer-${id}`}
          color="label"
        >
          {question}
        </Heading>

        <div>
          <FontAwesomeIcon
            css={iconStyle}
            icon={faChevronDown}
            color={open ? colors.white : primaryColor}
            rotation={open ? 180 : undefined}
            fixedWidth
            size="2x"
          />
        </div>
      </div>

      <div
        css={childrenStyle}
        id={`answer-${id}`}
        aria-labelledby={`question-${id}`}
      >
        {children}
      </div>
    </div>
  );
};

export default QandAItem;
