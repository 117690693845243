import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import Visible from "@Components/atoms/Visible";
import { Container } from "@Components/Container";
import Steps from "@Components/Steps";
import type { UserFormFields } from "@Components/UserForm";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import {
  marginBottomXl,
  marginX2xl,
  marginX4xl,
  paddingTopXl,
} from "@Styles/spacers";
import { PSF_STEP } from "@Types/components/psf";
import type { Signed } from "@Types/services/documents";
import type { Signable } from "@Types/services/post";
import api from "@Utils/api";

import { CSSObject, useTheme } from "@emotion/react";
import { breakpointUp } from "@Variables/breakpoints";
import ErrorPage from "../Error";
import PSFAlert from "./PSFAlert";
import MainStepComponent from "./PSFSteps";

type IPSStep = PSF_STEP.DocumentRequest | PSF_STEP.Donation | PSF_STEP.Share;
const postFlowSteps: IPSStep[] = [
  PSF_STEP.DocumentRequest,
  PSF_STEP.Donation,
  PSF_STEP.Share,
];

const PSF = () => {
  const [currentStep, setCurrentStep] = useState<PSF_STEP>(PSF_STEP.Donation);
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setPageTitle } = useContext(PageTitleContext);
  const { tone, setTone } = useTheme();

  const state = location.state as {
    signable?: Signable;
    signed?: Signed;
    signer?: UserFormFields;
  };

  const [signable, setSignable] = useState<Signable>(
    state?.signable || ({} as Signable),
  );
  const signer =
    state?.signer ||
    ({
      firstName: "",
      lastName: "",
      email: "",
      country: "DE",
      postalCode: "",
      postalCodeDisplay: "",
      location: "",
      locationDisplay: "",
    } as UserFormFields);

  const { _id, step = PSF_STEP.Donation.toString() } = useParams();

  useEffect(() => {
    if (!signable.name) {
      if (!_id) {
        return setHasError(true);
      }

      api
        .getSignable(_id)
        .then((response) => {
          return setSignable(response.data);
        })
        .catch(() => {
          return setHasError(true);
        })
        .finally(() => setLoaded(false));
    } else if (signable.name != _id) {
      if (signable._id == _id) {
        return navigate(`/${signable.name}/${PSF_STEP.Donation}`, {
          replace: true,
          state: location.state,
        });
      }
      return setHasError(true);
    }

    const currentStep = parseInt(step);

    if (postFlowSteps.includes(currentStep)) {
      setCurrentStep(currentStep);
    } else {
      return navigate(`/${_id}/${PSF_STEP.Donation}`, { replace: true });
    }

    setLoaded(true);
  }, [_id, location.state, navigate, signable, step]);

  useEffect(() => {
    if (signable.type && signable.type !== tone) {
      setTone(signable.type);
    }

    setPageTitle(signable.title);
  }, [setPageTitle, tone, signable]);

  if (hasError) {
    return <ErrorPage />;
  }

  const handleStepsClick = (step: PSF_STEP) =>
    navigate(`/${signable.name}/${step}`);

  const stepStyles: CSSObject = {
    ...marginX4xl,
    [breakpointUp["xs"]]: marginX2xl,
  };

  return (
    <article>
      <Visible when={loaded}>
        <PSFAlert />
      </Visible>

      <section css={paddingTopXl}>
        <Container
          size="sm"
          css={[marginBottomXl]}
          style={{
            alignItems: "center",
          }}
        >
          <div css={stepStyles}>
            <Visible when={loaded}>
              <Steps
                active={currentStep}
                max={postFlowSteps.length}
                onClick={handleStepsClick}
                buttonSize={3}
              />
            </Visible>
          </div>
        </Container>

        <MainStepComponent
          currentStep={currentStep}
          loaded={loaded}
          signable={signable}
          signer={signer}
        />
      </section>
    </article>
  );
};

export default PSF;
