import { CSSObject } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckDouble,
  faPartyHorn,
  faPen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { InitiativeStatus, Signable } from "../../types/services/post";
import { getSignableCountdown } from "../../utils/getSignableCountdown";
import { formatDate } from "../helpers/formatDate";
import { bgWhite } from "../styles/backgrounds";
import {
  gapSm,
  marginBottomLg,
  marginRightXs,
  paddingXMd,
  paddingYSm,
} from "../styles/spacers";
import { colorSecondary } from "../styles/textColors";
import colors from "../variables/colors";
import { Tone } from "../variables/themes";
import Button from "./Button";
import ButtonContainer from "./ButtonContainer";
import { Card, CardContent, CardHeader } from "./Card";
import Headline from "./Headline";
import InitiativeStage from "./InitiativeStage";
import InitiativeType from "./InitiativeType";
import SignatureCounter from "./SignatureCounter";
import { BodySm } from "./typography";
import { ModalsContext, useModalContext } from "@Contexts/ModalsContext";
import { useSignatureContext } from "@Contexts/SignatureContext";
import { adjustedSignableType } from "~/pages/UserProfile/MySignatures/my-signatures";

export interface Props {
  /** Signable object from the API. */
  signable: Signable;
  /** Mode to display the card. */
  mode?: "default" | "edit";

  modalId?: string;
  onDelete?: () => void; // Add this prop to handle deletion
}

export interface CardHeaderNavProps {
  status: InitiativeStatus;
  date?: string;
}

/**
 *  Header
 */

const STATUSES = ["deleted", "draft", "ended", "publish", "success"] as const;
type Status = (typeof STATUSES)[number];
type StatusesContent = {
  [key in Status]: {
    icon: IconProp;
    label: "string";
  };
};

const headerStyle: CSSObject[] = [
  bgWhite,
  paddingXMd,
  paddingYSm,
  {
    alignItems: "center",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-between",
    position: "relative",
  },
];

const SignableCardHeader = ({
  createdAt,
  isEnded,
  isSuccess,
  signableStatus,
  tone,
}: {
  createdAt: string;
  isEnded: boolean;
  isSuccess: boolean;
  signableStatus: Signable["status"];
  tone: Tone;
}) => {
  const { i18n, t } = useTranslation();
  let status: Status = "deleted";

  if ("draft" == signableStatus) {
    status = "draft";
  } else if (isSuccess) {
    status = "success";
  } else if (isEnded) {
    status = "ended";
  } else if ("publish" == signableStatus) {
    status = "publish";
  }

  const STATUSES_CONTENT: StatusesContent = {
    deleted: {
      icon: faTrash,
      label: t("components.signatureCard.statuses.deleted"),
    },
    draft: {
      icon: faPen,
      label: t("components.signatureCard.statuses.draft"),
    },
    ended: {
      icon: faCheckDouble,
      label: t("components.signatureCard.statuses.ended"),
    },
    publish: {
      icon: faCircleCheck,
      label: t("components.signatureCard.statuses.publish"),
    },
    success: {
      icon: faPartyHorn,
      label: t("components.signatureCard.statuses.success"),
    },
  };

  const statusIconStyle: CSSObject[] = [colorSecondary(tone), marginRightXs];

  return (
    <BodySm css={headerStyle}>
      <span>{formatDate(createdAt, i18n.language)}</span>
      <span>
        <FontAwesomeIcon
          css={statusIconStyle}
          fixedWidth
          icon={STATUSES_CONTENT[status].icon}
        />
        {STATUSES_CONTENT[status].label}
      </span>
    </BodySm>
  );
};

/**
 * Actions
 */
export interface SignableCardActionsContent {
  label: string;
  mode: Props["mode"][];
  url: string;
  onClick?: () => void;
}

const buttonContainerStyle: CSSObject = {
  marginTop: "auto",
};

const SignableCardActions = ({
  mode,
  signableName,
  tone,
  variant = "primary",
  signable,
  modalId,
  onDelete,
}: {
  mode: Props["mode"];
  signableName: string;
  tone: Tone;
  variant: "primary" | "secondary" | "outline" | "transparent";
  signable: adjustedSignableType;
  modalId: string;
  // onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onDelete?: () => void; // Add this prop to handle deletion
}) => {
  const { t } = useTranslation();
  const { toggleModal } = useModalContext();
  const { setSignature, signature } = useSignatureContext();

  const ACTIONS_CONTENT: SignableCardActionsContent[] = [
    {
      label: t("components.signatureCard.actions.link"),
      mode: ["edit"],
      url: `/${signableName}`,
    },
    {
      label: t("components.signatureCard.actions.delete"),
      mode: ["edit"],
      url: "/",
      onClick: () => {
        setSignature(
          {
            isDone: false,
            signatureId: signable?.signatureId as unknown as string,
          } || {
            ...signature,
          },
        );
        toggleModal(modalId, { onDelete });
      },
    },
  ];

  return (
    <div css={buttonContainerStyle}>
      <ButtonContainer
        align={{
          default: "justify",
        }}
        direction={{
          default: "vertical",
        }}
      >
        {ACTIONS_CONTENT.map((action, index) => {
          return action.mode.includes(mode) ? (
            <Button
              as="a"
              href={`${action.url}`}
              fullWidth={{
                default: true,
              }}
              label={action.label}
              key={action.label}
              size="md"
              variant={index === 1 ? "transparent" : variant}
              tone={tone}
              type="submit"
              onClick={(e) => {
                if (action?.onClick) {
                  e.preventDefault();
                  action.onClick();
                }
              }}
            />
          ) : null;
        })}
      </ButtonContainer>
    </div>
  );
};
/**
 * Card
 */
const contentHeaderStyle: CSSObject[] = [marginBottomLg];
const contentHeaderInitiativeStyle: CSSObject[] = [gapSm, { display: "flex" }];
const headlineContainerStyle: CSSObject[] = [marginBottomLg];

/**
 * Displays a card with the summary of a signable. Should always be in a container with max-width of 600 pixels.
 */
const SignatureCard = ({
  signable,
  mode = "default",
  modalId = "DeleteSignatureModal",
  onDelete,
}: Props) => {
  const countdown = getSignableCountdown(signable);

  const tone: Tone = signable.type;

  const signableType = signable.type;
  const isInitiative = "initiative" === signableType;
  const isPetition = "petition" === signableType;

  const isSuccess = !!signable.success;
  const isEnded =
    ("initiative" == signable.type &&
      signable.stage &&
      "ended" === signable?.initiativeStatuses?.[signable.stage]) ||
    (undefined !== countdown && countdown <= 0);
  const displayHeader = "edit" == mode;

  const displayContentHeaderInitiative = isInitiative && "default" == mode;
  const displayContentHeaderPetition = isPetition;

  return (
    <Card>
      <CardHeader
        alt={signable.title}
        attachment={signable?.featuredImage}
        href={`/${signable.name}`}
        size="md"
      >
        {displayHeader && (
          <SignableCardHeader
            createdAt={signable.createdAt}
            isEnded={isEnded}
            isSuccess={isSuccess}
            signableStatus={signable.status}
            tone={tone}
          />
        )}
      </CardHeader>
      <CardContent>
        <div css={contentHeaderStyle}>
          {displayContentHeaderInitiative && (
            <div css={contentHeaderInitiativeStyle}>
              <InitiativeType initiative={signable} />
              <InitiativeStage initiative={signable} />
            </div>
          )}
          {displayContentHeaderPetition && (
            <SignatureCounter signable={signable} />
          )}
        </div>

        <div css={headlineContainerStyle}>
          <Headline
            title={signable.title}
            text={signable.summary}
          />
        </div>

        <SignableCardActions
          mode={"edit"}
          signableName={signable.name}
          tone={tone}
          variant={"outline"}
          signable={signable}
          modalId={modalId}
          onDelete={onDelete}
        />
      </CardContent>
    </Card>
  );
};

export default SignatureCard;
