import { CSSObject } from "@emotion/styled";
import { rgba } from "emotion-rgba";

import { LineClampStyleProps, LineHeightElement } from "@Types/typesTypography";
import { fontFamily, headingFontFamily } from "../global/fonts";
import {
  breakpoints,
  lineHeights,
  setBreakpointProperties,
} from "../variables";
import colors from "../variables/colors";
import themes from "../variables/themes";
import {
  fontSize2xl,
  fontSize3xl,
  fontSize4xl,
  fontSize5xl,
  fontSizeLg,
  fontSizeMd,
  fontSizeSm,
  fontSizeXl,
} from "./fontSizes";
import { fontWeightMedium } from "./fontWeights";
import { lineHeightXl } from "./lineHeights";
import {
  marginBottom2xs,
  marginBottomLg,
  marginBottomMd,
  marginBottomSm,
  marginBottomXl,
  marginLeftMd,
  marginLeftXl,
  marginLeftXs,
  marginLeftZero,
  marginTopXl,
  marginZero,
  paddingZero,
} from "./spacers";

const headingStyle: CSSObject = {
  fontFamily: headingFontFamily,
  ...fontWeightMedium,
  lineHeight: lineHeights.lg,
  ...marginBottomLg,
  ...marginTopXl,
};

export const h1Style: CSSObject = {
  ...headingStyle,
  ...fontSize5xl,
};

export const h2Style: CSSObject = {
  ...headingStyle,
  ...fontSize4xl,
};

export const h3Style: CSSObject = {
  ...headingStyle,
  ...fontSize3xl,
};

export const h4Style: CSSObject = {
  ...headingStyle,
  ...fontSize2xl,
};

export const h5Style: CSSObject = {
  ...headingStyle,
  ...fontSizeXl,
};

export const h6Style: CSSObject = {
  ...headingStyle,
  ...fontSizeMd,
  letterSpacing: "0.0625em",
  textTransform: "uppercase",
};

export const bodyStyle: CSSObject = {
  fontFamily,
  ...fontSizeMd,
  fontWeight: 400,
  lineHeight: lineHeights.xl,
  margin: 0,
  padding: 0,
  ...marginBottomLg,
};

export const bodyLgStyle: CSSObject = {
  fontFamily,
  ...fontSizeLg,
  ...lineHeightXl,
  fontWeight: 400,
  margin: 0,
  ...marginBottomXl,
};

export const bodySmStyle: CSSObject = {
  display: "block",
  fontFamily,
  ...fontSizeSm,
  fontWeight: 400,
  lineHeight: lineHeights.md,
  ...marginBottomSm,
};

export const blockquoteStyle: CSSObject = {
  ...marginZero,
  ...marginBottomMd,
  ...marginLeftMd,
};

export const detailsStyle: CSSObject = {
  ...marginBottomMd,
  summary: {
    ...marginBottomMd,
  },
};

export const labelStyle: CSSObject = {
  display: "block",
  fontFamily,
  ...fontSizeSm,
  ...fontWeightMedium,
  lineHeight: lineHeights.md,
  ...marginBottomSm,
};

export const linkStyle = (
  linkTone: "initiative" | "petition" | "neutral",
): CSSObject => {
  let primaryColor = "";
  let tertiaryColor = "";
  switch (linkTone) {
    case "neutral":
      primaryColor = colors.gray5;
      tertiaryColor = colors.black;
      break;
    case "initiative":
    case "petition":
      primaryColor = themes[linkTone].colors.primary;
      tertiaryColor = themes[linkTone].colors.tertiary;
      break;

    default:
      break;
  }

  return {
    color: tertiaryColor,
    fontWeight: 700,
    textDecorationLine: "underline",

    ":active": {
      backgroundColor: rgba(tertiaryColor, 0.15),
      color: primaryColor,
    },
    ":focus": {
      backgroundColor: rgba(tertiaryColor, 0.15),
      color: primaryColor,
    },
    ":hover": {
      color: primaryColor,
    },
  };
};

const listBaseStyle: CSSObject = {
  ...paddingZero,
  ...marginZero,
  ...marginBottomLg,
  ...marginLeftXs,
};

const listItemBaseStyle: CSSObject = {
  ...marginBottomSm,
  ...marginLeftXl,
};

export const plainListStyle: CSSObject = {
  ...marginZero,
  ...paddingZero,
  listStyle: "none",
  li: {
    listStyle: "none",
    margin: 0,
  },
};

export const dlStyle: CSSObject = {
  ...listBaseStyle,
  ...marginLeftZero,
  dd: {
    ...listItemBaseStyle,
    ...marginLeftMd,
  },
  dt: {
    ...fontWeightMedium,
    ...marginBottom2xs,
  },
};

export const ulStyle: CSSObject = {
  ...listBaseStyle,
  li: {
    ...listItemBaseStyle,
    listStyleType: "circle",
  },
};

export const olStyle: CSSObject = {
  ...listBaseStyle,
  li: {
    ...listItemBaseStyle,
  },
};

export const lineClampStyle = ({
  lineClamp,
  lineHeight = "body", // Default line height, adjust if necessary
}: Partial<LineClampStyleProps>): CSSObject[] => {
  type LineHeight = Record<LineHeightElement, number>;

  const lineHeightsObj: LineHeight = {
    body: lineHeights.xl,
    bodySm: lineHeights.md,
    display: lineHeights.sm,
    heading: lineHeights.lg,
  };

  const styles: CSSObject[] = [];

  if (lineClamp) {
    const { lockHeight = true, ...rest } = lineClamp;

    for (const breakpoint of breakpoints) {
      const clampedLines = rest[breakpoint];

      if (clampedLines) {
        const properties: CSSObject = {
          display: "-webkit-box",
          overflow: "hidden",
          WebkitLineClamp: clampedLines,
          WebkitBoxOrient: "vertical",
        };

        if (lockHeight) {
          properties.height = `${clampedLines * lineHeightsObj[lineHeight]}em`; // Set height based on clamped lines and line height
        }

        styles.push(setBreakpointProperties(breakpoint, properties));
      }
    }
  }

  return styles;
};
