type ValidationRule = { message: string; valid: boolean };
export type ValidationRules = { [key: string]: ValidationRule[] };
export type Validation = { [key: string]: ValidationRule };
export type ValidationMessage = {
  valid: boolean;
  message: string;
};
export type ValidationMessages = {
  [fieldName: string]: ValidationMessage;
};

export const validate = (rules: ValidationRules) => {
  const validation: ValidationMessages = {};
  for (const property in rules) {
    for (const rule of rules[property]) {
      validation[property] = rule;
      if (!rule.valid) {
        break;
      }
    }
  }

  return validation;
};

export const isValid = (validation: ValidationMessages) => {
  let formValid = true;

  for (const field in validation) {
    if (!validation[field].valid) {
      formValid = false;
      break;
    }
  }

  return formValid;
};
