import React, { useContext, useState } from "react";

import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

import { ModalsContext } from "../../contexts/ModalsContext";
import { UnauthUser } from "../../contexts/UserContext";
import FormControl from "../../emotion/components/FormControl";
import ModalDialog from "../../emotion/components/ModalDialog";
import {
  ValidationMessage,
  isValid,
  validate,
} from "../../emotion/helpers/validation";
import api from "../../utils/api";

const GetUserModal = ({
  unauthUser,
  setUnauthUser,
}: {
  unauthUser: UnauthUser;
  setUnauthUser: React.Dispatch<React.SetStateAction<UnauthUser>>;
}) => {
  const { t } = useTranslation();
  const { toggleModal } = useContext(ModalsContext);
  const [isLoading, setIsLoading] = useState(false);

  const [validation, setValidation] = useState({} as ValidationMessage);
  const validationRules = {
    email: [
      {
        message: t("components.userForm.validation.email.notEmpty"),
        valid: !isEmpty(unauthUser.email),
      },
      {
        message: t("components.userForm.validation.email.notValid"),
        valid: isEmail(unauthUser.email),
      },
    ],
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setValidation({} as ValidationMessage);

    const validation = validate(validationRules);

    if (!isValid(validation)) {
      return setValidation(validation.email);
    }

    setIsLoading(true);
    api
      .getUser(unauthUser.email)
      .then((response) => {
        const unauthUser = response.data;
        setUnauthUser((prev) => ({ ...prev, ...unauthUser }));

        if (unauthUser.hasPassword) {
          toggleModal("login");
        } else {
          api
            .generateToken({
              email: unauthUser.email,
              type: "password",
              url: window.location.pathname,
            })
            .then(() => toggleModal("setPasswordRequestMessage"))
            .catch(null);
        }
      })
      .catch(() => toggleModal("registration"))
      .finally(() => setIsLoading(false));
  };

  return (
    <ModalDialog
      buttons={[
        {
          action: handleSubmit,
          label: t("common.navigation.next") as string,
          loading: isLoading,
          variant: "primary",
        },
      ]}
      content={
        <>
          <p>{t("pages.userAccount.modals.getUser.content")}</p>

          <form onSubmit={handleSubmit}>
            <FormControl
              disabled={isLoading}
              label={t("components.userForm.fields.email") as string}
              name="email"
              displayLabel={false}
              placeholder="user@example.com"
              type="email"
              value={unauthUser.email}
              setValue={setUnauthUser}
              validation={validation}
            />
          </form>
        </>
      }
      icon={faRightToBracket}
      id="getUser"
      title={t("pages.userAccount.modals.getUser.title")}
    ></ModalDialog>
  );
};

export default GetUserModal;
