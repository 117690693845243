import React from "react";

import styled from "@emotion/styled";

import { Container } from "@Components/Container";
import { displayFlex, displayInlineFlex } from "@Styles/displays";
import themes from "@Variables/themes";
import Step from "./Step";

type PaginationProps = {
  currentStep: number;
  completedSteps: number[];
  isSaving: boolean;

  handleNextStep: (step: number) => Promise<void>;
  handlePreviousStep: (step?: number) => void;
};

const Pagination = (props: PaginationProps) => {
  const STEPS = [1, 2, 3, 4, 5];

  const PrimaryLine = styled.div({
    height: "1px",
    backgroundColor: themes.petition.colors.primary,
    flex: 1,
    width: "100%",
  });

  const SecondaryLine = styled.div({
    height: "1px",
    backgroundColor: themes.petition.colors.secondary,
    flex: 1,
    width: "100%",
  });

  return (
    <div
      css={[
        displayFlex,
        { width: "100%", justifyContent: "center", alignItems: "center" },
      ]}
    >
      <Container
        size="lg"
        css={[
          displayInlineFlex,
          {
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            maxWidth: "400px",
          },
        ]}
      >
        {STEPS.map((step, index) => (
          <React.Fragment key={`pagination-step-${index}-${step}`}>
            <Step
              step={step}
              {...props}
              key={`pagination-step-${step}`}
              buttonSize={2.5}
            />

            {index < STEPS.length - 1 &&
              (index + 1 < props.currentStep ? (
                <SecondaryLine />
              ) : (
                <PrimaryLine />
              ))}
          </React.Fragment>
        ))}
      </Container>
    </div>
  );
};

export default Pagination;
