import { Navigate, createBrowserRouter } from "react-router-dom";

import { routes } from "@Configs";
import App from "./App";
import {
  AboutPage,
  AuthenticationPage,
  ErrorPage,
  PSFPage,
  PostRedirectionPage,
  SignablePage,
  Verification,
} from "./pages";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import StartPetitionPage from "./pages/StartPetition";
import UserProfile from "./pages/UserProfile";

const userRoutes = [
  routes.petition.user.account,
  routes.petition.user.petitions,
  routes.petition.user.signatures,
].map((path: string) => ({
  path,
  element: <UserProfile />,
}));

const router = createBrowserRouter([
  {
    path: routes.root,
    element: <App />,
    children: [
      { path: routes.root, element: <Home /> },
      { path: routes.about, element: <AboutPage /> },
      { path: routes.authenticate, element: <AuthenticationPage /> },
      { path: routes.error, element: <ErrorPage /> },
      { path: routes.faq, element: <Faq /> },
      {
        path: routes.home,
        element: (
          <Navigate
            to={routes.root}
            replace
          />
        ),
      },
      { path: routes.petition.start, element: <StartPetitionPage /> },
      { path: routes.petition.startById, element: <StartPetitionPage /> },
      { path: routes.verify, element: <Verification /> },
      { path: routes.postRedirection, element: <PostRedirectionPage /> },
      { path: routes.updates, element: <SignablePage /> },
      { path: routes.updatesId, element: <SignablePage /> },
      { path: routes.share, element: <SignablePage /> },
      { path: routes.psfStep, element: <PSFPage /> },
      { path: routes.notFound, element: <ErrorPage /> },

      // Grouped petition user routes
      ...userRoutes,

      {
        path: routes.initiative.search,
        element: <Home />,
      },
    ],
  },
]);

export default router;
