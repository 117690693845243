import { IconSVGProps } from "../Icon";

const SVGComponent = ({ primaryColor, secondaryColor }: IconSVGProps) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <path
        fill={secondaryColor}
        d="M14.5 28.9v17.6l-11-9.1c.1-.1.3-.2.4-.4l10.6-8.1zM96.5 37.4l-11.2 9.1V28.8l10.8 8.3c.2 0 .3.2.4.3zM72.7 19.2H27.3L47.4 3.9c1.5-1.2 3.7-1.2 5.2 0l20.1 15.3z"
      />
      <linearGradient
        id="SVGID_1_email"
        gradientUnits="userSpaceOnUse"
        x1={49.858}
        y1={47.15}
        x2={50.252}
        y2={101.599}
      >
        <stop
          offset={0}
          stopColor={primaryColor}
        />
        <stop
          offset={1}
          stopColor={secondaryColor}
        />
      </linearGradient>
      <path
        d="M97.7 41.9v49.8c0 2.2-1.9 3.9-4.1 3.9H6.5c-2.3 0-4.1-1.8-4.1-3.9V41.9l42.9 35.2c1.3 1.1 2.9 1.7 4.5 1.7s3.2-.5 4.5-1.6l43.4-35.3z"
        fill="url(#SVGID_1_email)"
      />
      <path
        fill={primaryColor}
        d="M52.3 40.6l-.6 2.9c-.3 1.3-.9 2.3-1.8 3-.7.4-1.4.7-2.1.7-1.1 0-1.9-.4-2.4-1.2-.8-1.2-.9-3.1-.1-5.2.6-1.5 1.7-2.7 3.1-3.1.4-.1.8-.2 1.2-.2.7 0 1.4.2 1.9.6.7.5 1 1.5.8 2.5z"
      />
      <path
        fill={primaryColor}
        d="M81.8 19.2H18c-1.9 0-3.5 1.6-3.5 3.5v23.9l33.8 27.7c.8.7 1.9.7 2.7 0l34.3-27.8V22.6c0-1.9-1.6-3.4-3.5-3.4zM50 26.6h.2c8.5.1 14.6 5.7 14.5 13.2-.1 7.3-4.4 10.6-8.7 10.6h-.1c-2 0-3.6-.9-4.4-2.3-1 1.3-2.7 2.2-4.4 2.2-1.8 0-3.3-.7-4.4-2-1-1.2-1.5-2.9-1.5-4.7 0-4.5 3.2-9.3 8.2-9.3h.1c1.8 0 3.1.8 3.8 1.7l.3-1.3H57l-2 10.8c-.2.8-.1 1.3.2 1.7.2.3.7.4 1.3.4h.1c3.1 0 5.2-3.1 5.2-7.9 0-3-1.1-5.6-3.2-7.4-2.1-1.8-5-2.8-8.3-2.9H50c-3.8 0-7.2 1.4-9.7 3.8-2.5 2.5-3.8 6-3.9 9.8-.1 9 6.9 12.3 13.5 12.4 3.4 0 6.4-.6 9.4-1.9l.3-.1v3l-.1.1c-2.5 1.2-6 1.9-9.3 1.9H50c-7.7-.1-16.7-4.1-16.6-15.3 0-9.6 7-16.5 16.6-16.5z"
      />
    </svg>
  );
};

export default SVGComponent;
