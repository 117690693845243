import { useMediaQuery } from "./useMediaQuery";
import { breakpointValues } from "../emotion/variables/breakpoints";

type BreakPoint = "sm" | "md" | "lg" | "xs";

type useBreakPoint = {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  active: BreakPoint;
  isSmallScreen: boolean;
};

export const useBreakpoints = (): useBreakPoint => {
  const { sm, md, lg } = breakpointValues;

  const breakpoints: useBreakPoint = {
    isXs: useMediaQuery(`(max-width: ${sm - 1}px)`),
    isSm: useMediaQuery(`(min-width: ${sm}px) and (max-width: ${md - 1}px)`),
    isMd: useMediaQuery(`(min-width: ${md}px) and (max-width: ${lg - 1}px)`),
    isLg: useMediaQuery(`(min-width: ${lg}px)`),
    active: <BreakPoint>"xs",
    isSmallScreen: false,
  };

  if (breakpoints.isXs) breakpoints.active = <BreakPoint>"xs";
  if (breakpoints.isSm) breakpoints.active = <BreakPoint>"sm";
  if (breakpoints.isMd) breakpoints.active = <BreakPoint>"md";
  if (breakpoints.isLg) breakpoints.active = <BreakPoint>"lg";
  if (breakpoints.isSm || breakpoints.isXs) breakpoints.isSmallScreen = true;

  return breakpoints;
};
