import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Post } from "../types/services/post";
import api from "../utils/api";
import Error from "./Error";
import PagePage from "./PagePage";
import SignablePage from "./SignablePage";

const PostRedirection = () => {
  const [post, setPost] = useState<Post>({ title: "", content: "" } as Post);
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { _id } = useParams();

  useEffect(() => {
    if (!_id) {
      return setHasError(true);
    }

    api
      .getPost(_id)
      .then((response) => {
        const post = response.data;
        if ("update" == post.type) {
          return navigate(`/${post.parent}/updates/${post.name}`);
        }
        setPost(response.data);
      })
      .catch(() => setHasError(true));
  }, [_id, navigate]);

  if (hasError) {
    return <Error />;
  }

  return (
    <>
      {"page" == post.type && <PagePage />}
      {("initiative" == post.type || "petition" == post.type) && (
        <SignablePage />
      )}
    </>
  );
};

export default PostRedirection;
