import React, { ReactElement } from "react";

import { CSSObject } from "@emotion/react";

import type { DivisionComponentProps } from "@Types/components/pageProps";
import { gapMd, gapSm } from "../styles/spacers";
import {
  Breakpoint,
  breakpoints,
  setBreakpointProperties,
} from "../variables/breakpoints";
import { ButtonProps } from "./Button";

const ALIGN = ["left", "center", "right", "justify"] as const;
const DIRECTION = ["horizontal", "vertical"] as const;
type Align = (typeof ALIGN)[number];
type Direction = (typeof DIRECTION)[number];

export type ButtonContainerProps = Omit<DivisionComponentProps, "children"> & {
  /** Horizontal alignment of the buttons. Responsive property. */
  align?: { [key in Breakpoint]?: Align };
  /** Orientation in which the buttons are displayed. Responsive property.  */
  direction?: { [key in Breakpoint]?: Direction };
  /** Button elements.  */
  children?:
    | ReactElement<ButtonProps>
    | Array<ReactElement<ButtonProps> | null>;
};

const getAlignAndDirection = (
  align: Align,
  direction: Direction,
): CSSObject => {
  let horizontalAlignment;
  let verticalAlignment;

  switch (align) {
    case "left":
      horizontalAlignment = "flex-start";
      verticalAlignment = "flex-start";
      break;
    case "justify":
      horizontalAlignment = "flex-start";
      verticalAlignment = "space-between";
      break;
    case "right":
      horizontalAlignment = "flex-end";
      verticalAlignment = "flex-end";
      break;
    default:
      horizontalAlignment = align;
      verticalAlignment = align;
      break;
  }

  const style: CSSObject = {
    flexDirection: "horizontal" === direction ? "row" : "column",
    alignItems: "horizontal" === direction ? horizontalAlignment : "stretch",
    alignContent: horizontalAlignment,
    justifyContent: "horizontal" === direction ? verticalAlignment : "stretch",
    ...("horizontal" === direction ? gapMd : gapSm),
  };

  return style;
};

const isButtonFullWidth = (align: Align, direction: Direction): boolean => {
  return "vertical" == direction && "justify" == align;
};

/**
 * Element that contain buttons. It features margins on top and bottom and distributes them through direction and alignment.
 *
 * The button container should always contain `<Button>` elements inside it.
 */
const ButtonContainer = ({
  align = { default: "left" },
  direction = { default: "horizontal" },
  children,
  ...props
}: ButtonContainerProps) => {
  let style: CSSObject = {
    display: "flex",
    flexWrap: "wrap",
  };
  const buttonFullWidthProperty: { [key in Breakpoint]?: boolean } = {};

  for (const breakpoint of breakpoints) {
    if (align[breakpoint] && direction[breakpoint]) {
      const properties = getAlignAndDirection(
        align[breakpoint] as Align,
        direction[breakpoint] as Direction,
      );
      style = {
        ...style,
        ...setBreakpointProperties(breakpoint, properties),
      };

      buttonFullWidthProperty[breakpoint] = isButtonFullWidth(
        align[breakpoint] as Align,
        direction[breakpoint] as Direction,
      );
    }
  }

  return (
    <div
      css={style}
      {...props}
    >
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child as ReactElement<ButtonProps>, {
            fullWidth: buttonFullWidthProperty,
          }),
      )}
    </div>
  );
};

export default ButtonContainer;
