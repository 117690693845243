// RadioButton.tsx
import { useTheme } from "@emotion/react";
import type { RadioInputProps } from "@Types";
import type { FC } from "react";
import {
  inputCSS,
  optionStyle,
  radioLabelStyle,
  radioStyle,
} from "./Input.styles";

const InputRadio: FC<RadioInputProps> = ({
  label,
  value,
  name,
  checked,
  onChange,
  onBlur,
}) => {
  const { colors } = useTheme();

  return (
    <label css={radioLabelStyle}>
      <input
        css={[inputCSS(colors), optionStyle, radioStyle(colors)]}
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      {label}
    </label>
  );
};

export default InputRadio;
