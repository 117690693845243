import type { CSSObject } from "@emotion/react";
import type { CombinedColorsValues } from "@Types/configs";
import type { ColorVariantTypes } from "@Types/typeUtils";
import { rgba } from "emotion-rgba";
import capitalize from "./stringCapitalize";

/**
 * @param combinedColors Combined theme specific and general colors
 * @returns variants of colors with different opacities both for color and background
 */
const generateColorVariants = (
  combinedColors: Partial<CombinedColorsValues>,
): ColorVariantTypes => {
  const baseBorder: CSSObject = {
    borderStyle: "solid",
    borderWidth: "1px",
  };

  const colorVariants: Partial<ColorVariantTypes> = {};

  Object.entries(combinedColors).forEach(([colorName, colorValue]) => {
    const capitalizedColorName = capitalize(colorName);

    Object.assign(colorVariants, {
      [`color${capitalizedColorName}Opacity15`]: {
        color: rgba(colorValue, 0.15),
      },
      [`color${capitalizedColorName}Opacity25`]: {
        color: rgba(colorValue, 0.25),
      },
      [`color${capitalizedColorName}Opacity50`]: {
        color: rgba(colorValue, 0.5),
      },
      [`color${capitalizedColorName}Opacity75`]: {
        color: rgba(colorValue, 0.75),
      },
      [`color${capitalizedColorName}`]: { color: colorValue },
      [`bg${capitalizedColorName}Opacity15`]: {
        backgroundColor: rgba(colorValue, 0.15),
      },
      [`bg${capitalizedColorName}Opacity25`]: {
        backgroundColor: rgba(colorValue, 0.25),
      },
      [`bg${capitalizedColorName}Opacity50`]: {
        backgroundColor: rgba(colorValue, 0.5),
      },
      [`bg${capitalizedColorName}Opacity75`]: {
        backgroundColor: rgba(colorValue, 0.75),
      },
      [`bg${capitalizedColorName}`]: { backgroundColor: colorValue },
      [`border${capitalizedColorName}`]: {
        border: { ...baseBorder, borderColor: colorValue },
      },
    });
  });

  return colorVariants as ColorVariantTypes;
};

export default generateColorVariants;
