import { useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { FormControl, Heading } from "@Components";
import { colorPrimary } from "@Styles/textColors";
import { ValidationRules } from "@Stylize/helpers/validation";
import { InitiativeInput } from "@Types/services/post";
import FieldHeading from "../components/FieldHeading";
import { PetitionStepProps } from "../utils/types";

const StepOne = ({
  counter,
  formErrors,
  initiative,
  maxLengths,
  handleChange,
  tags,
  setFieldHelp,
}: PetitionStepProps) => {
  const { t } = useTranslation();

  const fieldHeadingStyle: CSSObject[] = [colorPrimary("petition")];
  const [fields, setFields] = useState<InitiativeInput>(initiative);

  const [validation, setValidation] = useState<{
    title: boolean;
    tag: boolean;
  }>({
    title: false,
    tag: false,
  });

  const [showTitleError, setShowTitleError] = useState<boolean>(false);

  const validateFields = (): Record<
    "title" | "tag",
    { valid: boolean; message: string }
  > => {
    const response: Record<
      "title" | "tag",
      { valid: boolean; message: string }
    > = {
      title: {
        valid: true,
        message: "",
      },
      tag: {
        valid: true,
        message: "",
      },
    };

    for (const key of Object.keys(validationRules)) {
      const error = validationRules[key].find((rule) => !rule.valid);
      response[key as "title" | "tag"].valid = !error;
      response[key as "title" | "tag"].message = error?.message || "";
    }

    return response;
  };

  useEffect(() => {
    (["title", "tag"] as ("title" | "tag")[]).forEach((fieldName) => {
      if (fields[fieldName] !== initiative[fieldName]) {
        const newValidation = {
          ...validation,
          title: fields.title.length > maxLengths.title,
        };

        setValidation(newValidation);
        handleChange({
          target: { name: fieldName, value: fields[fieldName] },
        });
      }
    });
  }, [fields, initiative, handleChange, validation, maxLengths.title]);

  useEffect(() => {
    if (formErrors.title || formErrors.tag) {
      // the form is submitted, all fields should have validations.
      setValidation({ title: true, tag: true });
      if (formErrors.title) {
        setShowTitleError(true);
      }
    }
  }, [formErrors]);

  const CONTENT = {
    title: {
      heading: t("components.startPetitionForm.fields.title.heading"),
      description: t("components.startPetitionForm.fields.title.description"),
      placeholder: t("components.startPetitionForm.fields.title.placeholder"),
      caption: t("components.startPetitionForm.fields.title.caption"),
      emptyError: t(
        "components.startPetitionForm.fields.title.validationErrors.empty",
      ),
      limitError: t(
        "components.startPetitionForm.fields.title.validationErrors.limit",
      ),
    },
    tag: {
      heading: t("components.startPetitionForm.fields.tag.heading"),
      description: t("components.startPetitionForm.fields.tag.description"),
      placeholder: t("components.startPetitionForm.fields.tag.placeholder"),
      caption: t("components.startPetitionForm.fields.tag.caption"),
      emptyError: t(
        "components.startPetitionForm.fields.tag.validationErrors.empty",
      ),
    },
  };

  const validationRules: ValidationRules = {
    title: [
      {
        message: CONTENT.title.emptyError,
        valid: fields.title?.length > 0,
      },
      {
        message: `${maxLengths.title - counter.title}/${maxLengths.title} ${
          CONTENT.title.caption
        }`,
        valid: fields.title?.length < maxLengths.title,
      },
    ],
    tag: [
      {
        message: CONTENT.tag.emptyError,
        valid: fields.tag?.length > 0,
      },
    ],
  };

  const generateCaption = (field: "title" | "tag") => {
    switch (field) {
      case "title":
        return formErrors.title
          ? CONTENT.title.emptyError
          : `${maxLengths.title - counter.title}/${maxLengths.title} ${
              CONTENT.title.caption
            }`;
      case "tag":
        return;
    }
  };

  return (
    <>
      <div>
        <FieldHeading
          text={CONTENT.title.heading}
          tipAction={() => {
            setFieldHelp("title");
          }}
        />

        <p>{CONTENT.title.description}</p>

        <FormControl
          id="step1-title"
          placeholder={CONTENT.title.placeholder}
          name="title"
          setValue={setFields}
          tone="petition"
          type="text"
          value={fields.title}
          max={`${maxLengths.title}`}
          caption={`${generateCaption("title")}`}
          validation={validation.title ? validateFields().title : undefined}
          useValidationIcon={false}
          extraErrorMessage={showTitleError ? CONTENT.title.limitError : ""}
          captionAlign="right"
        />
      </div>

      <div>
        <Heading
          css={fieldHeadingStyle}
          scale={5}
          aria-level={2}
          role="heading"
        >
          {CONTENT.tag.heading}
        </Heading>

        <p>{CONTENT.tag.description}</p>

        <FormControl
          id="step1-tag"
          placeholder={CONTENT.tag.placeholder}
          name="tag"
          setValue={setFields}
          tone="petition"
          type="select"
          value={fields.tag}
          options={tags.map((tag) => ({
            value: tag._id,
            label: tag.title,
          }))}
          validation={validation.tag ? validateFields().tag : undefined}
          captionAlign="right"
        />
      </div>
    </>
  );
};

StepOne.propTypes = {
  counter: PropTypes.shape({
    title: PropTypes.number.isRequired,
  }).isRequired,

  formErrors: PropTypes.object.isRequired,

  initiative: PropTypes.object.isRequired,

  maxLengths: PropTypes.shape({
    title: PropTypes.number.isRequired,
  }).isRequired,

  handleChange: PropTypes.func.isRequired,

  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StepOne;
