import { IconSVGProps } from "../Icon";

const DataSecurityIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_datenschutz"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <linearGradient
      id="SVGID_1_datenschutz"
      gradientUnits="userSpaceOnUse"
      x1={25.18}
      y1={18.826}
      x2={74.82}
      y2={18.826}
    >
      <stop
        offset={0}
        stopColor={secondaryColor}
      />
      <stop
        offset={1}
        stopColor={primaryColor}
      />
    </linearGradient>
    <path
      d="M74.8 24.8v12.8L66 35.2V24.8c.1-8.8-7.1-16-16-16S33.9 16 33.9 24.9v10.4l-8.8 2.4V24.8C25.2 11.1 36.3 0 50 0s24.8 11.1 24.8 24.8z"
      fill="url(#SVGID_1_datenschutz)"
    />
    <path
      d="M40.6 56.2c0 3.6 2 6.7 5 8.3v11.4c0 2 1.6 3.7 3.7 3.7h.7V100s-32.5-7.3-32.5-29.4V39.7l7.7-2.1 8.8-2.4 16-4.3v15.9c-5.2 0-9.4 4.2-9.4 9.4z"
      fill={secondaryColor}
    />
    <path
      d="M82.5 39.7v30.9C82.5 92.7 50 100 50 100V79.5h.7c2 0 3.7-1.6 3.7-3.7V64.5c3-1.6 5-4.7 5-8.3 0-5.2-4.2-9.4-9.4-9.4V30.9l16.1 4.4 8.8 2.4 7.6 2z"
      fill={primaryColor}
    />
  </svg>
);

export default DataSecurityIcon;
