import { IconSVGProps } from "../Icon";

const Download = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_download"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={89.9}
        y1={7.4}
        x2={42.3}
        y2={90.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          stopColor={primaryColor}
        />
        <stop
          offset={0.8}
          stopColor={secondaryColor}
        />
      </linearGradient>
    </defs>
    <path
      className="cls-1"
      fill="url(#linear-gradient)"
      d="m97.6,27.9h-23.5c-.3,0-.6-.3-.6-.6V3.7l23.6,23.6c.2.2.3.4.4.6Zm-26.6,4.8c-1.2,0-2.2-1-2.2-2.2V2.3h-29.9c-1.1,0-2.1.9-2.1,2.1v84.9c0,1,.8,1.8,1.8,1.8h57.2c1.1,0,2.1-.9,2.1-2.1v-56.3h-26.8Z"
    />
    <path
      fill={primaryColor}
      d="m1.8,43.1v52c0,1.6,1.3,3,3,3h63.5c1.6,0,3-1.3,3-3v-41.7c0-1.6-1.3-3-3-3h-25.5c-.9,0-1.7-.4-2.2-1l-10.8-8.2c-.6-.7-1.4-1-2.2-1H4.8c-1.6,0-3,1.3-3,3Z"
    />
    <rect
      fill="#fff"
      x={34.8}
      y={58.4}
      width={3.4}
      height={20.5}
    />
    <path
      fill="#fff"
      d="m36.6,81c-.4,0-.8-.2-1.2-.5l-10.7-9.8,2.3-2.5,9.5,8.7,9.8-9,2.3,2.5-10.9,10c-.3.3-.7.5-1.2.5Z"
    />
    <path
      fill="#fff"
      d="m51.6,90.8h-30.4c-.9,0-1.7-.8-1.7-1.7v-6.1h3.4v4.4h27v-4.4h3.4v6.1c0,.9-.8,1.7-1.7,1.7Z"
    />
  </svg>
);

export default Download;
