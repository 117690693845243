import React from "react";

import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";

import { paddingXMd } from "../styles/spacers";
import { Breakpoint, breakpointUp } from "../variables/breakpoints";

export type ColumnNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ColumnOffset = { [key in Breakpoint]?: 0 | ColumnNumber };

export type ColumnOrder = { [key in Breakpoint]?: number };

export type ColumnSpan = { [key in Breakpoint]?: ColumnNumber };

export interface ColumnProps {
  css?: CSSObject | CSSObject[];
  children: React.ReactNode;
  offset?: ColumnOffset;
  order?: ColumnOrder;
  span?: ColumnSpan;
  [key: string]: unknown;
}

const getWidth = (columns: ColumnNumber | undefined) => {
  if (!columns) {
    return 0;
  }

  return (columns / 12) * 100 + "%";
};

const getFlex = (columns: ColumnNumber | undefined) => {
  return columns ? "0 0 auto" : "1 0 0%";
};

const ColumnElement = styled.div({});

const Column = (props: ColumnProps) => {
  const {
    css,
    span = {
      default: undefined,
      sm: undefined,
      md: undefined,
      lg: undefined,
    },
    offset = {
      default: 0,
      sm: undefined,
      md: undefined,
      lg: undefined,
    },
    order = {
      default: 0,
      sm: undefined,
      md: undefined,
      lg: undefined,
    },
    children,
  } = props;
  const defaultStyle: CSSObject = {
    ...paddingXMd,
    flex: getFlex(span.default),
    flexShrink: 0,
    maxWidth: "100%",
    order: order.default,
    marginLeft: offset.default && getWidth(offset.default),
    width: span.default && getWidth(span.default),
  };

  const sm = {
    ...(offset.sm && { marginLeft: getWidth(offset.sm) }),
    ...(order.sm && { order: order.sm }),
    ...(span.sm && { flex: getFlex(span.sm), width: getWidth(span.sm) }),
  };

  const md = {
    ...(offset.md && { marginLeft: getWidth(offset.md) }),
    ...(order.md && { order: order.md }),
    ...(span.md && { flex: getFlex(span.md), width: getWidth(span.md) }),
  };

  const lg = {
    ...(offset.lg && { marginLeft: getWidth(offset.lg) }),
    ...(order.lg && { order: order.lg }),
    ...(span.lg && { flex: getFlex(span.lg), width: getWidth(span.lg) }),
  };

  const columnStyles: CSSObject = {
    ...defaultStyle,
    ...(Object.keys(sm) && { [breakpointUp.sm as string]: sm }),
    ...(Object.keys(md) && { [breakpointUp.md as string]: md }),
    ...(Object.keys(lg) && { [breakpointUp.lg as string]: lg }),
  };

  return (
    <ColumnElement
      {...props}
      css={[columnStyles, css]}
    >
      {children}
    </ColumnElement>
  );
};

export default Column;
