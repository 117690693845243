import React, { createContext, useState } from "react";

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AUTH_ACTIONS = [
  "resetPassword",
  "setPassword",
  "verify",
  undefined,
] as const;
export type AuthActions = (typeof AUTH_ACTIONS)[number];

type AuthProps = { action?: AuthActions; token?: string };

type AuthContextValues = {
  auth: AuthProps;
  setAuth: React.Dispatch<React.SetStateAction<AuthProps>>;
};

export const AuthContext = createContext<AuthContextValues>({
  auth: { action: undefined, token: undefined },
  setAuth: () => undefined,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<AuthProps>({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
