import { CSSObject } from "@emotion/react";

import {
  marginBottomLg,
  marginBottomSm,
  marginBottomZero,
  marginYZero,
  paddingBottomZero,
  paddingYMdZero,
  paddingYXs,
  paddingYXsSm,
} from "@Styles/spacers";

import { Tone, breakpointUp, colors } from "@Variables";

import { displayFlex } from "@Styles";
import type { ColorPalette } from "@Types/configs";

//#region Tab Styles
export type buttonStyleProps = {
  content?: React.ReactNode | React.ReactNode[];
  active?: boolean;
  colors: ColorPalette;
  tone: Tone;
};

const tabButtonStyle = ({
  content,
  active,
  colors: swatchColors,
  tone,
}: buttonStyleProps): CSSObject => {
  const secondaryColor = swatchColors.secondary.main;
  const textColor = tone === "petition" ? colors.white : colors.black;

  return {
    ...(!content && paddingYXs),
    backgroundColor: "transparent",
    border: "1px solid transparent",
    borderRadius: "2rem",
    color: swatchColors.secondary.main,
    minHeight: "2rem",
    height: "100%",
    width: "100%",
    cursor: "pointer",

    ...(active && {
      backgroundColor: secondaryColor,
      border: `1px solid ${secondaryColor}`,
      color: textColor,
    }),

    span: {
      display: "-webkit-box",
      maxHeight: "3rem",
      textOverflow: "ellipsis",
      overflow: "hidden",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  };
};

const tabStyle: CSSObject = {
  height: "100%",
  [breakpointUp["md"]]: {
    display: "flex",
  },
};

export { tabButtonStyle, tabStyle };
//#endregion Tab

//region Tabs
const tabsContainerStyle = (hasToolbar: boolean): CSSObject[] => {
  const styles: CSSObject[] = [marginBottomLg];

  if (hasToolbar) {
    styles.push({
      [breakpointUp["md"]]: {
        display: "flex",
        justifyContent: "space-between",
      },
    });
  }

  return styles;
};

const navItemsContainerStyle = (colors: ColorPalette): CSSObject => {
  const color = colors.secondary.main;

  return {
    display: "flex",
    flexFlow: "column",
    flexWrap: "nowrap",
    minWidth: "0px",
    flex: "0 1 50%",
    alignItems: "center",
    justifyContent: "center",

    borderBottom: `1px solid ${color}`,
    borderTop: `1px solid ${color}`,
    cursor: "pointer",
    height: "3.375rem",

    ...marginBottomSm,

    ":nth-of-type(2n+1):last-of-type": {
      flex: "0 1 100%",
      borderRadius: "2rem",
      borderRight: `1px solid ${color}`,
    },

    ":nth-of-type(2n+1)": {
      borderRight: "none",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    ":nth-last-of-type(2), :nth-last-of-type(1)": {
      ...marginBottomZero,
    },

    ":nth-of-type(2n)": {
      borderLeft: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    [breakpointUp["default"]]: {
      borderLeft: `1px solid ${color}`,
      borderRight: `1px solid ${color}`,
      borderRadius: "2rem",
    },

    [breakpointUp["md"]]: {
      width: "12rem",
      flexShrink: 1,
      borderRadius: 0,
      borderLeft: "none",
      borderRight: "none",
      ...marginBottomZero,

      ":first-of-type": {
        borderLeft: `1px solid ${color}`,
        borderRadius: "2rem 0 0 2rem",
        borderRight: "none",
      },

      ":last-of-type": {
        borderLeft: "none",
        borderRadius: "0 2rem 2rem 0",
        borderRight: `1px solid ${color}`,
      },

      ":nth-of-type(2n+1):last-of-type": {
        borderLeft: "none",
        borderRadius: "0 2rem 2rem 0",
        borderRight: `1px solid ${color}`,
        flex: "1 1 50%",
      },
    },
  };
};

const tabsButtonStyle: CSSObject = {
  display: "flex",
  flexWrap: "wrap",
  textAlign: "center",
  [breakpointUp["md"]]: {
    flexWrap: "nowrap",
    flex: 1,
    flexGrow: 0,
    width: "100%",
    ...paddingBottomZero,
    ...marginBottomZero,
  },
};

const tabsToolbarContainerStyle: CSSObject[] = [
  paddingYXsSm,
  paddingYMdZero,
  marginYZero,
  displayFlex,
  {
    paddingInline: "-1rem",
    alignItems: "center",
  },
];

const navItemsStyle: CSSObject = {
  width: "100%",
  height: "100%",
};

export {
  navItemsContainerStyle,
  navItemsStyle,
  tabsButtonStyle,
  tabsContainerStyle,
  tabsToolbarContainerStyle,
};
//#endregion Tabs
