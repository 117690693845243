import React from "react";

import StepFive from "./StepFive";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { PetitionStepProps } from "../utils/types";

const STEPS = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
  4: StepFour,
  5: StepFive,
};

const StepComponent = ({
  step,
  ...rest
}: PetitionStepProps & { step: number }) => {
  const CurrentComponent =
    step in STEPS ? STEPS[step as 1 | 2 | 3 | 4 | 5] : StepOne;

  return <CurrentComponent {...rest} />;
};

export default StepComponent;
