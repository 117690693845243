import { faCheck } from "@fortawesome/pro-regular-svg-icons";

import Button from "@Components/Button";
import themes from "@Variables/themes";

const deriveVariant = (active: boolean, checked: boolean) => {
  if (active) {
    return "primary";
  } else if (checked) {
    return "secondary";
  } else {
    return "outline";
  }
};

type StepProps = {
  currentStep: number;
  completedSteps: number[];
  isSaving: boolean;
  step: number;
  buttonSize: number;

  handleNextStep: (step: number) => Promise<void>;
  handlePreviousStep: (step?: number) => void;
};

const Step = ({
  currentStep,
  completedSteps,
  handleNextStep,
  handlePreviousStep,
  step,
  buttonSize,
}: StepProps) => {
  const active = step === currentStep;
  const checked = completedSteps.includes(step) && !active;
  const onClick = step > currentStep ? handleNextStep : handlePreviousStep;

  const size = `${buttonSize}rem`;

  return (
    <div
      css={{
        maxHeight: size,
        maxWidth: size,
        width: "100%",
        height: "100%",
      }}
    >
      <Button
        onClick={() => onClick(step)}
        label={checked ? "" : `${step}`}
        icon={checked ? faCheck : undefined}
        iconOnly={Boolean(checked)}
        variant={deriveVariant(active, checked)}
        css={{
          borderRadius: "50%",
          height: size,
          width: size,
          padding: 0,
          margin: 0,
          borderColor: checked ? "transparent" : themes.petition.colors.primary,
        }}
      />
    </div>
  );
};

export default Step;
