import React from "react";

import styled from "@emotion/styled";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Container } from "../../../../emotion/components/Container";
import Panel from "../../../../emotion/components/Panel";
import { bgGray1 } from "../../../../emotion/styles/backgrounds";
import { marginRightLg, paddingLg } from "../../../../emotion/styles/spacers";
import { colorSecondary } from "../../../../emotion/styles/textColors";
import { FieldHelpSetter } from "../../utils/types";

interface TitleHelpProps {
  setFieldHelp: FieldHelpSetter;
}

const TitleHelp = ({ setFieldHelp }: TitleHelpProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    header: t("components.startPetitionForm.help.fieldTips.title.header"),
    paragraph1: t(
      "components.startPetitionForm.help.fieldTips.title.paragraph1",
    ),
    listTitle: t(
      "components.startPetitionForm.help.fieldTips.title.examples.title",
    ),
    examples: [
      t("components.startPetitionForm.help.fieldTips.title.examples.example1"),
      t("components.startPetitionForm.help.fieldTips.title.examples.example2"),
      t("components.startPetitionForm.help.fieldTips.title.examples.example3"),
    ],
  };

  const ListTitle = styled.p({
    ...colorSecondary("petition"),
    fontWeight: "700",
    fontSize: "16px",
  });

  const StyledList = styled.ul({
    listStyleType: "none",
    paddingLeft: "0",
  });

  const StyledLI = styled.li({
    display: "flex",
    marginLeft: "0",
    "&::marker": {
      content: "''",
    },
  });

  const StyledListItem = ({
    children,
  }: {
    children: React.ReactNode | string;
  }) => (
    <StyledLI>
      <div css={marginRightLg}>
        <FontAwesomeIcon
          icon={faCircle}
          size="2xs"
        />
      </div>
      {children}
    </StyledLI>
  );

  return (
    <Panel
      header={CONTENT.header}
      icon={faInfoCircle}
      onClose={() => {
        setFieldHelp(null);
      }}
    >
      <p>{CONTENT.paragraph1}</p>
      <Container css={[bgGray1, paddingLg, { borderRadius: "8px" }]}>
        <ListTitle>{CONTENT.listTitle}</ListTitle>
        <StyledList>
          {CONTENT.examples.map((example, index) => (
            <StyledListItem key={index}>{example}</StyledListItem>
          ))}
        </StyledList>
      </Container>
    </Panel>
  );
};

export default TitleHelp;
