import Column from "@Components/Column";
import Row from "@Components/Row";
import { CSSObject } from "@emotion/react";
import { marginBottom3xl } from "@Styles/spacers";
import { UpdatePassword } from "./components/update-password";
import { UpdateProfile } from "./components/update-profile";

const containerStyle: CSSObject = {
  ...marginBottom3xl,
};

export const MyProfilePage = () => {
  return (
    <section css={containerStyle}>
      <Row>
        <Column span={{ lg: 6, md: 7, default: 12 }}>
          <UpdateProfile />

          {/* <UpdateEmail /> */}

          <UpdatePassword />
        </Column>
      </Row>
    </section>
  );
};
