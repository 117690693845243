import React, { useContext, useEffect, useState } from "react";

import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ModalsContext } from "../../contexts/ModalsContext";
import { UnauthUser } from "../../contexts/UserContext";
import ModalDialog from "../../emotion/components/ModalDialog";
import UserForm, { UserFormFields } from "../../emotion/components/UserForm";
import { ValidationMessage } from "../../emotion/helpers/validation";
import api from "../../utils/api";

const RegistrationModal = ({ unauthUser }: { unauthUser: UnauthUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const { toggleModal } = useContext(ModalsContext);
  const [user, setUser] = useState<UserFormFields>({
    firstName: "",
    lastName: "",
    email: unauthUser.email,
    country: "DE",
    location: "",
    locationDisplay: "",
    password: "",
    postalCode: "",
    postalCodeDisplay: "",
  });

  const handleSubmit = (
    event?: React.FormEvent,
  ): Promise<ValidationMessage> => {
    if (event) event.preventDefault();

    setIsLoading(true);

    return api
      .createUser({ ...user, url: location.pathname })
      .then(() => {
        toggleModal("registrationMessage");
        return { valid: true, message: "" };
      })
      .catch(() => {
        return {
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        };
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setUser((prev) => ({ ...prev, email: unauthUser.email }));
  }, [unauthUser]);

  return (
    <ModalDialog
      buttons={[
        {
          action: () => {
            toggleModal("getUser");
          },
          label: t("common.navigation.back") as string,
          variant: "outline",
        },
      ]}
      content={
        <>
          <p>{t("pages.userAccount.modals.registration.content")}</p>

          <UserForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            optionalFields={["password"]}
            setUser={setUser}
            user={user}
          />
        </>
      }
      icon={faRightToBracket}
      id="registration"
      title={t("pages.userAccount.modals.registration.title")}
    ></ModalDialog>
  );
};

export default RegistrationModal;
