import { CSSObject } from "@emotion/react";

import Column, { ColumnProps } from "./Column";

/**
 * Basic card element.
 */
const CardColumn = ({ children, ...props }: ColumnProps) => {
  const style: CSSObject = {
    display: "flex",
    // justifyContent: "stretch",
    "& > *": {
      flexGrow: 1,
    },
  };

  return (
    <Column
      {...props}
      css={(props.css, style)}
    >
      {children}
    </Column>
  );
};

export default CardColumn;
