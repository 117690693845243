export const zIndexes = {
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  offcanvasBackdrop: 1040,
  offcanvas: 1045,
  modalBackdrop: 1050,
  modal: 1055,
  popover: 1070,
  tooltip: 1080,
  toast: 1090,
};
