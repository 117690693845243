import type { CSSObject } from "@emotion/react";
import {
  marginBottomLg,
  marginBottomMdXl,
  paddingYMdZero,
  paddingYXsXl,
} from "@Styles";

const rowStyles: CSSObject = {
  alignItems: "center",
  alignContent: "center",
};

const textStyles: CSSObject[] = [marginBottomLg, marginBottomMdXl];

const illustrationStyles: CSSObject[] = [paddingYXsXl, paddingYMdZero];

export { illustrationStyles, rowStyles, textStyles };
