import type { CSSObject } from "@emotion/react";
import type { FormControllerProps } from "@Types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormHelperText from "./Form.HelperText";

const containerStyle: CSSObject = {
  display: "flex",
  flexDirection: "column",
};

const FormController: FC<FormControllerProps> = ({
  showErrors = true,
  children = <></>,
  error,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "formErrors" });

  const hasError = !!error?.message && showErrors;

  return (
    <div css={containerStyle}>
      <div>{children}</div>

      <FormHelperText showErrors={hasError}>
        {t(error?.message ?? "")}
      </FormHelperText>
    </div>
  );
};

export default FormController;
