import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { FormControl, Heading } from "@Components";
import { colorPrimary } from "@Styles";
import { ValidationRules } from "@Stylize/helpers/validation";
import { InitiativeInput } from "@Types/services/post";
import FieldHeading from "../components/FieldHeading";
import { PetitionStepProps } from "../utils/types";

const StepFour = ({
  handleChange,
  initiative,
  setFieldHelp,
}: PetitionStepProps) => {
  const [error, setError] = useState({} as Record<string, boolean>);

  const [fields, setFields] = useState<InitiativeInput>(initiative);

  useEffect(() => {
    (["hashtag", "name"] as ("hashtag" | "name")[]).forEach((fieldName) => {
      if (fields[fieldName] !== initiative[fieldName]) {
        const value = fields[fieldName];
        if ("" !== value && !value?.match(/^[\w-]+$/)) {
          setError((prev: object) => ({ ...prev, [fieldName]: true }));
          // roll back!
          setFields(initiative);
        } else {
          setError((prev: object) => ({ ...prev, [fieldName]: false }));
          handleChange({
            target: { name: fieldName, value: fields[fieldName] || "" },
          });
        }
      }
    });
  }, [fields, initiative, handleChange]);

  const { t } = useTranslation();

  const CONTENT = {
    hashtag: {
      placeholder: t("components.startPetitionForm.fields.hashtag.placeholder"),
      heading: t("components.startPetitionForm.fields.hashtag.heading"),
      description: t("components.startPetitionForm.fields.hashtag.description"),
      caption: t("components.startPetitionForm.fields.hashtag.caption"),
      specialCharacters: t(
        "components.startPetitionForm.fields.hashtag.validationErrors.specialCharacters",
      ),
    },
    name: {
      placeholder: t("components.startPetitionForm.fields.name.placeholder"),
      heading: t("components.startPetitionForm.fields.name.heading"),
      description: t("components.startPetitionForm.fields.name.description"),
      caption: t("components.startPetitionForm.fields.name.caption"),
      specialCharacters: t(
        "components.startPetitionForm.fields.name.validationErrors.specialCharacters",
      ),
    },
  };

  const validationRules: ValidationRules = {
    hashtag: [
      {
        message: CONTENT.hashtag.specialCharacters,
        valid: !error.hashtag,
      },
    ],
    name: [
      {
        message: CONTENT.name.specialCharacters,
        valid: !error.name,
      },
    ],
  };

  const validateFields = (): Record<
    "hashtag" | "name",
    { valid: boolean; message: string }
  > => {
    const response: Record<
      "hashtag" | "name",
      { valid: boolean; message: string }
    > = {
      hashtag: {
        valid: true,
        message: "",
      },
      name: {
        valid: true,
        message: "",
      },
    };

    for (const key of Object.keys(validationRules)) {
      const error = validationRules[key].find((rule) => !rule.valid);
      response[key as "hashtag" | "name"].valid = !error;
      response[key as "hashtag" | "name"].message = error?.message || "";
    }

    return response;
  };

  return (
    <>
      <div>
        <FieldHeading
          text={CONTENT.hashtag.heading}
          tipAction={() => {
            setFieldHelp("hashtag");
          }}
        />

        <p>{CONTENT.hashtag.description}</p>

        <FormControl
          id="step4-hashtag"
          placeholder={CONTENT.hashtag.placeholder}
          name="hashtag"
          setValue={setFields}
          tone="petition"
          type="text"
          constantPrefix="#"
          paddingForPrefix="1.5rem"
          value={fields.hashtag}
          validation={validateFields().hashtag}
          captionAlign="right"
        />
      </div>

      <div>
        <Heading
          css={colorPrimary("petition")}
          scale={5}
          aria-level={2}
          role="heading"
        >
          {CONTENT.name.heading}
        </Heading>

        <p>{CONTENT.name.description}</p>

        <FormControl
          id="step4-name"
          placeholder={CONTENT.name.placeholder}
          name="name"
          setValue={setFields}
          tone="petition"
          type="text"
          constantPrefix="innn.it/"
          paddingForPrefix="3.8rem"
          value={fields.name}
          validation={validateFields().name}
          captionAlign="right"
        />
      </div>
    </>
  );
};

export default StepFour;
