interface ScaleValues {
  [key: string]: number;
}

const generateScales = (proportion: number, start: number): ScaleValues => {
  const values: ScaleValues = {};
  const sizes = [
    { name: "3xs", value: -4 },
    { name: "2xs", value: -3 },
    { name: "xs", value: -2 },
    { name: "sm", value: -1 },
    { name: "md", value: 0 },
    { name: "lg", value: 1 },
    { name: "xl", value: 2 },
    { name: "2xl", value: 3 },
    { name: "3xl", value: 4 },
    { name: "4xl", value: 5 },
    { name: "5xl", value: 6 },
    { name: "6xl", value: 7 },
    { name: "7xl", value: 8 },
  ];

  for (let i = 0; i < sizes.length; i++) {
    const value = start * Math.pow(proportion, sizes[i].value);
    const multiplier = Math.pow(10, 4); // Number of digits
    const truncatedValue = Math.floor(value * multiplier) / multiplier;
    values[sizes[i].name] = truncatedValue;
  }
  return values;
};

const phi = (1 + Math.sqrt(5)) / 2;
const spacers = generateScales(phi, 1);

export const fontSizeProportion = 1.25;
const fontSizes = generateScales(fontSizeProportion, 1);
const lineHeights: ScaleValues = {
  xs: 0.7,
  sm: 0.9,
  md: 1,
  lg: 1.2,
  xl: 1.4,
};

export { fontSizes, lineHeights, spacers };
