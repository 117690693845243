import routes from "@Configs/routes";
import { ThemeProvider as EmotionThemeProvider, Theme } from "@emotion/react";
import type { Tone } from "@Variables";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./StylesContext/styles";

export type SetTone = React.Dispatch<React.SetStateAction<Tone>>;

// Define whitelist as a constant set outside of the component
const whitelist = new Set([routes.home, routes.root, routes.postRedirection]);

const initiativeThemeRoutes = new Set([
  routes.initiative.faq,
  routes.initiative.search,
  routes.initiative.start,
]);

export type ThemeProviderProps = { children: React.ReactNode };
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [tone, setTone] = useState<Tone>("petition");

  const location = useLocation();
  const params = useParams<{ _id: string }>();

  useEffect(() => {
    const currentPath = location.pathname;
    const isDynamicRouteMatch =
      params._id !== undefined && location.pathname === `/${params._id}`;

    if (initiativeThemeRoutes.has(currentPath) && tone !== "initiative") {
      setTone("initiative");
    } else if (
      whitelist.has(currentPath) ||
      (isDynamicRouteMatch && tone !== "petition")
    ) {
      setTone("petition");
    }
  }, [location.pathname, params._id]);

  const currentTheme = useMemo((): Theme => {
    return {
      ...styles[tone],
      setTone,
    };
  }, [tone]);

  return (
    <EmotionThemeProvider theme={currentTheme}>{children}</EmotionThemeProvider>
  );
};
