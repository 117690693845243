import type { CSSObject } from "@emotion/react";
import { bgGray1, bgRounded, bgWhite, marginXMd2xl } from "@Styles";
import { breakpointUp } from "@Variables/breakpoints";

const containerStyle: CSSObject[] = [
  bgGray1,
  {
    [breakpointUp["md"]]: { ...bgRounded(bgWhite, true, false) },
  },
];
const headerStyle: CSSObject[] = [marginXMd2xl];

export { containerStyle, headerStyle };
