import { IconSVGProps } from "../Icon";

const ExpertiseIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_expertise"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <path
      fill={primaryColor}
      d="M61.9 20.3c-3.5 0-6.6 1.6-8.6 4.2.9.8 1.8 1.7 2.7 2.6 4.1 4.3 6.5 9.7 7.2 15.3 5.5-.6 9.7-5.3 9.8-10.9 0-6.2-4.9-11.2-11.1-11.2zm23.4 4c-.3-.1-.6-.4-.7-.6-.4-.9-.8-1.9-1.2-2.8-.2-.4-.1-.7.1-1.1 1-1.9 2-3.8 3-5.8.1-.3.2-.5-.1-.8-2.2-2.1-4.3-4.2-6.4-6.3-.3-.3-.5-.3-.8-.1-1.3.7-2.7 1.4-4 2.1-.8.4-1.5 1-2.3 1.1-.8 0-1.6-.6-2.4-.8-1.1-.3-1.8-1-2-2.1-.1-.5-.3-1.1-.5-1.6-.4-1.4-.9-2.8-1.3-4.1H57c-.7 2.2-1.4 4.5-2.2 6.7-.1.3-.4.6-.6.7-.9.4-1.9.8-2.9 1.2-.3.1-.7.1-1 0-1-.5-2-1-3.1-1.6-1.1-.6-2.1-1.1-3.1-1.6-2.3 2.2-4.5 4.5-6.7 6.7-.1.1-.1.5 0 .7.7 1.5 1.5 2.9 2.2 4.3 2.8.4 5.5 1.1 8 2.4 3.3-4.4 8.5-7.2 14.3-7.2 9.8.1 17.8 8 17.8 17.8 0 9.4-7.3 17.1-16.5 17.8-.5 4.3-2.1 8.5-4.6 12.3h8.3c.7-2.2 1.4-4.5 2.1-6.7.1-.3.4-.6.7-.7.9-.4 1.9-.8 2.9-1.2.3-.1.7-.1 1 0l5.7 3c.4.2.6.1.9-.2 2-2.1 4.1-4.1 6.1-6.1.3-.3.3-.6.1-1-1-1.9-2-3.8-2.9-5.8-.1-.3-.1-.7 0-.9.3-1 .7-1.9 1.2-2.9.1-.3.4-.5.7-.6 1.2-.4 2.5-.8 3.8-1.2 1-.3 2-.6 2.9-.9v-9.8c-2.4-.9-4.6-1.6-6.8-2.3z"
    />
    <g id="wuFDwe.tif_00000178884863306676271000000008341397555990346682_">
      <linearGradient
        id="SVGID_1_expertise"
        gradientUnits="userSpaceOnUse"
        x1={8.021}
        y1={48.188}
        x2={63.388}
        y2={48.188}
      >
        <stop
          offset={0}
          stopColor={primaryColor}
        />
        <stop
          offset={1}
          stopColor={secondaryColor}
        />
      </linearGradient>
      <path
        d="M57 61.4c-.7-2.2-1.4-4.4-2.1-6.7-.1-.4-.3-.6-.7-.8-.9-.3-1.9-.7-2.8-1.1-.4-.2-.7-.2-1.1 0l-5.7 3c-.4.2-.6.2-.9-.1-2-2.1-4-4.1-6.1-6.1-.3-.3-.4-.5-.2-.9 1-1.9 1.9-3.8 2.9-5.7.2-.4.2-.7.1-1.1-.4-1-.7-1.9-1.2-2.9-.1-.3-.4-.5-.7-.6-2.2-.7-4.5-1.4-6.7-2.2v-9.8c.9-.3 1.9-.6 2.9-.9 1.3-.4 2.6-.8 3.9-1.3.3-.1.6-.4.7-.6.4-.9.8-1.9 1.2-2.9.1-.3.1-.7 0-.9-.3-.5-.5-1-.8-1.5-3-.4-6.1-.3-9.4.4-7.1 1.3-12.9 5.1-17 11.1-6 8.8-7 18.2-2.3 27.8 2 4.2 5.1 7.9 7.7 11.8 1.7 2.5 3.5 5 4.4 7.9.2.7.5.9 1.2.9H47c.8 0 1.3-.2 1.6-1.1.4-1.3.9-2.5 1.7-3.6 2.7-4.1 5.5-8.1 8.3-12.2H57zm6.2-19.1c-.4.1-.9.1-1.3.1-6.1 0-11-5-11-11.1 0-2.6.9-5 2.4-6.8-1.8-1.5-3.7-2.7-5.7-3.7-2.2 3-3.5 6.6-3.5 10.6 0 9.8 8 17.8 17.8 17.8h1.3c.3-2.4.3-4.7 0-6.9z"
        fill="url(#SVGID_1_expertise)"
      />
      <path
        fill={primaryColor}
        d="M23.5 82.4H48c0 2.6.1 5.2 0 7.8-.1 1.3-1.4 2.4-2.9 2.4-.8 0-1.2.3-1.4 1-.2.9-.6 1.7-.9 2.5-.6 1.7-1.8 2.6-3.6 2.7-2.3.1-4.6.1-6.9 0-1.8-.1-3-1-3.6-2.7-.3-.9-.7-1.8-.9-2.7-.2-.6-.5-.8-1.1-.8-2.1-.1-3.2-1.2-3.2-3.3v-6.9z"
      />
    </g>
  </svg>
);

export default ExpertiseIcon;
