import { CSSObject, useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Column, Heading, SocialMediaShare } from "@Components";
import {
  bgGradient,
  colorSecondary,
  displayMdBlock,
  displayNone,
  elevation3,
  fontSizeXl,
  fontWeightMedium,
  marginBottomLg,
  marginBottomMd,
  marginBottomXl,
  marginTopLg,
  paddingLg,
  paddingZero,
} from "@Styles";
import { Signable } from "@Types/services/post";
import { navBarHeight, spacers } from "@Variables";
import SignableGauge from "../SignableGauge";
import SignForm from "./SignForm";

const Sidebar = ({ signable }: { signable: Signable }) => {
  const { tone } = useTheme();

  const sidebarStyle: CSSObject[] = [displayNone, displayMdBlock, paddingZero];

  const stickyContainer: CSSObject[] = [
    marginBottomXl,
    {
      position: "sticky",
      top: parseFloat(navBarHeight) + spacers.lg + "rem",
    },
  ];

  const signFormContainer: CSSObject[] = [
    bgGradient(tone, "light"),
    elevation3,
    paddingLg,
    marginBottomLg,
    marginTopLg,
  ];

  return (
    <Column
      css={sidebarStyle}
      span={{ default: 5, md: 4 }}
      offset={{ default: 0, md: 1 }}
    >
      <SignableGauge signable={signable} />
      <div css={stickyContainer}>
        <div css={signFormContainer}>
          <SignForm signable={signable} />
        </div>
        <SidebarShare signable={signable} />
      </div>
    </Column>
  );
};

const SidebarShare = ({ signable }: { signable: Signable }) => {
  const { t } = useTranslation();
  const { tone } = useTheme();

  const containerStyle: CSSObject[] = [
    elevation3,
    paddingLg,
    displayNone,
    displayMdBlock,
  ];

  const titleStyle: CSSObject[] = [
    colorSecondary(tone),
    fontSizeXl,
    fontWeightMedium,
    marginBottomMd,
  ];

  return (
    <div css={containerStyle}>
      <Heading
        scale={5}
        css={titleStyle}
      >
        {t("pages.signable.share.title")}
      </Heading>

      <SocialMediaShare
        context="signForm"
        size="sm"
        signableTitle={signable.title}
        signableType={signable.type}
        url={window.location.href}
        variant="secondary"
        background="dark"
        includeLabel={false}
        transparent
      />
    </div>
  );
};

export default Sidebar;
