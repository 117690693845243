import type { CSSObject } from "@emotion/react";

import {
  gapMd,
  marginBottomLg,
  marginBottomMd,
  marginBottomZero,
  marginRightMdXl,
  marginYLg,
  paddingBottomMdZero,
  paddingBottomXsMd,
  paddingTopMdZero,
  paddingTopXs2xl,
  paddingXMd2xl,
  paddingXMdLg,
  paddingXXsMd,
} from "@Styles";

const rowStyle: CSSObject = { alignItems: "center", justifyContent: "center" };

const headingStyle: CSSObject[] = [marginBottomLg];

const bodyStyles = [marginBottomZero];

const formContainerStyle: CSSObject[] = [
  gapMd,
  marginYLg,
  {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
];

const formInputStyle: CSSObject = {
  display: "flex",
  justifyContent: "center",
};

const textContainer: CSSObject[] = [marginRightMdXl, paddingXMdLg];

const illustrationContainer: CSSObject[] = [
  marginBottomMd,
  {
    display: "flex",
    justifyContent: "center",
  },
];

const illustrationStyles: CSSObject[] = [
  paddingXXsMd,
  paddingXMd2xl,
  paddingTopXs2xl,
  paddingTopMdZero,
  paddingBottomXsMd,
  paddingBottomMdZero,
];

export {
  bodyStyles,
  formContainerStyle,
  formInputStyle,
  headingStyle,
  illustrationContainer,
  illustrationStyles,
  rowStyle,
  textContainer,
};
