import { IconSVGProps } from "../Icon";

const SignatureIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_signature"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <linearGradient
      id="SVGID_1_signature"
      gradientUnits="userSpaceOnUse"
      x1={0.147}
      y1={84.133}
      x2={68.384}
      y2={84.133}
    >
      <stop
        offset={0}
        stopColor={secondaryColor}
      />
      <stop
        offset={1}
        stopColor={primaryColor}
      />
    </linearGradient>
    <path
      d="M51.6 89.6c.3-.9 1.5-1.2 2.1-.4 2.2 2.8 4.5 6.1 7.9 6.6 2.3.4 6.8.2 6.8.2v-7.9h-3.8c-1.3-.1-2.2-.6-3-1.6-.6-.8-1.3-1.5-2-2.3-4.5-5.2-12.2-4.4-15.3 1.8-.3.7-.7 1.3-1 2-.1 0-.2 0-.3-.1-.1-.3-.3-.6-.4-.8-.8-2.5-1.6-4.9-2.4-7.4-2-6-8.4-9.1-14.2-6.6-2.4 1-4 2.8-5.1 5-1.2 2.5-2.3 5-3.5 7.5-.9 1.9-2.5 2.6-4.7 2.1-1.5-.3-2.9-1-3.9-2.1-.9-.9-1.7-1.8-2.4-2.6-2.2 2.1-4.2 4-6.2 5.9 2.5 2.4 4.9 4.9 8.5 6.2 5.7 2 11.5.7 15-3.6.6-.8 1.1-1.7 1.5-2.5 1.2-2.4 2.3-4.8 3.4-7.3.3-.7.8-1.4 1.7-1.4.9 0 1.3.7 1.6 1.5.9 2.9 1.9 5.8 2.9 8.7 1.1 3.1 3.5 4.9 6.9 5.4 3.9.6 7.5-1.2 9.4-4.6.1-.4.3-1 .5-1.7z"
      fill="url(#SVGID_1_signature)"
    />
    <path
      fill={primaryColor}
      d="M99.9 95.9c0-5.4-.9-10.5-2.7-15.4-1.2-3.2-2.7-6.2-4.6-9-2.8-4.3-10.6-16.1-13.4-20.3l-.6-.9c-4.1 2.7-8.1 5.3-12.1 8 .1.2.1.4.2.5 3.4 5.2 11.7 18 15.3 23.1 4.6 6.3 10.6 10.9 17.9 14zM38.1 2.8C34.7 5 33.8 9.5 36 12.9l27.3 40.7 12.1-8L48.1 4.8c-2.2-3.3-6.7-4.2-10-2z"
    />
    <circle
      fill={primaryColor}
      cx={77.8}
      cy={91.9}
      r={4.5}
    />
  </svg>
);

export default SignatureIcon;
