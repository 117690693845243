import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";

const PasswordResetMessageModal = () => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      content={t("pages.userAccount.modals.passwordResetMessage.content")}
      icon={faCheck}
      id="passwordResetMessage"
      title={t("pages.userAccount.modals.passwordResetMessage.title")}
    ></ModalDialog>
  );
};

export default PasswordResetMessageModal;
