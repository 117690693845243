import countries from "i18n-iso-countries";
import countriesEN from "i18n-iso-countries/langs/de.json";
import countriesDE from "i18n-iso-countries/langs/en.json";

countries.registerLocale(countriesDE);
countries.registerLocale(countriesEN);

export default (language: string) => {
  const localizedCountriesList = countries.getNames(language);

  return Object.entries(localizedCountriesList).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
};
