const routes = {
  root: "/",
  about: "/about",
  authenticate: "/authenticate",
  error: "/error",
  faq: "/faq",
  home: "/home",
  search: "/#search",
  press: "//verein.innn.it/newsroom",
  organization: "//verein.innn.it/",
  petition: {
    start: "/petition-starten",
    startById: "/petition-starten/:_id",
    user: {
      petitions: "/user/petitions",
      signatures: "/user/signatures",
      account: "/user/profile",
    },
  },
  initiative: {
    search: "/initiatives",
    start: "/contact",
    faq: "/initiatives",
  },
  verify: "/verify/:model/:_id/:token",
  postRedirection: "/:_id",
  updates: "/:_id/updates",
  updatesId: "/:_id/updates/:updateId",
  share: "/:_id/share",
  psfStep: "/:_id/:step",
  notFound: "*",

  info: {
    dataProtection: "/datenschutz",
    imprint: "/impressum",
    termsOfUse: "/nutzungsbedingungen",
  },
};

export default routes;
