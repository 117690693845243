import { IconSVGProps } from "../Icon";

const ThankYouIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <style />
    <linearGradient
      id="SVGID_1_thankyou"
      gradientUnits="userSpaceOnUse"
      x1={36.571}
      y1={24.863}
      x2={66.53}
      y2={104.393}
    >
      <stop
        offset={0}
        stopColor={primaryColor}
      />
      <stop
        offset={1}
        stopColor={secondaryColor}
      />
    </linearGradient>
    <path
      d="M78.6 99.8H41.5l-19.1-26C18.6 68.4 18 61.3 21 55.5c.5-.9 1-1.7 1.6-2.4l11.2-14.7c2.7-3.5 5.3-6.5 7.7-8.9l.7-.7.7-.7c1.7-1.6 3.4-3 5-4l5.9-4 .8-.5.8-.5 4.3-2.6c2.2-1.5 5.2-1.1 6.8.9 1.7 2.2 1.4 6-1.4 8.1L59 30.1l-.8.6-.8.6-2.4 1.9c-.8.7-2.5 2.1-3.7 3.4-.3.4-.9 1.1-1.2 1.5l-11 16.1c-.4.6-.3 1.5.3 2 .3.2.6.3.9.3.5 0 .9-.2 1.2-.6L51.8 41c1.9-2.8 6-3.1 8.4-.7 1.8 1.9 2 4.7.6 6.9L50.7 61.8c-.4.6-.2 1.5.4 1.9.2.2.5.2.8.2.4 0 .9-.2 1.1-.6 1.5-2.3 5.7-8.4 8.8-12.9 1.8-2.6 5.6-2.9 7.9-.6 1.7 1.7 1.9 4.5.6 6.5L61.5 69c-.4.6-.3 1.3.3 1.7.2.2.5.3.8.3.4 0 .8-.2 1-.5L71 59.9c1.8-2.6 5.6-2.9 7.9-.6 2.2 2.3 2.5 5.9.7 8.5L74.5 75l1.1.9c6.9 5.8 6 16.5 3 23.9z"
      fill="url(#SVGID_1_thankyou)"
    />
    <path
      d="M57.5 4.2c-.1.1-.2 0-.2 0-.3-.6-.5-1-.7-1.5C55.5.9 53.8.2 52 .7c-1.8.4-3.2 2.1-3 4.3.2 1.4.6 2.7 1.3 3.8 1.3 2.1 3.1 3.9 4.7 5.8.1.1.2.1.3.1 2.2-1.1 4.5-2 6.5-3.4 1.1-.8 2.1-1.8 2.8-3 1-1.9.6-4.1-.9-5.3-1.5-1.2-3.4-1.1-5.1.2-.4.3-.7.6-1.1 1z"
      fill={secondaryColor}
    />
    <path
      d="M51.2 18.5l-6.1 4.3c-1.4 1-2.9 2.2-4.4 3.7l-3.6-8.6c-.6-1.3-.8-2.4-.4-3.2.4-1 1.4-1.8 3.1-2.4 1-.3 2-.5 3-.5 1.5 0 2.9.4 4.2 1.2 1.7 1 3.3 3 4.2 5.5z"
      fill={primaryColor}
    />
  </svg>
);

export default ThankYouIcon;
