import React from "react";

import Status from "./Status";
import { Initiative } from "../../types/services/post";

interface Props {
  initiative: Initiative;
}

const InitiativeStage = ({ initiative }: Props) => {
  const fetchInitiativeStage = () => {
    switch (initiative.stage) {
      case "support":
        return "Unterstützung";
        break;

      case "first-collection":
        return "1. Sammlung";
        break;

      case "second-collection":
        return "2. Sammlung";
        break;

      default:
        return null;
        break;
    }
  };

  const initiativeStage = fetchInitiativeStage();

  return (
    initiativeStage && (
      <Status
        label={initiativeStage}
        theme={initiative.type}
        variant="underline"
      />
    )
  );
};

export default InitiativeStage;
