import React from "react";

import styled, { CSSObject } from "@emotion/styled";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Container, Panel } from "@Components";
import { useTheme } from "@emotion/react";
import { bgGray1, colorSecondary, marginRightLg, paddingLg } from "@Styles";
import { FieldHelpSetter } from "../../utils/types";

interface HashtagHelpProps {
  setFieldHelp: FieldHelpSetter;
}

const HashtagHelp = ({ setFieldHelp }: HashtagHelpProps) => {
  const { tone } = useTheme();

  const { t } = useTranslation();

  const CONTENT = {
    header: t("components.startPetitionForm.help.fieldTips.hashtag.header"),
    paragraph1: t(
      "components.startPetitionForm.help.fieldTips.hashtag.paragraph1",
    ),
    listTitle: t(
      "components.startPetitionForm.help.fieldTips.hashtag.examples.title",
    ),
    examples: [
      {
        bold1: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example1.bold1",
        ),
        normal1: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example1.normal1",
        ),
        bold2: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example1.bold2",
        ),
        normal2: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example1.normal2",
        ),
      },
      {
        bold1: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example2.bold1",
        ),
        normal1: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example2.normal1",
        ),
        bold2: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example2.bold2",
        ),
        normal2: t(
          "components.startPetitionForm.help.fieldTips.hashtag.examples.example2.normal2",
        ),
      },
    ],
  };

  const ListTitle = styled.p({
    ...colorSecondary(tone),
    fontWeight: "700",
    fontSize: "16px",
  });

  const StyledList = styled.ul({
    listStyleType: "none",
    paddingLeft: "0",
  });

  const StyledLI = styled.li({
    display: "flex",
    marginLeft: "0",
    "&::marker": {
      content: "''",
    },
  });

  const StyledListItem = ({
    children,
  }: {
    children: React.ReactNode | string;
  }) => (
    <StyledLI>
      <div css={marginRightLg}>
        <FontAwesomeIcon
          icon={faCircle}
          size="2xs"
        />
      </div>
      {children}
    </StyledLI>
  );

  const boldTextStyle: CSSObject = {
    fontWeight: "700",
    fontSize: "16px",
  };

  const BoldSpan = styled.span(boldTextStyle);

  return (
    <Panel
      header={CONTENT.header}
      icon={faInfoCircle}
      onClose={() => {
        setFieldHelp(null);
      }}
    >
      <p>{CONTENT.paragraph1}</p>
      <Container css={[bgGray1, paddingLg, { borderRadius: "8px" }]}>
        <ListTitle>{CONTENT.listTitle}</ListTitle>
        <StyledList>
          {CONTENT.examples.map((example, index) => (
            <StyledListItem key={index}>
              <div>
                <div>
                  <BoldSpan>{example.bold1}</BoldSpan>
                  {example.normal1}
                </div>
                <div>
                  <BoldSpan>{example.bold2}</BoldSpan>
                  {example.normal2}
                </div>
              </div>
            </StyledListItem>
          ))}
        </StyledList>
      </Container>
    </Panel>
  );
};

export default HashtagHelp;
