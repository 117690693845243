import { CSSObject } from "@emotion/react";

import toCamelCase from "../helpers/toCamelCase";
import { breakpoints, setBreakpointProperties } from "../variables/breakpoints";
import { lineHeights } from "../variables/scales";

const createLineHeights = () => {
  const lineHeightStyles: { [key: string]: CSSObject } = {};
  const property = "lineHeight";

  for (const lineHeight of Object.keys(lineHeights)) {
    const sizeName: string = toCamelCase(lineHeight);
    const properties: CSSObject = {
      [property]: lineHeights[lineHeight],
    };

    lineHeightStyles[property + sizeName] = properties;

    for (const breakpoint of breakpoints) {
      lineHeightStyles[
        property +
          ("default" != breakpoint ? toCamelCase(breakpoint) : "") +
          sizeName
      ] = setBreakpointProperties(breakpoint, properties);
    }
  }
  return lineHeightStyles;
};

const lineHeightStyles = createLineHeights();

export const lineHeightMd = lineHeightStyles["lineHeightMd"];
export const lineHeightLg = lineHeightStyles["lineHeightLg"];
export const lineHeightXl = lineHeightStyles["lineHeightXl"];

export const lineHeightSmMd = lineHeightStyles["lineHeightSmMd"];
export const lineHeightSmLg = lineHeightStyles["lineHeightSmLg"];
export const lineHeightSmXl = lineHeightStyles["lineHeightSmXl"];

export const lineHeightMdMd = lineHeightStyles["lineHeightMdMd"];
export const lineHeightMdLg = lineHeightStyles["lineHeightMdLg"];
export const lineHeightMdXl = lineHeightStyles["lineHeightMdXl"];

export const lineHeightLgMd = lineHeightStyles["lineHeightLgMd"];
export const lineHeightLgLg = lineHeightStyles["lineHeightLgLg"];
export const lineHeightLgXl = lineHeightStyles["lineHeightLgXl"];
