import { CSSObject } from "@emotion/react";

import {
  Breakpoint,
  breakpoints,
  setBreakpointProperties,
} from "../variables/breakpoints";

type EllipsedTextProps = {
  /** Text to be ellipsed */
  text: JSX.Element | JSX.Element[] | string;
  /** Maximum number of lines */
  lineClamp: {
    [key in Breakpoint]?: number;
  };
};

/**
 * Overflow text after the specified number of lines
 */
const EllipsedText = ({ text, lineClamp }: EllipsedTextProps) => {
  const style: CSSObject[] = [];

  if (lineClamp) {
    for (const breakpoint of breakpoints) {
      if (lineClamp[breakpoint]) {
        const properties: CSSObject = {
          display: "-webkit-box",
          overflow: "hidden",
          WebkitLineClamp: lineClamp[breakpoint],
          WebkitBoxOrient: "vertical",
        };

        style.push(setBreakpointProperties(breakpoint, properties));
      }
    }
  }
  return <span css={style}>{text}</span>;
};

export default EllipsedText;
