import type { CSSObject } from "@emotion/react";

import {
  marginBottomMdZero,
  marginBottomXsXl,
  paddingBottomLg,
  paddingBottomZero,
} from "@Styles/spacers";
import { breakpointUp } from "@Variables";

const orderColumn = (defaultValue: 1 | 2, imagePosition: "left" | "right") => {
  if (defaultValue === 1) {
    const position = imagePosition === "right" ? 1 : 2;

    return {
      default: defaultValue,
      md: position,
      lg: position,
    };
  } else {
    const position = imagePosition === "right" ? 2 : 1;
    return {
      default: defaultValue,
      md: position,
      lg: position,
    };
  }
};

const buttonContainerCSS: CSSObject[] = [marginBottomXsXl, marginBottomMdZero];

const titleStyles: CSSObject = {
  ...paddingBottomLg,
};

const bannerContainerStyle: CSSObject[] = [];

const bannerImageColumnStyles: CSSObject[] = [
  {
    margin: "auto",
    [breakpointUp["lg"]]: {
      ...paddingBottomZero,
    },
  },
];

export {
  bannerContainerStyle,
  bannerImageColumnStyles,
  buttonContainerCSS,
  orderColumn,
  titleStyles,
};
