import { CSSObject } from "@emotion/react";
import {
  bgGray1,
  bgWhite,
  elevation3,
  marginBottomLg,
  marginBottomMd,
  marginBottomXl,
  marginBottomZero,
  marginTopZero,
  marginXMdXs,
  marginYXl,
  marginZero,
  paddingTopLg,
  paddingXMd2xl,
  paddingXMdMd,
} from "@Styles";
import { breakpointUp } from "@Variables";

const aboutSectionStyle: CSSObject[] = [bgGray1];

const aboutHeadingStyle: CSSObject[] = [
  paddingXMd2xl,
  {
    [breakpointUp["xs"]]: {
      ...marginTopZero,
    },

    [breakpointUp["md"]]: {
      ...marginZero,
    },
  },
];

const aboutContentStyle: CSSObject[] = [marginZero, paddingXMdMd];

const aboutIconBoxContainerStyle: CSSObject[] = [marginYXl, marginXMdXs];

const aboutIconBoxStyle: CSSObject = {
  ...marginBottomMd,

  [breakpointUp["xs"]]: {
    ":last-of-type": {
      ...marginBottomZero,
    },
  },

  [breakpointUp["sm"]]: {
    ":nth-last-of-type(-n+2)": {
      ...marginBottomZero,
    },
  },

  [breakpointUp["md"]]: {
    ":nth-last-of-type(-n+4)": {
      ...marginBottomZero,
    },
  },

  [breakpointUp["lg"]]: {
    ...marginBottomLg,
    ":last-child": {
      ...marginBottomZero,
    },
  },
};

const aboutIconBoxContentStyle: CSSObject[] = [
  bgWhite,
  elevation3,
  marginBottomXl,
  paddingTopLg,
  { height: "100%" },
];

export {
  aboutContentStyle,
  aboutHeadingStyle,
  aboutIconBoxContainerStyle,
  aboutIconBoxContentStyle,
  aboutIconBoxStyle,
  aboutSectionStyle,
};
