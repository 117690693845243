import { useContext } from "react";

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { routes } from "@Configs";
import { UserDispatchContext, login } from "@Contexts/UserContext";
import api from "@Utils/api";

export const Verification = () => {
  const dispatch = useContext(UserDispatchContext);
  const { model, _id, token } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { trackEvent } = useMatomo();

  const verifySubscription =
    searchParams.get("subscribe") == "true" ? true : false;

  let verify: "verifyDocument" | "verifySignature" = "verifyDocument";
  if ("document" == model) {
    verify = "verifyDocument";
  } else if ("signature" == model) {
    verify = "verifySignature";
  }

  if (!_id || !model || !token) {
    navigate(routes.error, { replace: true });
    return null;
  }

  api[verify](_id, { verifySubscription, token })
    .then((response) => {
      const signed = response.data;

      const signableId = signed.signableId
        ? signed.signableId
        : signed.initiativeId;

      trackEvent({
        category: "signable",
        action: verify,
        customDimensions: [
          {
            id: 1, // Signable ID
            value: signableId,
          },
          {
            id: 2, // Signable model
            value: model,
          },
          {
            id: 3, // Newsletter opt-in
            value:
              signed.subscribe && signed.verifySubsciption ? "true" : "false",
          },
        ],
      });

      login({ email: signed?.signer?.email, token }, dispatch);

      navigate(`/${signableId}/2`, { replace: true, state: { signed } });
    })
    .catch(() => navigate(routes.error, { replace: true }));

  return <>Loading…</>;
};
