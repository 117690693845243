import React from "react";

import { CSSObject, keyframes, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { zIndexes } from "@Variables/zIndexes";
import { breakpointValues } from "../variables/breakpoints";
import { Container } from "./Container";
import { Heading } from "./Heading";

export interface PanelProps {
  /** children can be any content that will be displayed below the header */
  children: React.ReactNode | React.ReactNode[];
  /** header text of the panel */
  header: string;
  /** icon will be shown at the start of the header */
  icon: IconProp;
  /** Close action */
  onClose: React.MouseEventHandler<SVGSVGElement>;
}

const fadeInRight = keyframes({
  from: {
    opacity: 0,
    transform: "translateX(100%)",
  },
  to: {
    opacity: 1,
    transform: "translateX(0)",
  },
});

const panelStyle: CSSObject = {
  position: "fixed",
  right: "0",
  minHeight: `${breakpointValues.sm}px`,
  height: "100%",
  overflowX: "hidden",
  backgroundColor: "white",
  zIndex: zIndexes.fixed,
  animation: `${fadeInRight} 0.5s`,
  boxShadow: "-8px 8px 8px rgba(130, 136, 148, 0.08)",
  [`@media (min-width: ${breakpointValues.sm}px)`]: {
    width: "60%",
    padding: "1rem 2rem",
    marginBottom: "0.5rem",
  },
  [`@media (min-width: ${breakpointValues.md}px)`]: {
    width: "35%",
    padding: "1rem 2rem",
    marginBottom: "0.5rem",
  },
  [`@media (max-width: ${breakpointValues.sm}px)`]: {
    width: "100%",
  },
};

const topBarStyle: CSSObject = {
  width: "100%",
  height: "40px",
  padding: "10px",
  gap: "8px",
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const headingContainerStyle: CSSObject = {
  width: "100%",
  height: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 16,
  display: "inline-flex",
  marginBottom: "2rem",
};

/**
 * Creates a side bar as a panel that can be triggered open from an outside element
 */
const Panel = ({ header, icon, children, onClose }: PanelProps) => {
  const { colors } = useTheme();

  return (
    <Container
      id="panel"
      css={panelStyle}
    >
      <Container css={topBarStyle}>
        <FontAwesomeIcon
          icon={faClose}
          color={colors.primary.main}
          size="2x"
          cursor="pointer"
          fixedWidth
          onClick={onClose}
        />
      </Container>

      <Container>
        <Container css={headingContainerStyle}>
          <FontAwesomeIcon
            icon={icon}
            color={colors.secondary.main}
            size="2xl"
            fixedWidth
          />

          <Heading
            scale={5}
            css={{ color: colors.secondary.main }}
          >
            {header}
          </Heading>
        </Container>
        {children}
      </Container>
    </Container>
  );
};

export default Panel;
