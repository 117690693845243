import React, { Children, forwardRef } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { To } from "react-router-dom";

import {
  bgDanger,
  bgDangerOpacity15,
  bgDangerOpacity75,
  bgGray3,
  bgGray4,
  bgPrimary,
  bgPrimaryOpacity15,
  bgPrimaryOpacity75,
  bgSecondary,
  bgSecondaryOpacity15,
  bgSecondaryOpacity75,
  bgSuccess,
  bgSuccessOpacity15,
  bgSuccessOpacity75,
  bgTransparent,
  bgWhite,
  bodyStyle,
  borderDanger,
  borderGray3,
  borderPrimary,
  borderSecondary,
  borderSuccess,
  borderTransparent,
  colorBlack,
  colorDanger,
  colorGray4,
  colorGray5,
  colorPrimary,
  colorSecondary,
  colorSuccess,
  colorWhite,
  fontWeightMedium,
} from "@Styles";
import {
  Breakpoint,
  Tone,
  breakpoints,
  fontSizes,
  lineHeights,
  setBreakpointProperties,
} from "@Variables";

import { Visible } from "./atoms";

export interface ButtonProps extends React.ComponentProps<"button"> {
  /** Used by Emotion to render a different tag than `<button>` */
  as?: React.ElementType;
  /** Disable actions on the button. */
  disabled?: boolean;
  /** Displays the button with the same width as the parent. Responsive property. */
  fullWidth?: { [key in Breakpoint]?: boolean };
  /** To be used when button is rendered as an anchor (`<a>`) */
  href?: string;
  /** Displays the button with loading status. */
  loading?: boolean;
  /** Action to be performed on button click. */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** Size of the button. */
  size?: "sm" | "md";
  /** Location that is the destination of some navigation */
  to?: To;
  /** Variant of the button. */
  variant:
    | "primary"
    | "secondary"
    | "outline"
    | "transparent"
    | "transparentSecondary"
    | "transcent";
  /** Theme of the button. Beside the site themes, there are also `danger` and `success` themes, that offer `primary` and `outline` variants. */
  tone?: Tone | "danger" | "success";
  /** Defines the label of the button, so the component is used as a self-closing element. */
  label: string;
  /** FontAwesome icon to be displayed in the button. */
  icon?: IconProp;

  /** Custom icon component to be displayed in the button. */
  Icon?: React.JSXElementConstructor<any>; // Accept component
  /** If true, the button will only display the icon */
  iconOnly?: boolean;
  /** If true, the button will have rounded corners */
  rounded?: boolean;
  /** If true, the icon will be displayed on the right side of the button */
  iconLeft?: boolean;
  /** If true, the icon will be displayed on the left side of the button */
  iconRight?: boolean;
  /**  If true, the button will be active.*/
  active?: boolean;
  children?: React.ReactNode;
}

//#region Styles

const maxWidth = "16rem";
const basicStyle: CSSObject = {
  ...fontWeightMedium,
  alignItems: "center",
  borderRadius: "6.25rem",
  cursor: "pointer",
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  margin: "0",
  maxWidth,
  padding: "0",
  position: "relative",
  userSelect: "none",
  textAlign: "center",
  textDecoration: "none",
  width: "auto",
};

const activeStyle: CSSObject = {
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(118, 118, 118, 0.15)",
  outline: 0,
};

const disabledStyle: CSSObject = {
  cursor: "default",
  pointerEvents: "none",
};

const hiddenStyle: CSSObject = {
  visibility: "hidden",
};

const hoverStyle: CSSObject = {
  fontWeight: "lighter",
};

const fullWidthStyle = {
  maxWidth: "100%",
  width: "100%",
};

const autoWidthStyle: CSSObject = {
  maxWidth,
  width: "auto",
};

const labelStyle: CSSObject = {
  display: "-webkit-box",
  maxHeight: "3rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const loadingIconStyle: CSSObject = {
  position: "absolute",
};

const sizeStyles: { [key in ButtonProps["size"] as string]: CSSObject } = {
  sm: {
    fontSize: `${fontSizes.sm}rem`,
    lineHeight: `${lineHeights.md}rem`,
    maxHeight: "2.25rem",
    padding: "0.5rem 1.125rem",
  },
  md: {
    fontSize: `${fontSizes.md}rem`,
    lineHeight: `${lineHeights.lg}rem`,
    maxHeight: "3.5rem",
    padding: "1.125rem 1.75rem",
  },
};

const iconOnlyStyle: CSSObject = {
  borderRadius: "100%",
};

const iconOnlySizeStyles: {
  [key in ButtonProps["size"] as string]: CSSObject;
} = {
  sm: {
    height: "2.15rem",
    padding: "0.625rem",
    width: "2.15rem",
  },
  md: {
    height: "3.5rem",
    padding: "1rem",
    width: "3.5rem",
  },
};

const overlayHoverStyle: CSSObject = {
  "&:hover::after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#EFE8FD",
    borderRadius: "2rem",
    zIndex: -1,
    pointerEvents: "none",
  },
};

const themeHoverStyles: {
  [key in ButtonProps["tone"] as string]: {
    [key in ButtonProps["variant"]]: CSSObject[];
  };
} = {
  petition: {
    primary: [
      bgPrimaryOpacity75("petition"),
      borderTransparent,
      colorWhite,
      overlayHoverStyle,
    ],
    secondary: [
      bgSecondaryOpacity75("petition"),
      borderTransparent,
      colorWhite,
    ],
    outline: [
      bgSecondaryOpacity15("petition"),
      colorPrimary("petition"),
      overlayHoverStyle,
    ],
    transparent: [bgSecondaryOpacity15("petition"), colorPrimary("petition")],
    transparentSecondary: [
      bgSecondaryOpacity15("petition"),
      colorSecondary("petition"),
    ],
    transcent: [
      colorBlack,
      bgPrimaryOpacity15("petition"),
      {
        transition: "all 0.3s ease",
      },
      overlayHoverStyle,
    ],
  },
  initiative: {
    primary: [
      bgPrimaryOpacity75("initiative"),
      borderTransparent,
      colorWhite,
      overlayHoverStyle,
    ],
    secondary: [
      bgSecondaryOpacity75("initiative"),
      borderTransparent,
      colorWhite,
    ],
    outline: [
      bgSecondaryOpacity15("initiative"),
      colorPrimary("initiative"),
      overlayHoverStyle,
    ],
    transparent: [
      bgSecondaryOpacity15("initiative"),
      colorPrimary("initiative"),
    ],
    transparentSecondary: [
      bgSecondaryOpacity15("initiative"),
      colorSecondary("initiative"),
    ],
    transcent: [
      colorBlack,
      borderTransparent,
      bgPrimaryOpacity15("initiative"),
      {
        transition: "all 0.3s ease",
      },
    ],
  },
  danger: {
    primary: [bgDangerOpacity75, colorWhite, overlayHoverStyle],
    secondary: [],
    outline: [bgDangerOpacity15, colorDanger, overlayHoverStyle],
    transparent: [bgDangerOpacity15, colorDanger],
    transparentSecondary: [bgDangerOpacity15, colorDanger],
    transcent: [bgDangerOpacity15, colorDanger],
  },
  success: {
    primary: [bgSuccessOpacity75, colorWhite, overlayHoverStyle],
    secondary: [],
    outline: [bgSuccessOpacity15, colorSuccess, overlayHoverStyle],
    transparent: [bgSuccessOpacity15, colorSuccess],
    transparentSecondary: [bgSuccessOpacity15, colorSuccess],
    transcent: [bgSuccessOpacity15, colorSuccess],
  },
};

const themeDisabledStyles: {
  [key in ButtonProps["tone"] as string]: {
    [key in ButtonProps["variant"]]: CSSObject[];
  };
} = {
  petition: {
    primary: [bgGray4, borderTransparent, colorWhite],
    secondary: [bgGray3, borderTransparent, colorGray5],
    outline: [bgWhite, borderGray3, colorGray4],
    transparent: [bgWhite, borderTransparent, colorGray4],
    transparentSecondary: [bgWhite, borderTransparent, colorGray4],
    transcent: [bgWhite, borderTransparent, colorGray4],
  },
  initiative: {
    primary: [bgGray4, borderTransparent, colorWhite],
    secondary: [bgGray3, borderTransparent, colorGray5],
    outline: [bgWhite, borderGray3, colorGray4],
    transparent: [bgWhite, borderTransparent, colorGray4],
    transparentSecondary: [bgWhite, borderTransparent, colorGray4],
    transcent: [bgWhite, borderTransparent, colorGray4],
  },
  danger: {
    primary: [bgGray4, borderTransparent, colorWhite],
    secondary: [],
    outline: [bgWhite, borderGray3, colorGray4],
    transparent: [],
    transparentSecondary: [],
    transcent: [],
  },
  success: {
    primary: [bgGray4, borderTransparent, colorWhite],
    secondary: [],
    outline: [bgWhite, borderGray3, colorGray4],
    transparent: [],
    transparentSecondary: [],
    transcent: [],
  },
};

//#endregion Styles

/**
 * Renders by default a `<button>` HTML element.
 *
 * The element is an Emotion styled components, so it can be rendered as different HTML elements, using the `as` property.
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled = false,
      fullWidth = { default: false },
      icon,
      iconOnly,
      label,
      loading = false,
      size = "md",
      tone: optionalTone,
      variant = "primary",
      rounded = false,
      iconLeft = true,
      iconRight = false,
      Icon,
      active,
      children,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const { tone } = useTheme();
    const theme = optionalTone || tone;

    const themeStyles: {
      [key in ButtonProps["tone"] as string]: {
        [key in ButtonProps["variant"]]: CSSObject[];
      };
    } = {
      petition: {
        primary: [bgPrimary("petition"), borderPrimary("petition"), colorWhite],
        secondary: [
          bgSecondary("petition"),
          borderSecondary("petition"),
          colorWhite,
        ],
        outline: [
          borderSecondary("petition"),
          bgWhite,
          colorPrimary("petition"),
        ],
        transparent: [
          bgTransparent,
          borderTransparent,
          colorPrimary("petition"),
        ],
        transparentSecondary: [
          bgTransparent,
          borderTransparent,
          colorSecondary("petition"),
        ],
        transcent: [
          bgTransparent,
          borderTransparent,
          colorGray4,
          {
            ...(active && {
              ...borderPrimary("petition"),
              ...colorBlack,
            }),
          },
        ],
      },
      initiative: {
        primary: [
          bgPrimary("initiative"),
          borderPrimary("initiative"),
          colorWhite,
        ],
        secondary: [
          bgSecondary("initiative"),
          borderSecondary("initiative"),
          colorWhite,
        ],
        outline: [
          borderSecondary("initiative"),
          bgWhite,
          colorPrimary("initiative"),
        ],
        transparent: [
          bgTransparent,
          borderTransparent,
          colorPrimary("initiative"),
        ],
        transparentSecondary: [
          bgTransparent,
          borderTransparent,
          colorSecondary("initiative"),
        ],
        transcent: [
          bgTransparent,
          borderTransparent,
          colorGray4,
          {
            ...(active && {
              ...borderPrimary("initiative"),
              ...colorBlack,
            }),
          },
        ],
      },
      danger: {
        primary: [bgDanger, borderDanger, colorWhite],
        secondary: [],
        outline: [bgWhite, borderDanger, colorDanger],
        transparent: [],
        transparentSecondary: [],
        transcent: [
          bgTransparent,
          borderTransparent,
          colorGray4,
          {
            ...(active && {
              ...borderDanger,
              ...colorBlack,
            }),
          },
        ],
      },
      success: {
        primary: [bgSuccess, borderSuccess, colorWhite],
        secondary: [],
        outline: [bgWhite, borderSuccess, colorSuccess],
        transparent: [],
        transparentSecondary: [],
        transcent: [
          bgTransparent,
          borderTransparent,
          colorGray4,
          {
            ...(active && {
              position: "relative",
              display: "inline-block",
              ...colorBlack,
              "::after": {
                content: "''",
                position: "absolute",
                left: 0,
                bottom: "0px",
                width: "100%",
                height: "3px",
                ...bgSuccess,
              },
            }),
          },
        ],
      },
    };

    //#region Styles
    const buttonCSS: CSSObject[] = [
      bodyStyle,
      basicStyle,
      sizeStyles[size],
      ...themeStyles[theme as string][variant],
    ];

    const { t } = useTranslation();

    for (const breakpoint of breakpoints) {
      if (typeof fullWidth[breakpoint] !== "undefined") {
        if (fullWidth[breakpoint]) {
          buttonCSS.push(setBreakpointProperties(breakpoint, fullWidthStyle));
        } else {
          buttonCSS.push(setBreakpointProperties(breakpoint, autoWidthStyle));
        }
      }
    }

    iconOnly = !!(iconOnly && icon);
    if (iconOnly || rounded) {
      buttonCSS.push(iconOnlyStyle, iconOnlySizeStyles[size]);
    }

    const hoverCSS: CSSObject = {
      "&:hover": [themeHoverStyles[theme as string][variant], hoverStyle],
    };

    const activeCSS: CSSObject = {
      "&:active, &:focus": [
        activeStyle,
        themeHoverStyles[theme as string][variant],
        {
          outline: "none",
        },
      ],
    };

    const disabledCSS: CSSObject = {
      "&:disabled, &.disabled, fieldset:disabled &, &[disabled]": [
        disabledStyle,
        disabled && !loading
          ? themeDisabledStyles[theme as string][variant]
          : null,
      ],
    };

    const StyledButton = styled("button")(
      buttonCSS,
      activeCSS,
      hoverCSS,
      disabledCSS,
    );

    //#endregion Styles
    return (
      <StyledButton
        disabled={disabled || loading}
        role="button"
        aria-label={label}
        ref={ref}
        {...props}
      >
        <Visible when={iconLeft && !iconRight}>
          {icon && (
            <FontAwesomeIcon
              aria-hidden={iconOnly ? false : true}
              css={loading ? hiddenStyle : []}
              fixedWidth
              icon={icon}
            />
          )}

          {Icon && <Icon css={loading ? hiddenStyle : []} />}
        </Visible>

        {!iconOnly &&
          (Children?.count(children) > 0 ? (
            <>{children}</>
          ) : (
            <span css={loading ? hiddenStyle : labelStyle}>{label}</span>
          ))}

        <Visible when={iconRight}>
          {icon && (
            <FontAwesomeIcon
              aria-hidden={iconOnly ? false : true}
              css={loading ? hiddenStyle : []}
              fixedWidth
              icon={icon}
            />
          )}

          {Icon && <Icon css={loading ? hiddenStyle : []} />}
        </Visible>

        {loading && (
          <FontAwesomeIcon
            css={loadingIconStyle}
            fixedWidth
            icon={faSpinner}
            aria-label={t("common.loading") as string}
            spin
          />
        )}
      </StyledButton>
    );
  },
);

Button.displayName = "Button";

export default Button;
