import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useCookies } from "react-cookie";

export const useCookieOptions = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const { pushInstruction } = useMatomo();

  const cookieCategories = [
    "essential",
    "statistics",
    // "socialMedia",
    // "thirdParty",
  ];

  const setConsents = (consents: string[]) => {
    setCookie("cookieConsent", consents, {
      path: "/",
      secure: true,
    });

    if (consents.includes("statistics")) {
      pushInstruction("setConsentGiven");
      pushInstruction("setCookieConsentGiven");
    } else {
      pushInstruction("forgetConsentGiven");
      pushInstruction("forgetCookieConsentGiven");
    }
  };

  const setAllConsents = () => {
    setConsents(cookieCategories);
  };

  const setEssentialConsent = () => {
    setConsents(["essential"]);
  };

  return {
    cookieCategories,
    cookieConsent: cookies.cookieConsent,
    setAllConsents,
    setConsents,
    setEssentialConsent,
  };
};
