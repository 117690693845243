import { IconSVGProps } from "../Icon";

const DonationIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <linearGradient
        id="SVGID_1_donation"
        gradientUnits="userSpaceOnUse"
        x1={-425.04}
        y1={670.941}
        x2={-388.471}
        y2={670.941}
        gradientTransform="rotate(24.733 1169.275 1456.46)"
      >
        <stop
          offset={0}
          stopColor={secondaryColor}
        />
        <stop
          offset={1}
          stopColor={primaryColor}
        />
      </linearGradient>
      <path
        d="M69.9 75.3c0 .2.3.3.5.2 1.2-.7 2.3-1.3 3.4-1.7 4.2-1.8 8.4-.8 10.9 2.5 2.4 3.2 2.6 8.1-.6 11.7-2.1 2.3-4.7 4.1-7.5 5.2-5.2 2-10.7 2.9-16.1 4.2-.3.1-.6-.1-.7-.3-2.5-4.9-5.3-9.6-7.2-14.7-1-2.9-1.4-6.1-1-9.2.7-4.8 4.4-8.1 8.7-8.3 4.2-.1 7.6 2.6 8.9 7.2.3 1.1.5 2 .7 3.2z"
        fill="url(#SVGID_1_donation)"
      />
      <path
        fill={primaryColor}
        d="M78.3 42.9c4.7 10.5-.2 11.7-.2 11.7l-2.8-6.3c-1.2-2.6-2.9-4.9-5.1-6.8-1.5-1.2-3.1-2.2-4.9-2.9l-.2-.1c-.2-.1-.6-.2-.9-.2-.4 0-.8.2-1 .5-.3.6 0 1.3.5 1.6l1.9 1c3.7 2 6.7 5.1 8.4 9 1.4 3.1 3 6.8 4.2 9.4 1 2.3.5 4.1-.3 5.4-1.1 1.7-3.8 1.5-4.7-.3L70 58.5c-1.5-3.1-4.1-5.4-6.9-7.2-2.4-1.5-5-2.6-7.7-3.3l-.8-.2c-3.8-1-7.8-.4-11 1.8l-.9.4c-5.9 4.3-3.1 10.9-.1 15.3s5 9.1 4 11-3.8 2.7-5.4 1.1c-1.6-1.6-6.2-7.8-12.1-11.8-6.1-4.1-9-9.2-10.5-12.6-2.4-5.3-3.1-10.3-3.2-14-.1-4.7-1.1-9.3-3.2-13.6L.3 0h28.1l8.1 18.1c.5 1.1 1.5 1.7 2.7 1.7l15.8-.6c4.6-.2 9.2.9 13.3 3l.2.1c5.2 2.7 8.8 5.9 10.9 10.7 2 4.4.2 7.7.2 7.7l-.3-.6c-1.4-3.2-3.6-5.7-6.4-7.6-.9-.6-2.1-1.4-3.1-1.9l-4-1.8c-.3-.1-.6-.2-.9-.2-.4 0-.9.2-1.1.5-.3.6-.1 1.2.5 1.5l5.1 2.5c3.8 2 6.8 5.2 8.5 9l.4.8zM-.2 93.7H100v5.5H-.2z"
      />
    </svg>
  );
};

export default DonationIcon;
