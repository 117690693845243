import { faEnvelopeCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";

const RegistrationMessageModal = () => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      content={t(
        "pages.userAccount.modals.passwordResetRequestMessage.content",
      )}
      icon={faEnvelopeCircleCheck}
      id="passwordResetRequestMessage"
      title={t("pages.userAccount.modals.passwordResetRequestMessage.title")}
    ></ModalDialog>
  );
};

export default RegistrationMessageModal;
