const matomoOptions = {
  urlBase: "https://innn.matomo.cloud/",
  siteId: 1,
  configurations: {
    requireConsent: true,
    requireCookieConsent: true,
    setSecureCookie: true,
  },
};

export default matomoOptions;
