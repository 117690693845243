import { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import styled from "@emotion/styled";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { useTranslation } from "react-i18next";

import { Container } from "@Components/Container";
import { borderBlack, borderDanger } from "@Styles/borders";
import { marginBottomZero, marginTopXs, paddingZero } from "@Styles/spacers";
import { colorDanger, colorGray5 } from "@Styles/textColors";
import { bodySmStyle } from "@Styles/typography";
import { InitiativeInput } from "@Types/services/post";
import FieldHeading from "../components/FieldHeading";
import { PetitionStepProps } from "../utils/types";
import themes from "@Variables/themes";
import { rgba } from "emotion-rgba";

const StepThree = ({
  formErrors,
  handleChange,
  initiative,
  setFieldHelp,
}: PetitionStepProps) => {
  const { t } = useTranslation();
  const [validation, setValidation] = useState<{ content: boolean }>({
    content: false,
  });
  const [fields, setFields] = useState<InitiativeInput>(initiative);

  const CONTENT = {
    content: {
      placeholder: t("components.startPetitionForm.fields.content.placeholder"),
      heading: t("components.startPetitionForm.fields.content.heading"),
      description: t("components.startPetitionForm.fields.content.description"),
      caption: t("components.startPetitionForm.fields.content.caption"),
      tooShortError: t(
        "components.startPetitionForm.fields.content.validationErrors.tooShort",
      ),
    },
  };

  useEffect(() => {
    // if we have any new field in this step later, we add it to the array.
    (["content"] as "content"[]).forEach((fieldName) => {
      if (fields[fieldName] !== initiative[fieldName]) {
        handleChange({
          target: { name: fieldName, value: fields[fieldName] || "" },
        });
        setValidation({ ...validation, [fieldName]: true });
      }
    });
  }, [fields, initiative, handleChange, validation]);

  useEffect(() => {
    if (formErrors.content) {
      // the form is submitted, all fields should have validations.
      setValidation({ content: true });
    }
  }, [formErrors]);

  const hasErrors = (
    errors: PetitionStepProps["formErrors"],
    validationState: { content: boolean },
  ): boolean => validationState.content && errors.content;

  const editorConfiguration: any = {
    toolbar: ["heading", "undo", "redo", "bold", "italic", "blockQuote"],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    placeholder: CONTENT.content.placeholder,
  };

  const Caption = styled.span([
    bodySmStyle,
    marginBottomZero,
    marginTopXs,
    { textAlign: "right" },
    hasErrors(formErrors, validation) ? colorDanger : colorGray5,
  ]);

  const petitionColors = themes.petition.colors;

  return (
    <div>
      <FieldHeading
        text={CONTENT.content.heading}
        tipAction={() => {
          setFieldHelp("content");
        }}
      />
      <p>{CONTENT.content.description}</p>
      <Container
        size="fw"
        css={[
          {
            borderRadius: "0.5rem",
            overflow: "hidden",
            ...paddingZero,
            borderColor: `${petitionColors.secondary} !important`,
          },
          hasErrors(formErrors, validation) ? borderDanger : borderBlack,
          {
            ".ck-editor__main": {
              borderColor: petitionColors.secondary,
            },
            ".ck-editor__editable_inline": {
              minHeight: "14em",
              overflowY: "auto",
            },
            ".ck-editor__top": {
              borderBottom: `1px solid ${petitionColors.secondary}`,
            },
            ".ck-toolbar": {
              backgroundColor: rgba(petitionColors.secondary, 0.15),
            },
          },
        ]}
      >
        <CKEditor
          editor={ClassicEditor as any}
          data={initiative.content}
          config={editorConfiguration}
          onChange={(_, editor) =>
            setFields({ ...fields, content: editor.data.get() })
          }
        />
      </Container>
      <Caption>
        {hasErrors(formErrors, validation)
          ? CONTENT.content.tooShortError
          : CONTENT.content.caption}
      </Caption>
    </div>
  );
};

export default StepThree;
