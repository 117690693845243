import { Visible } from "@Components/atoms";
import { Heading } from "@Components/Heading";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";

interface BackdropProps {
  visible: boolean;
  onClose?: () => void;
  opacity?: number;
  color?: string;
  children?: React.ReactNode; // Backdrop content
  defaultContent?: React.ReactNode; // Default content when backdrop is not open
  width?: string;
  height?: string;
}

const BackdropOverlay = styled(motion.div)<{
  opacity: number;
  color: string;
  height?: string;
  width?: string;
  containerTop?: number;
  containerLeft?: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width: width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const BackdropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Backdrop: React.FC<BackdropProps> = ({
  visible,
  onClose,
  opacity = 0.5,
  color = "rgba(0, 0, 0, 0.5)",
  children,
  defaultContent = (
    <Heading
      scale={2}
      color="label"
    >
      Loading...
    </Heading>
  ),
  width = "100%",
  height = "100%",
}) => {
  return (
    <BackdropContainer>
      {/* Render default content when backdrop is not open */}
      {children}
      <Visible when={visible}>
        <BackdropOverlay
          color={color}
          width={width}
          height={height}
          opacity={opacity}
          initial={{ opacity: 0 }}
          animate={{ opacity: opacity }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          {defaultContent}
        </BackdropOverlay>
      </Visible>
      {/* Render the backdrop overlay when open */}
    </BackdropContainer>
  );
};

export default Backdrop;
