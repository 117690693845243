import styled from "@emotion/styled";

import { marginNegXMd } from "../styles/spacers";

const Row = styled.div({
  ...marginNegXMd,
  display: "flex",
  flexWrap: "wrap",
});

export default Row;
