import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface UseBaseQueryOptions<T, K extends string = "data">
  extends Omit<UseQueryOptions<T, any, T, any>, "queryFn" | "initialData"> {
  service: (params: any) => Promise<AxiosResponse<T>>;
  dataName: K;
  defaultValue?: T;
  onError?: (error: any) => void;
  onSuccess?: (data: T) => void;
}

type CombinedQueryOptions<T, K extends string> = UseBaseQueryOptions<T, K> &
  Omit<UseQueryOptions<T, any, T, any>, "queryFn" | "initialData">;

export type UseBaseQueryResult<T, K extends string> = Omit<
  UseQueryResult<T, any>,
  "data" | "isLoading"
> & {
  [key in K]: T;
} & { [key: string]: boolean | any };

const useBaseQuery = <T, K extends string = "data">({
  service,
  dataName = "data" as K,
  queryKey,
  defaultValue,
  ...queryOptions
}: CombinedQueryOptions<T, K>): UseBaseQueryResult<T, K> => {
  const query = useQuery({
    queryKey,
    queryFn: async (params): Promise<T> => {
      const response: AxiosResponse<T> = await service(params);
      return response.data;
    },
    ...queryOptions,
  });

  const { isLoading, data, ...restQuery } = query;

  return {
    ...restQuery,
    [dataName]: data ?? defaultValue,
    loading: isLoading,
  } as Omit<UseQueryResult<T, any>, "data" | "isLoading"> & {
    [key in K]: T;
  } & { loading: boolean };
};

export default useBaseQuery;
