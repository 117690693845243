import React from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";

import Button, { ButtonProps } from "./Button";
import ButtonContainer, { ButtonContainerProps } from "./ButtonContainer";
import Headline from "./Headline";
import Modal from "./Modal";
import { CSSObject } from "@emotion/react";

export interface ModalDialogProps {
  /**
   * Array of buttons to be displayed at the modal footer. Each button has the following properties:
   * * `action`: The action triggered by the button click.
   * * `label`: Button label.
   * * `type`: Type of the button.
   * * `variant`: Button variant inside the modal theme.
   */
  buttons?: {
    action?: ButtonProps["onClick"];
    label: ButtonProps["label"];
    loading?: ButtonProps["loading"];
    type?: ButtonProps["type"];
    variant: ButtonProps["variant"];
  }[];
  buttonContainerProps?: ButtonContainerProps;
  /** Headline content. */
  content: React.ReactNode;
  /** Modal icon. */
  icon?: IconProp;
  /** Modal icon color. */
  iconColor?: string;
  /** Modal subtitle. */
  subtitle?: string;
  /** Modal title. */
  title: string;
  /** Modal title color. */
  titleColor?: string;
  /** Modal ID */
  id: string;
  titleCSS?: CSSObject;
  iconCSS?: CSSObject;
}

/**
 * Creates a modal with headline, content and buttons.
 */
const ModalDialog = ({
  buttons,
  content,
  icon,
  iconColor = "",
  id,
  subtitle = "",
  title,
  titleColor = "",
  titleCSS,
  buttonContainerProps,
  iconCSS,
}: ModalDialogProps) => {
  const Footer = () => {
    return buttons ? (
      <ButtonContainer
        align={{
          default: "justify",
          sm: "justify",
          md: "right",
          lg: "right",
        }}
        direction={{
          default: "vertical",
          sm: "vertical",
          md: "horizontal",
          lg: "horizontal",
        }}
        {...buttonContainerProps}
      >
        {buttons.map((button, index) => (
          <Button
            key={index}
            label={button.label}
            loading={button.loading}
            size="md"
            variant={button.variant}
            type={button.type as ButtonProps["type"]}
            onClick={button?.action}
          />
        ))}
      </ButtonContainer>
    ) : null;
  };

  return (
    <Modal
      footer={<Footer />}
      id={id}
    >
      <Headline
        icon={icon}
        iconColor={iconColor}
        subtitle={subtitle}
        text={content}
        title={title}
        titleColor={titleColor}
        titleCSS={titleCSS}
        size="md"
        iconCSS={iconCSS}
      />
    </Modal>
  );
};

export default ModalDialog;
