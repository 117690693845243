import { faEnvelopeCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";

const SetPasswordRequestMessageModal = () => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      content={t("pages.userAccount.modals.setPasswordRequestMessage.content")}
      icon={faEnvelopeCircleCheck}
      id="setPasswordRequestMessage"
      title={t("pages.userAccount.modals.setPasswordRequestMessage.title")}
    />
  );
};

export default SetPasswordRequestMessageModal;
