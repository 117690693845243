import type { ComponentType, FC } from "react";

import { type PSFSharedStepsProps, PSF_STEP } from "@Types/components/psf";

import DocumentRequestPage from "./DocumentRequestPage";
import DonationPage from "./DonationPage";
import SharePage from "./SharePage";

interface PageStepProps extends PSFSharedStepsProps {
  component: ComponentType<PSFSharedStepsProps>;
}

const PageStepComponent: FC<PageStepProps> = ({
  component: Component,
  ...sharedProps
}) => {
  return <Component {...sharedProps} />;
};

interface Props extends PSFSharedStepsProps {
  currentStep: PSF_STEP;
}

const PSFSteps: FC<Props> = ({ currentStep, ...sharedProps }) => {
  const stepMap: Record<PSF_STEP, ComponentType<PSFSharedStepsProps>> = {
    [PSF_STEP.DocumentRequest]: DocumentRequestPage,
    [PSF_STEP.Donation]: DonationPage,
    [PSF_STEP.Share]: SharePage,
  };

  const StepToRender = stepMap[currentStep] || null;

  return StepToRender ? (
    <PageStepComponent
      component={StepToRender}
      {...sharedProps}
    />
  ) : null;
};

export default PSFSteps;
