import React from "react";

import type { Tone } from "@Variables/themes";
import { QandAItemProps } from "./QandAItem";

export interface QandAProps {
  /** Color theme. */
  tone: Tone;
  /** Question and answers. Should be a `<QandAItem>` component, with `question` property and children as answer. */
  children:
    | React.ReactElement<QandAItemProps>
    | Array<React.ReactElement<QandAItemProps>>;
}

/**
 * Returns a module with questions and answers.
 */
const QandA = ({ tone = "petition", children }: QandAProps) => {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      const item = child as React.ReactElement<
        React.PropsWithChildren<QandAItemProps>
      >;
      return React.cloneElement(item, {
        tone,
      });
    });
  };

  return <div>{renderChildren()}</div>;
};

export default QandA;
