import { useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import PropTypes from "prop-types";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";

import { Container, FormControl, Heading } from "@Components";
import {
  colorPrimary,
  fontSize2xl,
  fontSizeSm,
  fontWeightMedium,
  marginYZero,
  paddingZero,
} from "@Styles";
import { ValidationRules } from "@Stylize/helpers/validation";
import { Actor, InitiativeInput } from "@Types/services/post";
import api from "@Utils/api";
import themes from "@Variables/themes";
import { zIndexes } from "@Variables/zIndexes";
import FieldHeading from "../components/FieldHeading";
import TargetToken from "../components/TargetToken";
import { PetitionStepProps } from "../utils/types";

const StepTwo = ({
  formErrors,
  handleChange,
  initiative,
  setFieldHelp,
}: PetitionStepProps) => {
  const [typeaheadState, setTypeaheadState] = useState<{
    isLoadingTargets: boolean;
    targets: Actor[];
  }>({
    isLoadingTargets: false,
    targets: [],
  });

  const [fields, setFields] = useState<InitiativeInput>(() => initiative);

  const [validation, setValidation] = useState<{
    displayName: boolean;
    targets: boolean;
  }>({
    displayName: false,
    targets: false,
  });

  const sameTargets = (
    targets1: { name?: string }[],
    targets2: { name?: string }[],
  ): boolean => {
    // just check for name equalities
    const firstTargetNames: string[] = Array.from(
      new Set(targets1.map((t) => t.name).filter((name) => name) as string[]),
    );
    const secondTargetNames: string[] = Array.from(
      new Set(targets2.map((t) => t.name).filter((name) => name) as string[]),
    );
    return (
      !firstTargetNames.some((name) => !secondTargetNames.includes(name)) &&
      !secondTargetNames.some((name) => !firstTargetNames.includes(name))
    );
  };

  useEffect(() => {
    if (fields.displayName !== initiative.displayName) {
      setValidation({ ...validation, displayName: true });
      handleChange({
        target: { name: "displayName", value: fields.displayName || "" },
      });
    }
  }, [fields.displayName, initiative.displayName, handleChange, validation]);

  useEffect(() => {
    if (!sameTargets(fields.targets || [], initiative.targets || [])) {
      handleChange({
        target: { name: "targets", value: fields.targets || [] },
      });
    }
  }, [fields.targets, initiative.targets, handleChange, validation]);

  useEffect(() => {
    if (formErrors.displayName || formErrors.targets) {
      // the form is submitted, all fields should have validations.
      setValidation({ displayName: true, targets: true });
    }
  }, [formErrors]);

  const { t } = useTranslation();

  const CONTENT = {
    displayName: {
      placeholder: t(
        "components.startPetitionForm.fields.displayName.placeholder",
      ),
      heading: t("components.startPetitionForm.fields.displayName.heading"),
      description: t(
        "components.startPetitionForm.fields.displayName.description",
      ),
      emptyError: t(
        "components.startPetitionForm.fields.displayName.validationErrors.empty",
      ),
    },
    targets: {
      placeholder: t("components.startPetitionForm.fields.targets.placeholder"),
      heading: t("components.startPetitionForm.fields.targets.heading"),
      description: t("components.startPetitionForm.fields.targets.description"),
      emptyError: t(
        "components.startPetitionForm.fields.targets.validationErrors.empty",
      ),
    },
  };

  const validationRules: ValidationRules = {
    displayName: [
      {
        message: CONTENT.displayName.emptyError,
        valid: Boolean(fields.displayName && fields.displayName?.length > 0),
      },
    ],
    targets: [
      {
        message: CONTENT.targets.emptyError,
        valid: Array.isArray(fields.targets) && fields.targets?.length > 0,
      },
    ],
  };

  const validateFields = (): Record<
    "displayName" | "targets",
    { valid: boolean; message: string }
  > => {
    const response: Record<
      "displayName" | "targets",
      { valid: boolean; message: string }
    > = {
      displayName: {
        valid: true,
        message: "",
      },
      targets: {
        valid: true,
        message: "",
      },
    };

    for (const key of Object.keys(validationRules)) {
      const error = validationRules[key].find((rule) => !rule.valid);
      response[key as "displayName" | "targets"].valid = !error;
      response[key as "displayName" | "targets"].message = error?.message || "";
    }

    return response;
  };

  const typeaheadContainerStyle: CSSObject = {
    ".form-control": {
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "0.375rem",
      boxSizing: "border-box",
      fontSize: "1rem",
      padding: "0.5rem 1rem",
      width: "100%",
      height: "3rem",
      "&:active, &:focus, &:hover, &:focus-within": {
        borderColor: themes["petition"].colors.secondary,
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(118, 118, 118, 0.15)", // eslint-disable-line max-len
        outline: 0,
      },
    },
    ".dropdown-menu": {
      border: "1px solid #ccc",
      borderRadius: "0.25rem",
      boxSizing: "border-box",
      backgroundColor: "white",
      zIndex: zIndexes.dropdown,
    },
    ".dropdown-item": {
      display: "block",
      padding: "0.5rem 1rem",
      textDecoration: "none",
      boxSizing: "border-box",
      borderBottom: "1px outset #ccc",
      borderRadius: "0.25rem",
    },
    ".rbt-input-wrapper": {
      marginTop: "0",
      marginBottom: "0",
      height: "100%",
      border: "0",
    },
    ".rbt-token-removeable": {
      height: "100%",
      backgroundColor: "rgba(148, 102, 242, 0.15)",
      ...marginYZero,
    },
    ".rbt-token-remove-button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100%",
      borderWidth: "0",
      backgroundColor: "transparent",
      cursor: "pointer",
      color: "#6F38B7",
    },
    ".rbt-token-label": {
      color: "black",
      margin: "auto",
      ...fontSizeSm,
      ...fontWeightMedium,
    },
    ".rbt-close-content": {
      ...fontSize2xl,
      fontFamily: "serif",
    },
  };

  return (
    <>
      <div>
        <Heading
          css={colorPrimary("petition")}
          scale={5}
          aria-level={2}
          role="heading"
        >
          {CONTENT.displayName.heading}
        </Heading>

        <p>{CONTENT.displayName.description}</p>

        <FormControl
          id="step2-displayName"
          placeholder={CONTENT.displayName.placeholder}
          name="displayName"
          setValue={setFields}
          tone="petition"
          type="text"
          value={fields.displayName || ""}
          validation={
            validation.displayName ? validateFields().displayName : undefined
          }
          captionAlign="right"
        />
      </div>

      <Container
        size="fw"
        css={[typeaheadContainerStyle, paddingZero]}
      >
        <FieldHeading
          text={CONTENT.targets.heading}
          tipAction={() => {
            setFieldHelp("targets");
          }}
        />

        <p>{CONTENT.targets.description}</p>

        <AsyncTypeahead
          filterBy={() => true}
          id="step4-initiativeTargets"
          allowNew={true}
          isLoading={typeaheadState.isLoadingTargets}
          isInvalid={Boolean(formErrors.targets)}
          labelKey="name"
          maxHeight="330px"
          minLength={3}
          multiple
          newSelectionPrefix=""
          onChange={(value) => {
            setFields((currentFields) => ({
              ...currentFields,
              targets: [...value] as object[],
            }));
          }}
          onSearch={(query) => {
            setTypeaheadState({ isLoadingTargets: true, targets: [] });
            api.getActors({ search: query }).then(({ data }) => {
              setTypeaheadState({
                isLoadingTargets: false,
                targets: data && Array.isArray(data) ? data : [],
              });
            });
          }}
          options={typeaheadState.targets}
          placeholder={CONTENT.targets.placeholder}
          renderMenuItemChildren={(option, props) => {
            return (
              <div>
                <div>
                  <Highlighter search={props.text}>
                    {(typeof option === "string" ? {} : option).name}
                  </Highlighter>
                </div>
              </div>
            );
          }}
          renderToken={(option, props) => (
            <TargetToken
              option={option}
              {...props}
            />
          )}
          selected={fields.targets}
        />
      </Container>
    </>
  );
};

StepTwo.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};

export default StepTwo;
