import {
  DefaultError,
  UseQueryOptions,
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";
import { MetaData, ResponsePayload } from "@Types/services/typeServiceParams";
import { AxiosResponse } from "axios";

type MetaLoad = { meta: MetaData; loading: boolean };

interface UsePagiBaseQueryOptions<T, K extends string = "data">
  extends Omit<
    UseQueryOptions<ResponsePayload<T>, any, T, any>,
    "queryFn" | "initialData"
  > {
  service: (params: any) => Promise<AxiosResponse<ResponsePayload<T>>>;
  dataName: K;
  defaultValue?: T;
  onError?: (error: DefaultError) => void;
  onSuccess?: (data: ResponsePayload<T>) => void;
}

type CombinedPagiQueryOptions<T, K extends string> = UsePagiBaseQueryOptions<
  T,
  K
> &
  Omit<
    UseQueryOptions<ResponsePayload<T>, any, ResponsePayload<T>, any>,
    "queryFn" | "initialData"
  >;

export type UsePagiBaseQueryResult<T, K extends string> = Omit<
  UseQueryResult<ResponsePayload<T>, any>,
  "data" | "isLoading"
> & {
  [key in K]: T;
} & MetaLoad;

const usePagiBaseQuery = <T, K extends string = "data">({
  service,
  dataName = "data" as K,
  queryKey,
  defaultValue,
  ...queryOptions
}: CombinedPagiQueryOptions<T, K>): UsePagiBaseQueryResult<T, K> => {
  const query = useQuery({
    queryKey,
    queryFn: async (params): Promise<ResponsePayload<T>> => {
      const response: AxiosResponse<ResponsePayload<T>> = await service(params);

      return response.data;
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });

  const { isLoading, data, ...restQuery } = query;

  return {
    ...restQuery,
    [dataName]: data?.data ?? defaultValue,
    loading: isLoading,
    meta: data?.meta,
  } as UsePagiBaseQueryResult<T, K>;
};

export default usePagiBaseQuery;
