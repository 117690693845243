import {
  SlidePaginationContainer,
  SlidePaginationDot,
  SlidePaginationDotsContainer,
  SlidePaginationWrapper,
  paginationContainerStyles,
  signableCardStyles,
  slidPaginationWrapperStyles,
} from "@Appearances/components/styleSlidePagination";
import { SignableCard } from "@Components";
import { useTheme } from "@emotion/react";
import { Signable } from "@Types/services/post";
import { MetaData } from "@Types/services/typeServiceParams";
import React from "react";
import { useSwipeable } from "react-swipeable";
import Backdrop from "./Backdrop";
export type SliderProps = {
  signables: Signable[];
  meta: MetaData;
  loading: boolean;
  onPageChange: (page: number) => void;
};

const SlidePagination = ({
  signables,
  meta,
  loading,
  onPageChange,
}: SliderProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { tone } = useTheme();

  // Handle next slide
  const handleNextSlidePage = () => {
    const total = (meta?.totalCount || 1) - 1;

    if (currentIndex === signables.length - 1 && total > currentIndex) {
      onPageChange(meta.currentPage + 1);
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Handle previous slide
  const handlePrevSlidePage = () => {
    const total = (meta?.totalCount || 1) - 1;

    if (currentIndex === 0 && total > currentIndex) {
      onPageChange(meta.currentPage + 1);
      setCurrentIndex(signables.length - 1);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const goToSlidePage = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      eventData.event.stopPropagation();
      handleNextSlidePage();
    },

    onSwipedRight: (eventData) => {
      eventData.event.stopPropagation();
      handlePrevSlidePage();
    },

    trackMouse: true,
    preventScrollOnSwipe: true,
    delta: 20,
    touchEventOptions: { passive: true },
  });

  return (
    <Backdrop visible={loading}>
      <div css={paginationContainerStyles}>
        <SlidePaginationContainer {...handlers}>
          <SlidePaginationWrapper
            {...slidPaginationWrapperStyles(currentIndex)}
          >
            {signables.map((petition) => (
              <SignableCard
                key={petition._id}
                signable={petition}
                css={signableCardStyles}
              />
            ))}
          </SlidePaginationWrapper>
        </SlidePaginationContainer>

        <SlidePaginationDotsContainer>
          {signables.map((_, slideIndex) => (
            <SlidePaginationDot
              key={slideIndex}
              active={currentIndex === slideIndex}
              tone={tone}
              onClick={() => goToSlidePage(slideIndex)}
            />
          ))}
        </SlidePaginationDotsContainer>
      </div>
    </Backdrop>
  );
};

export default SlidePagination;
