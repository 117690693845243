import { IconSVGProps } from "../Icon";

const PostIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_post"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <linearGradient
      id="SVGID_1_post"
      gradientUnits="userSpaceOnUse"
      x1={55.18}
      y1={15.436}
      x2={55.18}
      y2={98.778}
    >
      <stop
        offset={0}
        stopColor={secondaryColor}
      />
      <stop
        offset={1}
        stopColor={primaryColor}
      />
    </linearGradient>
    <path
      d="M99.5 38.8V76H62.1v22.7h-13V76H10.8V61.7h7.3v6.7h30.6V38.5c0-8.5-6.7-15.6-15.2-15.7-4.3 0-8.1 1.7-10.9 4.5-1.5 1.5-2.8 3.4-3.5 5.5h-7.4c2.7-10 11.8-17.3 22.6-17.3h41.9c12.8-.1 23.3 10.4 23.3 23.3z"
      fill="url(#SVGID_1_post)"
    />
    <path
      fill={primaryColor}
      d="M41.7 35.7v2.7L22 48.1c-.6.3-1.3.3-1.9 0L.5 37.9v-2.2c0-1.6 1.3-3 3-3h35.3c1.6.1 2.9 1.4 2.9 3z"
    />
    <path
      fill={primaryColor}
      d="M41.7 42.9v15.9c0 1.6-1.3 3-3 3H3.4c-1.6 0-3-1.3-3-3V42.4l18.2 9.4c.8.4 1.6.6 2.4.6.8 0 1.6-.2 2.4-.5l18.3-9zM79.8 4.9c0 2-1.7 3.7-3.7 3.7h-3.7v24.1H65V1.2h11.1c2 0 3.7 1.7 3.7 3.7z"
    />
  </svg>
);

export default PostIcon;
