const imageSizeNames = ["original", "thumb", "sm", "md", "lg", "xl"] as const;

export type ImageSizeNames = (typeof imageSizeNames)[number];

export type ImageSizes = {
  [key in ImageSizeNames]: string;
};

export const imgHeights: ImageSizes = {
  original: "auto",
  thumb: "10rem",
  sm: "15rem",
  md: "25rem",
  lg: "31rem",
  xl: "auto",
};

export const imgWidths: ImageSizes = {
  original: "auto",
  thumb: "10rem",
  sm: "25.5rem",
  md: "37.5rem",
  lg: "46.5rem",
  xl: "auto",
};
