import { useContext, useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import { faCookieBite } from "@fortawesome/pro-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ModalsContext } from "@Contexts/ModalsContext";
import {
  colorPrimary,
  marginBottomMd,
  marginBottomSm,
  marginLeftZero,
  marginTopLg,
  marginTopMd,
} from "@Styles";
import { useCookieOptions } from "@Utils/useCookies";
import themes from "../variables/themes";
import Button from "./Button";
import ButtonContainer from "./ButtonContainer";
import FormControl from "./FormControl";
import Headline from "./Headline";
import Modal from "./Modal";
import { Link as LinkComponent } from "./typography";

/**
 * Displays a modal where the user can edit cookie options.
 */
const CookieModal = () => {
  const { cookieCategories, cookieConsent, setAllConsents, setConsents } =
    useCookieOptions();
  const [checkedConsents, setCheckedConsents] = useState<{
    cookieConsent: Array<string>;
  }>({ cookieConsent });
  const { openModal, toggleModal } = useContext(ModalsContext);
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const save = () => {
    setConsents(checkedConsents.cookieConsent);
  };

  useEffect(() => {
    if (hash && "#cookies" == hash) {
      if ("cookieModal" != openModal) {
        toggleModal("cookieModal");
      } else {
        navigate(window.location.pathname);
      }
    }
  }, [hash, navigate, openModal, toggleModal]);

  const listStyle: CSSObject[] = [marginTopLg];

  const listItemStyle: CSSObject[] = [
    marginTopMd,
    {
      display: "flex",
      justifyContent: "space-between",
    },
  ];

  const listItemTitleStyle: CSSObject[] = [
    colorPrimary("initiative"),
    marginBottomSm,
    { fontWeight: "bold" },
  ];

  const listItemContentStyle: CSSObject[] = [marginLeftZero, marginBottomMd];

  return (
    <Modal id="cookieModal">
      <Headline
        icon={faCookieBite}
        iconColor={themes.petition.colors.primary}
        text={
          <Trans i18nKey="components.cookieModal.content">
            {""}
            <LinkComponent
              as={Link}
              to="/datenschutz"
            />
          </Trans>
        }
        title={t("components.cookieModal.title")}
        titleColor={themes.initiative.colors.primary}
      />

      <div css={listStyle}>
        {cookieCategories.map((category) => {
          return (
            <div
              css={listItemStyle}
              key={category}
            >
              <div>
                <div css={listItemTitleStyle}>
                  {t(`components.cookieModal.categories.${category}.title`)}
                </div>

                <div css={listItemContentStyle}>
                  {t(`components.cookieModal.categories.${category}.content`)}
                </div>
              </div>

              <FormControl
                checked={checkedConsents.cookieConsent?.includes(category)}
                disabled={category === "essential"}
                name="cookieConsent"
                setValue={setCheckedConsents}
                tone="initiative"
                type="checkbox"
                value={category}
              />
            </div>
          );
        })}

        <ButtonContainer
          align={{ default: "justify", md: "right" }}
          direction={{ default: "vertical", md: "horizontal" }}
        >
          <Button
            label={t("components.cookieModal.actions.save")}
            onClick={save}
            variant="outline"
          />

          <Button
            label={t("components.cookieModal.actions.acceptAll")}
            onClick={() => {
              setCheckedConsents({ cookieConsent: cookieCategories });
              setAllConsents();
            }}
            variant="primary"
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default CookieModal;
