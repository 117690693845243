//Styles
import { CSSObject } from "@emotion/react";
import { calculateFontSize, colorWhite, elevation3, gapXl } from "@Styles";
import { breakpointUp } from "@Variables";

const heroStyle: CSSObject[] = [elevation3];

const contentStyle: CSSObject[] = [
  gapXl,
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
];

const minFontSize = 91;
const maxFontSize = 186.3;

const fontSize = calculateFontSize(minFontSize, maxFontSize);

//Todo: Don't think need it anymore
const headingStyle: CSSObject[] = [
  colorWhite,
  {
    fontSize,
    [breakpointUp["md"]]: {
      fontSize: `calc(${fontSize} - 1rem)`,
    },
    [breakpointUp["lg"]]: {
      fontSize,
    },

    [breakpointUp["xl"]]: {
      fontSize: `calc(${fontSize} - 4rem)`,
    },
  },
];

const subtitleStyle: CSSObject[] = [
  {
    lineHeight: "1.875rem",
    [breakpointUp["md"]]: {
      maxWidth: "50%",
    },
  },
];

export { contentStyle, headingStyle, heroStyle, subtitleStyle };
