import { useContext, useEffect, useState } from "react";

import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { contentStyle } from "@Appearances/pages/styleHomeHero";
import {
  BodyLg,
  Column,
  Container,
  Heading,
  Hero,
  IconLi,
  Placeholder,
  QandA,
  QandAItem,
  Row,
} from "@Components";
import { routes } from "@Configs";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import { useTheme } from "@emotion/react";
import {
  colorPrimary,
  fontWeightMedium,
  fontWeightRegular,
  marginBottom2xl,
  marginBottomLg,
  marginBottomXl,
  marginBottomZero,
  marginTopXl,
  paddingBottomXl,
  paddingXMd,
} from "@Styles";
import { headingFontFamily } from "@Stylize/global/fonts";
import { FaqCategory } from "@Types/services/post";
import api from "@Utils/api";
import { breakpointDown } from "@Variables/breakpoints";

const Faq = () => {
  const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .getFaqs()
      .then((response) => {
        setFaqCategories(response?.data);
      })
      .catch(() => {
        navigate(routes.error);
      });
  }, [navigate]);

  return (
    <div css={marginBottom2xl}>
      <FaqHeader />

      <Container size="lg">
        <Row>
          <Column
            span={{ default: 12, md: 8, lg: 8, sm: 12, xs: 12 }}
            offset={{ md: 2, lg: 2 }}
          >
            <ul css={[marginBottomXl]}>
              {faqCategories.length === 0 ? (
                <IconLi
                  icon={faCircleQuestion}
                  variant="primary"
                >
                  <Placeholder lines={1} />
                </IconLi>
              ) : (
                faqCategories.map(({ name, theme, title, _id }) => {
                  return (
                    title && (
                      <IconLi
                        icon={faCircleQuestion}
                        key={_id}
                        variant="primary"
                      >
                        <Link
                          css={[
                            {
                              ...colorPrimary(theme),
                              fontFamily: headingFontFamily,
                              ...fontWeightRegular,
                              ...fontWeightMedium,
                            },
                          ]}
                          to={`#${name}`}
                        >
                          {title}
                        </Link>
                      </IconLi>
                    )
                  );
                })
              )}
            </ul>

            {faqCategories.length === 0 ? (
              <div>
                <Placeholder
                  lines={1}
                  css={[paddingBottomXl]}
                />

                <Placeholder lines={3} />
              </div>
            ) : (
              faqCategories.map(({ faqs, name, theme, title, _id }) => {
                return (
                  <div
                    id={name}
                    key={_id}
                    css={marginBottom2xl}
                  >
                    <Heading
                      scale={3}
                      aria-level={2}
                      css={[marginTopXl, marginBottomLg, colorPrimary(theme)]}
                    >
                      {title}
                    </Heading>

                    {faqs.map((faq) => {
                      return (
                        faq.question &&
                        faq.answer && (
                          <QandA
                            key={`${faq._id}`}
                            tone={theme}
                          >
                            <QandAItem question={faq.question}>
                              {parse(faq.answer)}
                            </QandAItem>
                          </QandA>
                        )
                      );
                    })}
                  </div>
                );
              })
            )}
          </Column>
        </Row>
      </Container>
    </div>
  );
};

const FaqHeader = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useContext(PageTitleContext);
  const { tone } = useTheme();

  useEffect(() => {
    setPageTitle(t("pages.faq.title") as string);
  }, []);

  return (
    <Hero
      bgCustomStyles={{
        [breakpointDown["md"]]: {
          height: "75%",
          width: "100%",
          bottom: "10%",
          ...paddingXMd,
        },
        opacity: 0.2,
      }}
      customStyles={[{ backgroundColor: "white" }, marginBottomXl]}
    >
      <Container css={contentStyle}>
        <Heading
          color="label"
          scale={1}
          role="heading"
          variant="display"
          align="center"
        >
          {parse(t("pages.faq.title"))}
        </Heading>

        <BodyLg
          css={[
            marginBottomZero,
            {
              lineHeight: "1.875rem",
            },
          ]}
          align="center"
          color={tone === "initiative" ? "white" : "label"}
        >
          {parse(t("pages.faq.text"))}
        </BodyLg>
      </Container>
    </Hero>
  );
};

export default Faq;
