import React, { createContext, useContext, useState } from "react";

type SignatureProviderProps = {
  children: React.ReactNode;
};

interface SignatureContext {
  signatureId: string;
  isDone: boolean;
}

type SignatureContextValues = {
  signature: SignatureContext;
  setSignature: React.Dispatch<React.SetStateAction<SignatureContext>>;
};

export const SignatureContext = createContext<SignatureContextValues>({
  signature: {
    signatureId: "",
    isDone: false,
  },
  setSignature: () => undefined,
});

export const useSignatureContext = (): SignatureContextValues => {
  const context = useContext(SignatureContext);

  if (!context) {
    throw new Error("useThemeContext must be used within Theme Provider");
  }

  return context;
};

export const SignatureProvider = ({ children }: SignatureProviderProps) => {
  const [signature, setSignature] = useState<SignatureContext>({
    signatureId: "",
    isDone: false,
  });

  return (
    <SignatureContext.Provider value={{ signature, setSignature }}>
      {children}
    </SignatureContext.Provider>
  );
};
