import type { StyleOpacityVariants } from "@Types/configs";
import type {
  ColorTone,
  Opacity,
  OpacityColors,
  ThemeColorsValues,
} from "@Types/typeUtils";
import alterOpacity from "@Utils/colorOpacity";
import generateColorVariants from "@Utils/colorVariants";
import { colors } from "@Variables";

import { color } from "@Utils";

const { opacityVariants } = color;

const petitionsAuraColors: ThemeColorsValues = {
  primary: colors.orange,
  secondary: colors.mauve,
  tertiary: colors.purple,
  label: colors.black,
  accent: colors.danger,
  white: colors.white,
  danger: colors.danger,
  success: colors.success,
};
const petitionsOpacityVariants: StyleOpacityVariants = {
  primary: opacityVariants(petitionsAuraColors.primary),
  secondary: opacityVariants(petitionsAuraColors.secondary),
  accent: opacityVariants(petitionsAuraColors.accent),
  label: opacityVariants(petitionsAuraColors.label),
  tertiary: opacityVariants(petitionsAuraColors.tertiary),
  white: opacityVariants(colors.white),
  danger: opacityVariants(colors.danger),
  success: opacityVariants(colors.success),
};

const initiativeAuraColors: ThemeColorsValues = {
  primary: colors.blue,
  secondary: colors.cyan,
  tertiary: colors.teal,
  label: colors.black,
  accent: colors.danger,
  danger: colors.danger,
  success: colors.success,
  white: colors.white,
};

const initiativeOpacityVariants: StyleOpacityVariants = {
  primary: opacityVariants(initiativeAuraColors.primary),
  secondary: opacityVariants(initiativeAuraColors.secondary),
  accent: opacityVariants(initiativeAuraColors.accent),
  label: opacityVariants(initiativeAuraColors.label),
  tertiary: opacityVariants(initiativeAuraColors.tertiary),
  white: opacityVariants(colors.white),
  danger: opacityVariants(colors.danger),
  success: opacityVariants(colors.success),
};

const sharedColorVariants = generateColorVariants(colors);

const initiativeColorVariants = generateColorVariants(initiativeAuraColors);
const petitionsColorVariants = generateColorVariants(petitionsAuraColors);

const generatePaletteColor = (color: OpacityColors): ColorTone => ({
  "100": color[100],
  "15": color[15],
  "25": color[25],
  "50": color[50],
  "75": color[75],
  main: color[100],
  contrastText: colors.black,
  alterOpacity: (opacity: Opacity, shade = "100") =>
    alterOpacity(opacity, color[shade]),
});

export {
  generatePaletteColor,
  initiativeAuraColors,
  initiativeColorVariants,
  initiativeOpacityVariants,
  petitionsAuraColors,
  petitionsColorVariants,
  petitionsOpacityVariants,
  sharedColorVariants,
};
