import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { StrictMode } from "react";
import router from "./Routing";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
