//UI
import React from "react";
import { Container } from "./Container";
import { IconSVGProps } from "./Icon";

//Hooks
import { type CSSObject, useTheme } from "@emotion/react";

//Styles
import { marginBottomLg, paddingY2xl } from "@Styles/spacers";

//Configs
import { breakpointDown } from "@Variables";

type HeroProps = {
  children: React.ReactNode;
  /** Hero variant */
  variant?: "default" | "invert";
  /** Custom styles for the hero */
  customStyles?: CSSObject[];

  bgCustomStyles?: CSSObject;
};

const Hero = ({
  children,
  variant = "default",
  customStyles = [],
  bgCustomStyles,
}: HeroProps) => {
  const { colors } = useTheme();
  const primaryScheme = variant === "default" ? "primary" : "secondary";
  const primaryColor = colors[primaryScheme].main;

  const secondaryScheme = primaryScheme === "primary" ? "secondary" : "primary";

  const secondaryColor = colors[secondaryScheme].main;

  const heroStyle: CSSObject[] = [
    paddingY2xl,
    marginBottomLg,
    {
      backgroundColor: secondaryColor,
      height: "100%",
      overflow: "hidden",
      position: "relative",
    },
  ];

  const bgStyle: CSSObject = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    zIndex: 0,
    height: "100%",
    svg: {
      position: "absolute",
      bottom: 0,
      // right: 0,
      height: "100%",
      [breakpointDown["sm"]]: {
        height: "75%",
      },
      ...bgCustomStyles,
    },
  };

  return (
    <Container css={[heroStyle, customStyles]}>
      <div css={bgStyle}>
        <Wave
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </div>

      <div css={{ position: "relative", zIndex: 1 }}>{children}</div>
    </Container>
  );
};

const Wave = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 952 360"
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M888.473 134.004c0-38.704-31.513-73.08-70.222-73.08-38.709 0-70.236 34.39-70.236 73.08v82.715c0 55.59-45.229 100.814-100.825 100.814-55.596 0-100.825-45.21-100.825-100.814v-40.234c0-38.704-31.5-70.213-70.236-70.213-38.737 0-70.222 31.509-70.222 70.213v82.702c0 55.589-45.23 100.813-100.826 100.813-55.595 0-100.825-45.224-100.825-100.813V219.09c0-38.704-31.499-70.213-70.222-70.213s-70.236 31.509-70.236 70.213V360H0V219.09C0 145.196 60.117 85.086 134.02 85.086c73.903 0 134.02 60.097 134.02 134.004v40.097c0 20.413 16.612 37.022 37.028 37.022s37.027-16.609 37.027-37.022v-82.702c0-73.894 60.117-134.004 134.02-134.004 73.903 0 134.02 60.097 134.02 134.004v40.234c0 20.414 16.612 37.023 37.028 37.023s37.027-16.609 37.027-37.023v-82.715C684.19 60.097 744.307 0 818.21 0c73.903 0 134.02 60.096 134.02 134.004v132.529h-63.798V134.004h.041Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        x2={847.278}
        y1={-104}
        y2={-234.199}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={secondaryColor} />
        <stop
          offset={1}
          stopColor={primaryColor}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default Hero;
