import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import { UserFormFields } from "../emotion/components/UserForm";
import { Document, Signature } from "../types/services/documents";
import {
  Actor,
  FaqCategory,
  Initiative,
  Location,
  Page,
  Post,
  PostalCode,
  Signable,
  Tag,
  Testimonial,
  Update,
} from "../types/services/post";

class Api {
  api: AxiosInstance;

  constructor() {
    const api = axios.create({
      baseURL: "/api/v1",
      headers: {},
    });

    api.interceptors.response.use(this.handleSuccess, this.handleError);

    api.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        lng: "de-DE",
      };
      return config;
    });

    this.api = api;
  }

  handleSuccess(response: AxiosResponse) {
    return response;
  }

  handleError = (error: AxiosError) => {
    return Promise.reject(error);
  };

  /**
   * Documents
   */
  createDocument(params = {}) {
    return this.api.post<Document>("/documents/", params);
  }

  createSignature(params = {}) {
    return this.api.post<Signature>("/documents/signatures", params);
  }

  deleteSignature(id: string, params = {}) {
    return this.api.delete<Signature>(`/documents/signatures/${id}/`, params);
  }

  downloadDocument(id: string, token?: string) {
    return this.api.get(
      `/documents/${id}/download${token ? `?token=${token}` : ""}`,
      {
        responseType: "blob",
      },
    );
  }

  getDocuments(params = {}) {
    return this.api.get<Document[]>("/documents", { params });
  }

  getSignatures(params = {}) {
    return this.api.get<Signature[]>("/documents/signatures", { params });
  }

  verifyDocument(id: string, params = {}) {
    return this.api.post(`/documents/${id}/verify`, params);
  }

  verifySignature(id: string, params = {}) {
    return this.api.post(`/documents/signatures/${id}/verify`, params);
  }

  /**
   * Email
   */
  subscribeNewsletter(payload: UserFormFields & { source: string }) {
    return this.api.post("/email/newsletter", payload);
  }

  /**
   * Posts
   */
  getFaqs() {
    return this.api.get<FaqCategory[]>("/posts/faqs");
  }

  getLocation(id: string) {
    return this.api.get<Location>(`/posts/locations/${id}`);
  }

  getLocations(params = {}) {
    return this.api.get<Location[]>("/posts/locations", { params });
  }

  getPage(id: string) {
    return this.api.get<Page>(`/posts/pages/${id}`);
  }

  getPost(id: string) {
    return this.api.get<Post>(`/posts/${id}`);
  }

  getPostalCode(id: string) {
    return this.api.get<PostalCode>(`/posts/postal-codes/${id}`);
  }

  getSignable(id: string) {
    return this.api.get<Signable>(`/posts/initiatives/${id}`);
  }

  getSignables(params = {}) {
    return this.api.get<Signable[]>("/posts/initiatives/", { params });
  }

  getTags(params = {}) {
    return this.api.get<Tag[]>("/posts/tags/", { params });
  }

  getTestimonials(params = {}) {
    return this.api.get<Testimonial[]>("/posts/testimonials/", { params });
  }

  getUpdate(signableId: string, _id: string) {
    return this.api.get<Update>(
      `/posts/initiatives/${signableId}/updates/${_id}`,
    );
  }

  getUpdates(_id: string, params = {}) {
    return this.api.get<Update[]>(`/posts/initiatives/${_id}/updates/`, {
      params,
    });
  }

  getInitiatives() {
    return this.api.get<Initiative[]>("/posts/initiatives");
  }

  getInitiative(id: string) {
    return this.api.get<Initiative>(`/posts/initiatives/${id}`);
  }

  async createInitiative(params = {}) {
    return this.api.post("/posts/initiatives", params);
  }

  async updateInitiative(id: string, params = {}) {
    return this.api.patch(`/posts/initiatives/${id}`, params);
  }

  async upsertInitiative(id: string, params = {}) {
    if (id) {
      return this.updateInitiative(id, params);
    } else {
      return this.createInitiative(params);
    }
  }

  async getActors(params = {}) {
    return this.api.get<Actor>("/posts/actors", { params });
  }

  async createActor(body: { name: string }) {
    return this.api.post("/posts/actors", body);
  }

  async createAttachment(formData: FormData) {
    return this.api.post("/posts/attachments", formData);
  }

  /**
   * Users
   */
  changePassword(
    id: string,
    params: {
      newPassword: string;
      password: string;
    },
  ) {
    return this.api.patch(`/users/${id}/change-password`, params);
  }

  createUser(params = {}) {
    return this.api.post("/users", params);
  }

  generateToken(params: {
    email: string;
    type?: "password" | "verification";
    url?: string;
  }) {
    return this.api.post("/users/token", params);
  }

  getUser(id: string) {
    return this.api.get(`/users/${id}`);
  }

  deleteUser(id: string) {
    return this.api.delete(`/users/${id}`);
  }

  updateProfile(id: string, params = {}) {
    return this.api.patch(`/users/${id}`, params);
  }

  login(params: { email: string; password?: string; token?: string }) {
    return this.api.post("/users/login", params);
  }

  logout() {
    return this.api.post("/users/logout");
  }

  me() {
    return this.api.get("/users/me");
  }

  setPassword(id: string, params: { password: string; token: string }) {
    return this.api.post(`/users/${id}/set-password`, params);
  }
}

export default new Api();
