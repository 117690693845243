import React from "react";

import { useTranslation } from "react-i18next";

import { Column, Row } from "@Components";
import { Visible } from "@Components/atoms";
import Button from "@Components/Button";
import { displayFlex, paddingXZero } from "@Styles";

type ButtonsBarProps = {
  currentStep: number;
  hasPreviousStep: boolean;
  isSaving: boolean;
  disabled?: "NEXT" | "PREVIOUS" | "SUBMIT" | "ALL" | "NONE";
  handleNextStep: (step: number) => Promise<void>;
  handlePreviousStep: (step: number) => void;
  handleSubmit: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => Promise<void>;
};

const ButtonsBar = ({
  currentStep,
  disabled = "NONE",
  hasPreviousStep,
  handleNextStep,
  handlePreviousStep,
  handleSubmit,
}: ButtonsBarProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    next: {
      label: t("components.startPetitionForm.buttons.next.label"),
    },
    submit: {
      label: t("components.startPetitionForm.buttons.submit.label"),
    },
    previous: {
      label: t("components.startPetitionForm.buttons.previous.label"),
    },
  };

  return (
    <Row>
      <Column
        span={{ default: 12, sm: 4 }}
        css={[
          paddingXZero,
          displayFlex,
          { justifyContent: "center", alignItems: "center" },
        ]}
      >
        <Visible when={hasPreviousStep}>
          <Button
            label={CONTENT.previous.label}
            variant="transparent"
            onClick={() => handlePreviousStep(currentStep - 1)}
            disabled={disabled === "PREVIOUS" || disabled === "ALL"}
            css={{ width: "100%", maxWidth: "100%" }}
          />
        </Visible>
      </Column>
      <Column
        span={{ default: 12, sm: 4 }}
        offset={{ default: 0, sm: 4 }}
        css={[
          paddingXZero,
          displayFlex,
          { justifyContent: "center", alignItems: "center" },
        ]}
      >
        <Button
          label={currentStep === 5 ? CONTENT.submit.label : CONTENT.next.label}
          variant="primary"
          onClick={(event) => {
            currentStep === 5
              ? handleSubmit(event)
              : handleNextStep(currentStep + 1);
          }}
          disabled={disabled === "NEXT" || disabled === "ALL"}
          css={{ width: "100%", maxWidth: "100%" }}
        />
      </Column>
    </Row>
  );
};

export default ButtonsBar;
