import { z } from "zod";
import { Amount } from "../types";

const schema = z
  .object({
    amount: z.nativeEnum(Amount),
    customValue: z.coerce.number().nullish(),
  })
  .refine(
    (data) => {
      if (data.amount !== Amount.OTHER) {
        return true;
      }
      if (
        data.amount === Amount.OTHER &&
        data?.customValue &&
        data.customValue > 0
      ) {
        return true;
      }

      return false;
    },
    {
      message: "validAmount",
      path: ["amount"],
    },
  );

export default schema;
