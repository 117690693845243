import { CSSObject, useTheme } from "@emotion/react";

import { marginNegMd, paddingMd } from "../styles/spacers";
import colors from "../variables/colors";
import ActivismIcon from "./icons/ActivismIcon";
import DataSecurityIcon from "./icons/DataSecurityIcon";
import DonationIcon from "./icons/DonationIcon";
import DownloadIcon from "./icons/DownloadIcon";
import EmailIcon from "./icons/EmailIcon";
import ExpertiseIcon from "./icons/ExpertiseIcon";
import PostIcon from "./icons/PostIcon";
import SignatureIcon from "./icons/SignatureIcon";
import TeamIcon from "./icons/TeamIcon";
import ThankYouIcon from "./icons/ThankYouIcon";

const ICON_NAMES = [
  "activism",
  "dataSecurity",
  "donation",
  "download",
  "email",
  "expertise",
  "post",
  "signature",
  "team",
  "thankYou",
] as const;

export type IconName = (typeof ICON_NAMES)[number];

export interface Props {
  /** Name of the icon. */
  name: IconName;
  /** Lightness of the background, used to display the icon with the right contrast. */
  bg?: "light" | "dark";
}

type IconMap = {
  [key in IconName]: (props: IconSVGProps) => JSX.Element;
};

export interface IconSVGProps {
  primaryColor: string;
  secondaryColor: string;
}

const iconMap: IconMap = {
  activism: ActivismIcon,
  donation: DonationIcon,
  dataSecurity: DataSecurityIcon,
  download: DownloadIcon,
  email: EmailIcon,
  expertise: ExpertiseIcon,
  post: PostIcon,
  signature: SignatureIcon,
  team: TeamIcon,
  thankYou: ThankYouIcon,
};

/** Icons for brand values. */
const Icon = ({ bg = "light", name }: Props) => {
  const { colors: swatchColors } = useTheme();
  const primaryColor = swatchColors.primary.main;
  const secondaryColor = swatchColors.secondary.main;
  const SVGComponent = iconMap[name];

  const iconContainerStyle: CSSObject = {
    backgroundColor: bg === "light" ? colors.transparent : colors.white,
    borderRadius: "50%",
    height: "6.25rem",
    width: "6.25rem",
    ...paddingMd,
    ...marginNegMd,
  };

  if (!SVGComponent) {
    return null;
  }

  return (
    <div css={iconContainerStyle}>
      <SVGComponent
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};

export default Icon;
