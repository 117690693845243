import { useTranslation } from "react-i18next";

import { Column, Container, Heading, Placeholder, Row } from "@Components";
import { useTheme } from "@emotion/react";
import { colorSecondary, marginTopZero } from "@Styles";
import { SectionPageProps } from "@Types/components/pageProps";
import { Fragment } from "@Types/services/post";

const HeadingParagraph = ({ loaded, page, ...props }: SectionPageProps) => {
  const { t } = useTranslation();

  const { tone } = useTheme();

  const fragments =
    loaded &&
    page?.fragments &&
    page?.fragments?.filter(
      (fragment: Fragment) => fragment?.section == "headline-section",
    );

  const title = fragments ? t(fragments[0]?.title) : <Placeholder />;
  const content = fragments ? t(fragments[0]?.content) : <Placeholder />;

  return (
    <section {...props}>
      <Container size="lg">
        <Row>
          <Column
            span={{ default: 12, md: 10 }}
            offset={{ default: 0, md: 1 }}
          >
            <Heading
              scale={3}
              color="label"
              css={[marginTopZero]}
            >
              <span css={[colorSecondary(tone)]}>{title}</span>
              <br />
              {content}
            </Heading>
          </Column>
        </Row>
      </Container>
    </section>
  );
};
export default HeadingParagraph;
