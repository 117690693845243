import { CSSObject } from "@emotion/react";
import { rgba } from "emotion-rgba";

import colors from "../variables/colors";
import themes, { Tone } from "../variables/themes";
import { linkStyle } from "./typography";

const textColor: { [key: string]: CSSObject } = {};
const textColors: { [key: string]: typeof textColor } = {};

for (const color in colors) {
  textColors[color] = {
    opacity100: { color: colors[color] },
    opacity15: { color: rgba(colors[color], 0.15) },
    opacity25: { color: rgba(colors[color], 0.25) },
    opacity50: { color: rgba(colors[color], 0.5) },
    opacity75: { color: rgba(colors[color], 0.75) },
  };
}

export const colorPrimary = (tone: Tone = "petition"): CSSObject => ({
  color: themes[tone].colors.primary,
});
export const colorSecondary = (tone: Tone = "petition"): CSSObject => ({
  color: themes[tone].colors.secondary,
});
export const colorTertiary = (tone: Tone = "petition"): CSSObject => ({
  color: themes[tone].colors.tertiary,
});

export const colorPrimaryOpacity15 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.primary, 0.15),
});
export const colorSecondaryOpacity15 = (
  tone: Tone = "petition",
): CSSObject => ({
  color: rgba(themes[tone].colors.secondary, 0.15),
});
export const colorTertiaryOpacity15 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.tertiary, 0.15),
});

export const colorPrimaryOpacity25 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.primary, 0.25),
});
export const colorSecondaryOpacity25 = (
  tone: Tone = "petition",
): CSSObject => ({
  color: rgba(themes[tone].colors.secondary, 0.25),
});
export const colorTertiaryOpacity25 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.tertiary, 0.25),
});

export const colorPrimaryOpacity50 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.primary, 0.5),
});
export const colorSecondaryOpacity50 = (
  tone: Tone = "petition",
): CSSObject => ({
  color: rgba(themes[tone].colors.secondary, 0.5),
});
export const colorTertiaryOpacity50 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.tertiary, 0.5),
});

export const colorPrimaryOpacity75 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.primary, 0.75),
});
export const colorSecondaryOpacity75 = (
  tone: Tone = "petition",
): CSSObject => ({
  color: rgba(themes[tone].colors.secondary, 0.75),
});
export const colorTertiaryOpacity75 = (tone: Tone = "petition"): CSSObject => ({
  color: rgba(themes[tone].colors.tertiary, 0.75),
});

export const colorChildren = (tone: Tone): CSSObject => {
  return {
    "h1, h2, h3, h4, h5, h6": {
      color: themes[tone].colors.primary,
    },
    "a:link": {
      ...linkStyle(tone),
    },
  };
};

export const colorTransparent: CSSObject = { color: "transparent" };

export const colorOrange = textColors.orange.opacity100;
export const colorMauve = textColors.mauve.opacity100;
export const colorPurple = textColors.purple.opacity100;
export const colorBlue = textColors.blue.opacity100;
export const colorCyan = textColors.cyan.opacity100;
export const colorTeal = textColors.teal.opacity100;
export const colorBlack = textColors.black.opacity100;
export const colorWhite = textColors.white.opacity100;
export const colorGray1 = textColors.gray1.opacity100;
export const colorGray2 = textColors.gray2.opacity100;
export const colorGray3 = textColors.gray3.opacity100;
export const colorGray4 = textColors.gray4.opacity100;
export const colorGray5 = textColors.gray5.opacity100;
export const colorDanger = textColors.danger.opacity100;
export const colorSuccess = textColors.success.opacity100;

export const colorOrangeOpacity15 = textColors.orange.opacity15;
export const colorMauveOpacity15 = textColors.mauve.opacity15;
export const colorPurpleOpacity15 = textColors.purple.opacity15;
export const colorBlueOpacity15 = textColors.blue.opacity15;
export const colorCyanOpacity15 = textColors.cyan.opacity15;
export const colorTealOpacity15 = textColors.teal.opacity15;
export const colorBlackOpacity15 = textColors.black.opacity15;
export const colorWhiteOpacity15 = textColors.white.opacity15;
export const colorGray1Opacity15 = textColors.gray1.opacity15;
export const colorGray2Opacity15 = textColors.gray2.opacity15;
export const colorGray3Opacity15 = textColors.gray3.opacity15;
export const colorGray4Opacity15 = textColors.gray4.opacity15;
export const colorGray5Opacity15 = textColors.gray5.opacity15;
export const colorDangerOpacity15 = textColors.danger.opacity15;
export const colorSuccessOpacity15 = textColors.success.opacity15;

export const colorOrangeOpacity25 = textColors.orange.opacity25;
export const colorMauveOpacity25 = textColors.mauve.opacity25;
export const colorPurpleOpacity25 = textColors.purple.opacity25;
export const colorBlueOpacity25 = textColors.blue.opacity25;
export const colorCyanOpacity25 = textColors.cyan.opacity25;
export const colorTealOpacity25 = textColors.teal.opacity25;
export const colorBlackOpacity25 = textColors.black.opacity25;
export const colorWhiteOpacity25 = textColors.white.opacity25;
export const colorGray1Opacity25 = textColors.gray1.opacity25;
export const colorGray2Opacity25 = textColors.gray2.opacity25;
export const colorGray3Opacity25 = textColors.gray3.opacity25;
export const colorGray4Opacity25 = textColors.gray4.opacity25;
export const colorGray5Opacity25 = textColors.gray5.opacity25;
export const colorDangerOpacity25 = textColors.danger.opacity25;
export const colorSuccessOpacity25 = textColors.success.opacity25;

export const colorOrangeOpacity50 = textColors.orange.opacity50;
export const colorMauveOpacity50 = textColors.mauve.opacity50;
export const colorPurpleOpacity50 = textColors.purple.opacity50;
export const colorBlueOpacity50 = textColors.blue.opacity50;
export const colorCyanOpacity50 = textColors.cyan.opacity50;
export const colorTealOpacity50 = textColors.teal.opacity50;
export const colorBlackOpacity50 = textColors.black.opacity50;
export const colorWhiteOpacity50 = textColors.white.opacity50;
export const colorGray1Opacity50 = textColors.gray1.opacity50;
export const colorGray2Opacity50 = textColors.gray2.opacity50;
export const colorGray3Opacity50 = textColors.gray3.opacity50;
export const colorGray4Opacity50 = textColors.gray4.opacity50;
export const colorGray5Opacity50 = textColors.gray5.opacity50;
export const colorDangerOpacity50 = textColors.danger.opacity50;
export const colorSuccessOpacity50 = textColors.success.opacity50;

export const colorOrangeOpacity75 = textColors.orange.opacity75;
export const colorMauveOpacity75 = textColors.mauve.opacity75;
export const colorPurpleOpacity75 = textColors.purple.opacity75;
export const colorBlueOpacity75 = textColors.blue.opacity75;
export const colorCyanOpacity75 = textColors.cyan.opacity75;
export const colorTealOpacity75 = textColors.teal.opacity75;
export const colorBlackOpacity75 = textColors.black.opacity75;
export const colorWhiteOpacity75 = textColors.white.opacity75;
export const colorGray1Opacity75 = textColors.gray1.opacity75;
export const colorGray2Opacity75 = textColors.gray2.opacity75;
export const colorGray3Opacity75 = textColors.gray3.opacity75;
export const colorGray4Opacity75 = textColors.gray4.opacity75;
export const colorGray5Opacity75 = textColors.gray5.opacity75;
export const colorDangerOpacity75 = textColors.danger.opacity75;
export const colorSuccessOpacity75 = textColors.success.opacity75;
