import postsService from "@Services/servicePosts";
import type { Testimonial } from "@Types/services/post";
import useBaseQuery from "../useBaseQuery";

export type UseTestimonialsProps = {
  limit?: number;
  offset?: number;
};

const useTestimonials = (params: UseTestimonialsProps = {}) => {
  const service = new postsService();

  return useBaseQuery<Testimonial[], "testimonials">({
    queryKey: ["testimonial"],
    dataName: "testimonials",
    defaultValue: [],
    service: async () => service.getTestimonials(params),
    onError: (error) => {
      console.error("An error occurred:", error);
    },
  });
};

export { useTestimonials };
