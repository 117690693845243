import { Actor, Initiator } from "../../types/services/post";

export const fetchActors = (actors: (Actor | Initiator)[]): string => {
  if (!actors) {
    return "";
  }

  const actorNames = actors.map((e) => ("name" in e ? e.name : e.displayName));

  const actorsLabel = actorNames.join(", ");

  if (!actorsLabel) {
    return "";
  }

  return actorsLabel;
};
