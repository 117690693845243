//#region Donation
export enum Amount {
  OTHER = "input",
  FIVE = "5",
  TEN = "10",
  TWENTY_FIVE = "25",
  FIFTY = "50",
}

export type DonationFormValues = {
  amount: Amount;
  customValue: string;
};
//#endregion
