import type { TextControlProps } from "@Types";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextInput from "../inputs/Input.Text";
import FormController from "./Form.Control";

const RadioControlGroup: FC<TextControlProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  required = false,
  showErrors = true,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value = "" }, fieldState: { error } }) => {
        return (
          <FormController
            showErrors={showErrors}
            error={error}
          >
            <TextInput
              label={label}
              value={value}
              name={name}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              error={error}
            />
          </FormController>
        );
      }}
    />
  );
};

export default RadioControlGroup;
