import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fontWeightMedium, fontWeightRegular } from "../styles/fontWeights";
import { lineHeightMd } from "../styles/lineHeights";
import { gap2xs, marginZero, paddingXMd, paddingY2xs } from "../styles/spacers";
import { bodySmStyle } from "../styles/typography";
import colors from "../variables/colors";

export interface StickerProps {
  /** FontAwesome icon. */
  icon?: IconProp;
  /** Label of the sticker. */
  label: string;
  /** Style of the sticker. */
  style?: "default" | "outline";
  /** Color variant of the sticker. */
  variant?: "primary" | "secondary";
}

/**
 * Displays a pill-shaped badge.
 */
const Sticker = ({
  icon,
  label,
  style = "default",
  variant = "primary",
}: StickerProps) => {
  const { colors: swatchColors } = useTheme();

  const themeColor = swatchColors[variant].main;

  const stickerStyle: CSSObject = {
    ...bodySmStyle,
    ...gap2xs,
    ...lineHeightMd,
    ...marginZero,
    ...paddingXMd,
    ...paddingY2xs,
    alignItems: "center",
    backgroundColor: style === "outline" ? colors.white : themeColor,
    border: `1px solid ${themeColor}`,
    borderRadius: "1.5rem",
    color: style === "outline" ? colors.black : colors.white,
    display: "inline-flex",
    flexWrap: "nowrap",
    ...(style === "outline" ? fontWeightRegular : fontWeightMedium),
    whiteSpace: "nowrap",
  };

  return (
    <span css={stickerStyle}>
      {icon && (
        <FontAwesomeIcon
          fixedWidth
          icon={icon}
          aria-hidden={true}
        />
      )}
      <span>{label}</span>
    </span>
  );
};

export default Sticker;
