import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
} from "react";

import { CSSObject, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretDown,
  faCheck,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { MultiValue, SingleValue, StylesConfig } from "react-select";

import { ValidationMessage } from "../helpers/validation";
import { bgSecondaryOpacity25 } from "../styles/backgrounds";
import { displayFlex, displayNone } from "../styles/displays";
import {
  gapSm,
  marginBottomMd,
  marginBottomZero,
  marginRightSm,
  marginTopXs,
  paddingLeftLg,
  paddingZero,
} from "../styles/spacers";
import { colorGray5, colorTransparent } from "../styles/textColors";
import { bodyStyle } from "../styles/typography";
import colors from "../variables/colors";
import { Tone } from "../variables/themes";
import Visible from "./atoms/Visible";
import { ConditionalWrapper } from "./ConditionalWrapper";
import { BodySm, Label } from "./typography";

export interface FormControlProps {
  id?: string;
  autoFocus?: boolean;
  checked?: boolean;
  disabled?: boolean;
  name: string;
  type: React.HTMLInputTypeAttribute;
  label?: string;
  displayLabel?: boolean;
  caption?: string;
  captionAlign?: "left" | "center" | "right";
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  options?: any;
  placeholder?: string;
  validation?: ValidationMessage;
  extraErrorMessage?: string;
  useValidationIcon?: boolean;
  tone?: Tone;
  max?: string;
  min?: string;
  step?: number | string;
  componentRef?: React.Ref<any>;
  hiddenValue?: string;
  hasHiddenValue?: boolean;
  value?: number | string;
  key?: string;
  constantPrefix?: string;
  paddingForPrefix?: string;
  acceptFormat?: string;
  handleUpload?: ChangeEventHandler<HTMLInputElement>;
  containerStyle?: CSSObject;
  customFormControlStyle?: CSSObject;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}

export type FormData = {
  email: string;
  password: string;
  lastName: string;
  name: string;
  [key: string]: any;
};
export interface SelectOption {
  value: string;
  label: string;
}

type GroupType = {
  label: string;
  options: SelectOption[];
};

type CustomStylesConfig = StylesConfig<SelectOption, true, GroupType>;

export const initialFormData: FormData = {
  name: "",
  lastName: "",
  email: "",
  password: "",
  subscribe: "yes",
  "checked-checkbox": true,
  "petition-radio-group-1": "off",
  "petition-radio-group-2": "off",
  "initiative-radio-group-1": "off",
  "initiative-radio-group-2": "off",
  "switch-checked-initiative": true,
  "switch-checked-petition": true,
  "switch-disabled-checked-initiative": true,
  "switch-disabled-checked-petition": true,
};

const formControlStyle = {
  ...marginBottomMd,
};

const iconStyle: CSSObject = {
  pointerEvents: "none",
};

const inputWrapperStyle: CSSObject = {
  display: "flex",
  flexDirection: "column",
  position: "relative",
};

const inputStyle: CSSObject = {
  ...bodyStyle,
  gap: "0.75rem",
  marginBottom: 0,
  padding: "0.75rem 1rem",
};

const fieldsetStyle: CSSObject = {
  height: "8.9rem",
};

const disabledCSS: CSSObject = {
  "&:disabled, &.disabled, fieldset:disabled &, &[readonly]": {
    borderColor: colors.gray5,
    cursor: "default",
    pointerEvents: "none",
  },
};

const leftIconStyle: CSSObject = {
  paddingLeft: "2.5rem",
};

const errorMessageStyle: CSSObject = {
  color: colors.danger,
};

const captionStyle: CSSObject = {
  color: colors.gray5,
};

const FormControl = ({
  id,
  autoFocus = false,
  disabled = false,
  name,
  type,
  label,
  displayLabel = true,
  caption,
  captionAlign,
  leftIcon,
  rightIcon,
  setValue,
  options = [],
  placeholder,
  validation,
  extraErrorMessage,
  useValidationIcon = true,
  tone: optionalTheme,
  hiddenValue,
  hasHiddenValue = false,
  componentRef,
  value,
  constantPrefix,
  paddingForPrefix,
  acceptFormat,
  handleUpload,
  containerStyle,
  customFormControlStyle,
  onKeyUp,
  ...props
}: FormControlProps) => {
  const { tone: swatchTone, colors: swatchColors } = useTheme();
  const tone = optionalTheme || swatchTone;

  const [formData] = useState<FormData>(initialFormData);
  const [passwordDisplayed, setPasswordDisplayed] = useState(false);

  const { valid, message } = validation || {};
  const isValidInput = valid === true;
  const isInValidInput = valid === false;
  const showValidationIcon =
    useValidationIcon && (isValidInput || isInValidInput);
  const hasErrorMessage = isInValidInput && message;

  const {
    primary: { main: primaryColor },
    label: { main: labelColor },
    secondary: { main: secondaryColor },
  } = swatchColors;

  const validationIconColor = isValidInput ? colors.success : colors.danger;

  const isTypeCheckbox = type === "checkbox";
  const isTypePassword = type === "password";
  const isTypeDate = type == "date";
  const isTypeFieldset = type === "fieldset";
  const isTypeSelect = type === "select";
  const isTypeSwitch = type === "switch";
  const isTypeRadio = type === "radio";
  const isTypeFile = type === "file";
  const inputType = isTypePassword
    ? passwordDisplayed
      ? "text"
      : "password"
    : type;

  if (isTypeDate) {
    rightIcon = undefined;
  }

  const hasRightIcon = Boolean(rightIcon);
  const hasLeftIcon = Boolean(leftIcon);
  const hasIcon = isTypePassword || isTypeDate || isTypeSelect || hasRightIcon;

  const rightIconPosition = isTypePassword ? "1.75rem" : 0;
  let validationIconPosition = "0";

  if (hasRightIcon && (isTypePassword || isTypeDate)) {
    validationIconPosition = "3.5rem";
  } else if (hasIcon) {
    validationIconPosition = "1.75rem";
  }

  const iconWrapperStyle: CSSObject = {
    position: "absolute",
    top: "50%",
    padding: "0.75rem",
    transform: "translateY(-50%)",
  };

  const buttonStyle: CSSObject = {
    ...iconWrapperStyle,
    all: "unset",
    cursor: "pointer",
    "&:focus span": {
      ...bgSecondaryOpacity25(tone),
    },
    span: {
      borderRadius: "50%",
      padding: "0.3rem",
      marginRight: "-.3rem",
    },
  };

  const baseBorder: CSSObject = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0.375rem",
    boxSizing: "border-box",
    width: "100%",
    height: "3rem",
    borderColor: isInValidInput ? colors.danger : labelColor,
  };

  const inputDateStyle: CSSObject[] = [
    {
      "::-webkit-calendar-picker-indicator": {
        ...iconWrapperStyle,
        cursor: "pointer",
        right: "1rem",
        borderRadius: "50%",
        padding: "0.3rem",
        "&:focus": {
          ...bgSecondaryOpacity25(tone),
          outline: 0,
        },
      },
    },
  ];

  const optionStyle: CSSObject = {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    boxShadow:
      "0px 0px 2px rgba(130, 136, 148, 0.16), 0px 4px 6px rgba(130, 136, 148, 0.16);", // eslint-disable-line max-len
    borderRadius: "0.375rem",
  };

  const activeCSS: CSSObject = {
    "&:active, &:focus, &:hover, &:focus-within": {
      borderColor: secondaryColor,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 3px rgba(118, 118, 118, 0.15)", // eslint-disable-line max-len
      outline: 0,
    },
  };

  const placeholderStyle: CSSObject = {
    "&::placeholder": {
      ...bodyStyle,
      ...colorGray5,
    },
    "&:focus::placeholder": {
      ...colorTransparent,
    },
  };
  const inputCSS: CSSObject = {
    ...activeCSS,
    ...disabledCSS,
    ...baseBorder,
    ...placeholderStyle,
  };

  const checkBoxformControlStyle: CSSObject = {
    alignItems: "left",
    display: "flex",
    flexDirection: "row-reverse",
    ...gapSm,

    label: {
      color: colors.black,
      marginBottom: 0,
      paddingTop: "0.25rem",
    },
  };

  const checkboxStyle: CSSObject = {
    WebkitAppearance: "none",
    border: `1px solid ${primaryColor}`,
    cursor: "pointer",
    height: "1.25rem",
    lineHeight: 0,
    outline: 0,
    padding: 0,
    width: "1.25rem",
    ":checked": {
      backgroundColor: `${primaryColor}`,
    },

    ":hover, :active, :focus": {
      border: `1px solid ${primaryColor}`,
    },

    ":checked:before": {
      border: `solid ${colors.white}`,
      borderWidth: "0 2px 2px 0",
      content: "''",
      height: "0.75rem",
      margin: "0 0.375rem",
      position: "absolute",
      top: "43%",
      right: "12%",
      transform: "rotate(45deg) translate(-50%, -50%)",
      width: "0.438rem",
      zIndex: "2",
    },

    ":disabled": {
      backgroundColor: colors.gray3,
      border: `1px solid ${colors.gray4}`,
    },
  };

  const uploadButtonStyle: CSSObject = {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    borderWidth: "1px",
    borderRadius: "6.25rem",
    borderColor: secondaryColor,
    backgroundColor: "transparent",
    textAlign: "center",
    color: primaryColor,
  };

  const radioLabelStyle: CSSObject = {
    color: colors.black,
    cursor: "pointer",
    fontWeight: 400,
    display: "inline-block",
    position: "relative",
    ...paddingLeftLg,
    ...marginBottomZero,
  };

  const radioStyle: CSSObject = {
    WebkitAppearance: "none",
    appearance: "none",
    backgroundColor: colors.white,
    border: `1px solid ${primaryColor}`,
    borderRadius: "50%",
    color: colors.white,
    display: "grid",
    height: "1.25rem",
    left: "0",
    outline: 0,
    textAlign: "center",
    right: 0,
    transform: "translateY(-0.075em)",
    placeContent: "center",
    width: "1.25rem",
    top: 0,
    margin: 0,
    position: "absolute",
    ...paddingZero,

    ":checked": {
      backgroundColor: primaryColor,
    },

    ":disabled": {
      backgroundColor: colors.gray3,
      border: colors.gray4,
    },

    ":focus, :hover": {
      border: `1px solid ${primaryColor}`,
    },

    "::before": {
      backgroundColor: colors.white,
      borderRadius: "50%",
      content: "''",
      height: "0.375rem",
      transform: "scale(0)",
      transition: "120ms transform ease-in-out",
      width: "0.375rem",
    },

    ":checked::before": {
      transform: "scale(1)",
    },
  };

  const selectStyle: CustomStylesConfig = {
    control: (styles) => ({ ...styles, ...inputCSS }),
    dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
    indicatorSeparator: () => ({ display: "none" }),
    clearIndicator: (styles) => ({ ...styles, display: "none" }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "1em",
      color: colors.gray5,
      fontWeight: 400,
    }),
    menu: (styles) => ({ ...styles, border: "none", boxShadow: "none" }),
    option: (styles, { isDisabled, isFocused }) => ({
      ...styles,
      ...inputCSS,
      ...optionStyle,
      borderColor: isDisabled ? colors.gray5 : "transparent",
      backgroundColor: isDisabled
        ? colors.gray5
        : isFocused
          ? secondaryColor // eslint-disable-line prettier/prettier
          : undefined, // eslint-disable-line prettier/prettier
      cursor: isDisabled ? "not-allowed" : "default",
      fontWeight: isFocused ? "bold" : "default",
      color: isFocused ? labelColor : "default",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: secondaryColor,
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: labelColor,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: primaryColor,
      ":hover": {
        backgroundColor: primaryColor,
        color: colors.white,
      },
    }),
  };

  const switchLabelStyle: CSSObject = {
    color: colors.black,
    cursor: "pointer",
    display: "inline-block",
    fontWeight: 400,
    position: "relative",
    paddingLeft: "2.8rem",
    paddingTop: "0.25rem",
    ...marginBottomZero,
  };

  const switchInputStyle: CSSObject = {
    height: "0",
    opacity: "0",
    width: "0",

    ":checked + span:before": {
      transform: "translateX(16px)",
    },

    ":checked + span": {
      backgroundColor: `${primaryColor}`,
    },

    ":checked,:disabled  + span": {
      backgroundColor: colors.gray3,
      border: colors.gray4,
    },
  };

  const switchSpanStyle: CSSObject = {
    backgroundColor: colors.gray3,
    border: `1px solid ${primaryColor}`,
    borderRadius: "2.125rem",
    cursor: "pointer",
    height: "1.25rem",
    left: 0,
    top: "-0.128rem",
    right: 0,
    position: "absolute",
    textAlign: "center",
    transition: "6s",
    WebkitTransition: "10s",
    width: "2.25rem",

    ":before": {
      backgroundColor: colors.white,
      borderRadius: "50%",
      content: "''",
      height: "0.75rem",
      left: "0.25rem",
      position: "absolute",
      transition: "4s",
      top: "0.188rem",
      width: "0.75rem",
      WebkitTransition: "5s",
    },
  };

  captionStyle.textAlign = captionAlign || "left";
  errorMessageStyle.textAlign = captionStyle.textAlign;

  const Textarea = styled.textarea(inputCSS, inputStyle, fieldsetStyle);
  const IconWrapper = styled.span(iconWrapperStyle);
  const Button = styled.button(buttonStyle);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name;

    if (!setValue) {
      return;
    }

    if ("checkbox" == target.type) {
      const checked = target.checked;

      setValue((prev: Record<string, any>) => {
        let checkedValues: any[] = [];

        if (checked) {
          checkedValues = [...prev[name], value];
        } else {
          checkedValues = prev[name].filter(
            (checkedValue: string) => value !== checkedValue,
          );
        }

        return {
          ...prev,
          [name]: checkedValues,
        };
      });
    } else {
      setValue((prev: object) => ({
        ...prev,
        [name]: target.value,
      }));
    }
  };

  const handleSelectChange = (
    selectedOption: MultiValue<SelectOption> | SingleValue<SelectOption>,
  ) => {
    const newSelectedOption = Array.isArray(selectedOption)
      ? selectedOption[0]
      : selectedOption;
    if (!setValue) {
      return;
    }
    setValue((prev: object) => ({
      ...prev,
      [name]: newSelectedOption?.value,
    }));
  };

  const togglePasswordDisplay = () => {
    setPasswordDisplayed(!passwordDisplayed);
  };

  return (
    <div
      css={[
        formControlStyle,
        customFormControlStyle,
        isTypeCheckbox && checkBoxformControlStyle,
        containerStyle,
      ]}
    >
      {displayLabel && label && !(isTypeRadio || isTypeSwitch) && (
        <Label htmlFor={id}>{label}</Label>
      )}
      <div css={{ ...inputWrapperStyle }}>
        {isTypeSelect && (
          <Select
            id={id}
            styles={selectStyle}
            options={options}
            components={{
              DropdownIndicator: () => (
                <IconWrapper css={{ right: 0 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    color={primaryColor}
                    fixedWidth
                  />
                </IconWrapper>
              ),
            }}
            value={options.filter(
              (option: SelectOption) => option.value === value,
            )}
            onChange={handleSelectChange}
            placeholder={placeholder}
            // isMulti
            isSearchable
            openMenuOnFocus={options.length > 1}
            isDisabled={disabled}
            autoFocus={autoFocus}
            ref={componentRef}
            {...props}
          />
        )}
        {isTypeFieldset && (
          <Textarea
            name={name}
            id={id}
            {...props}
          />
        )}
        {isTypeFile && (
          <>
            <Button
              css={[inputCSS, inputDateStyle, inputStyle, uploadButtonStyle]}
              onClick={(event) => {
                event.preventDefault();
                if (componentRef && typeof componentRef !== "function") {
                  componentRef.current.click();
                }
              }}
            >
              {hasLeftIcon && (
                <IconWrapper
                  css={{
                    position: "relative",
                    marginRight: `${marginRightSm.marginRight} !important`,
                    padding: "0 !important",
                  }}
                >
                  <FontAwesomeIcon
                    icon={leftIcon as IconProp}
                    color={primaryColor}
                    fixedWidth
                  />
                </IconWrapper>
              )}
              {label}
              {hasRightIcon && rightIcon && (
                <IconWrapper css={{ position: "relative", marginRight: "0" }}>
                  <FontAwesomeIcon
                    icon={rightIcon}
                    color={primaryColor}
                    fixedWidth
                  />
                </IconWrapper>
              )}
            </Button>
            <input
              css={displayNone}
              type={isTypeSwitch ? "checkbox" : inputType}
              name={hasHiddenValue ? `${name}Display` : name}
              id={id}
              onChange={handleUpload}
              aria-label={!displayLabel ? label : undefined}
              autoComplete="on"
              placeholder={placeholder}
              checked={formData[name]}
              autoFocus={autoFocus}
              disabled={disabled}
              value={value || ""}
              ref={componentRef}
              accept={acceptFormat}
              {...props}
            />
          </>
        )}
        {!isTypeFieldset && !isTypeSelect && !isTypeFile && (
          <ConditionalWrapper
            condition={isTypeSwitch || isTypeRadio}
            wrapper={(children: JSX.Element): JSX.Element => (
              <Label
                htmlFor={id}
                css={isTypeSwitch ? switchLabelStyle : radioLabelStyle}
              >
                {label}
                {children}
                {isTypeSwitch && <span css={switchSpanStyle}></span>}
              </Label>
            )}
          >
            <input
              css={[
                inputCSS,
                inputDateStyle,
                inputStyle,
                isTypeCheckbox && checkboxStyle,
                isTypeRadio && radioStyle,
                isTypeSwitch && switchInputStyle,
                leftIcon && leftIconStyle,
                paddingForPrefix && { paddingLeft: paddingForPrefix },
              ]}
              type={isTypeSwitch ? "checkbox" : inputType}
              name={hasHiddenValue ? `${name}Display` : name}
              id={id}
              onChange={handleChange}
              aria-label={!displayLabel ? label : undefined}
              autoComplete="on"
              placeholder={placeholder}
              checked={isTypeRadio ? formData[name] === value : formData[name]}
              autoFocus={autoFocus}
              disabled={disabled}
              value={value || ""}
              ref={componentRef}
              onKeyUp={onKeyUp}
              {...props}
            />
          </ConditionalWrapper>
        )}
        {hasHiddenValue && (
          <input
            type="hidden"
            name={name}
            value={hiddenValue}
          />
        )}
        {isTypePassword && (
          <Button
            onClick={togglePasswordDisplay}
            css={{ ...iconWrapperStyle, right: 0 }}
            type="button"
          >
            <span>
              <FontAwesomeIcon
                aria-label={
                  passwordDisplayed
                    ? "Passwort verstecken"
                    : "Passwort anzeigen"
                }
                icon={passwordDisplayed ? faEye : faEyeSlash}
                color={primaryColor}
                fixedWidth
              />
            </span>
          </Button>
        )}
        {Boolean(constantPrefix) && (
          <IconWrapper css={{ ...iconStyle, left: 0 }}>
            <span>{constantPrefix}</span>
          </IconWrapper>
        )}
        {hasLeftIcon && !isTypeFile && (
          <IconWrapper css={{ ...iconStyle, left: 0 }}>
            <FontAwesomeIcon
              icon={leftIcon as IconProp}
              color={primaryColor}
              fixedWidth
            />
          </IconWrapper>
        )}
        {hasRightIcon && rightIcon && !isTypeFile && (
          <IconWrapper css={{ ...iconStyle, right: rightIconPosition }}>
            <FontAwesomeIcon
              icon={rightIcon}
              color={primaryColor}
              fixedWidth
            />
          </IconWrapper>
        )}
        {showValidationIcon && (
          <IconWrapper css={{ right: validationIconPosition }}>
            <FontAwesomeIcon
              icon={isValidInput ? faCheck : faCircleExclamation}
              color={validationIconColor}
              fixedWidth
            />
          </IconWrapper>
        )}
      </div>
      {Boolean(caption) && !hasErrorMessage && (
        <BodySm css={[marginBottomZero, marginTopXs, captionStyle]}>
          {caption}
        </BodySm>
      )}
      {hasErrorMessage && (
        <div css={[displayFlex, { justifyContent: "space-between" }]}>
          <div>
            <Visible when={extraErrorMessage}>
              <BodySm css={[marginBottomZero, marginTopXs, errorMessageStyle]}>
                {extraErrorMessage}
              </BodySm>
            </Visible>
          </div>
          <div>
            <BodySm css={[marginBottomZero, marginTopXs, errorMessageStyle]}>
              {message}
            </BodySm>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormControl;
