import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckDouble,
  faHourglassClock,
  faMapMarkerAlt,
  faPartyHorn,
  faPen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  bgWhite,
  colorSecondary,
  gapSm,
  marginBottomLg,
  marginBottomZero,
  marginRightXs,
  marginYSm,
  paddingXMd,
  paddingYSm,
} from "@Styles";

import { DivisionComponentProps } from "@Types/components/pageProps";
import type { ToneColors } from "@Types/configs";
import type { Signable } from "@Types/services/post";
import { getSignableCountdown } from "@Utils/getSignableCountdown";
import { formatDate } from "../helpers/formatDate";
import { Visible } from "./atoms";
import Button from "./Button";
import ButtonContainer from "./ButtonContainer";
import { Card, CardContent, CardHeader } from "./Card";
import IconLabel from "./IconLabel";
import InitiativeStage from "./InitiativeStage";
import InitiativeType from "./InitiativeType";
import { Headline } from "./organisms";
import { HeadlineProps } from "./organisms/Headline";
import SignatureCounter from "./SignatureCounter";
import Sticker from "./Sticker";
import { BodySm, Label } from "./typography";

export type SignableCardProps = DivisionComponentProps & {
  /** Signable object from the API. */
  signable: Signable;
  /** Mode to display the card. */
  mode?: "default" | "edit";
};

/**
 *  Header
 */

const STATUSES = ["deleted", "draft", "ended", "publish", "success"] as const;
type Status = (typeof STATUSES)[number];
type StatusesContent = {
  [key in Status]: {
    icon: IconProp;
    label: "string";
  };
};

const headerStyle: CSSObject[] = [
  bgWhite,
  paddingXMd,
  paddingYSm,
  {
    alignItems: "center",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-between",
    position: "relative",
  },
];

type SignableCardHeaderProps = {
  createdAt: string;
  isEnded: boolean;
  isSuccess: boolean;
  signableStatus: Signable["status"];
};

const SignableCardHeader = ({
  createdAt,
  isEnded,
  isSuccess,
  signableStatus,
}: SignableCardHeaderProps) => {
  const { tone } = useTheme();

  const { i18n, t } = useTranslation();

  let status: Status = "deleted";

  if ("draft" == signableStatus) {
    status = "draft";
  } else if (isSuccess) {
    status = "success";
  } else if (isEnded) {
    status = "ended";
  } else if ("publish" == signableStatus) {
    status = "publish";
  }

  const STATUSES_CONTENT: StatusesContent = {
    deleted: {
      icon: faTrash,
      label: t("components.signableCard.statuses.deleted"),
    },
    draft: {
      icon: faPen,
      label: t("components.signableCard.statuses.draft"),
    },
    ended: {
      icon: faCheckDouble,
      label: t("components.signableCard.statuses.ended"),
    },
    publish: {
      icon: faCircleCheck,
      label: t("components.signableCard.statuses.publish"),
    },
    success: {
      icon: faPartyHorn,
      label: t("components.signableCard.statuses.success"),
    },
  };

  const statusIconStyle: CSSObject[] = [colorSecondary(tone), marginRightXs];

  return (
    <BodySm css={headerStyle}>
      <span>{formatDate(createdAt, i18n.language)}</span>

      <span>
        <FontAwesomeIcon
          css={statusIconStyle}
          fixedWidth
          icon={STATUSES_CONTENT[status].icon}
        />
        {STATUSES_CONTENT[status].label}
      </span>
    </BodySm>
  );
};

/**
 *  Sticker
 */

const stickerContainerStyle: CSSObject[] = [
  marginYSm,
  {
    position: "relative",
    textAlign: "center",
    pointerEvents: "none",
  },
];

const STICKER_CONTENT_LABELS = ["countdown", "ended", "success"] as const;
type StickerContentLabel = (typeof STICKER_CONTENT_LABELS)[number];
type StickerContent = {
  [key in StickerContentLabel]: {
    icon: IconProp;
    label: "string";
    variant: "primary" | "secondary";
  };
};

type SignableCardStickerProps = {
  countdown?: number;
  isSuccess: boolean;
  isEnded: boolean;
};

const SignableCardSticker = ({
  countdown,
  isEnded,
  isSuccess,
}: SignableCardStickerProps) => {
  const { t } = useTranslation();
  const STICKER_CONTENT: StickerContent = {
    countdown: {
      icon: faHourglassClock,
      label: t("components.signableCard.sticker.countdown", {
        count: countdown || 0,
      }),
      variant: "primary",
    },
    ended: {
      icon: faCheckDouble,
      label: t("components.signableCard.sticker.ended"),
      variant: "secondary",
    },
    success: {
      icon: faPartyHorn,
      label: t("components.signableCard.sticker.success"),
      variant: "secondary",
    },
  };

  let stickerContent: StickerContentLabel | undefined;

  if (isSuccess) {
    stickerContent = "success";
  } else if (isEnded) {
    stickerContent = "ended";
  } else if (countdown && countdown <= 10) {
    stickerContent = "countdown";
  }
  if (!stickerContent) {
    return null;
  }
  return (
    <div css={stickerContainerStyle}>
      <Sticker
        icon={
          STICKER_CONTENT[stickerContent] &&
          STICKER_CONTENT[stickerContent].icon
        }
        label={STICKER_CONTENT[stickerContent].label}
        style="default"
        variant={STICKER_CONTENT[stickerContent].variant}
      />
    </div>
  );
};

/**
 * Labels
 */

type SignableCardLabelsProps = {
  displayInitiators: boolean;
  displayLocation: boolean;
  displayTag: boolean;
  signable: Signable;
};

const SignableCardLabels = ({
  displayInitiators,
  displayLocation,
  displayTag,
  signable,
}: SignableCardLabelsProps) => {
  const containerStyle: CSSObject[] = [
    gapSm,
    marginBottomLg,
    {
      display: "flex",
      flexDirection: "column",
    },
  ];

  const labelStyle: CSSObject[] = [marginBottomZero];

  const tagType = displayTag && !!signable.tag;

  const initiativeType = displayInitiators && signable.initiators;

  const hasLocation =
    displayLocation && signable.type === "initiative" && signable.location;

  return (
    <div css={containerStyle}>
      <Visible when={tagType}>
        <IconLabel
          signable={signable}
          type="tag"
        />
      </Visible>

      <Visible when={initiativeType}>
        <IconLabel
          signable={signable}
          type="initiators"
        />
      </Visible>

      {hasLocation && (
        <Label
          css={labelStyle}
          icon={faMapMarkerAlt}
          variant="secondary"
        >
          {signable.location.name}
        </Label>
      )}

      <Visible when={!tagType && !initiativeType && !hasLocation}>
        <Label css={labelStyle}>
          <span />
        </Label>
      </Visible>
    </div>
  );
};

/**
 * Actions
 */
export interface SignableCardActionsContent {
  label: string;
  mode: SignableCardProps["mode"][];
  url: string;
}

const buttonContainerStyle: CSSObject = {
  marginTop: "auto",
};

const SignableCardActions = ({
  mode,
  signableName,
}: {
  mode: SignableCardProps["mode"];
  signableName: string;
}) => {
  const { t } = useTranslation();

  const ACTIONS_CONTENT: SignableCardActionsContent[] = [
    {
      label: t("components.signableCard.actions.edit"),
      mode: ["edit"],
      url: "/",
    },
    {
      label: t("components.signableCard.actions.updates"),
      mode: ["edit"],
      url: "/",
    },
    {
      label: t("components.signableCard.actions.share"),
      mode: ["edit"],
      url: "/",
    },
    {
      label: t("components.signableCard.actions.sign"),
      mode: ["default"],
      url: `/${signableName}`,
    },
  ];

  return (
    <div css={buttonContainerStyle}>
      <ButtonContainer
        align={{
          default: "justify",
        }}
        direction={{
          default: "vertical",
        }}
      >
        {ACTIONS_CONTENT.map((action) => {
          return action.mode.includes(mode) ? (
            <Button
              as="a"
              href={`${action.url}`}
              fullWidth={{
                default: true,
              }}
              label={action.label}
              key={action.label}
              size="md"
              variant="primary"
              type="submit"
            />
          ) : null;
        })}
      </ButtonContainer>
    </div>
  );
};
/**
 * Card
 */

const headlineProps = (
  signable: Signable,
  isPetition: boolean,
): HeadlineProps => {
  const titleColor: ToneColors = isPetition ? "label" : "primary";

  return {
    title: {
      title: signable.title,
      color: titleColor,
      lineClamp: {
        default: 2,
        lockHeight: true,
      },
    },
    text: {
      lineClamp: {
        default: 3,
        lockHeight: true,
      },
      align: "center",
      title: signable.summary,
    },
  };
};
const contentHeaderStyle: CSSObject[] = [marginBottomLg];
const contentHeaderInitiativeStyle: CSSObject[] = [gapSm, { display: "flex" }];
const headlineContainerStyle: CSSObject[] = [marginBottomLg];

/**
 * Displays a card with the summary of a signable. Should always be in a container with max-width of 600 pixels.
 */
const SignableCard = ({
  signable,
  mode = "default",
  ...props
}: SignableCardProps) => {
  const countdown = getSignableCountdown(signable);

  const tone = signable.type;
  const isInitiative = tone === "initiative";
  const isPetition = tone === "petition";

  const isSuccess = !!signable.success;
  const isEnded =
    (isInitiative &&
      signable.stage &&
      signable?.initiativeStatuses?.[signable.stage]) === "ended" ||
    (countdown !== undefined && countdown <= 0);

  const displayHeader = mode === "edit";
  const displaySticker = mode === "default";

  const displayContentHeaderInitiative = isInitiative && mode === "default";

  const displayContentHeaderPetition = isPetition;

  const displayLabels = mode === "default";
  const displayInitiators = displayLabels && isPetition;
  const displayLocation = displayLabels && isInitiative;
  const displayTag = displayLabels;

  return (
    <Card {...props}>
      <CardHeader
        alt={signable.title}
        attachment={signable?.featuredImage}
        href={`/${signable.name}`}
        size="md"
      >
        <Visible when={displayHeader}>
          <SignableCardHeader
            createdAt={signable.createdAt}
            isEnded={isEnded}
            isSuccess={isSuccess}
            signableStatus={signable.status}
          />
        </Visible>

        <Visible when={displaySticker}>
          <SignableCardSticker
            countdown={countdown}
            isEnded={isEnded}
            isSuccess={isSuccess}
          />
        </Visible>
      </CardHeader>

      <CardContent>
        <div css={contentHeaderStyle}>
          {displayContentHeaderInitiative && (
            <div css={contentHeaderInitiativeStyle}>
              <InitiativeType initiative={signable} />
              <InitiativeStage initiative={signable} />
            </div>
          )}

          <Visible when={displayContentHeaderPetition}>
            <SignatureCounter signable={signable} />
          </Visible>
        </div>

        <div css={headlineContainerStyle}>
          <Headline {...headlineProps(signable, isPetition)} />
        </div>

        <SignableCardLabels
          displayInitiators={displayInitiators}
          displayLocation={displayLocation}
          displayTag={displayTag}
          signable={signable}
        />

        <SignableCardActions
          mode={mode}
          signableName={signable.name}
        />
      </CardContent>
    </Card>
  );
};

export default SignableCard;
