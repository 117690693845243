import { InitiativeInput } from "../../../types/services/post";

export const validateStep = (
  step: number,
  initiative: InitiativeInput,
  source = "dashboard",
  minLengths: Record<string, number>,
  maxLengths: Record<string, number>,
): { isValid: boolean; errors: Record<string, boolean> } => {
  const model = initiative.type;
  type DashboardSwitch = Record<number, (keyof InitiativeInput)[]>;
  const stepRequiredFields: Record<string, Record<string, DashboardSwitch>> = {
    initiative: {
      dashboard: {
        1: ["title", "subtitle", "name"],
        2: ["location"],
        3: ["summary", "content"],
      },
    },
    petition: {
      dashboard: {
        1: ["title", "name"],
        // 2: ["initiators", "targets"],
        3: ["content"],
      },
      "petition-starten": {
        1: ["title", "tag"],
        2: ["displayName", "targets"],
        3: ["content"],
        5: ["featuredImage"],
      },
    },
  };

  const requiredFields = stepRequiredFields[model]?.[source]?.[step];
  const errors: Record<string, boolean> = {};

  if (!requiredFields) {
    return { isValid: true, errors };
  }

  requiredFields.forEach((field) => {
    const fieldValue = initiative[field];
    if (
      fieldValue &&
      (isValidString(field, fieldValue, minLengths, maxLengths) ||
        isValidArray(fieldValue) ||
        isValidObject(fieldValue))
    ) {
      errors[field] = false;
    } else {
      errors[field] = true;
    }
  });

  return { isValid: isValid(errors), errors };
};

const isValid = (errors: Record<string, boolean>) => {
  return !Object.values(errors).includes(true);
};

const isValidString = (
  field: string,
  fieldValue: any,
  minLengths: Record<string, number>,
  maxLengths: Record<string, number>,
) =>
  "string" === typeof fieldValue &&
  fieldValue !== "" &&
  (!maxLengths[field] || fieldValue.length < maxLengths[field]) &&
  (!minLengths[field] || fieldValue.length > minLengths[field]);

const isValidArray = (fieldValue: any) =>
  Array.isArray(fieldValue) && fieldValue.length > 0;

const isValidObject = (fieldValue: any) =>
  "object" === typeof fieldValue &&
  !Array.isArray(fieldValue) &&
  Object.keys(fieldValue).length > 0;
