import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";

const SetPasswordMessageModal = () => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      content={t("pages.userAccount.modals.setPasswordMessage.content")}
      icon={faCheck}
      id="setPasswordMessage"
      title={t("pages.userAccount.modals.setPasswordMessage.title")}
    ></ModalDialog>
  );
};

export default SetPasswordMessageModal;
