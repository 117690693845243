import postsService from "@Services/servicePosts";
import type { Page } from "@Types/services/post";
import useBaseQuery from "./useBaseQuery";

type UsePageProps = {
  id: string;
  onSuccess: (data: Page) => void;
  onError: (error: any) => void;
};

const usePage = ({ id, onError, onSuccess }: UsePageProps) => {
  const service = new postsService();

  return useBaseQuery<Page, "page">({
    service: async () => service.getPage<Page>(id),
    dataName: "page",
    queryKey: ["page", id],
    onError,
    onSuccess,
  });
};

export default usePage;
