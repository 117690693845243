import type { CSSObject } from "@emotion/react";
import type { FormHelperTextProps } from "@Types";
import { colors } from "@Variables";
import type { FC } from "react";
import { Visible } from "../atoms";

const errorStyle: CSSObject = {
  color: colors.danger,
  marginTop: "4px",
};

const FormHelperText: FC<FormHelperTextProps> = ({
  children,
  showErrors = true,
  ...props
}) => {
  return (
    <Visible when={showErrors}>
      <div
        css={errorStyle}
        {...props}
      >
        {children}
      </div>
    </Visible>
  );
};

export default FormHelperText;
