import { useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Button, Heading, UpdateCard } from "@Components";
import { paddingLg } from "@Styles/spacers";
import { Update } from "@Types/services/post";
import api from "@Utils/api";
import { SignablePageElementProps } from ".";

const UpdatesTab = ({ signable }: SignablePageElementProps) => {
  const [hasMoreUpdates, setHasMoreUpdates] = useState(true);

  const [loadingUpdates, setLoadingUpdates] = useState(false);

  const { t } = useTranslation();

  const [updates, setUpdates] = useState([] as Update[]);
  const [updatesOffset, setUpdatesOffset] = useState(0);

  const updatesEmptyStyle: CSSObject[] = [paddingLg];

  const handleLoadMoreUpdates = () => setUpdatesOffset((prev) => prev + 5);

  useEffect(() => {
    let unmounted = false;
    if (!signable._id) return;

    setLoadingUpdates(true);

    api
      .getUpdates(signable._id, { limit: 5, offset: updatesOffset })
      .then((response) => {
        if (!unmounted) {
          const updates = response.data;
          if (updates.length == 0) {
            setHasMoreUpdates(false);
          } else {
            setUpdates(
              (prevUpdates) => [...prevUpdates, ...updates] as Update[],
            );
          }
        }
      })
      .catch(() => null)
      .finally(() => setLoadingUpdates(false));
    return () => {
      unmounted = true;
    };
  }, [signable, updatesOffset]);

  return updates.length == 0 ? (
    <div css={updatesEmptyStyle}>
      <Heading scale={5}>{t("pages.signable.updates.noUpdates.title")}</Heading>
      <p>{t("pages.signable.updates.noUpdates.text")}</p>
    </div>
  ) : (
    <>
      {updates.map((update) => (
        <UpdateCard
          key={`update-${update._id}`}
          signableTitle={signable.title}
          update={update}
        />
      ))}

      {hasMoreUpdates && (
        <Button
          fullWidth={{ default: true }}
          label={t("pages.signable.updates.loadMore")}
          loading={loadingUpdates}
          onClick={handleLoadMoreUpdates}
          variant="outline"
        />
      )}
    </>
  );
};

export default UpdatesTab;
