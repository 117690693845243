import { useContext, useState } from "react";

import { UnauthUser, UserContext } from "../../contexts/UserContext";
import EmailConfirmedMessageModal from "./EmailConfirmedMessageModal";
import GetUserModal from "./GetUserModal";
import LoginModal from "./LoginModal";
import PasswordResetMessageModal from "./PasswordResetMessageModal";
import PasswordResetModal from "./PasswordResetModal";
import PasswordResetRequestMessageModal from "./PasswordResetRequestMessageModal";
import PasswordResetRequestModal from "./PasswordResetRequestModal";
import RegistrationMessageModal from "./RegistrationMessageModal";
import RegistrationModal from "./RegistrationModal";
import SetPasswordMessageModal from "./SetPasswordMessageModal";
import SetPasswordModal from "./SetPasswordModal";
import SetPasswordRequestMessageModal from "./SetPasswordRequestMessageModal";

const AuthenticationModals = () => {
  const { isAuth } = useContext(UserContext);
  const [unauthUser, setUnauthUser] = useState<UnauthUser>({
    email: "",
    password: "",
  } as UnauthUser);

  return (
    <>
      {!isAuth ? (
        <>
          <GetUserModal
            unauthUser={unauthUser}
            setUnauthUser={setUnauthUser}
          />
          <LoginModal
            unauthUser={unauthUser}
            setUnauthUser={setUnauthUser}
          />
          <PasswordResetRequestModal
            unauthUser={unauthUser}
            setUnauthUser={setUnauthUser}
          />
          <PasswordResetRequestMessageModal />
          <RegistrationModal unauthUser={unauthUser} />
        </>
      ) : (
        <>
          <EmailConfirmedMessageModal />

          {/* Message after logged in user requested to set password */}
          <SetPasswordRequestMessageModal />
          <SetPasswordModal />
          {/* Message after logged in set a password */}
          <SetPasswordMessageModal />

          <PasswordResetModal />
          <PasswordResetMessageModal />
        </>
      )}
      {/* This modal is displayed for logged in users that are not verified and not logged in users that just registered */}
      <RegistrationMessageModal />
    </>
  );
};

export default AuthenticationModals;
