import React from "react";

import { CSSObject } from "@emotion/react";

import { paddingXMd } from "../styles/spacers";

export interface ContainerProps extends React.ComponentPropsWithoutRef<"div"> {
  size?: keyof typeof containerWidths;
}

enum containerWidths {
  sm = "466px",
  md = "700px",
  lg = "1400px",
  fw = "100%",
}

export const Container = ({ size = "fw", ...props }: ContainerProps) => {
  const style: CSSObject = {
    ...paddingXMd,
    margin: "0 auto",
    maxWidth: containerWidths[size],
    width: "100%",
  };

  return (
    <div
      css={style}
      {...props}
    />
  );
};
