import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { Link as LinkComponent } from "./typography";

type PrivacyTextProps = {
  tone?: "neutral";
};

const PrivacyText = ({ tone }: PrivacyTextProps) => (
  <Trans i18nKey="common.privacyText">
    <LinkComponent
      variant={tone}
      as={Link}
    />
  </Trans>
);

export default PrivacyText;
