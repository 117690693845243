import Button from "@Components/Button";
import FormControl from "@Components/FormControl";
import { Heading } from "@Components/Heading";
import Row from "@Components/Row";
import { marginTopXs, marginYLg } from "@Styles/spacers";
import { FormContentRow } from "./update-profile";
import Column from "@Components/Column";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useState } from "react";
import api from "@Utils/api";
import { useUserContext } from "@Contexts/UserContext";
import { ModalsContext } from "@Contexts/ModalsContext";

export const UpdatePassword = () => {
  const { t } = useTranslation();
  const { isAuth, user } = useUserContext();
  const [userPassword, setUserPassword] = useState<{
    password: string;
    newPassword: string;
  }>({
    password: "",
    newPassword: "",
  });
  const [saving, setSaving] = useState<boolean>(false);

  const { toggleModal } = useContext(ModalsContext);

  const handleSubmit: MouseEventHandler = (e) => {
    e.preventDefault();
    if (isAuth && user) {
      const userId = user?._id; // Capture the latest user ID
      setSaving(true);
      api
        .changePassword(userId, {
          password: userPassword?.password,
          newPassword: userPassword?.newPassword,
        })
        .then((res) => {
          // open modal
          toggleModal("UpdatePasswordModal");
          setSaving(false);
          setUserPassword({
            newPassword: "",
            password: "",
          });
        })
        .catch((e) => {
          console.error(e);
          setSaving(false);
        });
    }
  };

  const FORM_CONTENT_ROWS: FormContentRow[][] = [
    [
      {
        formType: "form",
        label: t(
          "pages.userAccount.account.updatePassword.form.passwordInput.label",
        ),
        name: "password",
        type: "password",
        placeholder: "**********",
        span: { default: 12 },
        value: userPassword?.password,
        setValue: setUserPassword,
      },
    ],
    [
      {
        formType: "form",
        label: t(
          "pages.userAccount.account.updatePassword.form.newPasswordInput.label",
        ),
        name: "newPassword",
        type: "password",
        placeholder: "**********",
        span: { default: 12 },
        value: userPassword?.newPassword,
        setValue: setUserPassword,
      },
    ],
    [
      {
        formType: "button",
        name: "submit",
        type: "button",
        fullWidth: { default: true },
        label: t(
          "pages.userAccount.account.updatePassword.form.submitButton.label",
        ),
        tone: "petition",
        variant: "primary",
        span: { default: 12 },
      },
    ],
  ];

  return (
    <>
      <Row>
        <Column>
          <Heading
            scale={5}
            css={marginYLg}
          >
            {t("pages.userAccount.account.updatePassword.title")}
          </Heading>
        </Column>
      </Row>
      {FORM_CONTENT_ROWS.map((row, index) => {
        return (
          <section
            css={{ ...marginTopXs }}
            key={index}
          >
            <Row>
              {row?.map((item) => {
                return (
                  <Column
                    key={item?.name}
                    span={item?.span}
                  >
                    {item?.formType === "form" ? (
                      <FormControl
                        type={item?.type}
                        label={item.label}
                        name={item?.name}
                        placeholder={item?.placeholder}
                        value={item?.value}
                        setValue={item?.setValue}
                      />
                    ) : (
                      <Button
                        fullWidth={item?.fullWidth}
                        tone="petition"
                        variant="primary"
                        label={item?.label}
                        onClick={handleSubmit}
                        loading={saving}
                      />
                    )}
                  </Column>
                );
              })}
            </Row>
          </section>
        );
      })}
    </>
  );
};
