import React, { useContext, useEffect, useState } from "react";

import { faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import isEmpty from "validator/lib/isEmpty";

import { AuthContext } from "../../contexts/AuthContext";
import { ModalsContext } from "../../contexts/ModalsContext";
import {
  UnauthUser,
  UserContext,
  UserDispatchContext,
  me,
} from "../../contexts/UserContext";
import FormControl from "../../emotion/components/FormControl";
import ModalDialog from "../../emotion/components/ModalDialog";
import {
  ValidationMessage,
  isValid,
  validate,
} from "../../emotion/helpers/validation";
import api from "../../utils/api";

const PasswordResetModal = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const dispatch = useContext(UserDispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { openModal, toggleModal } = useContext(ModalsContext);
  const [unauthUser, setUnauthUser] = useState({
    email: "",
    password: "",
  } as UnauthUser);
  const { user } = useContext(UserContext);
  const [validation, setValidation] = useState({} as ValidationMessage);
  const validationRules = {
    password: [
      {
        message: t("components.userForm.validation.password.notEmpty"),
        valid: !!unauthUser.password && !isEmpty(unauthUser.password),
      },
    ],
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setValidation({} as ValidationMessage);

    const validation = validate(validationRules);

    if (!isValid(validation)) {
      return setValidation(validation.password);
    }

    if (!unauthUser.email || !auth.token) {
      return setValidation({
        valid: false,
        message: t("components.userForm.validation.submit.error"),
      });
    }

    setIsLoading(true);
    api
      .setPassword(unauthUser.email, {
        password: unauthUser.password,
        token: auth.token,
      })
      .then(() => {
        me(dispatch);
        toggleModal("passwordResetMessage");
      })
      .catch(() => {
        setValidation({
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        });
      })
      .finally(() => {
        setIsLoading(false);
        setAuth({});
      });
  };

  useEffect(() => {
    if ("resetPassword" == auth.action && "passwordReset" !== openModal) {
      toggleModal("passwordReset");
    }
  }, [auth, openModal, toggleModal]);

  useEffect(() => {
    if (user && user.email) {
      setUnauthUser((prev) => ({ ...prev, email: user.email }));
    }
  }, [user]);

  return (
    <ModalDialog
      buttons={[
        {
          action: handleSubmit,
          label: t("common.navigation.next") as string,
          loading: isLoading,
          variant: "primary",
        },
      ]}
      content={
        <>
          <p>{t("pages.userAccount.modals.passwordReset.content")}</p>

          <form onSubmit={handleSubmit}>
            <FormControl
              disabled={isLoading}
              label={t("components.userForm.fields.password") as string}
              name="password"
              setValue={setUnauthUser}
              type="password"
              validation={validation}
              value={unauthUser.password}
            />
          </form>
        </>
      }
      icon={faUnlock}
      id="passwordReset"
      title={t("pages.userAccount.modals.passwordReset.title")}
    />
  );
};

export default PasswordResetModal;
