import { CSSObject } from "@emotion/react";

import toCamelCase from "../helpers/toCamelCase";
import { breakpoints, setBreakpointProperties } from "../variables/breakpoints";

interface DisplayStyles {
  [key: string]: CSSObject;
}

const displayValues = [
  "none",
  "block",
  "inline",
  "inline-block",
  "flex",
  "inline-flex",
  "grid",
  "inline-grid",
  "table",
  "table-cell",
  "table-row",
  "table-caption",
];

const displayStyles: DisplayStyles = {};

for (const value of displayValues) {
  const valueName = toCamelCase(value);
  displayStyles["display" + valueName] = { display: value };

  for (const breakpoint of breakpoints) {
    const breakpointDisplay = () => {
      return setBreakpointProperties(breakpoint, {
        display: value,
      });
    };

    const breakpointName = toCamelCase(breakpoint);
    displayStyles[
      "display" +
        ("default" != breakpointName ? breakpointName : "") +
        valueName
    ] = breakpointDisplay();
  }
}

export const displayNone = displayStyles["displayNone"];
export const displayBlock = displayStyles["displayBlock"];
export const displayInline = displayStyles["displayInline"];
export const displayInlineBlock = displayStyles["displayInlineBlock"];
export const displayFlex = displayStyles["displayFlex"];
export const displayInlineFlex = displayStyles["displayInlineFlex"];
export const displayGrid = displayStyles["displayGrid"];
export const displayInlineGrid = displayStyles["displayInlineGrid"];
export const displayTable = displayStyles["displayTable"];
export const displayTableCell = displayStyles["displayTableCell"];
export const displayTableRow = displayStyles["displayTableRow"];
export const displayCaption = displayStyles["displayCaption"];

export const displaySmNone = displayStyles["displaySmNone"];
export const displaySmBlock = displayStyles["displaySmBlock"];
export const displaySmInline = displayStyles["displaySmInline"];
export const displaySmInlineBlock = displayStyles["displaySmInlineBlock"];
export const displaySmFlex = displayStyles["displaySmFlex"];
export const displaySmInlineFlex = displayStyles["displaySmInlineFlex"];
export const displaySmGrid = displayStyles["displaySmGrid"];
export const displaySmInlineGrid = displayStyles["displaySmInlineGrid"];
export const displaySmTable = displayStyles["displaySmTable"];
export const displaySmTableCell = displayStyles["displaySmTableCell"];
export const displaySmTableRow = displayStyles["displaySmTableRow"];
export const displaySmCaption = displayStyles["displaySmCaption"];

export const displayMdNone = displayStyles["displayMdNone"];
export const displayMdBlock = displayStyles["displayMdBlock"];
export const displayMdInline = displayStyles["displayMdInline"];
export const displayMdInlineBlock = displayStyles["displayMdInlineBlock"];
export const displayMdFlex = displayStyles["displayMdFlex"];
export const displayMdInlineFlex = displayStyles["displayMdInlineFlex"];
export const displayMdGrid = displayStyles["displayMdGrid"];
export const displayMdInlineGrid = displayStyles["displayMdInlineGrid"];
export const displayMdTable = displayStyles["displayMdTable"];
export const displayMdTableCell = displayStyles["displayMdTableCell"];
export const displayMdTableRow = displayStyles["displayMdTableRow"];
export const displayMdCaption = displayStyles["displayMdCaption"];

export const displayLgNone = displayStyles["displayLgNone"];
export const displayLgBlock = displayStyles["displayLgBlock"];
export const displayLgInline = displayStyles["displayLgInline"];
export const displayLgInlineBlock = displayStyles["displayLgInlineBlock"];
export const displayLgFlex = displayStyles["displayLgFlex"];
export const displayLgInlineFlex = displayStyles["displayLgInlineFlex"];
export const displayLgGrid = displayStyles["displayLgGrid"];
export const displayLgInlineGrid = displayStyles["displayLgInlineGrid"];
export const displayLgTable = displayStyles["displayLgTable"];
export const displayLgTableCell = displayStyles["displayLgTableCell"];
export const displayLgTableRow = displayStyles["displayLgTableRow"];
export const displayLgCaption = displayStyles["displayLgCaption"];
