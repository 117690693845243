import { CSSObject } from "@emotion/react";

import colors from "../variables/colors";
import { fontSizes, lineHeights } from "../variables/scales";
import themes, { Tone } from "../variables/themes";

export interface Props {
  /** Label of the status */
  label?: string;
  /** Color theme. */
  theme?: Tone;
  /** Variant of the component. */
  variant?: "bg" | "underline";
}

/** An inline-block text to display statuses. */
const Status = ({ label, theme = "petition", variant = "bg" }: Props) => {
  const primaryColor =
    "petition" == theme ? colors.white : themes[theme].colors.primary;
  const secondaryColor =
    "petition" == theme
      ? themes[theme].colors.primary
      : themes[theme].colors.secondary;

  const underlineColor =
    "petition" == theme ? themes[theme].colors.secondary : primaryColor;

  const baseStyle: CSSObject = {
    fontSize: `${fontSizes.md}rem`,
    fontWeight: 800,
    lineHeight: `${lineHeights.xl}rem`,
  };

  const styles = {
    bg: {
      background: `linear-gradient(180deg, transparent 15%, ${secondaryColor} 15%, ${secondaryColor} 85%, transparent 85%)`,
      color: primaryColor,
    },
    underline: {
      background: `linear-gradient(180deg, transparent 85%, ${underlineColor} 85%, ${underlineColor} 95%, transparent 95%)`,
      color: secondaryColor,
    },
  };

  return <span css={[baseStyle, styles[variant]]}>{label}</span>;
};

export default Status;
