import { CSSObject, Global, css } from "@emotion/react";
import normalize from "modern-normalize";

import {
  bgMauveOpacity25,
  blockquoteStyle,
  bodySmStyle,
  bodyStyle,
  colorBlack,
  detailsStyle,
  dlStyle,
  h1Style,
  h2Style,
  h3Style,
  h4Style,
  h5Style,
  h6Style,
  labelStyle,
  linkStyle,
  olStyle,
  ulStyle,
} from "@Styles";
import "../i18n";
import { fonts } from "./fonts";

// Source: https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
const fieldsetStyles: CSSObject = {
  legend: {
    padding: 0,
    display: "table",
  },
  fieldset: {
    border: 0,
    padding: "0.01em 0 0 0",
    margin: 0,
    minWidth: 0,
  },
  "body:not(:-moz-handler-blocked) fieldset": {
    display: "table-cell",
  },
};

const typographyStyles: CSSObject[] = [
  fieldsetStyles,
  {
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },
    "::selection": {
      ...bgMauveOpacity25,
      ...colorBlack,
    },
    body: bodyStyle,
    h1: h1Style,
    h2: h2Style,
    h3: h3Style,
    h4: h4Style,
    h5: h5Style,
    h6: h6Style,
    label: labelStyle,
    legend: labelStyle,
    p: bodyStyle,
    small: bodySmStyle,

    a: linkStyle("neutral"),

    blockquote: blockquoteStyle,
    details: detailsStyle,

    dl: dlStyle,
    ol: olStyle,
    ul: ulStyle,
  },
];

const globalCSS = css([`${normalize}`, fonts, typographyStyles]);

export const GlobalStyles = () => {
  return <Global styles={globalCSS} />;
};
