import React, { createContext, useContext, useState } from "react";

type PageTitleProviderProps = {
  children: React.ReactNode;
};

type PageTitleContextValues = {
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
};

export const PageTitleContext = createContext<PageTitleContextValues>({
  pageTitle: "",
  setPageTitle: () => undefined,
});

export const usePageTitleContext = (): PageTitleContextValues => {
  const context = useContext(PageTitleContext);

  if (!context) {
    throw new Error(
      "usePageTitleContext must be used within Page Title Provider",
    );
  }

  return context;
};

export const PageTitleProvider = ({ children }: PageTitleProviderProps) => {
  const [pageTitle, setPageTitle] = useState<string>("");

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};
