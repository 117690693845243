import { CSSObject, useTheme } from "@emotion/react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import {
  BodyLg,
  Column,
  Container,
  Heading,
  Illustration,
  Placeholder,
  Row,
  SocialMediaShare,
} from "@Components";
import { useBreakpoints } from "@Hooks/useBreakpoints";
import { bgSecondary } from "@Styles";
import {
  marginBottomZero,
  paddingBottomLg,
  paddingLgXl,
  paddingMdLg,
  paddingYLg,
} from "@Styles/spacers";
import { SectionPageProps } from "@Types/components/pageProps";
import { Fragment } from "@Types/services/post";

const SocialMediaBanner = ({ loaded, page, ...props }: SectionPageProps) => {
  const { isSmallScreen } = useBreakpoints();

  const { t } = useTranslation();

  const fragments =
    loaded &&
    page?.fragments &&
    page?.fragments?.filter(
      (fragment: Fragment) =>
        fragment?.section == "social-media-banner-section",
    );

  const title = fragments ? t(fragments[0]?.title) : <Placeholder />;
  const content = fragments ? parse(t(fragments[0]?.content)) : <Placeholder />;

  const headingStyle: CSSObject[] = [paddingBottomLg];

  const { tone } = useTheme();

  return (
    <section
      css={[bgSecondary(tone)]}
      {...props}
    >
      <Container size="lg">
        <Row>
          <Column span={{ default: 12, sm: 12, md: 6, lg: 7 }}>
            <Row css={[paddingLgXl, paddingMdLg]}>
              <Column
                css={[isSmallScreen ? { textAlign: "center" } : {}]}
                span={{ default: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Heading
                  css={headingStyle}
                  color="white"
                  scale={2}
                  variant="display"
                >
                  {title}
                </Heading>

                <BodyLg
                  css={marginBottomZero}
                  color="white"
                >
                  {content}
                </BodyLg>
              </Column>

              <Column
                css={paddingYLg}
                span={{ default: 12, sm: 12, md: 12, lg: 12 }}
              >
                <SocialMediaShare
                  signableTitle="Folge uns auf Social Media"
                  signableType="petition"
                  url="fdsfd"
                  context="fsfsd"
                  variant="primary"
                  background="dark"
                  size={isSmallScreen ? "sm" : "md"}
                />
              </Column>
            </Row>
          </Column>

          <Column span={{ default: 12, sm: 12, md: 6, lg: 5 }}>
            <Illustration
              name="share"
              size="lg"
            />
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default SocialMediaBanner;
