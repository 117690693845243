import api from "@Utils/api";
import { adjustedSignableType } from "./my-signatures";

export const fetchSignatures = async (
  offset: number,
  limit = 6,
): Promise<adjustedSignableType[]> => {
  const signatures = (
    await api.getSignatures({
      offset,
      limit,
      signer: "me",
    })
  )?.data;

  const ids = signatures?.map((signature) => signature?.signableId);

  if (ids.length === 0) {
    return [];
  }

  const signables = (
    await api.getSignables({
      type: "petition",
      ids,
    })
  ).data;

  const filteredResult = signables.map((signable) => {
    return {
      ...signable,
      signatureId: signatures.find((signature) => {
        return signature?.signableId === signable?._id;
      })?._id,
    };
  });

  return filteredResult;
};
