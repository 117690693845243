import { CSSObject } from "@emotion/react";

import type { TextAlign } from "@Types/typesTypography";
import { displayFlex } from "../styles/displays";
import { marginBottomZero, marginYLg, paddingXMd } from "../styles/spacers";
import { Heading } from "./Heading";
import Icon, { IconName } from "./Icon";
import { Body } from "./typography";

export interface IconBoxProps {
  /** Text alignment. */
  align?: TextAlign;
  /** Icon name. */
  icon: IconName;
  /** Text to be displayed below the title. */
  text: string;
  /** Box title. */
  title: string;
}

//#region Styles
const iconBoxContainerStyle = (alignItems: TextAlign): CSSObject => ({
  alignItems,
  flexDirection: "column",
  ...displayFlex,
  ...paddingXMd,
});

const bodyStyle = (textAlign: TextAlign): CSSObject[] => [
  marginBottomZero,
  { textAlign },
];
//#endregion Styles

/**
 * Displays an icon box with icon, title and text.
 */
const IconBox = ({ align = "left", icon, text, title }: IconBoxProps) => {
  return (
    <div css={iconBoxContainerStyle(align)}>
      <Icon name={icon} />

      <Heading
        scale={5}
        css={marginYLg}
        align={align}
      >
        {title}
      </Heading>

      <Body css={bodyStyle(align)}>{text}</Body>
    </div>
  );
};

export default IconBox;
