import { IconSVGProps } from "../Icon";

const TeamIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <style />
    <linearGradient
      id="SVGID_1_teamwork"
      gradientUnits="userSpaceOnUse"
      x1={26.531}
      y1={16.524}
      x2={89.158}
      y2={16.524}
    >
      <stop
        offset={0}
        stopColor={secondaryColor}
      />
      <stop
        offset={1}
        stopColor={primaryColor}
      />
    </linearGradient>
    <path
      d="M89.2 22h-25v.1l-6.9 6.1c-2.8 2.6-5.3 4-9 4H45c-1.3 0-2.4-1.1-2.4-2.4 0-.7.3-1.3.7-1.7.4-.4 1-.7 1.7-.7h.5c.4 0 .8-.4.8-.8s-.3-.8-.8-.8H32.6c-1.4 0-2.6-1.1-2.5-2.5 0-.6.3-1.2.7-1.6.4-.4 1-.7 1.7-.7h7.9c.5 0 1-.4 1-1 0-.5-.4-1-1-1H29.1c-1.4 0-2.6-1.1-2.5-2.5 0-.6.3-1.2.7-1.6.4-.4 1-.7 1.7-.7h11.5c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-7.8c-1.4 0-2.6-1.1-2.5-2.5 0-.6.3-1.2.7-1.6.4-.4 1-.7 1.7-.7h7.9c.5 0 1-.4 1-1 0-.5-.4-1-1-1-1.4 0-2.6-1.1-2.5-2.5 0-.6.3-1.2.7-1.6.4-.4 1-.7 1.7-.7h8.2C70.3.9 82.8 13.7 89.2 22z"
      fill="url(#SVGID_1_teamwork)"
    />
    <path
      d="M78.5 88.7v-25h-.1l-6.1-6.9c-2.6-2.8-4-5.3-4-9v-3.3c0-1.3 1.1-2.4 2.4-2.4.7 0 1.3.3 1.7.7.4.4.7 1 .7 1.7v.5c0 .4.4.8.8.8s.8-.3.8-.8V32.1c0-1.4 1.1-2.6 2.5-2.5.6 0 1.2.3 1.6.7.4.4.7 1 .7 1.7v7.9c0 .5.4 1 1 1 .5 0 1-.4 1-1V28.6c0-1.4 1.1-2.6 2.5-2.5.6 0 1.2.3 1.6.7.4.4.7 1 .7 1.7V40c0 .5.4.9.9.9s.9-.4.9-.9v-7.8c0-1.4 1.1-2.6 2.5-2.5.6 0 1.2.3 1.6.7.4.4.7 1 .7 1.7V40c0 .5.4 1 1 1 .5 0 1-.4 1-1 0-1.4 1.1-2.6 2.5-2.5.6 0 1.2.3 1.6.7.4.4.7 1 .7 1.7V48c-.2 21.9-12.9 34.3-21.2 40.7z"
      fill={primaryColor}
    />
    <path
      d="M21.5 10.7v25h.1l6.1 6.9c2.6 2.8 4 5.3 4 9v3.3c0 1.3-1.1 2.4-2.4 2.4-.7 0-1.3-.3-1.7-.7-.4-.4-.7-1-.7-1.7v-.5c0-.4-.4-.8-.8-.8s-.8.3-.8.8v12.9c0 1.4-1.1 2.6-2.5 2.5-.6 0-1.2-.3-1.6-.7-.4-.4-.7-1-.7-1.7v-7.9c0-.5-.4-1-1-1-.5 0-1 .4-1 1v11.3c0 1.4-1.1 2.6-2.5 2.5-.6 0-1.2-.3-1.6-.7-.4-.4-.7-1-.7-1.7V59.5c0-.5-.4-.9-.9-.9s-.9.4-.9.9v7.8c0 1.4-1.1 2.6-2.5 2.5-.6 0-1.2-.3-1.6-.7-.4-.4-.7-1-.7-1.7v-7.9c0-.5-.4-1-1-1-.5 0-1 .4-1 1 0 1.4-1.1 2.6-2.5 2.5-.6 0-1.2-.3-1.6-.7-.4-.4-.7-1-.7-1.7v-8.2c.2-21.9 12.9-34.3 21.2-40.7z"
      fill={secondaryColor}
    />
    <linearGradient
      id="SVGID_00000099633022736497755660000018170485460191646904_"
      gradientUnits="userSpaceOnUse"
      x1={10.802}
      y1={83.364}
      x2={73.43}
      y2={83.364}
    >
      <stop
        offset={0}
        stopColor={secondaryColor}
      />
      <stop
        offset={1}
        stopColor={primaryColor}
      />
    </linearGradient>
    <path
      d="M10.8 77.9h25v-.1l6.9-6.1c2.8-2.6 5.3-4 9-4H55c1.3 0 2.4 1.1 2.4 2.4 0 .7-.3 1.3-.7 1.7-.4.4-1 .7-1.7.7h-.5c-.4 0-.8.4-.8.8s.3.8.8.8h12.9c1.4 0 2.6 1.1 2.5 2.5 0 .6-.3 1.2-.7 1.6-.4.4-1 .7-1.7.7h-7.9c-.5 0-1 .4-1 1 0 .5.4 1 1 1h11.3c1.4 0 2.6 1.1 2.5 2.5 0 .6-.3 1.2-.7 1.6-.4.4-1 .7-1.7.7H59.6c-.5 0-.9.4-.9.9s.4.9.9.9h7.8c1.4 0 2.6 1.1 2.5 2.5 0 .6-.3 1.2-.7 1.6-.4.4-1 .7-1.7.7h-7.9c-.5 0-1 .4-1 1 0 .5.4 1 1 1 1.4 0 2.6 1.1 2.5 2.5 0 .6-.3 1.2-.7 1.6-.4.4-1 .7-1.7.7h-8.2c-21.9-.2-34.3-12.9-40.7-21.2z"
      fill="url(#SVGID_00000099633022736497755660000018170485460191646904_)"
    />
  </svg>
);

export default TeamIcon;
