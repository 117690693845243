import React from "react";

import { CSSObject, useTheme } from "@emotion/react";

import { bgPrimary, bgTransparent } from "@Styles/backgrounds";

type MenuTogglerProps = {
  /** Whether the menu is open or not. */
  menuOpen: boolean;
  /** Function that changes the menu status. */
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Animated toggler for menus.
 */
export const MenuToggler = ({ menuOpen, setMenuOpen }: MenuTogglerProps) => {
  const { tone } = useTheme();
  const barHeight = 0.1875;

  const barCSS: CSSObject[] = [
    bgPrimary(tone),
    {
      borderRadius: "0.125rem",
      height: `${barHeight}rem`,
      position: "absolute",
      transition:
        "0.3s ease transform," +
        "0.3s ease top," +
        "0.3s ease width," +
        "0.3s ease right," +
        "0.3s ease top," +
        "0.3s ease opacity",
      width: "1.5rem",
    },
  ];

  const bar1Style: CSSObject = {
    top: 0,
    ...(menuOpen && {
      position: "absolute",
      top: "0.625rem",
      transform: "rotateZ(-45deg)",
    }),
  };

  const bar2Style: CSSObject = {
    opacity: 1,
    top: `calc(50% - ${barHeight / 2}rem)`,
    ...(menuOpen && {
      opacity: 0,
    }),
  };

  const bar3Style: CSSObject = {
    top: `${1.5 - barHeight}rem`,
    ...(menuOpen && {
      position: "absolute",
      top: "0.625rem",
      transform: "rotateZ(45deg)",
    }),
  };

  const menuTogglerCSS: CSSObject[] = [
    bgTransparent,
    {
      border: "none",
      cursor: "pointer",
      height: "1.5rem",
      outline: "0 !important",
      position: "relative",
      width: "1.5rem",
    },
  ];

  return (
    <div
      css={menuTogglerCSS}
      onClick={() => setMenuOpen((prev) => !prev)}
    >
      <div css={[barCSS, bar1Style]}></div>
      <div css={[barCSS, bar2Style]}></div>
      <div css={[barCSS, bar3Style]}></div>
    </div>
  );
};
