import { useContext } from "react";

import { CSSObject, useTheme } from "@emotion/react";

import { Button, Column, Container, Modal, Row } from "@Components";
import SignatureCounter from "@Components/SignatureCounter";
import { ModalsContext } from "@Contexts/ModalsContext";
import { bgGradient, bgWhite } from "@Styles/backgrounds";
import { displayMdNone, displayNone, displaySmBlock } from "@Styles/displays";
import { reversedElevation3 } from "@Styles/elevations";
import { paddingYSm } from "@Styles/spacers";
import { Signable } from "@Types/services/post";
import { zIndexes } from "@Variables/zIndexes";
import { useTranslation } from "react-i18next";
import SignForm from "./SignForm";

const Fixed = ({ signable }: { signable: Signable }) => {
  const { tone } = useTheme();
  const { toggleModal } = useContext(ModalsContext);

  const { t } = useTranslation();

  const CONTENT = {
    buttonLabel: t(`pages.signable.signForm.submitButtonLabel.${tone}`),
  };

  const containerStyle: CSSObject[] = [
    bgWhite,
    displayMdNone,
    reversedElevation3,
    paddingYSm,
    {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: zIndexes.fixed,
      borderRadius: 0,
    },
  ];

  const signatureCounterContainerStyle: CSSObject[] = [
    displayNone,
    displaySmBlock,
  ];

  return signable._id ? (
    <div css={containerStyle}>
      <Container>
        <Row>
          <Column
            css={signatureCounterContainerStyle}
            span={{ default: 12, sm: 6 }}
          >
            <SignatureCounter signable={signable} />
          </Column>

          <Column span={{ default: 12, sm: 6 }}>
            <Button
              fullWidth={{ default: true }}
              label={CONTENT.buttonLabel}
              onClick={() => toggleModal("signModal")}
              variant="primary"
            />
          </Column>
        </Row>
      </Container>
      <Modal
        id="signModal"
        modalCss={bgGradient(tone, "light")}
      >
        <SignForm signable={signable} />
      </Modal>
    </div>
  ) : null;
};

export default Fixed;
