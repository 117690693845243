import { useTranslation } from "react-i18next";

import Gauge from "../../emotion/components/Gauge";
import { Signable } from "../../types/services/post";
import { getCurrentSignatureCount } from "../../utils/getCurrentSignatureCount";
import { getSignatureTarget } from "../../utils/getSignatureTarget";

interface SignableGaugeProps {
  signable: Signable;
}

const SignableGauge = ({ signable }: SignableGaugeProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    progressLabel: t("components.signableCard.progressLabel"),
  };

  return (
    <Gauge
      label={CONTENT.progressLabel}
      max={getSignatureTarget(signable)}
      value={getCurrentSignatureCount(signable)}
    />
  );
};

export default SignableGauge;
