import { useContext, useEffect, useState } from "react";

import { faBoxHeart, faShareNodes } from "@fortawesome/pro-regular-svg-icons";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BodyLg,
  Button,
  ButtonContainer,
  Column,
  Container,
  ContentLoader,
  Heading,
  Illustration,
  Row,
  UserFormFields,
} from "@Components";
import { Visible } from "@Components/atoms";
import { UserContext } from "@Contexts/UserContext";

import {
  illustrationStyles,
  rowStyles,
  textStyles,
} from "@Appearances/pages/stylePSFDonation";
import { useTheme } from "@emotion/react";
import { marginBottomLg, marginBottomMdLg } from "@Styles";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import { type PSFSharedStepsProps, PSF_STEP } from "@Types/components/psf";

const DonationPage = ({ loaded, signable, signer }: PSFSharedStepsProps) => {
  const navigate = useNavigate();
  const [donor, setDonor] = useState<UserFormFields>({} as UserFormFields);
  const { t } = useTranslation();
  const { tone } = useTheme();
  const { trackEvent } = useMatomo();
  const { isAuth, user } = useContext(UserContext);

  useEffect(() => {
    if (signer && signer.email) {
      return setDonor(signer);
    }

    if (isAuth) {
      return setDonor({
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
      } as UserFormFields);
    }
  }, [isAuth, signer, user]);

  useEffect(() => {
    trackEvent({
      category: "signable",
      action: "donationLoaded",
      customDimensions: [
        {
          id: 1, // Signable ID
          value: signable._id,
        },
        {
          id: 2, // Signable model
          value: signable.type,
        },
        {
          id: 4, // Logged in
          value: isAuth ? "true" : "false",
        },
      ],
    });
  }, [isAuth, signable, trackEvent]);

  const handleDonation = () =>
    trackEvent({
      category: "signable",
      action: "startDonation",
      customDimensions: [
        {
          id: 1, // Signable ID
          value: signable._id,
        },
        {
          id: 2, // Signable model
          value: signable.type,
        },
        {
          id: 4, // Logged in
          value: isAuth ? "true" : "false",
        },
      ],
    });

  const handleShare = () => navigate(`/${signable.name}/${PSF_STEP.Share}`);

  return (
    <Container
      size="lg"
      css={marginBottomMdLg}
    >
      <Row css={rowStyles}>
        <Column
          span={{ default: 12, md: 5 }}
          offset={{ default: 0, md: 1 }}
        >
          <Heading
            scale={2}
            variant="display"
            css={[marginBottomLg]}
            color="primary"
          >
            <ContentLoader
              loading={!loaded}
              lines={2}
            >
              {t("pages.psf.donation.title")}
            </ContentLoader>
          </Heading>

          <ContentLoader
            loading={!loaded}
            lines={3}
          >
            <BodyLg css={textStyles}>{t("pages.psf.donation.text")}</BodyLg>
          </ContentLoader>

          <ButtonContainer
            align={{ default: "justify" }}
            direction={{ default: "vertical" }}
          >
            <Button
              as="a"
              href={getDonationUrl(tone, "post_signature", signable._id, donor)}
              icon={faBoxHeart}
              label={t("pages.psf.donation.buttons.donate")}
              loading={!loaded}
              onClick={handleDonation}
              variant="primary"
            />

            <Button
              icon={faShareNodes}
              label={t("pages.psf.donation.buttons.share")}
              loading={!loaded}
              onClick={handleShare}
              variant="outline"
            />
          </ButtonContainer>
        </Column>

        <Column
          span={{ default: 12, md: 4 }}
          offset={{ default: 0, md: 1 }}
        >
          <div css={illustrationStyles}>
            <Visible when={loaded}>
              <Illustration
                name="donation"
                size="lg"
              />
            </Visible>
          </div>
        </Column>
      </Row>
    </Container>
  );
};

export default DonationPage;
