import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactElement } from "react";

import { gapMd, gapSm } from "@Styles/spacers";
import type { ButtonProps } from "@Types/components/typeButton";
import {
  Breakpoint,
  breakpoints,
  setBreakpointProperties,
} from "@Variables/breakpoints";
import Button from "../Button";

const ALIGN = ["left", "center", "right", "justify"] as const;
const DIRECTION = ["horizontal", "vertical"] as const;
type Align = (typeof ALIGN)[number];
type Direction = (typeof DIRECTION)[number];

interface ToggleButtonGroupProps {
  /** Horizontal alignment of the buttons. Responsive property. */
  align?: { [key in Breakpoint]?: Align };
  /** Orientation in which the buttons are displayed. Responsive property.  */
  direction?: { [key in Breakpoint]?: Direction };
  /** Whether only one button can be selected at a time. */
  exclusive?: boolean;
  /** Button elements.  */
  children: ReactElement<ButtonProps> | Array<ReactElement<ButtonProps> | null>;
  onChange?: (value: string) => void;
  value?: string;
}

const getAlignAndDirection = (
  align: Align,
  direction: Direction,
): CSSObject => {
  let horizontalAlignment;
  let verticalAlignment;

  switch (align) {
    case "left":
      horizontalAlignment = "flex-start";
      verticalAlignment = "flex-start";
      break;
    case "justify":
      horizontalAlignment = "flex-start";
      verticalAlignment = "space-between";
      break;
    case "right":
      horizontalAlignment = "flex-end";
      verticalAlignment = "flex-end";
      break;
    default:
      horizontalAlignment = align;
      verticalAlignment = align;
      break;
  }

  const style: CSSObject = {
    flexDirection: "horizontal" === direction ? "row" : "column",
    alignItems: "horizontal" === direction ? horizontalAlignment : "stretch",
    alignContent: horizontalAlignment,
    justifyContent: "horizontal" === direction ? verticalAlignment : "stretch",
    ...("horizontal" === direction ? gapMd : gapSm),
  };

  return style;
};

const isButtonFullWidth = (align: Align, direction: Direction): boolean => {
  return "vertical" == direction && "justify" == align;
};

const StyledToggleButtonGroup = styled.div<{ css: CSSObject }>(({ css }) => ({
  display: "flex",
  flexWrap: "wrap",
  css,
}));

const ToggleButtonGroup = ({
  align = { default: "left" },
  direction = { default: "horizontal" },
  exclusive = true,
  onChange,
  children,
  value: defaultSelected,
}: ToggleButtonGroupProps) => {
  let style: CSSObject = {
    display: "flex",
    flexWrap: "wrap",
  };
  const buttonFullWidthProperty: { [key in Breakpoint]?: boolean } = {};

  for (const breakpoint of breakpoints) {
    if (align[breakpoint] && direction[breakpoint]) {
      const properties = getAlignAndDirection(
        align[breakpoint] as Align,
        direction[breakpoint] as Direction,
      );
      style = {
        ...style,
        ...setBreakpointProperties(breakpoint, properties),
      };

      buttonFullWidthProperty[breakpoint] = isButtonFullWidth(
        align[breakpoint] as Align,
        direction[breakpoint] as Direction,
      );
    }
  }

  return (
    <StyledToggleButtonGroup css={style}>
      {React.Children.map(children, (child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child as ReactElement<ButtonProps>, {
              fullWidth: buttonFullWidthProperty,
              // disabled: exclusive
              disabled: exclusive
                ? defaultSelected === child.props.value
                : child.props.active,
              onClick: (
                event: React.MouseEvent<HTMLElement, MouseEvent>,
              ): void => {
                const target = event.target as HTMLButtonElement;

                if (child.props?.onClick) {
                  child.props.onClick?.(event);
                } else {
                  onChange?.(target.value);
                }
              },
            })
          : null;
      })}
    </StyledToggleButtonGroup>
  );
};

export default Object.assign(ToggleButtonGroup, { Button });
