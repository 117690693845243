import CardColumn from "../../../emotion/components/CardColumn";
import { Container } from "../../../emotion/components/Container";
import Row from "../../../emotion/components/Row";
import { Signable } from "../../../types/services/post";
import SignableCardProfile from "@Components/SignableCardProfile";
import LazyLoader from "@Components/LazyLoader";
import { fetchPetitions } from "./fetchPetitions";

const MyPetitions = ({ initialData }: { initialData: Signable[] }) => {
  return (
    <section>
      <Container size="lg">
        <Row>
          {
            <LazyLoader
              fetchData={fetchPetitions}
              initialItems={initialData}
              renderItem={(petition: Signable) => (
                <CardColumn
                  key={`my-signatures-successes-${petition._id}`}
                  span={{ default: 12, sm: 6, md: 4 }}
                >
                  <SignableCardProfile
                    mode="edit"
                    signable={petition}
                  />
                </CardColumn>
              )}
              limit={6} // Number of items to load per page
            />
          }
        </Row>
      </Container>
    </section>
  );
};

export default MyPetitions;
