import React, { createContext, useContext, useState } from "react";

type ModalsProviderProps = {
  children: React.ReactNode;
};

type ModalsProps = string;

type ModalsContextValues = {
  openModal: string;
  modalProps: any; // Add this to store modal props
  toggleModal: (modal: string, props?: any) => void;
};

export const ModalsContext = createContext<ModalsContextValues>({
  openModal: "",
  modalProps: {},
  toggleModal: () => undefined,
});

export const useModalContext = (): ModalsContextValues => {
  const context = useContext(ModalsContext);

  if (!context) {
    throw new Error("useModalContext must be used within Modal Provider");
  }

  return context;
};

export const ModalsProvider = ({ children }: ModalsProviderProps) => {
  const [openModal, setOpenModal] = useState<ModalsProps>("");
  const [modalProps, setModalProps] = useState<any>({});
  const toggleModal = (modal: string, props: any = {}) => {
    setOpenModal((prev) => (modal == prev ? "" : modal));
    setModalProps(props);
  };

  return (
    <ModalsContext.Provider value={{ openModal, modalProps, toggleModal }}>
      {children}
    </ModalsContext.Provider>
  );
};
