import { marginBottomXl } from "@Styles";
import { SectionPageProps } from "@Types/components/pageProps";
import ModerationHeader from "./ModerationHeader";
import { ModerationQuestions } from "./ModerationQuestions"; // Assuming it's exported from a file

const Moderatos = ({ page, loaded, ...props }: SectionPageProps) => {
  return (
    <section {...props}>
      {/* Render the Header */}
      <ModerationHeader
        page={page}
        loaded={loaded}
      />

      <div css={marginBottomXl} />
      {/* Render the Questions */}
      <ModerationQuestions />
    </section>
  );
};

export default Moderatos;
