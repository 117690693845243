import { useCallback, useEffect, useState } from "react";
import Button from "./Button";
import Column from "./Column";
import Row from "./Row";
import { breakpointUp } from "@Variables/breakpoints";
import { marginXZero } from "@Styles/spacers";

interface LazyLoaderProps<T> {
  fetchData: (offset: number, limit: number) => Promise<T[]>; // Function to fetch data
  renderItem: (item: T) => JSX.Element; // Function to render each item
  limit?: number; // Limit of items per page
  initialItems?: T[]; // Optionally pass initial items to avoid the first API call
}

// eslint-disable-next-line comma-spacing
const LazyLoader = <T,>({
  fetchData,
  renderItem,
  limit = 6,
  initialItems = [],
}: LazyLoaderProps<T>) => {
  const [items, setItems] = useState<T[]>(initialItems); // Initialize with initial items if provided
  const [loading, setLoading] = useState(false); // Track loading state

  const [hasMore, setHasMore] = useState(
    initialItems.length < limit ? false : true,
  ); // Check if there are more items to load
  const [offset, setOffset] = useState(initialItems.length); // Set initial offset based on the number of initial items

  // Function to load more items
  const loadMore = useCallback(async () => {
    if (loading || !hasMore) return; // Prevent multiple API calls

    setLoading(true); // Start loading
    try {
      const newItems = await fetchData(offset, limit); // Fetch the data
      // Update state with new items and check if more items are available
      setItems((prevItems) => [...prevItems, ...newItems]);

      // If fewer items than the limit were returned, stop further loading
      if (newItems.length < limit) {
        setHasMore(false); // No more data available
      } else {
        setOffset((prevOffset) => prevOffset + limit); // Increase offset for next load
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false); // End loading
    }
  }, [offset, limit, fetchData, loading, hasMore]);

  // Load more data if initial items are not provided or incomplete
  useEffect(() => {
    if (initialItems.length === 0) {
      loadMore(); // Load initial data if no initial items are passed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  // Handle the "Show More" button click
  const handleShowMore = () => {
    if (!loading && hasMore) {
      loadMore(); // Trigger loading of more data
    }
  };

  return (
    <>
      <>{items.map((item) => renderItem(item))}</>
      <>
        {loading && <div>Loading more...</div>}

        {!loading && hasMore && (
          <Row css={{ width: "100%", ...marginXZero }}>
            <Column
              span={{ default: 12 }}
              css={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                disabled={!hasMore}
                fullWidth={{ default: true }}
                label="Show More"
                tone="petition"
                variant="primary"
                onClick={handleShowMore}
                css={{
                  maxWidth: "100%",
                  [breakpointUp["sm"]]: {
                    maxWidth: "16rem",
                  },
                }}
              />
            </Column>
          </Row>
        )}

        {/* {!hasMore && <div>No more items to load.</div>} */}
      </>
    </>
  );
};

export default LazyLoader;
