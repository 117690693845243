const v1 = "/v1";
const v2 = "/v2";
const apiRoutes = {
  baseURL: {
    root: "/api",
    v1,
    v2,
  },

  document: {
    downloadDocument: (id: string, token?: string) =>
      `${v1}/documents/${id}/download${token ? `?token=${token}` : ""}`,

    getDocuments: `${v1}/documents`,
    getSignatures: `${v1}/documents/signatures`,

    verifyDocument: (id: string) => `${v1}/documents/${id}/verify`,

    verifySignature: (id: string) => `${v1}/documents/signatures/${id}/verify`,
  },

  email: {
    subscribeNewsletter: `${v1}/email/newsletter`,
  },

  posts: {
    getFaqs: `${v1}/posts/faqs`,

    // URL for getting a specific location by ID
    getLocation: (id: string) => `${v1}/posts/locations/${id}`,

    // URL for getting multiple locations
    getLocations: `${v1}/posts/locations`,

    // URL for getting a specific page by ID
    getPage: (id: string) => `${v1}/posts/pages/${id}`,

    // URL for getting a specific post by ID
    getPost: (id: string) => `${v1}/posts/${id}`,

    // URL for getting a specific postal code by ID
    getPostalCode: (id: string) => `${v1}/posts/postal-codes/${id}`,

    // URL for getting a specific signable by ID
    getSignable: (id: string) => `${v1}/posts/initiatives/${id}`,

    // URL for getting multiple signables
    getSignables: `${v1}/posts/initiatives`,

    // URL for getting multiple signables with pagination info
    getPagiSignables: `${v2}/posts/initiatives`,

    // URL for getting multiple tags
    getTags: `${v1}/posts/tags`,

    getTestimonials: `${v1}/posts/testimonials`,

    // URL for getting a specific update by signableId and _id
    getUpdate: (signableId: string, _id: string) =>
      `${v1}/posts/initiatives/${signableId}/updates/${_id}`,

    // URL for getting multiple updates by _id with optional params
    getUpdates: (_id: string) => `${v1}/posts/initiatives/${_id}/updates`,

    // URL for getting all initiatives
    getInitiatives: `${v1}/posts/initiatives`,

    // URL for getting a specific initiative by ID
    getInitiative: (id: string) => `${v1}/posts/initiatives/${id}`,

    // URL for creating a new initiative
    createInitiative: `${v1}/posts/initiatives`,

    // URL for updating a specific initiative by ID
    updateInitiative: (id: string) => `${v1}/posts/initiatives/${id}`,

    // URL for upserting (create or update) an initiative
    upsertInitiative: (id?: string) =>
      id ? `${v1}/posts/initiatives/${id}` : `${v1}/posts/initiatives`,

    createAttachments: `${v1}/posts/attachments`,
    getActors: `${v1}/posts/actors`,
    createActor: `${v1}/posts/actors`, // URL for creating a new actor
  },

  users: {
    changePassword: (id: string) => `${v1}/users/${id}/change-password`,
    createUser: `${v1}/users`,
    generateToken: `${v1}/users/token`,
    getUser: (id: string) => `${v1}/users/${id}`,
    deleteUser: (id: string) => `${v1}/users/${id}`,
    login: `${v1}/users/login`,
    logout: `${v1}/users/logout`,
    me: `${v1}/users/me`,
    setPassword: (id: string) => `${v1}/users/${id}/set-password`,
  },
};

export default apiRoutes;
