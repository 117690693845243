import { CSSObject } from "@emotion/react";

import colors from "../variables/colors";
import themes, { Tone } from "../variables/themes";

const borders: { [key: string]: CSSObject } = {};

const baseBorder: CSSObject = {
  borderStyle: "solid",
  borderWidth: "1px",
};

for (const color in colors) {
  borders[color] = {
    ...baseBorder,
    borderColor: colors[color],
  };
}

export const borderPrimary = (theme: Tone = "petition"): CSSObject => ({
  ...baseBorder,
  borderColor: themes[theme].colors.primary,
});

export const borderSecondary = (theme: Tone = "petition"): CSSObject => ({
  ...baseBorder,
  borderColor: themes[theme].colors.secondary,
});

export const borderTransparent: CSSObject = {
  ...baseBorder,
  borderColor: "transparent",
};

export const borderOrange = borders.orange;
export const borderMauve = borders.mauve;
export const borderBlue = borders.blue;
export const borderCyan = borders.cyan;
export const borderBlack = borders.black;
export const borderWhite = borders.white;
export const borderGray1 = borders.gray1;
export const borderGray2 = borders.gray2;
export const borderGray3 = borders.gray3;
export const borderGray4 = borders.gray4;
export const borderGray5 = borders.gray5;
export const borderDanger = borders.danger;
export const borderSuccess = borders.success;
