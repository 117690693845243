import { Trans } from "react-i18next";

import { Signable } from "../../types/services/post";
import { getCurrentSignatureCount } from "../../utils/getCurrentSignatureCount";
import { getSignatureTarget } from "../../utils/getSignatureTarget";
import Progress from "./Progress";

export interface SignatureCounterProps {
  /** Signable object. */
  signable: Signable;
}

/**
 * Displays a pill-shaped badge.
 */
const SignatureCounter = ({ signable }: SignatureCounterProps) => {
  return (
    <Progress
      label={
        <Trans i18nKey="signableCard.progressLabel">
          Signatures at <strong>innn.it</strong>
        </Trans>
      }
      max={getSignatureTarget(signable)}
      value={getCurrentSignatureCount(signable)}
    />
  );
};

export default SignatureCounter;
