import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Alert, SocialMediaShare } from "@Components";
import { useBreakpoints } from "@Hooks/useBreakpoints";

interface ContactAlertProps {
  url: string;
  signableTitle: string;
}

const ContactAlert = ({ url, signableTitle }: ContactAlertProps) => {
  const { t } = useTranslation();
  const { isSmallScreen } = useBreakpoints();

  const CONTENT = {
    title: t("pages.signable.petition.contactBar.title"),
    text: t("pages.signable.petition.contactBar.text"),
  };

  return (
    <Alert
      closeButton={true}
      icon={faGlobe}
      text={CONTENT.text}
      title={CONTENT.title}
      footer={
        <SocialMediaShare
          background="dark"
          signableTitle={signableTitle}
          signableType="petition"
          url={url}
          variant="primary"
          size={isSmallScreen ? "sm" : "md"}
        />
      }
    />
  );
};

export default ContactAlert;
