import type { CSSObject } from "@emotion/react";
import {
  bgGradient,
  bgGray1,
  bgRounded,
  bgWhite,
  elevation3,
  marginBottomMd,
  marginBottomMdLg,
  marginXMdXl,
  paddingBottomZero,
  paddingLg,
  paddingTopLg,
} from "@Styles";
import type { Tone } from "@Variables";

const sectionStyle: CSSObject[] = [
  bgGray1,
  bgRounded({ ...bgWhite, ...paddingBottomZero }, false, true),
];

const rowStyle: CSSObject[] = [{ alignItems: "center" }];

const descriptionContainer: CSSObject[] = [paddingTopLg, marginXMdXl];
const illustrationContainerStyle: CSSObject[] = [marginBottomMdLg];

const headingStyle: CSSObject[] = [marginBottomMd];
const formContainer = (tone: Tone): CSSObject[] => [
  bgGradient(tone, "light"),
  elevation3,
  paddingLg,
];

export {
  descriptionContainer,
  formContainer,
  headingStyle,
  illustrationContainerStyle,
  rowStyle,
  sectionStyle,
};
