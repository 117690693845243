import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useToken } from "react-bootstrap-typeahead";
import type {
  Option,
  OptionHandler,
} from "react-bootstrap-typeahead/types/types";

type MyTokenProps = {
  onRemove?: OptionHandler;
  option: Option;
};

const TargetToken = (props: MyTokenProps) => {
  const { onRemove, ref } = useToken(props);
  const { option } = props;
  return (
    <div
      className="rbt-token rbt-token-removeable"
      ref={ref}
    >
      <div className="rbt-token-label">
        {typeof option === "string" ? option : option.name}
      </div>
      <button
        className="rbt-token-remove-button"
        onClick={(event) => {
          event.persist = () => null;
          event.preventDefault();
          if (onRemove) {
            onRemove();
          }
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </button>
    </div>
  );
};

TargetToken.displayName = "MyToken";

TargetToken.propTypes = {
  active: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default TargetToken;
