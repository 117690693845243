import { useContext } from "react";

import { UserContext } from "../../contexts/UserContext";
import DeleteSignatureModal from "./DeleteSignatureModal";
import UpdateProfileModal from "./updateProfileModal";
import UpdatePasswordModal from "./UpdatePasswordModal";

const UserProfileModals = () => {
  const { isAuth } = useContext(UserContext);

  return (
    <>
      {isAuth ? (
        <>
          {" "}
          <DeleteSignatureModal />
          <UpdateProfileModal />
          <UpdatePasswordModal />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserProfileModals;
