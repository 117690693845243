//UI
import {
  Button,
  ButtonContainer,
  Container,
  Heading,
  Hero,
  Placeholder,
} from "@Components";
import { useUserContext } from "@Contexts/UserContext";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";

//Hooks
import { useModalContext } from "@Contexts/ModalsContext";
import { useTheme } from "@emotion/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

//Configs
import { routes } from "@Configs";

//Styles
import {
  contentStyle,
  headingStyle,
  heroStyle,
  subtitleStyle,
} from "@Appearances/pages/styleHomeHero";
import { marginBottomZero } from "@Styles";

//Utils
import parse from "html-react-parser";

//Types
import type { Page } from "@Types/services/post";

interface HomeHeroProps {
  loaded: boolean;
  page: Page;
}

const HomeHero = ({ loaded, page }: HomeHeroProps) => {
  const { tone } = useTheme();

  const { isAuth } = useUserContext();

  const { toggleModal } = useModalContext();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleStartPetition = useCallback(
    () => (isAuth ? navigate(routes.petition.start) : toggleModal("getUser")),
    [isAuth],
  );

  return (
    <section>
      <Hero
        css={heroStyle}
        variant="invert"
        customStyles={[marginBottomZero]}
        bgCustomStyles={{ right: 0 }}
      >
        <Container
          size="lg"
          css={contentStyle}
        >
          <Heading
            css={headingStyle}
            scale={1}
            role="heading"
            variant="display"
            align="center"
          >
            {loaded ? page.title : <Placeholder />}
          </Heading>

          <Heading
            css={subtitleStyle}
            scale={5}
            align="center"
            color={tone === "initiative" ? "white" : "label"}
          >
            {loaded ? parse(page.content) : <Placeholder />}
          </Heading>

          <ButtonContainer
            align={{ default: "center", md: "center" }}
            direction={{ default: "vertical", md: "horizontal" }}
          >
            <Button
              icon={faMegaphone}
              label={t("common.banners.startPetition.buttonLabel")}
              variant="outline"
              onClick={handleStartPetition}
            />

            <Button
              as={Link}
              icon={faArrowDown}
              label={t("common.petitions.browse")}
              to="#highlights"
              variant="secondary"
            />
          </ButtonContainer>
        </Container>
      </Hero>
    </section>
  );
};

export default HomeHero;
