interface ConditionalWrapperProps {
  /** Element to be encapsulated. */
  children: JSX.Element;
  /** Condition to be fulfilled to apply the wrapper. */
  condition: boolean;
  /** Wrapper for the children. */
  wrapper: (children: JSX.Element) => JSX.Element;
}

/**
 * Encapsulates the children only if a condition is fulfilled.
 */
export const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: ConditionalWrapperProps): JSX.Element =>
  condition ? wrapper(children) : children;
