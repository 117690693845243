import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";
import { useModalContext } from "@Contexts/ModalsContext";
import { fontSizeXl } from "@Styles/fontSizes";
import { breakpointUp } from "@Variables/breakpoints";
import { ButtonProps } from "@Components/Button";

const UpdatePasswordModal = () => {
  const { t } = useTranslation();
  const { toggleModal } = useModalContext();

  const buttons: {
    action?: ButtonProps["onClick"];
    label: ButtonProps["label"];
    loading?: ButtonProps["loading"];
    type?: ButtonProps["type"];
    variant: ButtonProps["variant"];
  }[] = [
    {
      action: () => {
        toggleModal("UpdatePasswordModal");
      },
      label: t("pages.userAccount.account.updateProfile.modal.button.label"),
      variant: "primary",
      type: "button",
    },
  ];
  return (
    <ModalDialog
      buttons={buttons}
      titleCSS={{
        flexDirection: "row",
        alignItems: "center",
        [breakpointUp["md"]]: {
          ...fontSizeXl,
        },
      }}
      buttonContainerProps={{
        align: {
          default: "justify",
          sm: "justify",
          md: "right",
          lg: "right",
        },
      }}
      content={<></>}
      id="UpdatePasswordModal"
      title={t("pages.userAccount.account.updatePassword.modal.text")}
      icon={faCheck}
      iconColor="primary"
      titleColor="primary"
    ></ModalDialog>
  );
};

export default UpdatePasswordModal;
