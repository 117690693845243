interface Colors {
  [index: string]: string;
}

const colors: Colors = {
  orange: "#F7661E",
  mauve: "#9466F2",
  purple: "#6F38B7",
  blue: "#300FCB",
  cyan: "#70C2B7",
  teal: "#03635D",
  black: "#272729",
  white: "#FFFFFF",
  gray1: "#F3F3F3",
  gray2: "#F0F0F5",
  gray3: "#E6E6EB",
  gray4: "#BABBC2",
  gray5: "#767676",
  danger: "#FC1515",
  success: "#3DCC79",
};

export default colors;
