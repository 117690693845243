import { PAGINATION_LIMIT } from "@Configs/constants";
import type { Initiative, Testimonial, Update } from "@Types/services/post";
import type {
  CreateInitiativeParams,
  PagiAxiosResponse,
  ResponsePayload,
  SignableParams,
} from "@Types/services/typeServiceParams";
import type { AxiosResponse } from "axios";
import BaseService from "./serviceBase";
import apiRoutes from "./serviceRoutes";

const signableParams: SignableParams = {
  limit: PAGINATION_LIMIT,
  type: "petition",
};

class SignablePostService extends BaseService {
  public async getSignable<T>(id: string): Promise<AxiosResponse<T>> {
    return this.get<T>(apiRoutes.posts.getSignable(id));
  }

  public async getPagiSignables<T>(
    params: SignableParams = signableParams,
  ): Promise<PagiAxiosResponse<T>> {
    return this.get<ResponsePayload<T>>(apiRoutes.posts.getPagiSignables, {
      params,
    });
  }

  public async getSignables<T>(
    params: SignableParams = signableParams,
  ): Promise<AxiosResponse<T>> {
    return this.get<T>(apiRoutes.posts.getSignables, {
      params,
    });
  }

  public async getTags<T>(params = {}): Promise<AxiosResponse<T>> {
    return this.get<T>(apiRoutes.posts.getTags, { params });
  }

  public async getTestimonials(
    params = {},
  ): Promise<AxiosResponse<Testimonial[]>> {
    return this.get<Testimonial[]>(apiRoutes.posts.getTestimonials, {
      params,
    });
  }

  public async getUpdate(
    signableId: string,
    _id: string,
  ): Promise<AxiosResponse<Update>> {
    return this.get<Update>(apiRoutes.posts.getUpdate(signableId, _id));
  }

  public async getUpdates(
    _id: string,
    params: SignableParams = signableParams,
  ): Promise<AxiosResponse<Update[]>> {
    return this.get<Update[]>(apiRoutes.posts.getUpdates(_id), {
      params,
    });
  }

  public async getInitiatives(): Promise<AxiosResponse<Initiative[]>> {
    return this.get<Initiative[]>(apiRoutes.posts.getInitiatives);
  }

  public async getInitiative(id: string): Promise<AxiosResponse<Initiative>> {
    return this.get<Initiative>(apiRoutes.posts.getInitiative(id));
  }

  public async createInitiative(
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<Initiative>> {
    return this.post(apiRoutes.posts.createInitiative, params);
  }

  public async updateInitiative(
    id: string,
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<Initiative>> {
    return this.patch(apiRoutes.posts.updateInitiative(id), params);
  }

  public async upsertInitiative(
    id: string | null,
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<Initiative>> {
    if (id) {
      return this.updateInitiative(id, params);
    } else {
      return this.createInitiative(params);
    }
  }

  public async getPage<T>(id: string): Promise<AxiosResponse<T>> {
    return await this.get<T>(apiRoutes.posts.getPage(id));
  }
}

export default SignablePostService;
