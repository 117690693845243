import type { UserFormFields } from "@Components/UserForm";
import type { Signable } from "@Types/services/post";

export enum PSF_STEP {
  DocumentRequest = 1,
  Donation,
  Share,
}

export interface PSFSharedStepsProps {
  loaded: boolean;
  signer: UserFormFields;
  signable: Signable;
}
