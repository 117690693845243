import { CSSObject } from "@emotion/react";

import { getRfsValue } from "../helpers/rfs";
import toCamelCase from "../helpers/toCamelCase";
import { breakpoints, setBreakpointProperties } from "../variables/breakpoints";
import { fontSizes } from "../variables/scales";

const createFontSizes = () => {
  const fontSizeStyles: { [key: string]: CSSObject } = {};
  const property = "fontSize";

  for (const fontSize of Object.keys(fontSizes)) {
    const sizeName: string = toCamelCase(fontSize);
    const properties: CSSObject = {
      [property]: getRfsValue(fontSizes[fontSize]),
    };

    fontSizeStyles[property + sizeName] = properties;

    for (const breakpoint of breakpoints) {
      fontSizeStyles[
        property +
          ("default" != breakpoint ? toCamelCase(breakpoint) : "") +
          sizeName
      ] = setBreakpointProperties(breakpoint, properties);
    }
  }
  return fontSizeStyles;
};

const fontSizeStyles = createFontSizes();

export const fontSize2xs = fontSizeStyles["fontSize2xs"];
export const fontSizeXs = fontSizeStyles["fontSizeXs"];
export const fontSizeSm = fontSizeStyles["fontSizeSm"];
export const fontSizeMd = fontSizeStyles["fontSizeMd"];
export const fontSizeLg = fontSizeStyles["fontSizeLg"];
export const fontSizeXl = fontSizeStyles["fontSizeXl"];
export const fontSize2xl = fontSizeStyles["fontSize2xl"];
export const fontSize3xl = fontSizeStyles["fontSize3xl"];
export const fontSize4xl = fontSizeStyles["fontSize4xl"];
export const fontSize5xl = fontSizeStyles["fontSize5xl"];
export const fontSize6xl = fontSizeStyles["fontSize6xl"];
export const fontSize7xl = fontSizeStyles["fontSize7xl"];

export const fontSizeXs2xs = fontSizeStyles["fontSizeXs2xs"];
export const fontSizeXsXs = fontSizeStyles["fontSizeXsXs"];
export const fontSizeXsSm = fontSizeStyles["fontSizeXsSm"];
export const fontSizeXsMd = fontSizeStyles["fontSizeXsMd"];
export const fontSizeXsLg = fontSizeStyles["fontSizeXsLg"];
export const fontSizeXsXl = fontSizeStyles["fontSizeXsXl"];
export const fontSizeXs2xl = fontSizeStyles["fontSizeXs2xl"];
export const fontSizeXs3xl = fontSizeStyles["fontSizeXs3xl"];
export const fontSizeXs4xl = fontSizeStyles["fontSizeXs4xl"];
export const fontSizeXs5xl = fontSizeStyles["fontSizeXs5xl"];
export const fontSizeXs6xl = fontSizeStyles["fontSizeXs6xl"];
export const fontSizeXs7xl = fontSizeStyles["fontSizeSm7xl"];

export const fontSizeSm2xs = fontSizeStyles["fontSizeSm2xs"];
export const fontSizeSmXs = fontSizeStyles["fontSizeSmXs"];
export const fontSizeSmSm = fontSizeStyles["fontSizeSmSm"];
export const fontSizeSmMd = fontSizeStyles["fontSizeSmMd"];
export const fontSizeSmLg = fontSizeStyles["fontSizeSmLg"];
export const fontSizeSmXl = fontSizeStyles["fontSizeSmXl"];
export const fontSizeSm2xl = fontSizeStyles["fontSizeSm2xl"];
export const fontSizeSm3xl = fontSizeStyles["fontSizeSm3xl"];
export const fontSizeSm4xl = fontSizeStyles["fontSizeSm4xl"];
export const fontSizeSm5xl = fontSizeStyles["fontSizeSm5xl"];
export const fontSizeSm6xl = fontSizeStyles["fontSizeSm6xl"];
export const fontSizeSm7xl = fontSizeStyles["fontSizeSm7xl"];

export const fontSizeMd2xs = fontSizeStyles["fontSizeMd2xs"];
export const fontSizeMdXs = fontSizeStyles["fontSizeMdXs"];
export const fontSizeMdSm = fontSizeStyles["fontSizeMdSm"];
export const fontSizeMdMd = fontSizeStyles["fontSizeMdMd"];
export const fontSizeMdLg = fontSizeStyles["fontSizeMdLg"];
export const fontSizeMdXl = fontSizeStyles["fontSizeMdXl"];
export const fontSizeMd2xl = fontSizeStyles["fontSizeMd2xl"];
export const fontSizeMd3xl = fontSizeStyles["fontSizeMd3xl"];
export const fontSizeMd4xl = fontSizeStyles["fontSizeMd4xl"];
export const fontSizeMd5xl = fontSizeStyles["fontSizeMd5xl"];
export const fontSizeMd6xl = fontSizeStyles["fontSizeMd6xl"];
export const fontSizeMd7xl = fontSizeStyles["fontSizeMd7xl"];

export const fontSizeLg2xs = fontSizeStyles["fontSizeLg2xs"];
export const fontSizeLgXs = fontSizeStyles["fontSizeLgXs"];
export const fontSizeLgSm = fontSizeStyles["fontSizeLgSm"];
export const fontSizeLgMd = fontSizeStyles["fontSizeLgMd"];
export const fontSizeLgLg = fontSizeStyles["fontSizeLgLg"];
export const fontSizeLgXl = fontSizeStyles["fontSizeLgXl"];
export const fontSizeLg2xl = fontSizeStyles["fontSizeLg2xl"];
export const fontSizeLg3xl = fontSizeStyles["fontSizeLg3xl"];
export const fontSizeLg4xl = fontSizeStyles["fontSizeLg4xl"];
export const fontSizeLg5xl = fontSizeStyles["fontSizeLg5xl"];
export const fontSizeLg6xl = fontSizeStyles["fontSizeLg6xl"];
export const fontSizeLg7xl = fontSizeStyles["fontSizeLg7xl"];

export const fontSizeXl2xs = fontSizeStyles["fontSizeXl2xs"];
export const fontSizeXlXs = fontSizeStyles["fontSizeXlXs"];
export const fontSizeXlSm = fontSizeStyles["fontSizeXlSm"];
export const fontSizeXlMd = fontSizeStyles["fontSizeXlMd"];
export const fontSizeXlLg = fontSizeStyles["fontSizeXlLg"];
export const fontSizeXlXl = fontSizeStyles["fontSizeXlXl"];
export const fontSizeXl2xl = fontSizeStyles["fontSizeXl2xl"];
export const fontSizeXl3xl = fontSizeStyles["fontSizeXl3xl"];
export const fontSizeXl4xl = fontSizeStyles["fontSizeXl4xl"];
export const fontSizeXl5xl = fontSizeStyles["fontSizeXl5xl"];
export const fontSizeXl6xl = fontSizeStyles["fontSizeXl6xl"];
export const fontSizeXl7xl = fontSizeStyles["fontSizeXl7xl"];
