import type { CSSObject } from "@emotion/react";
import {
  bgGray1,
  bgRounded,
  bgWhite,
  marginBottomXl,
  marginBottomZero,
  marginTopZero,
  paddingYMdLg,
} from "@Styles";

const containerStyle: CSSObject[] = [bgGray1, bgRounded(bgWhite, true, false)];

const bannerContainerStyle: CSSObject[] = [marginBottomZero, paddingYMdLg];

const headingStyle: CSSObject[] = [
  marginBottomXl,
  marginTopZero,
  { textAlign: "center" },
];

export { bannerContainerStyle, containerStyle, headingStyle };
