/**
 * @param primaryColor
 * @param secondaryColor
 * @returns Gradient color out of two colors
 */
const linearGradient = (
  primaryColor: string,
  secondaryColor: string,
): string => {
  return `linear-gradient(86.68deg, ${primaryColor} 30%, ${secondaryColor})`;
};

export default linearGradient;
