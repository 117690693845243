import { useTranslation } from "react-i18next";

import {
  containerStyle,
  headerStyle,
} from "@Appearances/pages/styleHomeSuccesses";
import {
  BodyLg,
  Button,
  ButtonContainer,
  Column,
  Container,
  ContentLoader,
  Heading,
  Row,
  Testimonial,
} from "@Components";
import { useTestimonials } from "@Hooks/posts/useTestimonial";
import {
  marginXMd3xl,
  marginYMdXl,
  marginYXsLg,
  marginZero,
  paddingBottomMdZero,
  paddingBottomXsMd,
  paddingXMdXl,
  paddingYXl,
} from "@Styles";
import type { SharedHomeProps } from "@Types";
import { CSSObject } from "@emotion/react";

const bodyTextStyles: CSSObject[] = [
  marginYXsLg,
  marginYMdXl,
  marginXMd3xl,
  paddingXMdXl,
];

const testimonialColumnStyles: CSSObject[] = [
  paddingBottomXsMd,
  paddingBottomMdZero,
];

const Successes = (props: SharedHomeProps) => {
  const { t } = useTranslation();

  const { testimonials, loading } = useTestimonials({ limit: 2 });

  return (
    <section
      css={containerStyle}
      {...props}
    >
      <Container size="lg">
        <div css={[headerStyle]}>
          <Heading
            scale={3}
            role="heading"
            aria-level={2}
            css={[marginZero]}
            align="center"
            color="label"
          >
            <ContentLoader loading={loading}>
              {t("pages.home.successes.title")}
            </ContentLoader>
          </Heading>

          <ContentLoader loading={loading}>
            <BodyLg css={bodyTextStyles}>
              {t("pages.home.successes.text")}
            </BodyLg>
          </ContentLoader>
        </div>

        <Row css={[paddingYXl]}>
          {testimonials.map((testimonial) => (
            <Column
              key={`home-testimonial-${testimonial._id}`}
              span={{ default: 12, md: 6 }}
              css={testimonialColumnStyles}
            >
              <Testimonial
                alt={testimonial.author}
                attachment={testimonial.featuredImage}
                link={testimonial.url}
                content={testimonial.content}
                quote={testimonial.title}
                quoteAuthor={testimonial.author || ""}
                quoteAuthorDescription={testimonial.authorDescription}
                tag={testimonial.label}
              />
            </Column>
          ))}
        </Row>

        <ButtonContainer
          align={{ default: "center" }}
          direction={{ default: "horizontal" }}
        >
          <Button
            as="a"
            href="https://verein.innn.it/erfolge/"
            label={t("pages.home.successes.buttonLabel")}
            variant="primary"
          />
        </ButtonContainer>
      </Container>
    </section>
  );
};

export default Successes;
