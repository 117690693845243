import React, { useContext, useEffect, useState } from "react";

import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import isEmpty from "validator/lib/isEmpty";

import { ModalsContext } from "../../contexts/ModalsContext";
import {
  UnauthUser,
  UserContext,
  UserDispatchContext,
  login,
} from "../../contexts/UserContext";
import FormControl from "../../emotion/components/FormControl";
import ModalDialog from "../../emotion/components/ModalDialog";
import { BodySm, Link } from "../../emotion/components/typography";
import {
  ValidationMessage,
  isValid,
  validate,
} from "../../emotion/helpers/validation";

const LoginModal = ({
  unauthUser,
  setUnauthUser,
}: {
  unauthUser: UnauthUser;
  setUnauthUser: React.Dispatch<React.SetStateAction<UnauthUser>>;
}) => {
  const { t } = useTranslation();
  const { openModal, toggleModal } = useContext(ModalsContext);
  const dispatch = useContext(UserDispatchContext);
  const { error, isAuth, isLoading } = useContext(UserContext);
  const [validation, setValidation] = useState({} as ValidationMessage);
  const validationRules = {
    password: [
      {
        message: t("components.userForm.validation.password.notEmpty"),
        valid: !!unauthUser.password && !isEmpty(unauthUser.password),
      },
    ],
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setValidation({} as ValidationMessage);

    const validation = validate(validationRules);

    if (!isValid(validation)) {
      return setValidation(validation.password);
    }

    if (unauthUser.email && unauthUser.password) {
      login(unauthUser, dispatch);
    }
  };

  useEffect(() => {
    if (error?.response) {
      setValidation({
        message:
          error.response?.status == 401
            ? t("components.userForm.validation.submit.unauthorized")
            : t("components.userForm.validation.submit.error"),
        valid: false,
      });
    }
  }, [error, t]);

  useEffect(() => {
    if (isAuth && "login" == openModal) {
      toggleModal("login");
    }
  }, [isAuth, openModal, toggleModal]);

  return (
    <ModalDialog
      buttons={[
        {
          action: () => toggleModal("getUser"),
          label: t("common.navigation.back") as string,
          variant: "outline",
        },
        {
          action: handleSubmit,
          label: t("common.navigation.next") as string,
          loading: isLoading,
          variant: "primary",
        },
      ]}
      content={
        <>
          <p>
            <Trans
              i18nKey="pages.userAccount.modals.login.content"
              values={{ email: unauthUser.email }}
            >
              {" "}
              <strong></strong>
            </Trans>
          </p>

          <form onSubmit={handleSubmit}>
            <FormControl
              disabled={isLoading}
              label={t("components.userForm.fields.password") as string}
              name="password"
              type="password"
              value={unauthUser.password}
              setValue={setUnauthUser}
              validation={validation}
            />
          </form>

          <BodySm>
            <Link
              href="#"
              onClick={(event) => {
                event.preventDefault();
                toggleModal("passwordResetRequest");
              }}
            >
              {t("pages.userAccount.forgottenPassword")}
            </Link>
          </BodySm>
        </>
      }
      icon={faRightToBracket}
      id="login"
      title={t("pages.userAccount.modals.login.title")}
    ></ModalDialog>
  );
};

export default LoginModal;
