//UI
import {
  BodyLg,
  Button,
  ButtonContainer,
  Column,
  Container,
  Heading,
  IconBox,
  Row,
} from "@Components";
import { Link } from "react-router-dom";

//Hooks
import { useTranslation } from "react-i18next";

//Styles
import {
  aboutContentStyle,
  aboutHeadingStyle,
  aboutIconBoxContainerStyle,
  aboutIconBoxContentStyle,
  aboutIconBoxStyle,
  aboutSectionStyle,
} from "@Appearances/pages/styleHomeAbout";

//Configs
import { routes } from "@Configs";
import React from "react";

//Types
import type { SharedHomeProps } from "@Types";

type Items = "support" | "donation" | "security" | "scale";

type ContentType = {
  title: string;
  text: string;
  items: {
    [key in Items]: {
      icon: React.ComponentProps<typeof IconBox>["icon"];
      title: string;
      text: string;
    };
  };
};

const AboutUs = (props: SharedHomeProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "pages.home.aboutUs" });

  return (
    <section
      css={[aboutSectionStyle]}
      {...props}
    >
      <Container size="lg">
        <Row>
          <Column span={{ default: 12, md: 6 }}>
            <Heading
              css={aboutHeadingStyle}
              scale={3}
              aria-level={2}
              role="heading"
            >
              {t(CONTENT.title)}
            </Heading>
          </Column>

          <Column span={{ default: 12, md: 6 }}>
            <BodyLg css={aboutContentStyle}>{t(CONTENT.text)}</BodyLg>
          </Column>
        </Row>

        <Row css={aboutIconBoxContainerStyle}>
          {Object.keys(CONTENT.items).map((key) => (
            <Column
              css={aboutIconBoxStyle}
              key={key}
              span={{ default: 12, sm: 6, md: 3 }}
            >
              <div css={aboutIconBoxContentStyle}>
                <IconBox
                  align="center"
                  icon={CONTENT.items[key as keyof typeof CONTENT.items].icon}
                  title={t(
                    CONTENT.items[key as keyof typeof CONTENT.items].title,
                  )}
                  text={t(
                    CONTENT.items[key as keyof typeof CONTENT.items].text,
                  )}
                />
              </div>
            </Column>
          ))}
        </Row>

        <ButtonContainer
          align={{ default: "center" }}
          direction={{ default: "horizontal" }}
        >
          <Button
            as={Link}
            to={routes.about}
            label={t("buttonLabel")}
            variant="primary"
          />
        </ButtonContainer>
      </Container>
    </section>
  );
};

export default AboutUs;

const CONTENT: ContentType = {
  title: "title",
  text: "text",
  items: {
    support: {
      icon: "team",
      title: "items.support.title",
      text: "items.support.text",
    },
    donation: {
      icon: "donation",
      title: "items.donation.title",
      text: "items.donation.text",
    },
    security: {
      icon: "dataSecurity",
      title: "items.security.title",
      text: "items.security.text",
    },
    scale: {
      icon: "activism",
      title: "items.scale.title",
      text: "items.scale.text",
    },
  },
};
