import { CSSObject } from "@emotion/react";

import toCamelCase from "../helpers/toCamelCase";
import { fontWeights } from "../variables/fontWeights";

const createFontWeights = () => {
  const fontWeightStyles: { [key: string]: CSSObject } = {};
  const property = "fontWeight";

  for (const fontWeight of Object.keys(fontWeights)) {
    const sizeName: string = toCamelCase(fontWeight);
    const properties: CSSObject = {
      [property]: fontWeights[fontWeight],
    };

    fontWeightStyles[property + sizeName] = properties;
  }
  return fontWeightStyles;
};

const fontWeightStyles = createFontWeights();

export const fontWeightRegular = fontWeightStyles["fontWeightRegular"];
export const fontWeightMedium = fontWeightStyles["fontWeightMedium"];
export const fontWeightBold = fontWeightStyles["fontWeightBold"];
