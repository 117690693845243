import { CSSObject } from "@emotion/react";

import {
  fontSize2xl,
  fontSize3xl,
  fontSize5xl,
  fontSize6xl,
  fontSizeXl,
} from "./fontSizes";
import { fontWeightBold } from "./fontWeights";
import { marginYZero } from "./spacers";
import { displayFontFamily } from "../global/fonts";
import { lineHeights } from "../variables/scales";

const displayHeadingStyle: CSSObject[] = [
  fontWeightBold,
  marginYZero,
  {
    fontFamily: displayFontFamily,
    letterSpacing: ".01em",
    lineHeight: lineHeights.sm,
  },
];

export const displayH1Style: CSSObject[] = [
  ...displayHeadingStyle,
  fontSize6xl,
];

export const displayH2Style: CSSObject[] = [
  ...displayHeadingStyle,
  fontSize5xl,
];

export const displayH3Style: CSSObject[] = [
  ...displayHeadingStyle,
  fontSize3xl,
];

export const displayH4Style: CSSObject[] = [
  ...displayHeadingStyle,
  fontSize2xl,
];

export const displayH5Style: CSSObject[] = [...displayHeadingStyle, fontSizeXl];
