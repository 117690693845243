import { MouseEventHandler } from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TipProps {
  text: string;
  icon: IconProp;
  onClick: MouseEventHandler<HTMLDivElement>;
}
const Tip = ({ text, icon, onClick }: TipProps) => {
  const { colors } = useTheme();

  const StyledSpan = styled.span({
    fontSize: 12,
    fontWeight: "500",
    color: colors.secondary.main,
  });

  return (
    <div
      id="tip"
      css={{
        alignItems: "center",
        display: "inline-flex",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={icon}
        color={colors.secondary.main}
        size="sm"
        cursor="pointer"
        fixedWidth
      />
      <StyledSpan>{text}</StyledSpan>
    </div>
  );
};

export default Tip;
