import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import {
  bgGray1,
  bgWhite,
  bodyLgStyle,
  colorOrange,
  colorPrimary,
  displayBlock,
  displayFlex,
  elevation3,
  fontWeightBold,
  marginBottomZero,
  marginNegTopMd,
  marginTopMd,
  marginXMd,
  marginXZero,
  marginYZero,
  marginZero,
  paddingLeftXl,
  paddingLg,
  paddingTopZero,
  paddingXLg,
  paddingYMd,
} from "@Styles";
import type {
  MenuItemContentProps,
  MenuItemProps,
  MenuProps,
} from "@Types/components/typeMenu";
import { breakpointUp, navBarHeight, zIndexes } from "@Variables";
import themes, { type Tone } from "@Variables/themes";
import { rotateAnimation } from "../../../animations/rotate";
import { Visible } from "../../atoms";

//#region MenuItems Styles
const itemCSS: CSSObject[] = [
  marginZero,
  {
    listStyleType: "none",
    textDecoration: "none",
  },
];

const itemLinkCSS = (tone: Tone): CSSObject[] => {
  return [
    colorPrimary(tone),
    marginZero,
    paddingXLg,
    paddingYMd,
    bodyLgStyle,
    displayFlex,

    {
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      position: "relative",
      textDecoration: "none",

      "&:after": [
        marginXMd,
        displayBlock,
        {
          borderBottom: `1px solid ${themes[tone].colors.secondary}`,
          bottom: 0,
          content: "''",
          left: 0,
          right: 0,
          position: "absolute",
        },
      ],

      "&.active": [bgWhite, fontWeightBold],

      ":hover, :active, :focus": [colorOrange, bgWhite, fontWeightBold],
    },
  ];
};

const itemSubmenuStyle = (submenuHeight: number): CSSObject => ({
  margin: 0,
  overflow: "auto",
  transition: "height 250ms ease-out, padding 250ms ease-out",
  "&.open": { height: submenuHeight },
  "&.close": { height: 0, padding: 0 },
  "& a": paddingLeftXl,
});

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.main};
`;
const submenuIconStyle: CSSObject = rotateAnimation;
//#endregion MenuItems Styles
const MenuItem = ({ blank, label, url, items, setMenuOpen }: MenuItemProps) => {
  const { tone } = useTheme();

  const { pathname, hash } = useLocation();

  const submenuRef = useRef<HTMLUListElement>(null);

  const [submenuHeight, setSubmenuHeight] = useState(0);

  const [submenuOpen, setSubmenuOpen] = useState(false);

  const itemUrl = !url || items?.length ? "" : url;

  useEffect(() => {
    if (submenuRef.current) {
      setSubmenuHeight(submenuRef.current.scrollHeight);
    }
  }, [submenuRef, setSubmenuHeight]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    if (!itemUrl) {
      event.preventDefault();
      setSubmenuOpen((prev) => !prev);
    } else {
      setMenuOpen(false);
    }
  };

  const isLinkActive = useMemo(
    () => (url === pathname + hash ? "active" : ""),
    [pathname, hash],
  );

  return (
    <li
      css={itemCSS}
      key={label}
    >
      <StyledLink
        css={itemLinkCSS(tone)}
        className={isLinkActive}
        onClick={handleMenuClick}
        rel={blank ? "noopener noreferrer" : ""}
        target={blank ? "_blank" : ""}
        to={itemUrl}
        color="primary"
      >
        {label}

        {items?.length && (
          <FontAwesomeIcon
            className={submenuOpen ? "rotate" : ""}
            css={submenuIconStyle}
            fixedWidth
            icon={faChevronDown}
            size="xl"
          />
        )}
      </StyledLink>

      {items?.length && (
        <ul
          css={itemSubmenuStyle(submenuHeight)}
          className={submenuOpen ? "open" : "close"}
          ref={submenuRef}
        >
          {items?.map((item: MenuItemContentProps) => (
            <MenuItem
              blank={item.blank}
              key={item.label}
              label={item.label}
              url={item.url}
              tone={item.tone}
              items={item.items}
              setMenuOpen={setMenuOpen}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

/**
 * Dropdown menu for navigation bar.
 */

//#region Menu Styles

const containerCSS = (navBarHeight: string): CSSObject => ({
  overflow: "auto",
  pointerEvents: "none",
  position: "fixed",
  marginTop: navBarHeight,
  right: 0,
  top: 0,
  transition: "height 500ms ease-out, padding 500ms ease-out",
  width: "100%",
  zIndex: zIndexes.dropdown,
  [breakpointUp["md"]]: {
    right: "2.5rem",
    width: "25rem",
  },
  "&.open": { height: `calc(100vh - ${navBarHeight})` },
  "&.close": { height: 0, padding: 0 },
});

const menuCSS: CSSObject[] = [
  bgWhite,
  elevation3,
  marginXZero,
  marginYZero,
  marginNegTopMd,
  paddingTopZero,
  paddingYMd,
  {
    pointerEvents: "all",
  },
];

const headerStyle: CSSObject[] = [
  bgGray1,
  marginBottomZero,
  paddingLg,
  bodyLgStyle,
  displayFlex,
  {
    justifyContent: "space-between",
  },
];

const extraContentItemCSS: CSSObject[] = [
  marginTopMd,
  marginBottomZero,
  marginXMd,
  {
    listStyle: "none",
  },
];
//#endregion Menu Styles
export const Menu = ({
  content,
  menuOpen,
  setMenuOpen,
  preContent,
  postContent,
  header,
}: MenuProps) => {
  const { colors } = useTheme();
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div
      css={containerCSS(navBarHeight)}
      className={menuOpen ? "open" : "close"}
      ref={menuContainerRef}
    >
      <ul
        css={menuCSS}
        ref={menuRef}
      >
        {header && (
          <li css={headerStyle}>
            <span>{header}</span>

            <a
              css={{ color: colors.primary.main }}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setMenuOpen(false);
              }}
            >
              <FontAwesomeIcon
                icon={faChevronUp}
                fixedWidth
              />
            </a>
          </li>
        )}

        <Visible when={preContent?.length}>
          {React.Children.map(preContent, (child) => {
            if (React.isValidElement(child)) {
              return (
                <li css={[extraContentItemCSS, child.props.css]}>
                  {React.cloneElement(child)}
                </li>
              );
            }
          })}
        </Visible>

        {content.map((item) => (
          <MenuItem
            blank={item.blank}
            key={item.label}
            label={item.label}
            url={item.url}
            tone={item.tone}
            items={item.items}
            setMenuOpen={setMenuOpen}
          />
        ))}

        <Visible when={postContent?.length}>
          {React.Children.map(postContent, (child) => {
            if (React.isValidElement(child)) {
              return (
                <li css={[extraContentItemCSS, child.props.css]}>
                  {React.cloneElement(child)}
                </li>
              );
            }
          })}
        </Visible>
      </ul>
    </div>
  );
};
