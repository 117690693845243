import React, { useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { faChevronDown, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Locale, formatDistanceToNow } from "date-fns";
import { de, enUS } from "date-fns/locale";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { Update } from "../../types/services/post";
import { rotateAnimation } from "../animations/rotate";
import i18n from "../i18n";
import { fontWeightMedium } from "../styles/fontWeights";
import {
  marginBottomZero,
  marginRightSm,
  marginTopLg,
  marginTopMdZero,
  marginTopSm,
} from "../styles/spacers";
import { colorPrimary, colorSecondary } from "../styles/textColors";
import { linkStyle } from "../styles/typography";
import { breakpointUp } from "../variables/breakpoints";
import colors from "../variables/colors";
import { Card, CardContent, CardHeader } from "./Card";
import EllipsedText from "./EllipsedContent";
import Headline from "./Headline";
import { Body } from "./typography";

export interface UpdateCardProps {
  /** Title of the signable to which the update belongs */
  signableTitle: string;
  /** An object from the updates API */
  update: Update;
}

const locales: { [key: string]: Locale } = {
  de,
  en: enUS,
};

const UpdateCard = ({ signableTitle, update }: UpdateCardProps) => {
  const { tone } = useTheme();
  const [contentOpen, setContentOpen] = useState(false);
  const { t } = useTranslation();

  const footerCSS: CSSObject[] = [
    marginTopLg,
    {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "space-between",

      [breakpointUp["md"]]: {
        flexDirection: "row",
      },
    },
  ];

  const authorName = update?.customAuthor?.name || signableTitle;

  const authorStyle: CSSObject = {
    display: "flex",
    alignItems: "center",
  };

  const authorIconStyle: CSSObject[] = [colorSecondary(tone), marginRightSm];

  const authorNameStyle: CSSObject[] = [marginBottomZero];
  const authorDateStyle: CSSObject[] = [marginBottomZero];

  const moreContainerStyle: CSSObject[] = [marginTopSm, marginTopMdZero];
  const moreStyle: CSSObject[] = [linkStyle(tone), fontWeightMedium];

  const moreIconStyle: CSSObject[] = [
    colorPrimary(tone),
    marginRightSm,
    rotateAnimation,
  ];

  const handleMoreClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    return setContentOpen((prev) => !prev);
  };

  return (
    <Card>
      <CardHeader
        alt={update.title}
        attachment={update.featuredImage}
        size="xl"
        thumbnail={true}
      ></CardHeader>
      <CardContent>
        <Headline
          title={update.title}
          titleColor={colors.black}
          titleEllipsis={true}
          text={
            <EllipsedText
              text={
                contentOpen
                  ? parse(update.content)
                  : update.content.replace(/(<([^>]+)>)/gi, "")
              }
              lineClamp={contentOpen ? {} : { default: 4, md: 3 }}
            />
          }
        />
        <div css={footerCSS}>
          <div css={authorStyle}>
            <FontAwesomeIcon
              icon={faUserGroup}
              css={authorIconStyle}
              fixedWidth
            />
            <span>
              <Body css={authorNameStyle}>{authorName}</Body>

              {update.createdAt && (
                <small css={authorDateStyle}>
                  {formatDistanceToNow(new Date(update.createdAt), {
                    addSuffix: true,
                    locale: locales[i18n.language],
                  })}
                </small>
              )}
            </span>
          </div>
          <div css={moreContainerStyle}>
            <a
              css={moreStyle}
              href="#"
              onClick={handleMoreClick}
            >
              <span>
                <FontAwesomeIcon
                  className={contentOpen ? "rotate" : ""}
                  css={moreIconStyle}
                  icon={faChevronDown}
                  fixedWidth
                />
              </span>
              {contentOpen ? t("common.more.showLess") : t("common.more.show")}
            </a>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UpdateCard;
