import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faGlobe,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";
import { SignablePageElementProps } from ".";
import { IconLi } from "../../../emotion/components/typography";

const ContactTab = ({ signable }: SignablePageElementProps) => {
  const { tone } = useTheme();

  const { t } = useTranslation();
  return signable.type === "initiative" &&
    typeof signable.contact === "object" ? (
    <ul>
      {signable.contact.address && (
        <IconLi
          icon={faMapMarkerAlt}
          label={t(`pages.signable.${tone}.tabs.contact.address`) as string}
          variant="primary"
        >
          <span style={{ whiteSpace: "pre-wrap" }}>
            {signable.contact.address}
          </span>
        </IconLi>
      )}

      {signable.contact.phone && (
        <IconLi
          icon={faPhone}
          label={t(`pages.signable.${tone}.tabs.contact.phone`) as string}
          variant="primary"
        >
          <a href={`tel:${signable.contact.phone}`}>{signable.contact.phone}</a>
        </IconLi>
      )}

      {signable.contact.url && (
        <IconLi
          icon={faGlobe}
          label={t(`pages.signable.${tone}.tabs.contact.site`) as string}
          variant="primary"
        >
          <a
            href={signable.contact.url}
            rel="noreferrer"
            target="_blank"
          >
            {signable.contact.url}
          </a>
        </IconLi>
      )}

      {signable.contact.email && (
        <IconLi
          icon={faEnvelope}
          label={t(`pages.signable.${tone}.tabs.contact.email`) as string}
          variant="primary"
        >
          <a href={`mailto:${signable.contact.email}`}>
            {signable.contact.email}
          </a>
        </IconLi>
      )}

      {signable.contact.facebook && (
        <IconLi
          icon={faFacebook}
          label={t(`pages.signable.${tone}.tabs.contact.facebook`) as string}
          variant="primary"
        >
          <a
            href={`https://facebook.com/${signable.contact.facebook}`}
            rel="noreferrer"
            target="_blank"
          >
            /{signable.contact.facebook}
          </a>
        </IconLi>
      )}

      {signable.contact.twitter && (
        <IconLi
          icon={faXTwitter}
          label={t(`pages.signable.${tone}.tabs.contact.x`) as string}
          variant="primary"
        >
          <a
            href={`https://x.com/${signable.contact.twitter}`}
            rel="noreferrer"
            target="_blank"
          >
            @{signable.contact.twitter}
          </a>
        </IconLi>
      )}

      {signable.contact.instagram && (
        <IconLi
          icon={faInstagram}
          label={t(`pages.signable.${tone}.tabs.contact.instagram`) as string}
          variant="primary"
        >
          <a
            href={`https://instagram.com/${signable.contact.instagram}`}
            rel="noreferrer"
            target="_blank"
          >
            @{signable.contact.instagram}
          </a>
        </IconLi>
      )}
    </ul>
  ) : null;
};

export default ContactTab;
