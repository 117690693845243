import React from "react";

import { CSSObject } from "@emotion/react";

import { elevation3 } from "../styles/elevations";
import { marginBottomLg, paddingLg } from "../styles/spacers";
import AttachmentImage, { AttachmentProps } from "./AttachmentImage";

export const CardHeader = ({
  alt,
  attachment,
  children,
  href,
  size,
}: React.PropsWithChildren<AttachmentProps>) => {
  const style: CSSObject = {
    aspectRatio: 3 / 2,
    overflow: "hidden",
    position: "relative",
  };

  const attachmentContainerStyle: CSSObject = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <div css={style}>
      <div css={attachmentContainerStyle}>
        <AttachmentImage
          alt={alt}
          attachment={attachment}
          href={href}
          size={size}
          thumbnail
        />
      </div>
      {children}
    </div>
  );
};

export const CardContent = ({ children }: React.PropsWithChildren) => {
  const style: CSSObject[] = [
    paddingLg,
    { display: "flex", flexDirection: "column", flexGrow: 1 },
  ];
  return <div css={style}>{children}</div>;
};

/**
 * Basic card element.
 */
export const Card = ({ children, ...props }: React.PropsWithChildren) => {
  const style: CSSObject[] = [
    elevation3,
    marginBottomLg,
    { display: "flex", flexDirection: "column", overflow: "hidden" },
  ];

  return (
    <div
      css={style}
      {...props}
    >
      {children}
    </div>
  );
};
