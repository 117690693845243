import Button from "@Components/Button";
import Column, { ColumnNumber } from "@Components/Column";
import FormControl from "@Components/FormControl";
import { Heading } from "@Components/Heading";
import Row from "@Components/Row";
import {
  UserContextAction,
  useUserContext,
  useUserDispatchContext,
} from "@Contexts/UserContext";

import { CSSObject } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  marginBottomZero,
  marginTopLg,
  marginTopSm,
  marginYLg,
} from "@Styles/spacers";
import api from "@Utils/api";
import { Breakpoint } from "@Variables/breakpoints";
// import { Theme } from "@Variables/themes";
import { ModalsContext } from "@Contexts/ModalsContext";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import getCountries from "@Stylize/helpers/getCountries";
import { Tone } from "@Variables/themes";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface FormContentRow {
  formType: "form" | "button" | "text";
  type: string;
  label: string;
  name: string;
  span: { [key in Breakpoint]?: ColumnNumber };
  options?: {
    value: string;
    label: string;
  }[];
  value?: string;
  fullWidth?: { default: boolean };
  // theme?: Theme;
  variant?: "primary";
  placeholder?: string;
  content?: React.ReactNode;
  css?: CSSObject;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  onKeyUp?: true;
  hasHiddenValue?: boolean;
  hiddenValue?: string;
  canBeHidden?: boolean;
  tone?: Tone;
}

interface updateProfileState {
  firstName: string;
  lastName: string;
  country: string;
  location: string;
  locationValue: string;
  postalCode: string;
  postalCodeValue: string;
}

export const UpdateProfile = () => {
  const { i18n, t } = useTranslation();
  const { isAuth, user, isLoading } = useUserContext();
  const dispatch = useUserDispatchContext(); // Get the dispatch function
  const { toggleModal } = useContext(ModalsContext);

  const [userProfileFields, setUserProfileFields] =
    useState<updateProfileState>({
      firstName: "",
      lastName: "",
      country: "",
      location: "",
      locationValue: "",
      postalCode: "",
      postalCodeValue: "",
    });

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);

  const FORM_CONTENT_ROWS: FormContentRow[][] = [
    [
      {
        formType: "form",
        type: "text",
        label: t(
          "pages.userAccount.account.updateProfile.form.firstNameInput.label",
        ),
        placeholder: userProfileFields.firstName,
        value: userProfileFields.firstName,
        setValue: setUserProfileFields,
        name: "firstName",
        span: { default: 6 },
        css: { paddingRight: 0 },
      },
      {
        formType: "form",
        type: "text",
        label: t(
          "pages.userAccount.account.updateProfile.form.lastNameInput.label",
        ),
        placeholder: userProfileFields.lastName,
        value: userProfileFields.lastName,
        setValue: setUserProfileFields,
        name: "lastName",
        span: { default: 6 },
      },
    ],
    [
      {
        formType: "form",
        name: "country",
        type: "select",
        label: t(
          "pages.userAccount.account.updateProfile.form.countryInput.label",
        ),
        placeholder: userProfileFields.country,
        value: userProfileFields.country,
        setValue: setUserProfileFields,
        span: { default: 12 },
      },
    ],
    [
      {
        formType: "form",
        name: "postalCode",
        type: "text",
        label: t(
          "pages.userAccount.account.updateProfile.form.postalCodeInput.label",
        ),
        placeholder: userProfileFields.postalCode,
        value: userProfileFields.postalCode,
        setValue: setUserProfileFields,
        span: { default: 5 },
        css: { paddingRight: 0 },
        onKeyUp: true,
        canBeHidden: true,
      },
      {
        formType: "form",
        name: "location",
        placeholder: userProfileFields.location,
        value: userProfileFields.location,
        setValue: setUserProfileFields,
        type: "text",
        label: t(
          "pages.userAccount.account.updateProfile.form.locationInput.label",
        ),
        span: { default: 7 },
        canBeHidden: true,
      },
    ],
    [
      {
        formType: "button",
        name: "submit",
        type: "button",
        fullWidth: { default: true },
        label: t(
          "pages.userAccount.account.updateProfile.form.saveChangesButton.label",
        ),
        tone: "petition",
        variant: "primary",
        span: { default: 12 },
      },
    ],
    [
      {
        formType: "text",
        name: "text",
        type: "text",
        content: (
          <p css={{ ...marginTopLg }}>
            {t(
              "pages.userAccount.account.updateProfile.form.dataProtectionSection.text",
            )}{" "}
            <br />{" "}
            <a href="">
              {t(
                "pages.userAccount.account.updateProfile.form.dataProtectionSection.link",
              )}
            </a>
          </p>
        ),
        label: "",
        span: { default: 12 },
      },
    ],
  ];

  // Initialize profile fields when the component mounts or when user data changes
  useEffect(() => {
    if (user && isAuth) {
      const initializeProfileFields = async () => {
        try {
          let postalCodeResult, locationResult;
          if (user.location && user.postalCode) {
            const postalCodeResponse = await api.getPostalCode(user.postalCode);
            postalCodeResult = postalCodeResponse.data;
            const locationResponse = await api.getLocation(user.location);
            locationResult = locationResponse.data;
          }

          const countries = getCountries(i18n.language);
          setCountries(countries);

          setUserProfileFields({
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            country: user.country || "",
            location: locationResult?.name || "",
            locationValue: locationResult?._id || "",
            postalCode: postalCodeResult?.postalCode || "",
            postalCodeValue: postalCodeResult?._id || "",
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      initializeProfileFields();
    }
  }, [user, isAuth, i18n.language]);

  const handleSubmit: MouseEventHandler = async (e) => {
    e.preventDefault();
    setSaving(true);

    if (user?._id) {
      const updatedUserData = {
        ...userProfileFields,
        location: userProfileFields?.locationValue,
        postalCode: userProfileFields?.postalCodeValue,
      };

      try {
        await api.updateProfile(user._id, {
          ...updatedUserData,
        });

        // Dispatch the updated user data to update the context
        dispatch({
          type: UserContextAction.UPDATE_USER,
          payload: {
            user: { ...user, ...updatedUserData },
            isAuth,
            isLoading,
          },
        });

        toggleModal("UpdateProfileModal");
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    }
  };

  // Handle updates to country and postal code
  useEffect(() => {
    const params = {
      country: userProfileFields.country,
      postalCode: userProfileFields.postalCode,
      withPostalCodes: true,
    };

    const getLocation = async () => {
      try {
        const result = await api.getLocations(params);
        if (result.data && result.data.length > 0) {
          const location = result.data[0];
          setUserProfileFields((prev) => ({
            ...prev,
            location: location?.name,
            locationValue: location?._id,
            postalCodeValue:
              location && location.postalCodes?.length
                ? location.postalCodes[0]._id
                : "",
          }));
        }
      } catch (error) {
        setUserProfileFields((prev) => ({
          ...prev,
          location: "Ort",
        }));
        console.error("Location with this postal code is not found");
      }
    };

    if (
      userProfileFields.postalCode.length >= 5 &&
      userProfileFields.country === "DE"
    ) {
      getLocation();
    }

    if (userProfileFields.country !== "DE") {
      setUserProfileFields((prev) => ({
        ...prev,
        location: "",
        locationValue: "",
        postalCode: "",
        postalCodeValue: "",
      }));
    }
  }, [userProfileFields.postalCode, userProfileFields.country]);

  return (
    <>
      <Row>
        <Column>
          <Heading
            scale={5}
            css={marginYLg}
          >
            {t("pages.userAccount.account.updateProfile.title")}
          </Heading>
        </Column>
      </Row>

      {/* Conditional rendering of form content */}
      {loading ? (
        <section
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            fixedWidth
            fontSize={30}
            aria-label={t("common.loading") as string}
          />
          <p css={{ ...marginBottomZero, ...marginTopSm }}>
            {t("common.loading") as string} ...
          </p>
        </section>
      ) : (
        FORM_CONTENT_ROWS.map((row, index) => (
          <section
            css={{ ...marginTopSm }}
            key={index}
          >
            <Row>
              {row?.map((item) => {
                if (item?.canBeHidden && userProfileFields?.country !== "DE") {
                  return null;
                }

                return (
                  <Column
                    key={item?.name}
                    span={item?.span}
                    css={{ ...item?.css }}
                  >
                    {item?.formType === "form" ? (
                      <FormControl
                        setValue={item?.setValue}
                        type={item?.type}
                        label={item.label}
                        name={item?.name}
                        value={item?.value}
                        options={countries}
                        hasHiddenValue={item?.hasHiddenValue}
                        hiddenValue={item?.hiddenValue}
                        disabled={item?.name === "location"}
                      />
                    ) : item?.formType === "button" ? (
                      <Button
                        fullWidth={item?.fullWidth}
                        tone="petition"
                        variant="primary"
                        label={item?.label}
                        onClick={handleSubmit}
                        loading={saving}
                      />
                    ) : (
                      item?.content
                    )}
                  </Column>
                );
              })}
            </Row>
          </section>
        ))
      )}
    </>
  );
};
