import { useContext, useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { routes } from "@Configs";
import { AUTH_ACTIONS, AuthActions, AuthContext } from "@Contexts/AuthContext";
import { UserContext, UserDispatchContext, login } from "@Contexts/UserContext";

const Authentication = () => {
  const { setAuth } = useContext(AuthContext);
  const dispatch = useContext(UserDispatchContext);
  const { isAuth, error } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const action = searchParams.get("action") as AuthActions;
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const redirectTo = searchParams.get("redirectTo") || routes.root;

  useEffect(() => {
    if (email && token) {
      if (action && AUTH_ACTIONS.includes(action)) setAuth({ action, token });
      login({ email, token }, dispatch);
    } else {
      navigate(redirectTo, { replace: true });
    }
  }, [action, dispatch, email, navigate, setAuth, token, redirectTo]);

  useEffect(() => {
    if (isAuth) {
      navigate(redirectTo, { replace: true });
    }
  }, [isAuth, navigate, redirectTo]);

  useEffect(() => {
    if (error?.response) {
      setAuth({});
      navigate(routes.error, { replace: true });
    }
  }, [error, navigate, setAuth]);

  return <>Loading…</>;
};

export default Authentication;
