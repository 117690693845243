import { faGlassesRound } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Alert from "../../emotion/components/Alert";

interface PublishAlertProps {
  handlePublish: () => Promise<void>;
  handleEdit: () => void;
}

const PublishAlert = ({ handlePublish, handleEdit }: PublishAlertProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    title: t("pages.signable.petition.publishBar.title"),
    publishLabel: t("pages.signable.petition.publishBar.buttonLabels.publish"),
    editLabel: t("pages.signable.petition.publishBar.buttonLabels.edit"),
  };

  return (
    <Alert
      closeButton={false}
      icon={faGlassesRound}
      title={CONTENT.title}
      buttonProps={{
        align: {
          default: "justify",
          lg: "right",
        },
      }}
      buttons={[
        {
          label: CONTENT.publishLabel,
          variant: "primary",
          onClick: handlePublish,
        },
        {
          label: CONTENT.editLabel,
          variant: "outline",
          onClick: handleEdit,
        },
      ]}
    />
  );
};

export default PublishAlert;
