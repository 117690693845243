import { CSSObject, useTheme } from "@emotion/react";

import {
  CardColumn,
  Container,
  FormControl,
  Heading,
  Row,
  SignableCard,
  Tab,
  Tabs,
} from "@Components";
import { Visible } from "@Components/atoms";
import { Backdrop, Pagination } from "@Components/molecules";
import SlidePagination from "@Components/molecules/SlidePagination";
import { PAGINATION_LIMIT } from "@Configs/constants";
import { useBreakpoints } from "@Hooks/useBreakpoints";
import { usePagiSignables } from "@Hooks/useSignable";
import { useTags } from "@Hooks/useTag";
import { marginLeftXl, marginLeftZero, marginTopZero } from "@Styles";
import type { SharedHomeProps, SignableType as SignType } from "@Types";
import type { SignableParams as SignParams } from "@Types/services/typeServiceParams";
import { breakpointDown } from "@Variables/breakpoints";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const headerStyle: CSSObject = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const petitionTagsStyle: CSSObject = {
  width: "12rem",
  marginBottom: "-1rem",
  ...marginLeftXl,
  [breakpointDown["md"]]: {
    width: "100%",
    ...marginLeftZero,
  },
};

type SignVariantValue = SignParams & { variant: SignType };

const limit = PAGINATION_LIMIT;

const Petitions = (props: SharedHomeProps) => {
  //#region Functionality
  const { t } = useTranslation(undefined, {
    keyPrefix: "pages.home.petitions",
  });

  const { tone } = useTheme();

  const { isSmallScreen } = useBreakpoints();

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => setCurrentPage(page);

  const { tags, loading: tagsLoading } = useTags();

  //#region signVariants
  const signVariants = useMemo(
    (): { [key in SignType]: SignVariantValue } => ({
      highlights: {
        highlight: true,
        limit,
        offset: limit * (currentPage - 1),
        type: tone,
        variant: "highlights",
        sort: null,
      },
      mostSigned: {
        type: tone,
        limit,
        offset: limit * (currentPage - 1),
        sort: "signatureCount",
        variant: "mostSigned",
      },
      successes: {
        type: tone,
        limit,
        offset: limit * (currentPage - 1),
        success: true,
        variant: "successes",
        sort: null,
      },
    }),
    [currentPage, tone],
  );
  //#endregion signVariants

  const [activeType, setActiveType] = useState<SignType>("highlights");

  const activeSign = useMemo(
    () => signVariants[activeType],
    [activeType, currentPage, tone],
  );

  const [tagId, setTagId] = useState("");

  const { variant, ...params } = activeSign;

  const { meta, signables, loading } = usePagiSignables({
    params,
    variant,
    tone,
  });

  const handleTabClick = (type: SignType = "highlights") => {
    setActiveType(type);

    setTagId("");
  };

  //#endregion Functionality

  const PetitionTags = () => (
    <div
      id="tagPetitions"
      css={[petitionTagsStyle]}
    >
      <FormControl
        disabled={tagsLoading}
        name="id"
        label={t("tabs.tags") as string}
        displayLabel={false}
        options={tags?.map((tag) => ({
          value: tag._id,
          label: tag.title,
        }))}
        type="select"
        value={tagId}
        setValue={setTagId}
      />
    </div>
  );

  return (
    <section {...props}>
      <Container size="lg">
        <div css={headerStyle}>
          <Heading
            scale={3}
            color="label"
            role="heading"
            aria-level={2}
            css={marginTopZero}
          >
            {t("title")}
          </Heading>
        </div>

        <Tabs<SignType> toolbar={<></>}>
          {tabList.map(({ key, label }) => (
            <Tab
              key={key}
              onTabChange={handleTabClick}
              id={key}
              label={t(label)}
            >
              {isSmallScreen ? (
                <SlidePagination
                  meta={meta}
                  signables={signables}
                  onPageChange={handlePageChange}
                  loading={loading && activeType === key}
                />
              ) : (
                <Backdrop visible={loading && activeType !== key}>
                  <Row>
                    {signables?.map((petition) => {
                      return (
                        <CardColumn
                          key={`${key}-${petition._id}`}
                          span={{ default: 12, sm: 6, md: 4 }}
                        >
                          <SignableCard signable={petition} />
                        </CardColumn>
                      );
                    })}
                  </Row>

                  <Visible when={signables?.length}>
                    <Pagination
                      loading={loading}
                      totalPages={meta?.totalPages ?? 0}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </Visible>
                </Backdrop>
              )}
            </Tab>
          ))}
        </Tabs>
      </Container>
    </section>
  );
};

export default Petitions;

const tabList: {
  key: SignType;
  label: string;
}[] = [
  {
    key: "highlights",
    label: "tabs.highlights",
  },
  {
    key: "mostSigned",
    label: "tabs.mostSigned",
  },
  {
    key: "successes",
    label: "tabs.successes",
  },
];
