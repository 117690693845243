import { useContext, useEffect, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useTranslation } from "react-i18next";
import {
  ScrollRestoration,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  AttachmentImage,
  BannerIllustration,
  Column,
  Container,
  ContentLoader,
  Heading,
  IconLabel,
  InitiativeStage,
  InitiativeType,
  Placeholder,
  Row,
} from "@Components";
import { routes } from "@Configs";
import { usePageTitleContext } from "@Contexts/PageTitleContext";
import { UserContext } from "@Contexts/UserContext";
import {
  displayBlock,
  displayFlex,
  displaySmNone,
  gapMd,
  marginBottomLg,
  marginTopMd,
  marginTopSm,
  paddingXMd,
  paddingXZero,
  paddingYLg,
  paddingZero,
} from "@Styles";
import type { Signable } from "@Types/services/post";
import api from "@Utils/api";
import { setBreakpointProperties } from "@Variables/breakpoints";
import ErrorPage from "../Error";
import Aside from "./Aside";
import ContactAlert from "./ContactAlert";
import More from "./More";
import PublishAlert from "./PublishAlert";
import SignableGauge from "./SignableGauge";
import Tabs from "./Tabs";
import InfoTab from "./Tabs/InfoTab";

const SignablePage = () => {
  const { _id, updateId } = useParams();
  const [signable, setSignable] = useState<Signable>({} as Signable);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isFirstPublish, setIsFirstPublish] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const { isAuth } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitleContext();
  const { t } = useTranslation();
  const { tone, setTone } = useTheme();
  const { trackEvent } = useMatomo();

  const isUpdatesPath = !!matchPath(
    { path: `${routes.updates}/*` },
    location.pathname,
  );

  useEffect(() => {
    if (!_id) {
      return setHasError(true);
    }

    api
      .getSignable(_id)
      .then((response) => {
        const signable = response.data;

        if (signable.type !== tone) {
          setTone(signable.type);
        }

        setLoaded(true);
        setSignable(signable);
      })
      .catch(() => setHasError(true));
  }, [_id, tone]);

  useEffect(() => {
    if (!signable._id) {
      return;
    }

    let pathname = `/${signable.name}`;

    if (isUpdatesPath) {
      pathname += "/updates";

      if (updateId) {
        pathname += `/${updateId}`;
      }
    }

    if (
      signable._id === _id || // If URL is valid, but contains the ID
      (signable.name && signable.name !== _id) // If URL is not the exact slug (especially case)
    ) {
      return navigate(pathname, { replace: true });
    }

    setShareUrl(window.location.href);

    setPageTitle(signable.title);

    trackEvent({
      category: "signable",
      action: "signableLoaded",
      customDimensions: [
        {
          id: 1, // Signable ID
          value: signable._id,
        },
        {
          id: 2, // Signable model
          value: signable.type,
        },
        {
          id: 4, // Logged-in
          value: isAuth ? "true" : "false",
        },
      ],
    });
  }, [
    _id,
    isAuth,
    isUpdatesPath,
    navigate,
    setPageTitle,
    signable,
    trackEvent,
    updateId,
  ]);

  if (hasError) {
    return <ErrorPage />;
  }

  const isPreviewPetition = (signable: Signable) =>
    signable.status === "draft" && signable.type === "petition";

  const pageStyle: CSSObject[] = [paddingYLg];

  const initiativeLabelsContainerStyle: CSSObject[] = [
    gapMd,
    { display: "flex", alignItems: "baseline", "& > *": { flexGrow: 0 } },
  ];

  const attachmentContainerStyle: CSSObject[] = [marginBottomLg];

  const signatureGaugeContainerStyle: CSSObject[] = [
    displayBlock,
    displaySmNone,
    marginBottomLg,
  ];

  const actorsCounterContainerStyle: CSSObject[] = [marginBottomLg];

  const handlePublish = (): Promise<void> =>
    api
      .updateInitiative(signable._id, {
        status: "publish",
        type: signable.type,
      })
      .then(({ data }) => {
        setIsFirstPublish(true);
        setSignable(data);
      })
      .catch((error) => {
        alert(error);
      });

  const handleEdit = (): void =>
    navigate(`${routes.petition.start}/${signable._id}`);

  return (
    <>
      {isPreviewPetition(signable) && (
        <PublishAlert
          handlePublish={handlePublish}
          handleEdit={handleEdit}
        />
      )}

      {!isPreviewPetition(signable) && isFirstPublish && (
        <ContactAlert
          url={shareUrl}
          signableTitle={signable.title}
        />
      )}

      <article css={pageStyle}>
        <Container size="lg">
          <Row
            css={[
              displayFlex,
              { justifyContent: "space-between" },
              setBreakpointProperties("default", paddingXMd),
              setBreakpointProperties("xl", paddingXZero),
            ]}
          >
            <Column
              span={{ default: 12, md: 7 }}
              css={[paddingZero]}
            >
              {Boolean(signable.tag?.title) && (
                <IconLabel
                  signable={signable}
                  type="tag"
                />
              )}

              {signable.type == "initiative" && (
                <div css={initiativeLabelsContainerStyle}>
                  <IconLabel
                    signable={signable}
                    type="location"
                  />

                  <InitiativeType initiative={signable} />

                  <InitiativeStage initiative={signable} />
                </div>
              )}

              <ContentLoader
                loading={!loaded}
                lines={3}
              >
                <Heading
                  scale={3}
                  aria-level={1}
                  css={[marginTopMd, marginBottomLg]}
                  color="primary"
                >
                  {signable.title}
                </Heading>
              </ContentLoader>

              <Heading
                scale={5}
                css={marginBottomLg}
              >
                {loaded ? signable.summary : <Placeholder lines={3} />}
              </Heading>

              <div css={signatureGaugeContainerStyle}>
                <SignableGauge signable={signable} />
              </div>

              <div css={attachmentContainerStyle}>
                <AttachmentImage
                  imageCss={[{ borderRadius: "0.5rem" }]}
                  alt={signable.title}
                  attachment={signable.featuredImage}
                  size="xl"
                />
              </div>

              {signable.type === "petition" && (
                <div css={actorsCounterContainerStyle}>
                  <ContentLoader loading={!loaded}>
                    <IconLabel
                      signable={signable}
                      type="initiators"
                      prefix={true}
                    />
                  </ContentLoader>

                  <div css={marginTopSm}>
                    <ContentLoader loading={!loaded}>
                      <IconLabel
                        signable={signable}
                        type="targets"
                        prefix={true}
                      />
                    </ContentLoader>
                  </div>
                </div>
              )}

              {isPreviewPetition(signable) ? (
                <InfoTab signable={signable} />
              ) : (
                <ContentLoader loading={!loaded}>
                  <Tabs signable={signable} />
                </ContentLoader>
              )}
            </Column>

            {loaded && !isPreviewPetition(signable) && (
              <Aside signable={signable} />
            )}
          </Row>
        </Container>

        <BannerIllustration
          buttonLabel={t("common.banners.startPetition.buttonLabel")}
          buttonLink="/start"
          content={t("common.banners.startPetition.content")}
          illustration="start"
          title={t("common.banners.startPetition.title")}
        />

        <More signable={signable} />

        <ScrollRestoration />
      </article>
    </>
  );
};

export default SignablePage;
