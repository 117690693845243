import { SCREEN_WIDTH } from "@Configs/constants";

const calculateFontSize = (
  minFontSize: number,
  maxFontSize: number,
): string => {
  const maxWidth = SCREEN_WIDTH.max;
  const minWidth = SCREEN_WIDTH.min;

  const fontSize = `clamp(${minFontSize}px, calc(${minFontSize}px + (${maxFontSize} - ${
    minFontSize
  }) * ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth}))), ${maxFontSize}px);`;

  return fontSize;
};

export default calculateFontSize;
