import type { Opacity } from "@Types/typeUtils";

/**
 * Modify the opacity of an RGBA string
 * @param newOpacity - The new opacity value
 * @param rgbaString - The original RGBA string
 * @returns The modified RGBA string
 */
const alterOpacity = (newOpacity: Opacity, rgbaString: string): string => {
  // Parse the original RGBA values
  const matches = rgbaString.match(
    /\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d.]+)\s*\)/,
  );
  if (!matches) {
    console.error("Invalid RGBA string format: ", rgbaString);
    return rgbaString;
  }

  const [, red, green, blue] = matches;

  // Create the modified RGBA string with the new opacity
  const modifiedRgba = `rgba(${red},${green},${blue},${newOpacity})`;

  return modifiedRgba;
};

export default alterOpacity;
