import colors from "./colors";

export type Tone = "initiative" | "petition";
export const THEMES: Tone[] = ["initiative", "petition"];

interface ToneProps {
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    label: string;
    white: string;
  };
}

type Themes = {
  [key in Tone]: ToneProps;
};

const themes: Themes = {
  initiative: {
    colors: {
      white: colors.white,
      primary: colors.blue,
      secondary: colors.cyan,
      tertiary: colors.teal,
      label: colors.blue,
    },
  },
  petition: {
    colors: {
      white: colors.white,
      primary: colors.orange,
      secondary: colors.mauve,
      tertiary: colors.purple,
      label: colors.black,
    },
  },
};

export default themes;
