import { CSSObject } from "@emotion/react";

const transitionProperties = ".3s ease-in-out";

export const rotateAnimation: CSSObject = {
  transform: "rotate(0deg)",
  transition: transitionProperties,

  "&.rotate": {
    transform: "rotate(180deg)",
  },
};

export const rotateBackAnimation: CSSObject = {
  transition: transitionProperties,
};
