import { Signable } from "../types/services/post";

export const getSignatureTarget = (signable: Signable): number => {
  let signatureCount = 0;

  if ("initiative" == signable.type) {
    const signatureTarget = signable.currentStage?.signatureTarget || 0;

    if (signatureTarget) {
      return signatureTarget;
    } else {
      signatureCount = signable.currentStage?.signatureCount || 0;
    }
  } else {
    signatureCount = signable.signatureCount;
  }

  const targets = [
    100, 250, 500,

    1000, 2000, 5000,

    10000, 25000, 50000,

    100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,

    1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000,
    9000000, 10000000,
  ];

  let i;
  for (i = 0; i < targets.length - 1; i++) {
    if (signatureCount < targets[i]) {
      break;
    }
  }
  return targets[i];
};
