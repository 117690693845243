import { Signable } from "../types/services/post";

export const getSignableCountdown = (
  signable: Signable,
): number | undefined => {
  const endDate =
    "initiative" == signable.type
      ? signable.currentStage.endDate
      : signable.endDate;

  if (!endDate) {
    return undefined;
  }

  const dayInMiliseconds = 24 * 60 * 60 * 1000;
  const now = new Date();
  const endDateDate = new Date(endDate);
  const countdown = Math.ceil(
    (endDateDate.getTime() - now.getTime()) / dayInMiliseconds,
  );

  return countdown;
};
