import React from "react";

import { CSSObject } from "@emotion/react";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Heading, Tip } from "@Components";
import { colorPrimary } from "@Styles/textColors";

const fieldHeadingStyle: CSSObject[] = [
  colorPrimary("petition"),
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
];

interface FieldHeadingProps {
  text: string;
  tipAction: React.MouseEventHandler<HTMLDivElement>;
}

const FieldHeading = ({ text, tipAction }: FieldHeadingProps) => {
  const { t } = useTranslation();
  const CONTENT = {
    help: t("components.startPetitionForm.help.buttonLabel"),
  };
  return (
    <Heading
      css={fieldHeadingStyle}
      scale={5}
      aria-level={2}
      role="heading"
    >
      {text}
      <Tip
        text={CONTENT.help}
        icon={faCircleInfo}
        onClick={tipAction}
      />
    </Heading>
  );
};

export default FieldHeading;
