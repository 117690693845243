import React from "react";

import styled, { CSSObject } from "@emotion/styled";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Panel from "../../../../emotion/components/Panel";
import { FieldHelpSetter } from "../../utils/types";

interface FeaturedImageHelpProps {
  setFieldHelp: FieldHelpSetter;
}

const FeaturedImageHelp = ({ setFieldHelp }: FeaturedImageHelpProps) => {
  const { t } = useTranslation();

  const CONTENT = {
    header: t(
      "components.startPetitionForm.help.fieldTips.featuredImage.header",
    ),
    listItems: [
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.items.item1",
      ),
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.items.item2",
      ),
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.items.item3",
      ),
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.items.item4",
      ),
    ],
    examplesTitle: t(
      "components.startPetitionForm.help.fieldTips.featuredImage.examples.title",
    ),
    examples: [
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.examples.example1",
      ),
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.examples.example2",
      ),
      t(
        "components.startPetitionForm.help.fieldTips.featuredImage.examples.example3",
      ),
    ],
  };

  const boldTextStyle: CSSObject = {
    fontWeight: "700",
    fontSize: "16px",
  };

  const BoldParagraph = styled.p(boldTextStyle);

  const StyledList = styled.ul({
    listStyleType: "none",
    paddingLeft: "1em",
  });

  const StyledListItem = styled.li({
    marginLeft: "0",
    paddingLeft: "1rem",
    marginBottom: "1.5rem",
    "&::marker": {
      content: "'•'",
    },
  });

  const StyledExample = styled.li({
    marginLeft: "0",
    paddingLeft: "1rem",
    marginBottom: "0",
    "&::marker": {
      content: "'•'",
    },
  });

  return (
    <Panel
      header={CONTENT.header}
      icon={faInfoCircle}
      onClose={() => {
        setFieldHelp(null);
      }}
    >
      <StyledList>
        {CONTENT.listItems.map((item, index) => (
          <StyledListItem key={index}>{item}</StyledListItem>
        ))}
      </StyledList>
      <BoldParagraph>{CONTENT.examplesTitle}</BoldParagraph>
      <StyledList>
        {CONTENT.examples.map((example, index) => (
          <StyledExample key={index}>{example}</StyledExample>
        ))}
      </StyledList>
    </Panel>
  );
};

export default FeaturedImageHelp;
