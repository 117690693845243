import { IconSVGProps } from "../Icon";

const ActivismIcon = ({ primaryColor, secondaryColor }: IconSVGProps) => (
  <svg
    id="Layer_1_megaphone"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <path
      fill={primaryColor}
      d="M60.5 89.6l-12.8 8.3-17.8-16.1-2.5 1.8c-1 .7-2.5.4-3.2-.8l-2.1-3.5C16.4 82.7 9 80.9 5.5 75.2 2.1 69.4 3.9 62 9.6 58.6L7.5 55c-.7-1.2-.3-2.6.8-3.2L28 42.6 45.1 71l-2.5 1.8 17.9 16.8zM86.4 40.6c-.5.3-1 .5-1.5.7L78.7 31l-6.2-10.3c.4-.3.9-.6 1.3-.9 5.7-3.4 13.2-1.6 16.6 4.1s1.7 13.2-4 16.7z"
    />
    <linearGradient
      id="SVGID_1_megaphone"
      gradientUnits="userSpaceOnUse"
      x1={33.034}
      y1={34.18}
      x2={96.224}
      y2={34.18}
    >
      <stop
        offset={0}
        stopColor={primaryColor}
      />
      <stop
        offset={1}
        stopColor={secondaryColor}
      />
    </linearGradient>
    <path
      d="M93.9 62.7c-26.8-5.3-45.2 4.1-45.2 4.1l-7.8-13L33 41S50 29.1 57.8 3c.5-1.8 2.8-1.9 3.9-.2l10.9 18.1 6.2 10.3L85 41.3l10.9 18.1c1 1.7-.2 3.7-2 3.3z"
      fill="url(#SVGID_1_megaphone)"
    />
  </svg>
);

export default ActivismIcon;
