import { Signable } from "../types/services/post";

export const getCurrentSignatureCount = (signable: Signable): number => {
  const signatureCount =
    "initiative" == signable.type
      ? signable.currentStage.signatureCount
      : signable.signatureCount;

  return signatureCount ? signatureCount : 0;
};
