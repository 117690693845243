import type { Opacity, OpacityColors, OpacityKey } from "@Types/typeUtils";

/**
 * Converts a hex color code to an object containing RGBA strings
 * for different opacity levels.
 *
 * @param hex - The hex color code (e.g., '#F7661E').
 * @param alpha - An array of alpha values for the color (e.g., [0.15, 0.25, 0.5]).
 * @returns An object with RGBA strings mapped to different opacity levels.
 */
const opacityVariants = (
  hex: string,
  alpha: Opacity[] = [0.15, 0.25, 0.5, 0.75, 1],
): OpacityColors => {
  // Remove the '#' if it's present
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values from the hex string
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Map each alpha value to the corresponding RGBA string
  const opacityVariants: Partial<OpacityColors> = {};

  alpha.forEach((a) => {
    const opacityKey: OpacityKey = `${Math.round(a * 100)}` as OpacityKey;
    opacityVariants[opacityKey] = `rgba(${r}, ${g}, ${b}, ${a})`;
  });

  // Ensure all keys are present, even if not all opacities were provided
  const completeOpacityVariants: OpacityColors = {
    "15": opacityVariants["15"] || `rgba(${r}, ${g}, ${b}, 0.15)`,
    "25": opacityVariants["25"] || `rgba(${r}, ${g}, ${b}, 0.25)`,
    "50": opacityVariants["50"] || `rgba(${r}, ${g}, ${b}, 0.5)`,
    "75": opacityVariants["75"] || `rgba(${r}, ${g}, ${b}, 0.75)`,
    "100": opacityVariants["100"] || `rgba(${r}, ${g}, ${b}, 1)`,
  };

  return completeOpacityVariants;
};

export default opacityVariants;
