export const formatDate = (
  rawDate: string,
  language: string,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
): string | null => {
  if (!rawDate) {
    return null;
  }

  const dateObject = new Date(rawDate);

  if (!dateObject) {
    return null;
  }

  return dateObject.toLocaleDateString(language, options);
};
