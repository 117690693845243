import { DivisionComponentProps } from "@Types/components/pageProps";
import type { ReactNode } from "react";

export interface IVisibleProps extends DivisionComponentProps {
  when?: any;
  children: ReactNode | ReactNode[] | JSX.Element | string;
}

const Visible = ({ when = false, children, ...props }: IVisibleProps) => (
  <div {...props}>{when ? children : null}</div>
);

export default Visible;
