import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, UpdateCard } from "@Components";
import { Update } from "@Types/services/post";
import api from "@Utils/api";
import { navBarHeight, spacers } from "@Variables";
import { SignablePageElementProps } from ".";

const SingleUpdateTab = ({
  signable,
  tabsRef,
  updateId,
}: SignablePageElementProps & {
  tabsRef: React.RefObject<HTMLDivElement>;
  updateId?: string;
}) => {
  const [loadingUpdates, setLoadingUpdates] = useState(false);
  const navigate = useNavigate();
  const [singleUpdate, setSingleUpdate] = useState<Update>({} as Update);
  const { t } = useTranslation();

  useEffect(() => {
    if (updateId) {
      api
        .getUpdate(signable._id, updateId)
        .then((response) => {
          setSingleUpdate(response.data);
          if (tabsRef.current) {
            const element = tabsRef.current;
            const navBarHeightPx =
              (parseFloat(navBarHeight) + spacers.md) *
              parseFloat(getComputedStyle(element).fontSize);
            window.scroll({
              top: element.offsetTop - navBarHeightPx,
              behavior: "smooth",
            });
          }
        })
        .catch(() => {
          navigate(`/${signable.name}/updates`, { replace: true });
        })
        .finally(() => setLoadingUpdates(false));
    }
  }, [updateId, navigate, signable, tabsRef]);

  return singleUpdate._id ? (
    <>
      <UpdateCard
        key={`update-${singleUpdate._id}`}
        signableTitle={signable.title}
        update={singleUpdate}
      />

      <Button
        fullWidth={{ default: true }}
        label={t("pages.signable.updates.loadMore")}
        loading={loadingUpdates}
        onClick={() => {
          navigate(`/${signable.name}/updates`);
        }}
        variant="outline"
      />
    </>
  ) : null;
};

export default SingleUpdateTab;
