import * as i18next from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./locales/de.json";
import en from "./locales/en.json";

const namespace = "innnit-ui";

const loadLocales = (i18n: i18next.i18n) => {
  i18n
    .addResourceBundle("de", namespace, de)
    .addResourceBundle("en", namespace, en);
};

const options: i18next.InitOptions = {
  debug: false,
  lng: "de",
  ns: namespace,
  defaultNS: namespace,
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  returnEmptyString: false,
  initImmediate: false,
};

const i18n = i18next.createInstance();
i18n.use(initReactI18next).init(options);

loadLocales(i18n);

export default i18n;
