import { CSSObject } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBoxHeart,
  faGlobe,
  faPaperPlaneTop,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { routes } from "@Configs";
import {
  bodySmStyle,
  colorBlack,
  colorPrimary,
  fontWeightMedium,
  gapMd,
  gapSm,
  marginBottomMd,
  marginBottomMdLg,
  marginBottomXl,
  marginTopLg,
  marginTopLgLg,
  marginTopMdMd,
  marginTopXl,
  paddingBottomXl,
  paddingYXl,
  plainListStyle,
} from "@Styles";
import { breakpointUp, colors } from "@Variables";
import LogoSVG from "../images/LogoSVG";
import Column from "./Column";
import { Container } from "./Container";
import FormControl from "./FormControl";
import { Heading } from "./Heading";
import PrivacyText from "./PrivacyText";
import Row from "./Row";
import { BodySm, IconLi, Link } from "./typography";

const borderTopStyle: CSSObject = {
  borderTop: `1px solid ${colors.gray3}`,
};

const footerCSS: CSSObject[] = [paddingYXl, borderTopStyle];
const footerLowerCSS: CSSObject[] = [paddingBottomXl, borderTopStyle];

const Footer = () => {
  return (
    <div css={footerCSS}>
      <Container size="lg">
        <Row>
          <Column span={{ default: 12, sm: 12, md: 12, lg: 4 }}>
            <Features />
          </Column>

          <Column
            span={{ default: 12, sm: 12, md: 6, lg: 2 }}
            offset={{ lg: 1 }}
          >
            <AboutUs />
          </Column>

          <Column
            span={{ default: 12, sm: 12, md: 6, lg: 4 }}
            offset={{ lg: 1 }}
          >
            <NewsletterWidget />
          </Column>
        </Row>
        <div css={footerLowerCSS}>
          <Row css={{ alignItems: "center" }}>
            <Column
              span={{ default: 12, md: 4, lg: 4 }}
              order={{ md: 2, lg: 3 }}
            >
              <SocialMediaIcons />
            </Column>

            <Column
              span={{ default: 12, md: 8, lg: 4 }}
              order={{ md: 1, lg: 2 }}
            >
              <Info />
            </Column>

            <Column
              span={{ default: 12, md: 12, lg: 4 }}
              order={{ md: 2, lg: 1 }}
            >
              <Colophon />
            </Column>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

const logoContainerCSS: CSSObject[] = [
  marginBottomMd,
  {
    display: "block",
    height: "3rem",
    width: "6.234rem",
  },
];

const columnCSS: CSSObject[] = [marginBottomXl, marginBottomMdLg];
const columnTitleCSS: CSSObject[] = [
  marginTopLg,
  marginBottomMd,
  colorPrimary("initiative"),
];

const Features = () => {
  const { t } = useTranslation();

  const iconItems = [
    { label: t("footer.features.description"), icon: faBoxHeart },
    { label: t("footer.features.server"), icon: faGlobe },
  ];

  return (
    <div css={columnCSS}>
      <div css={logoContainerCSS}>
        <LogoSVG tone="petition" />
      </div>

      {iconItems.map((item) => (
        <IconLi
          key={item.label}
          icon={item.icon}
          variant="primary"
        >
          {item.label}
        </IconLi>
      ))}
    </div>
  );
};

const linkCSS: CSSObject[] = [
  colorPrimary("initiative"),
  {
    textDecoration: "none",
  },
];

const linkListCSS: CSSObject[] = [
  gapSm,
  plainListStyle,
  {
    display: "flex",
    flexDirection: "column",
  },
];

const linkListItemCSS: CSSObject = fontWeightMedium;

const linkListItemLinkCSS: CSSObject[] = [...linkCSS, colorBlack];

const AboutUs = () => {
  const { t } = useTranslation();
  const aboutUsItems = [
    { label: t("footer.column.aboutUs"), href: routes.about },
    { label: t("footer.column.faq"), href: routes.faq },
    {
      label: t("footer.column.press"),
      href: `${routes.press}/#pressekontakt`,
      blank: true,
    },
    {
      label: t("footer.column.organization"),
      href: "//verein.innn.it/",
      blank: true,
    },
  ];

  return (
    <div css={columnCSS}>
      <Heading
        scale={6}
        css={columnTitleCSS}
      >
        {t("footer.column.title")}
      </Heading>
      <ul css={linkListCSS}>
        {aboutUsItems.map((item) => {
          return (
            <li
              css={linkListItemCSS}
              key={item.label}
            >
              <Link
                css={linkListItemLinkCSS}
                rel={item.blank ? "noopener noreferrer" : ""}
                target={item.blank ? "_blank" : ""}
                href={item.href}
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const NewsletterWidget = () => {
  const { t } = useTranslation();

  return (
    <div css={columnCSS}>
      <Heading
        scale={6}
        css={columnTitleCSS}
      >
        {t("footer.newsletter.title")}
      </Heading>

      <FormControl
        tone="initiative"
        type="email"
        name="email"
        rightIcon={faPaperPlaneTop}
      />

      <BodySm>
        <PrivacyText tone="neutral" />
      </BodySm>
    </div>
  );
};

const socialMediaListCSS: CSSObject[] = [
  gapMd,
  plainListStyle,
  marginTopLg,
  {
    display: "flex",
    justifyContent: "space-around",
    [breakpointUp["md"]]: {
      justifyContent: "end",
    },
  },
];

const SocialMediaIcons = () => {
  const { t } = useTranslation();

  const SocialMediaItems = [
    // {
    //   name: "instagram",
    //   label: t("footer.socialMedia.instagram"),
    //   icon: faInstagram,
    //   href: "https://instagram.com/innn.it",
    // },
    {
      name: "facebook",
      label: t("footer.socialMedia.facebook"),
      icon: faFacebook,
      href: "https://facebook.com/HierKannstDuMitbestimmen",
    },
    {
      name: "x",
      label: t("footer.socialMedia.x"),
      icon: faXTwitter,
      href: "https://x.com/innn_it",
    },
    {
      name: "tiktok",
      label: t("footer.socialMedia.tiktok"),
      icon: faTiktok,
      href: "https://tiktok.com/@innn.it",
    },
  ];

  return (
    <ul css={socialMediaListCSS}>
      {SocialMediaItems.map((item) => (
        <li key={`socialMedia-${item.name}`}>
          <Link
            css={linkCSS}
            href={item.href}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              aria-label={item.label}
              fixedWidth
              icon={item.icon as IconProp}
              size="xl"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};

const infoCSS: CSSObject[] = [
  bodySmStyle,
  gapMd,
  plainListStyle,
  marginTopLg,
  {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [breakpointUp["md"]]: {
      flexDirection: "row",
    },
    li: {
      listStyle: "none",
      margin: 0,
    },
  },
];

const Info = () => {
  const { t } = useTranslation();

  const infoItems = [
    {
      label: t("footer.column.dataProtection"),
      href: routes.info.dataProtection,
    },
    {
      label: t("footer.column.imprint"),
      href: routes.info.imprint,
      blank: true,
    },
    {
      label: t("footer.column.termsOfUse"),
      href: routes.info.termsOfUse,
      blank: true,
    },
  ];

  return (
    <ul css={infoCSS}>
      {infoItems.map((item) => {
        return (
          <li key={item.label}>
            <Link
              href={item.href}
              css={linkCSS}
            >
              {item.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const colophonCSS: CSSObject[] = [
  marginTopXl,
  marginTopMdMd,
  marginTopLgLg,
  {
    textAlign: "center",
    [breakpointUp["md"]]: {
      textAlign: "start",
    },
  },
];

const Colophon = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <div css={colophonCSS}>
      <BodySm>{t("footer.colophon", { year })}</BodySm>
    </div>
  );
};
