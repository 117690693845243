import { CSSObject } from "@emotion/react";

export const breakpoints = ["default", "xs", "sm", "md", "lg", "xl"] as const;

export type Breakpoint = (typeof breakpoints)[number];

type BreakpointValues = {
  [key in Breakpoint]: number;
};

export type BreakpointUp = {
  [key in Breakpoint]: string;
};

export const breakpointValues: BreakpointValues = {
  default: 0,
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1200,
  xl: 1600,
};

export const breakpointUp: BreakpointUp = breakpoints.reduce(
  (accumulator, breakpoint) => {
    return {
      ...accumulator,
      [breakpoint]: `@media (min-width: ${breakpointValues[breakpoint]}px)`,
    };
  },
  {} as BreakpointUp,
);

export const breakpointDown: BreakpointUp = breakpoints.reduce(
  (accumulator, breakpoint) => {
    return {
      ...accumulator,
      [breakpoint]: `@media (max-width: ${breakpointValues[breakpoint]}px)`,
    };
  },
  {} as BreakpointUp,
);

export const setBreakpointProperties = (
  breakpoint: Breakpoint,
  properties: CSSObject,
): CSSObject => {
  if ("default" == breakpoint) {
    return properties;
  } else {
    return {
      [breakpointUp[breakpoint]]: properties,
    };
  }
};
