import postsService from "@Services/servicePosts";
import type { Tag } from "@Types/services/post";
import useBaseQuery from "./useBaseQuery";

const useTags = (params = {}) => {
  const service = new postsService();
  return useBaseQuery<Tag[], "tags">({
    queryKey: ["tags", "home"],
    dataName: "tags",
    defaultValue: [],
    service: async () => service.getTags<Tag[]>({ params }),
    onError: (error) => {
      console.error("An error occurred:", error);
    },
  });
};

export { useTags };
