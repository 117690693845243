import { ReactNode } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fontSize2xl } from "@Styles/fontSizes";
import {
  marginBottomLg,
  marginBottomMdLg,
  marginBottomMdZero,
  marginRightMd,
  marginYZero,
} from "@Styles/spacers";
import { h5Style } from "@Styles/typography";
import { breakpointUp } from "@Variables/breakpoints";
import Visible from "./atoms/Visible";
import EllipsedText from "./EllipsedContent";
import { Body, BodyLg } from "./typography";

interface Props {
  /** FontAwesome icon to be displayed with the title. */
  icon?: IconProp;
  /** Icon color to overwrite theme color. */
  iconColor?: string;
  /** Headline title size. */
  size?: "md" | "lg";
  /** Headline subtitle. */
  subtitle?: string;
  /** Text to be displayed below the title. */
  text?: ReactNode;
  /** Title. */
  title: string;
  /** Title color to overwrite theme color. */
  titleColor?: string;
  /** Title direction. Applies only for mobile. */
  titleDirection?: "row" | "column";
  /** If title should be ellipsed. */
  titleEllipsis?: boolean;

  titleCSS?: CSSObject;
  iconCSS?: CSSObject;
}

/**
 * Displays a title with icon, subtitle and text, to be used as headline and modal text.
 */
const Headline = ({
  icon,
  iconColor,
  subtitle,
  size = "md",
  text,
  title,
  titleColor,
  titleDirection = "column",
  titleEllipsis = false,
  titleCSS,
  iconCSS,
}: Props) => {
  const { colors: swatchColors } = useTheme();
  const titleTone = titleColor ? titleColor : swatchColors.primary.main;
  const iconTone = iconColor ? iconColor : swatchColors.primary.main;

  const iconStyle: CSSObject = {
    ...marginRightMd,
    ...iconCSS,
    color: iconTone || titleTone,
  };

  const subtitleStyle: CSSObject = {
    ...marginBottomLg,
    fontWeight: 700,
  };

  let titleMargin = [marginBottomMdZero];
  if (text) {
    titleMargin = [marginBottomLg, marginBottomMdLg];
  }

  const titleStyle: CSSObject[] = [
    h5Style,
    marginYZero,
    ...titleMargin,
    {
      color: titleTone,
      display: "flex",
    },
    {
      alignItems: "flex-start",
      flexDirection: titleDirection,
    },
    {
      [breakpointUp["md"]]: {
        ...(size === "lg" && fontSize2xl),
        alignItems: "center",
        flexDirection: "row",
      },
    },
    { ...titleCSS },
  ];

  return (
    <div>
      <Visible when={!!subtitle}>
        <Body css={subtitleStyle}>{subtitle}</Body>
      </Visible>

      <div css={titleStyle}>
        {icon && (
          <FontAwesomeIcon
            aria-hidden={true}
            css={iconStyle}
            fixedWidth
            icon={icon}
            size="lg"
          />
        )}

        <EllipsedText
          text={title}
          lineClamp={titleEllipsis ? { default: 3, md: 3 } : {}}
        />
      </div>

      <Visible when={!!text}>
        <BodyLg>{text}</BodyLg>
      </Visible>
    </div>
  );
};

export default Headline;
