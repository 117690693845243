import React, { useCallback, useEffect, useId, useRef, useState } from "react";

import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { navBarHeight, spacers } from "@Variables";

import {
  navItemsContainerStyle,
  navItemsStyle,
  tabsButtonStyle,
  tabsContainerStyle,
  tabsToolbarContainerStyle,
} from "@Appearances/components/styleTab";
import { useTheme } from "@emotion/react";
import { TabProps } from "./Tab";
import { Visible } from "./atoms";

export interface TabsProps<T = string> {
  children:
    | React.ReactElement<TabsProps<T>>
    | Array<React.ReactElement<TabsProps<T>>>;

  toolbar?: React.ReactNode | React.ReactNode[];
}

/** Displays content with tab navigation. Should contain a tab component for each content block. Navigation is created automatically. */
const Tabs = <T extends string>({ children, toolbar }: TabsProps<T>) => {
  const { colors } = useTheme();

  const [activeTabId, setActiveTabId] = useState("");

  const id = useId();

  const location = useLocation();

  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const switchToTab = useCallback(
    (id: T) => {
      let path: string | undefined;

      React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }

        const item = child as React.ReactElement<TabProps<T>>;
        if (id == item.props.id) {
          path = item.props.path;
        }
      });

      if (!path) {
        path = `#${id}`;
      }
      navigate(path, { preventScrollReset: true });
    },
    [children, navigate],
  );

  useEffect(() => {
    let tabName = location.hash.slice(1) as T;

    if (!tabName) {
      React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }

        const item = child as React.ReactElement<TabProps<T>>;
        if (matchPath({ path: item.props.path || "" }, location.pathname)) {
          tabName = item.props.id;
        }
      });
    }

    if (tabName && tabName !== activeTabId) {
      setActiveTabId(tabName);
      if (ref.current) {
        const element = ref.current;
        const navBarHeightPx =
          (parseFloat(navBarHeight) + spacers.md) *
          parseFloat(getComputedStyle(element).fontSize);
        window.scroll({
          top: element.offsetTop - navBarHeightPx,
          behavior: "smooth",
        });
      }
    }
  }, [activeTabId, children, location, switchToTab]);

  let hasActiveTab = false;
  const navItems = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    const item = child as React.ReactElement<TabProps<T>>;
    const active =
      activeTabId == item.props.id ||
      (!activeTabId && !hasActiveTab && item.props.active);

    if (active) {
      hasActiveTab = true;
    }

    return React.cloneElement(item, {
      ...item.props,
      active,
      switchToTab,
    });
  });

  const hasToolbar =
    !!toolbar || (Array.isArray(toolbar) && toolbar.length !== 0);

  return (
    <div ref={ref}>
      <div css={[tabsContainerStyle(hasToolbar)]}>
        <div css={tabsButtonStyle}>
          {navItems.map((navItemsGroup, index) => (
            <div
              key={`tabs-${id}-${index.toString()}`}
              css={navItemsContainerStyle(colors)}
            >
              <div css={navItemsStyle}>{navItemsGroup}</div>
            </div>
          ))}
        </div>

        <Visible when={hasToolbar}>
          <div css={tabsToolbarContainerStyle}>{toolbar}</div>
        </Visible>
      </div>

      {navItems.map((item) => {
        return (
          <section
            key={`tabs-${id}-${item.props.id}`}
            style={{
              display: (
                activeTabId ? activeTabId === item.props.id : item.props.active
              )
                ? "block"
                : "none",
            }}
          >
            {item.props.children}
          </section>
        );
      })}
    </div>
  );
};

export default Tabs;
