import { useContext } from "react";

import { PageTitleContext } from "@Contexts/PageTitleContext";
import type { CSSObject } from "@emotion/react";
import usePage from "@Hooks/usePage";
import { paddingY2xl } from "@Styles";
import Donation from "./Donation";
import Hero from "./Hero";
import AboutUs from "./HomeAboutUs";
import Newsletter from "./Newsletter";
import Petitions from "./Petitions";
import Reasons from "./Reasons";
import Successes from "./Successes";

const customCss: CSSObject = paddingY2xl;

const Home = () => {
  const { setPageTitle } = useContext(PageTitleContext);

  const { page, loading } = usePage({
    id: "home",
    onError: (error) => console.error("An error occurred:", error),
    onSuccess: (data) => setPageTitle(data.title),
  });

  return (
    <>
      <Hero
        loaded={!loading}
        page={page}
      />

      <Petitions css={customCss} />

      <AboutUs css={customCss} />

      <Successes css={customCss} />

      <Donation css={customCss} />

      <Newsletter css={customCss} />

      <Reasons
        loaded={!loading}
        page={page}
        css={customCss}
      />
    </>
  );
};

export default Home;
