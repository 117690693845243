import React from "react";

import ContentHelp from "./ContentHelp";
import FeaturedImageHelp from "./FeaturedImageHelp";
import HashtagHelp from "./HashtagHelp";
import TargetsHelp from "./TargetsHelp";
import TitleHelp from "./TitleHelp";
import { FieldHelpSetter, FieldsWithHelp } from "../../utils/types";

interface FieldHelpProps {
  fieldName: FieldsWithHelp;
  setFieldHelp: FieldHelpSetter;
}

const helpComponentsDictionary: Record<
  FieldsWithHelp,
  React.FunctionComponent<{ setFieldHelp: FieldHelpSetter }>
> = {
  title: TitleHelp,
  content: ContentHelp,
  hashtag: HashtagHelp,
  featuredImage: FeaturedImageHelp,
  targets: TargetsHelp,
};

const FieldHelp = ({ fieldName, setFieldHelp }: FieldHelpProps) => {
  const Help = helpComponentsDictionary[fieldName];
  return <Help setFieldHelp={setFieldHelp} />;
};

export default FieldHelp;
