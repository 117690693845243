import React from "react";

import { css as CSS, CSSObject, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import {
  displayH1Style,
  displayH2Style,
  displayH3Style,
  displayH4Style,
  displayH5Style,
} from "@Styles/displayHeadings";
import { marginYZero } from "@Styles/spacers";
import {
  h1Style,
  h2Style,
  h3Style,
  h4Style,
  h5Style,
  h6Style,
  lineClampStyle,
} from "@Styles/typography";
import type { Typography } from "@Types/typesTypography";

export type HeadingScale = 1 | 2 | 3 | 4 | 5 | 6;
export interface HeadingProps
  extends Omit<React.HTMLAttributes<HTMLElement>, "color">,
    Typography {
  children: React.ReactNode;
  css?: CSSObject | CSSObject[];
  /** Heading size scaling. */
  scale: HeadingScale;
  /** Heading variant */
  variant?: "default" | "display";
}

const sizes: {
  [key in HeadingProps["scale"]]: {
    element: keyof JSX.IntrinsicElements;
    defaultStyle: CSSObject | CSSObject[];
    displayStyle: CSSObject | CSSObject[];
  };
} = {
  1: {
    element: "h1",
    defaultStyle: h1Style,
    displayStyle: displayH1Style,
  },
  2: {
    element: "h2",
    defaultStyle: h2Style,
    displayStyle: displayH2Style,
  },
  3: {
    element: "h3",
    defaultStyle: h3Style,
    displayStyle: displayH3Style,
  },
  4: {
    element: "h4",
    defaultStyle: h4Style,
    displayStyle: displayH4Style,
  },
  5: {
    element: "h5",
    defaultStyle: h5Style,
    displayStyle: displayH5Style,
  },
  6: {
    element: "h6",
    defaultStyle: h6Style,
    displayStyle: displayH5Style,
  },
};

/**
 * Renders a heading according to the declared scale.
 * If no role or ARIA level is declared, the element is considered a styled element, and not a hierarchical element.
 * When the element has no role, no y-margins are set.
 */
const Heading = ({
  css,
  scale,
  variant = "default",
  children,
  color = "primary",
  align,
  lineClamp,
  ...props
}: HeadingProps) => {
  const { colors } = useTheme();

  const role = props.role
    ? props.role
    : props["aria-level"]
      ? "heading"
      : "none";

  if (role === "heading" && !props["aria-level"]) {
    props["aria-level"] = scale;
  }

  const marginStyle = role === "none" ? marginYZero : {};

  const headingStyle = sizes[scale][`${variant}Style`] as CSSObject;

  const headingCss = CSS([
    headingStyle,
    marginStyle,
    css,
    { color: colors[color][100], textAlign: align },
  ]);

  const lineHeight = variant === "default" ? "heading" : "display";
  const lineClampStyles: CSSObject[] = lineClampStyle({
    lineClamp,
    lineHeight,
  });

  const StyledComponent = styled(sizes[scale].element)`
    ${headingCss}
    ${lineClampStyles}
  `;

  return (
    <StyledComponent
      aria-level={props["aria-level"]}
      css={[marginStyle, css]}
      role={role}
      color={color}
      {...props}
    >
      {children}
    </StyledComponent>
  );
};

export { Heading };
