// src/queryClient.ts
import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      // cacheTime:"", // 10 minutes
      gcTime: 5 * 60 * 1000, // 5 minutes

      refetchOnWindowFocus: true,
      retry: 3,
      retryOnMount: false,
      retryDelay: 1000,
    },
    mutations: {
      onError: (error: any) => {
        // Global error handling for mutations
        console.error("Mutation error:", error);
      },
    },
  },
});

export default queryClient;
